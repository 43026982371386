import { JocGatewayAPI } from '@joc/api-gateway';
import axios from 'axios';
import { urls } from './appUrls';

const instance = axios.create();

instance.interceptors.request.use(
	async req => {
		const token = localStorage.getItem('accessToken');
		const orgId = localStorage.getItem('organisationId');
		if (token) {
			req.headers.Authorization = `Bearer ${token}`;
		}
		if (orgId) {
			req.headers['x-organization-id'] = orgId.toString();
		}
		return req;
	},
	err => {
		console.log(err);
		throw err;
	}
);

instance.interceptors.response.use(
	async res => {
		return res;
	},
	err => {
		console.log('ERROR', err);
		if (err?.response?.status === 401) {
			localStorage.clear();
			window.location.href = `${window.location.origin}${urls.accessDenied}`;
		}
		console.dir(err);
		throw err;
	}
);

const API = new JocGatewayAPI(process.env.REACT_APP_JOC_API, instance);

export { API };
