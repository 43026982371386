import { Dispatch } from 'redux';
import {
	GET_INVITES_ALL,
	GET_INVITES_PENDING,
	GET_INVITES_JOINED,
	RESET_INVITES,
	SET_ERROR,
	DELETE_INVITE,
	UPDATE_INVITE,
} from 'redux/actions-types';
import { API } from 'core/API';
import {
	DeleteInvitationsRequest,
	INVITATION_ACCEPTED_STATUS,
	ResendInvitationsRequest,
	SearchInvitedUserRequest,
} from '@joc/api-gateway';

export const getInvitesAll =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const invitesResponse = await API.invitedUserSearch(
				undefined,
				SearchInvitedUserRequest.fromJS({
					...searchBody,
					isAccepted: [INVITATION_ACCEPTED_STATUS.Accepted, INVITATION_ACCEPTED_STATUS.NotAccepted],
				})
			);

			dispatch({
				type: GET_INVITES_ALL,
				payload: { records: invitesResponse.records, total: invitesResponse.total },
			});
		} catch (error: any) {
			dispatch({
				type: SET_ERROR,
				payload: { state: true, message: error?.response?.message || error?.message },
			});
		}
	};

export const getInvitesJoined =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const invitesResponse = await API.invitedUserSearch(
				undefined,
				SearchInvitedUserRequest.fromJS({ ...searchBody, isAccepted: [INVITATION_ACCEPTED_STATUS.Accepted] })
			);
			const invitesResponseJoined = invitesResponse.records.filter(ivent => ivent.isAccepted === true);
			dispatch({
				type: GET_INVITES_JOINED,
				payload: { records: invitesResponseJoined, total: invitesResponseJoined.length },
			});
		} catch (error: any) {
			dispatch({
				type: SET_ERROR,
				payload: { state: true, message: error?.response?.message || error?.message },
			});
		}
	};

export const getInvitesPending =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const invitesResponse = await API.invitedUserSearch(
				undefined,
				SearchInvitedUserRequest.fromJS({ ...searchBody, isAccepted: [INVITATION_ACCEPTED_STATUS.NotAccepted] })
			);
			const invitesResponsePending = invitesResponse.records.filter(ivent => ivent.isAccepted === false);
			dispatch({
				type: GET_INVITES_PENDING,
				payload: { records: invitesResponsePending, total: invitesResponsePending.length },
			});
		} catch (error: any) {
			dispatch({
				type: SET_ERROR,
				payload: { state: true, message: error?.response?.message || error?.message },
			});
		}
	};

export const resetInvites =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: RESET_INVITES,
		});
	};

export const updateInvite =
	(idOrg: string, idInvite: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		console.log('Action!!!!!!!!!!!!!!!!!');
		try {
			await API.resendInvitations(ResendInvitationsRequest.fromJS({ ids: [idInvite] }));
			dispatch({ type: UPDATE_INVITE, payload: [idInvite] });
		} catch (error: any) {
			throw new Error(error.message);
		}
	};

export const deleteInvite =
	(idOrg: string, idInvite: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const delParm = {
				ids: [idInvite],
			};
			await API.deleteInvites(idOrg, DeleteInvitationsRequest.fromJS(delParm));
			dispatch({ type: DELETE_INVITE, payload: [idInvite] });
		} catch (error: any) {
			throw new Error(error.message);
		}
	};
