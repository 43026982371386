import { FC, useCallback, useEffect, useState } from 'react';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ISearchUsersForAdminRequest, PaginationRequest, SearchUsersForAdminRequest } from '@joc/api-gateway';
import { Store } from 'redux/root';
// core
import { INITIAL_PAGINATION } from 'core/constants';
// components
import TabNav from 'shared/components/TabNav';
import TabNavItem from 'shared/components/TabNav/TabNavItem';
import {
	getCompaniesUsersAll,
	getCompaniesUsersCoordinators,
	getCompaniesUsersMarketers,
	resetCompaniesUsers,
	getCompaniesUsersAdmins,
} from 'redux/companiesUsers-service/actions';
import { resetError } from 'redux/error-service/action';
import UsersTable from '../UsersTable';
// styles
import styles from './UserListContent.module.scss';

const parseQueryString = (search: string): SearchUsersForAdminRequest => {
	const searchParams: any = qs.parse(search, { ignoreQueryPrefix: true });
	return searchParams;
};

enum TABS {
	ALL,
	ADMIN,
	COORDINATOR,
	MARKETER,
}

const UserListContent: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const initTab = () => {
		if (location.search === '') return TABS.ALL;
		if (location.search.includes('admin')) return TABS.ADMIN;
		if (location.search.includes('coordinator')) return TABS.COORDINATOR;
		if (location.search.includes('marketer')) return TABS.MARKETER;
	};
	const [activeTab, setActiveTab] = useState(initTab);
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const [isLoading, setIsLoading] = useState(true);
	const [requestBody, setRequestBody] = useState<ISearchUsersForAdminRequest>();

	const usersRecordsTotal = useSelector((store: Store) => store.companiesUsersRedux.total);

	const getOrganizationUsersRequest = useCallback(async () => {
		setIsLoading(true);
		try {
			if (location.search.includes('admins')) {
				await dispatch(
					getCompaniesUsersAdmins({ ...requestBody, pagination: PaginationRequest.fromJS(pagination) })
				);
			} else if (location.search.includes('coordinators')) {
				await dispatch(
					getCompaniesUsersCoordinators({ ...requestBody, pagination: PaginationRequest.fromJS(pagination) })
				);
			} else if (location.search.includes('marketers')) {
				await dispatch(
					getCompaniesUsersMarketers({ ...requestBody, pagination: PaginationRequest.fromJS(pagination) })
				);
			} else {
				await dispatch(
					getCompaniesUsersAll({ ...requestBody, pagination: PaginationRequest.fromJS(pagination) })
				);
			}
			setIsLoading(false);
		} catch (error: any) {
			setIsLoading(false);
			console.error(error);
			// throw error;
		}
	}, [pagination, requestBody]);

	useEffect(() => {
		if (requestBody !== undefined) getOrganizationUsersRequest();
	}, [getOrganizationUsersRequest]);

	useEffect(() => {
		dispatch(resetCompaniesUsers());
		// if (!location.search.length) return;
		setPagination(INITIAL_PAGINATION);
		const data = parseQueryString(location.search);
		setRequestBody(data);
	}, [location.search]);

	useEffect(() => {
		// if without volunteers should to drop
		return () => {
			dispatch(resetCompaniesUsers());
			dispatch(resetError());
		};
	}, []);

	return (
		<div className={styles.content__wrapper}>
			<div className={styles.content}>
				<TabNav>
					<TabNavItem
						parentClassName={styles.tabNavItem}
						counter={usersRecordsTotal}
						title="All"
						activeTab={activeTab === TABS.ALL}
						clickHandler={() => {
							history.push({ search: '' });
							setActiveTab(TABS.ALL);
							dispatch(resetCompaniesUsers());
						}}
					/>
					<TabNavItem
						parentClassName={styles.generateGroup}
						counter={usersRecordsTotal || 0}
						title="Admins"
						activeTab={activeTab === TABS.ADMIN}
						clickHandler={() => {
							history.push({ search: '?admins' });
							setActiveTab(TABS.ADMIN);
							dispatch(resetCompaniesUsers());
						}}
					/>
					<TabNavItem
						parentClassName={styles.generateGroup}
						counter={usersRecordsTotal || 0}
						title="Coordinators"
						activeTab={activeTab === TABS.COORDINATOR}
						clickHandler={() => {
							history.push({ search: '?coordinators' });
							setActiveTab(TABS.COORDINATOR);
							dispatch(resetCompaniesUsers());
						}}
					/>
					<TabNavItem
						parentClassName={styles.generateGroup}
						counter={usersRecordsTotal || 0}
						title="Marketers"
						activeTab={activeTab === TABS.MARKETER}
						clickHandler={() => {
							history.push({ search: '?marketers' });
							setActiveTab(TABS.MARKETER);
							dispatch(resetCompaniesUsers());
						}}
					/>
				</TabNav>
				<UsersTable setPagination={setPagination} isLoading={isLoading} />
			</div>
		</div>
	);
};

export default UserListContent;
