import React, { FC } from 'react';
import cx from 'classnames';
import TabNavItemActions from './TabNavItemActions';
import styles from './TabNavItem.module.scss';

type TabNavItemPropsType = {
	counter?: number | null;
	title: string;
	activeTab?: boolean;
	clickHandler: () => void;
	parentClassName?: string;
	isSmartGroup?: boolean;
	groupId?: number;
};

const TabNavItem: FC<TabNavItemPropsType> = ({
	title,
	counter,
	clickHandler,
	activeTab,
	parentClassName,
	isSmartGroup,
	groupId,
}: TabNavItemPropsType) => {
	return (
		<div
			className={cx(styles.item, activeTab && styles.active, parentClassName && parentClassName, {
				[styles.smart_group]: isSmartGroup,
			})}
			onClick={clickHandler}
			title={title}
		>
			{isSmartGroup && <TabNavItemActions groupId={groupId} />}
			<span className={cx(styles.title, { [styles.smart_group]: isSmartGroup })}>{title}</span>
			{(!!counter || counter === 0) && <div className={styles.item__counter}>{counter}</div>}
		</div>
	);
};

export default TabNavItem;
