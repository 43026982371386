import { messaging } from 'index';

export const fireBaseGetToken = () => {
	// export const fireBaseGetToken = (setTokenFound: React.Dispatch<React.SetStateAction<boolean>>) => {
	return new Promise(resolve => {
		messaging
			.getToken({
				vapidKey: process.env.REACT_APP_PUSH_TOKEN,
			})
			.then(currentToken => {
				if (!currentToken) {
					console.log('No registration token available. Request permission to generate one.');
					// setTokenFound(false);
					// Track the token -> client mapping, by sending to backend server
					// show on the UI that permission is secured
				} else {
					console.log('current token for client: ', currentToken);
					// setTokenFound(true);
					resolve(currentToken);
					// shows on the UI that permission is required
				}
			})
			.catch(err => {
				console.log('An error occurred while retrieving token. ', err);
				// catch error while creating client token
			});
	});
};

export const fireBaseMessageListener = () =>
	new Promise(resolve => {
		messaging.onMessage(payload => {
			resolve(payload);
		});
	});
