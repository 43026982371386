import { Quill } from 'react-quill';

const Break = Quill.import('blots/break');

export class CustomBreak extends Break {
	static tagName = 'br'; // Override the tag if needed

	static create() {
		const node = super.create();
		// Apply inline styles if needed, e.g. for spacing
		node.style.margin = 'unset';
		return node;
	}
}
