import React, { ChangeEvent, FC } from 'react';
import { FieldHookConfig, useField } from 'formik';

// API & helpers
import { API } from '../../../../core/API';
import { getUserPhotoSrc, sendFilesToS3Bucket } from '../../../../core/functions';
import { defaultPhotoTypes, getIsUploadedPhotoValid } from './helpers';

// images
import { ReactComponent as UploadIcon } from '../../../../assets/image/upload-lg.svg';

// styles
import cx from 'classnames';
import styles from './EditPlaceholderDialog.module.scss';
import InputErrorHint from '../../../../shared/components/InputErrorHint';

// types
type PlaceholderImageProps = FieldHookConfig<string> & {
	label: string;
	placeholder?: string;
	maxMegabyteSize?: number;
	toS3bucket?: boolean;
};

const PlaceholderImageField: FC<PlaceholderImageProps> = ({ label, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const changePhotoHandler = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		event.preventDefault();
		const { target } = event;

		try {
			if (target && target.files && target.files[0]) {
				if (getIsUploadedPhotoValid(target.files[0], props?.maxMegabyteSize || 5)) {
					if (props.toS3bucket) {
						const [responseFileName] = await sendFilesToS3Bucket([target.files[0]], true);

						if (responseFileName?.length) {
							target.value = '';
							await helpers.setValue(responseFileName);
							helpers.setTouched(true).then();
						}
					} else {
						const fileName = target.files[0].name;
						const photo = target.files[0];
						const uploadData = { data: photo, fileName };
						const { fileName: responseFileName } = await API.uploadFile(uploadData);

						if (responseFileName?.length) {
							target.value = '';
							await helpers.setValue(responseFileName);
							helpers.setTouched(true).then();
						}
					}
				} else {
					helpers.setError(
						`File Size Limit: Please ensure the file does not exceed ${props?.maxMegabyteSize || 5} MB.`
					);
				}
			}
		} catch (error: any) {
			helpers.setError(error.message);
		}
	};

	const deletePhoto = async () => {
		await helpers.setValue('');
	};

	const selectPhotoHandler = () => {
		if (inputRef?.current) {
			inputRef.current.click();
		}
	};

	return (
		<>
			{field.value ? (
				<div className={styles.dialog__form_item}>
					<label className={styles.dialog__form_label} htmlFor="imagePath">
						{label}
						<span className={styles.dialog__form_required}>*</span>
					</label>
					<div className={styles.dialog__form_item__image_hover} onClick={deletePhoto}>
						<img
							className={cx(styles.dialog__form_item__image)}
							src={getUserPhotoSrc(field.value)}
							alt={props.placeholder || ''}
						/>
					</div>
				</div>
			) : (
				<div className={styles.dialog__form_item}>
					<label className={styles.dialog__form_label} htmlFor="imagePath">
						{label}
						<span className={styles.dialog__form_required}>*</span>
					</label>
					<UploadIcon className={styles.dialog__form_item__image} onClick={selectPhotoHandler} />
					{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
				</div>
			)}
			<input
				ref={inputRef}
				id="imagePath"
				type="file"
				accept={defaultPhotoTypes}
				hidden={true}
				onChange={changePhotoHandler}
			/>
		</>
	);
};

export default PlaceholderImageField;
