import React, { ReactNode } from 'react';
import styles from './TableMain.module.scss';

type TableMainProps = {
	children: ReactNode;
	ref?: React.RefObject<HTMLDivElement>;
};

const TableMain = React.forwardRef<HTMLDivElement, TableMainProps>(({ children }: TableMainProps, ref) => {
	return (
		<div ref={ref} className={styles.main}>
			{children}
		</div>
	);
});

TableMain.displayName = 'TableMain';

export default TableMain;
