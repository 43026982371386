import React, { FC, useState } from 'react';
import cx from 'classnames';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import styles from './PopupCongrats.module.scss';

type PopupCongratsProps = {
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	setIsSubmited: React.Dispatch<React.SetStateAction<boolean>>;
	setIsShowUpdatePopup?: React.Dispatch<React.SetStateAction<boolean>>;
	createOneMoreClickHandler?: () => void;
};

const PopupCongrats: FC<PopupCongratsProps> = ({
	setIsShowPopup,
	setIsSubmited,
	setIsShowUpdatePopup,
	createOneMoreClickHandler,
}: PopupCongratsProps) => {
	const [isClosePopup, setIsClosePopup] = useState<boolean>(false);

	const doneButtonClickHandler = () => {
		setIsClosePopup(true);
		setTimeout(() => setIsShowPopup(false), 200);
	};

	const editButtonClickHandler = () => {
		setIsClosePopup(true);
		setTimeout(() => {
			setIsSubmited(false);
			setIsShowUpdatePopup && setIsShowUpdatePopup(true);
		}, 200);
	};

	const createClickHandler = () => {
		setIsClosePopup(true);
		createOneMoreClickHandler && setTimeout(createOneMoreClickHandler, 200);
	};

	return (
		<div
			className={cx(styles.container, {
				[styles.close]: isClosePopup,
			})}
		>
			<Appeal isHeightDisable>
				<div className={styles.appeal}>
					<span className="title">Congrats!</span>
					<span className={styles.subtitle}>Oppotunity has been updated successfully.</span>
					{/* <span className={styles.subtitle}>New Chesed Opportuinty has been created.</span>
					<span className={styles.subtitle}>
						Now, you can find it under vacancies on the Opportuinty tab.
					</span> */}
				</div>
			</Appeal>
			<div className={styles.options}>
				<ButtonDefault classList={['lg', 'primary']} title="Done" clickHandler={doneButtonClickHandler} />
				<ButtonDefault
					classList={['lg', 'secondary']}
					title="View or edit"
					clickHandler={editButtonClickHandler}
				/>
				{/* <ButtonDefault
					classList={['lg', 'secondary']}
					title="Create one more"
					clickHandler={createClickHandler}
				/> */}
			</div>
		</div>
	);
};

export default PopupCongrats;
