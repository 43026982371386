import React, { FC } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type SnackbarSuccessProps = {
	isOpenSnackbar: boolean;
	setIsOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
	text: string;
};

const SnackbarSuccess: FC<SnackbarSuccessProps> = ({
	isOpenSnackbar,
	setIsOpenSnackbar,
	text,
}: SnackbarSuccessProps) => {
	return (
		<Snackbar open={isOpenSnackbar} autoHideDuration={2000} onClose={() => setIsOpenSnackbar(false)}>
			<Alert onClose={() => setIsOpenSnackbar(false)} severity="success">
				{text}
			</Alert>
		</Snackbar>
	);
};

export default SnackbarSuccess;
