import { FC, useMemo, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { saveAs } from 'file-saver';
// API
import { API } from 'core/API';
// types
import {
	ISearchVolunteersByGroupRequest,
	ISearchVolunteersRequest,
	SearchVolunteersAllRequest,
	SearchVolunteersRequest,
	VolunteerUserInfo,
} from '@joc/api-gateway';
import { BroadcastEnum } from 'shared/components/BroadcastInputTextPopup/helpers';
// redux
import { setError } from 'redux/error-service/action';
import { selectorGetUserAddress } from 'redux/user-service/selector';
import { selectorGetVolunteersAllRecords } from 'redux/volunteer-service/selector';
// functions
import { parseQueryStringVolunteersSearch } from 'core/functions';
// components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import ButtonDownload from 'shared/components/Buttons/ButtonDownload';
import BroadcastInputTextPopup from 'shared/components/BroadcastInputTextPopup';
// styles
import styles from './Actions.module.scss';

const Actions: FC = () => {
	const location = useLocation();

	const dispatch = useDispatch();

	const [isShowMessageInputPopup, setIsShowMessageInputPopup] = useState(false);

	const volunteers = useSelector(selectorGetVolunteersAllRecords);
	const userAddress = useSelector(selectorGetUserAddress);

	const searchBody: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest = useMemo(
		() => parseQueryStringVolunteersSearch(location.search, userAddress),
		[location.search]
	);

	const getVolunteersFullListRequest = async (): Promise<VolunteerUserInfo[] | undefined> => {
		try {
			const volunteersTotal = (
				await API.adminSearchVolunteers(SearchVolunteersRequest.fromJS({ pagination: { skip: 0, take: 1 } }))
			).total;

			let volunteerPaginationSkips: number[] = [];
			for (let volunteerPagination = 0; volunteerPagination < volunteersTotal; volunteerPagination += 50) {
				volunteerPaginationSkips = [...volunteerPaginationSkips, volunteerPagination];
			}

			return (
				await Promise.all(
					volunteerPaginationSkips.map(async skip =>
						API.getAllVolunteersByAdmin(
							SearchVolunteersAllRequest.fromJS({ ...searchBody, pagination: { skip, take: 50 } })
						)
					)
				)
			).flat();
		} catch (error: any) {
			dispatch(setError(error?.response?.message || error.message, error.code));
		}
	};

	const buttonDownloadClickHandler = async () => {
		try {
			const accessToken = localStorage.getItem('accessToken');
			if (!accessToken) throw new Error('Sorry, we can`t find your access token');
			const volunteersResponse = await axios(`${process.env.REACT_APP_JOC_API}/volunteer/adminExport`, {
				method: 'POST',
				responseType: 'blob',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				data: searchBody,
			});

			saveAs(volunteersResponse.data, `Volunteers_list`);
		} catch (error: any) {
			dispatch(setError(error.message, error.code));
		}
	};

	const showMessageInputPopupHandler = () => setIsShowMessageInputPopup(true);
	const closeMessageInputPopupHandler = () => setIsShowMessageInputPopup(false);

	return (
		<div className={styles.wrapper}>
			<ButtonDownload clickHandler={buttonDownloadClickHandler} disabled={!volunteers.length} />
			<ButtonDefault
				title="Broadcast"
				classList={['primary', 'sm']}
				clickHandler={showMessageInputPopupHandler}
				disabled={!volunteers.length}
			/>
			{isShowMessageInputPopup && (
				<BroadcastInputTextPopup
					broadcastType={BroadcastEnum.Volonteers}
					closePopupHandler={closeMessageInputPopupHandler}
				/>
			)}
		</div>
	);
};

export default Actions;
