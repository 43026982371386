import {
	IInvitedUserResponse,
	InvitedUserResponse,
	IResendInvitationsRequest,
	ResendInvitationsRequest,
} from '@joc/api-gateway';
import update from 'immutability-helper';
import {
	DELETE_INVITE,
	GET_INVITES_ALL,
	GET_INVITES_JOINED,
	GET_INVITES_PENDING,
	RESET_INVITES,
	UPDATE_INVITE,
} from 'redux/actions-types';
import { IInvitesPayload, IInvitesReducer, InviteActionTypes } from './types';

const initState: IInvitesReducer = {
	invites: { records: [], total: 0 },
};

const invitesReducer = (state = initState, action: InviteActionTypes): IInvitesReducer => {
	const { type, payload }: InviteActionTypes = action;

	switch (type) {
		case GET_INVITES_ALL: {
			return {
				...state,
				invites: {
					records: [...state.invites.records, ...(payload as IInvitesPayload).records],
					total: (payload as IInvitesPayload).total,
				},
			};
		}

		case GET_INVITES_JOINED: {
			return {
				...state,
				invites: {
					records: [...state.invites.records, ...(payload as IInvitesPayload).records],
					total: (payload as IInvitesPayload).total,
				},
			};
		}

		case GET_INVITES_PENDING: {
			return {
				...state,
				invites: {
					records: [...state.invites.records, ...(payload as IInvitesPayload).records],
					total: (payload as IInvitesPayload).total,
				},
			};
		}

		case RESET_INVITES: {
			return initState;
		}

		case UPDATE_INVITE: {
			console.log('Reduser!!!!!!!!!!!!!!!!!', payload);
			return {
				...state,
				invites: {
					records: state.invites.records.filter(i => i.id !== (payload as Array<string>)[0]),
					total: state.invites.total,
				},
			};
			// const index = state.invites.records.findIndex(item => +item.id === +(payload as Array<string>));
			// return update(state, {
			// 	invites: { records: { [index]: { $set: payload as IResendInvitationsRequest } } },
			// });
			// return { ...state };
		}

		case DELETE_INVITE: {
			return {
				...state,
				invites: {
					records: state.invites.records.filter(i => i.id !== (payload as Array<string>)[0]),
					total: state.invites.total - 1,
				},
			};
		}

		default:
			return state;
	}
};

export default invitesReducer;
