import React, { FC } from 'react';
import cx from 'classnames';
import styles from './CellDefault.module.scss';

type CellDefaultProps = {
	text: string | number;
	parentClassName: string;
};

const CellDefault: FC<CellDefaultProps> = ({ text, parentClassName }: CellDefaultProps) => {
	return (
		<div className={cx(styles.main, styles[parentClassName])} title={text as string}>
			<span>{text}</span>
		</div>
	);
};

export default CellDefault;
