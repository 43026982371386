import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { ReactComponent as FilterTriangle } from 'assets/image/filter-triangle-dark.svg';
import styles from './TableHeadCell.module.scss';

type TableHeadCellPropsType = {
	text?: string;
	itemClassName: string;
	clickHandler?: () => void;
	isSortDisabled?: boolean;
	children?: ReactNode;
};

const TableHeadCell: FC<TableHeadCellPropsType> = ({
	text,
	itemClassName,
	clickHandler,
	isSortDisabled,
	children,
}: TableHeadCellPropsType) => {
	return (
		<div
			className={cx(styles.main, itemClassName && styles[itemClassName])}
			onClick={() => {
				clickHandler && clickHandler();
				// setImageDeskDirection(!imageDeskDirection);
			}}
		>
			{children || (
				<>
					<span>{text}</span>
					{text && !isSortDisabled && (
						<FilterTriangle
							className={cx(
								styles.image
								// imageDeskDirection && styles.desc_dir
							)}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default TableHeadCell;
