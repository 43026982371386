import { FC, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
// icons
import PauseSVG from 'assets/image/pause.svg';
import PlaySVG from 'assets/image/play.svg';
// components
import EditVolunteerAccount from 'components/Organization/Volunteers/VolunteersPopups/EditVolunteerAccount';
import ButtonPencil from 'shared/components/Buttons/ButtonPencil';
import PopupContainer from 'shared/components/PopupContainer';
import ButtonSuspending from 'shared/components/Buttons/ButtonSuspending';
// types
import { IBasicVolunteerResponse, VOLUNTEER_ACTIVE_STATUS } from '@joc/api-gateway';
import { updateActiveStatus } from 'redux/volunteer-service/actions';
import { VolunteerTableDataContext } from 'components/Organization/Volunteers/Table';
// styles
import styles from './VolunteerActionCell.module.scss';

const VolunteerActionCell: FC = () => {
	const dispatch = useDispatch();

	const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

	const volunteerData: IBasicVolunteerResponse = useContext(VolunteerTableDataContext);

	const isVolunteerSuspended = useMemo(
		() => volunteerData.volunteerActiveStatus.status === VOLUNTEER_ACTIVE_STATUS.SUSPENDED,
		[]
	);

	const handleChangeVolunteerStatus = () =>
		dispatch(
			updateActiveStatus({
				volunteerId: volunteerData.id,
				status: isVolunteerSuspended ? VOLUNTEER_ACTIVE_STATUS.ACTIVE : VOLUNTEER_ACTIVE_STATUS.SUSPENDED,
			})
		);

	return (
		<div>
			<div className={styles.actions__content}>
				<ButtonPencil clickHandler={() => setIsShowPopup(true)} />
				<ButtonSuspending
					clickHandler={handleChangeVolunteerStatus}
					title="suspend volunteer"
					href={isVolunteerSuspended ? PlaySVG : PauseSVG}
				/>
			</div>
			{isShowPopup && (
				<PopupContainer setIsShowPopup={setIsShowPopup}>
					<EditVolunteerAccount setIsShowPopup={setIsShowPopup} />
				</PopupContainer>
			)}
		</div>
	);
};

export default VolunteerActionCell;
