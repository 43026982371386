import React, { FC, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

import { IChallengeResponse } from '@joc/api-gateway';

import { ReactComponent as DeleteSvg } from 'assets/image/icons/trash-new.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/image/icons/menu.svg';
import { ReactComponent as EditSvg } from 'assets/image/icons/edit-new.svg';
import { ReactComponent as PreviewSvg } from 'assets/image/preview.svg';

import styles from './ChallengeActions.module.scss';
import { useDispatch } from 'react-redux';
import { deleteChallenge } from '../../../../../../redux/challenges-service/actions';

type ChallengeActionsTypes = {
	challenge: IChallengeResponse;
	onEditAction: (challenge: IChallengeResponse) => void;
	onPreviewAction: (challenge: IChallengeResponse) => void;
};

const ChallengeActions: FC<ChallengeActionsTypes> = ({ challenge, onEditAction, onPreviewAction }) => {
	const dispatch = useDispatch();
	const [openItemId, setOpenItemId] = useState<number>(0);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleEditChallenge = () => {
		onEditAction(challenge);
		handleActionsButtonClose();
	};

	const handlePreviewChallenge = () => {
		onPreviewAction(challenge);
		handleActionsButtonClose();
	};

	const handleDeleteChallenge = () => {
		dispatch(deleteChallenge(challenge.id));
		handleActionsButtonClose();
	};

	const handleActionsButtonClose = () => {
		setAnchorEl(null);
		setOpenItemId(0);
	};

	const handleActionsButtonClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpenItemId(challenge.id);
	};

	return (
		<>
			<IconButton
				aria-label="actions"
				aria-controls={openItemId === challenge.id ? 'long-menu' : undefined}
				onClick={event => handleActionsButtonClick(event)}
			>
				<ThreeDotsSvg />
			</IconButton>
			<Menu
				open={openItemId === challenge.id}
				anchorEl={anchorEl}
				onClose={handleActionsButtonClose}
				MenuListProps={{
					className: styles.actionsList,
				}}
				PaperProps={{
					className: styles.paper,
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem className={styles.menuItem} onClick={handlePreviewChallenge}>
					<div className={styles.actionsContainer}>
						<PreviewSvg title="Preview" />
						<span className={styles.actionsText}>Preview</span>
					</div>
				</MenuItem>
				<MenuItem className={styles.menuItem} onClick={handleEditChallenge}>
					<div className={styles.actionsContainer}>
						<EditSvg title="Edit challenge" />
						<span className={styles.actionsText}>Edit</span>
					</div>
				</MenuItem>
				<MenuItem className={styles.menuItem} onClick={handleDeleteChallenge}>
					<div className={styles.actionsContainer}>
						<DeleteSvg title="Delete challenge" />
						<span className={styles.actionsText}>Delete</span>
					</div>
				</MenuItem>
			</Menu>
		</>
	);
};

export default ChallengeActions;
