import React, { createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
import { IRolesResponse } from '@joc/api-gateway';
import { API } from 'core/API';
import styles from './index.module.scss';

const getOrganizationRoles = async (setOptions: React.Dispatch<React.SetStateAction<Array<IRolesResponse>>>) => {
	try {
		const rolesResponse = await API.getAllRoles();
		rolesResponse?.length &&
			setOptions(
				rolesResponse
					.filter(
						i =>
							i.roleName === 'COORDINATOR' ||
							i.roleName === 'ADMIN' ||
							i.roleName === 'MARKETER' ||
							i.roleName === 'VOLUNTEER'
					)
					.map(i => {
						return { ...i, roleName: i.roleName.toLowerCase() };
					})
			);
	} catch (error: any) {
		console.error(error);
	}
};

type RolesSelectProps = {
	fieldName: string;
	fieldValue: number;
	id?: string;
	placeholder?: string;
	setFieldValue: (field: string, value: number) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	classList?: Array<string>;
};

const RolesSelect: FC<RolesSelectProps> = ({
	fieldName,
	fieldValue,
	id,
	placeholder = 'Select role',
	setFieldValue,
	setFieldTouched,
	classList,
}: RolesSelectProps) => {
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const [options, setOptions] = useState<Array<IRolesResponse>>([]);
	const title = useMemo(() => {
		// console.log('fieldValue: ', fieldValue, options);
		if (fieldValue && options.length)
			return options.find(option => option.id.toString() === fieldValue.toString())?.roleName;
		return '';
	}, [fieldValue, options]);
	const selectRef = useRef<HTMLDivElement>(null);
	const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

	const optionClickHandler = (id: number): void => {
		setFieldValue(fieldName, id);
		setIsShowOptions(false);
	};

	const handleClickOutside = (event: Event) => {
		isShowOptions && setFieldTouched(fieldName, true);
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};
	const titleClickHandler = () => {
		const valueIndex = options.findIndex(i => i.id === fieldValue);
		const target = optionRef[valueIndex]?.current;
		if (target)
			// set timeout cause in a first time the scroll doesn't work
			setTimeout(() => {
				target.scrollIntoView();
			}, 0);

		if (isShowOptions) setFieldTouched(fieldName, true);
		setIsShowOptions(!isShowOptions);
	};

	useEffect(() => {
		getOrganizationRoles(setOptions);
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	return (
		<div
			id={id}
			className={cx(styles.dropdown_select, styles.roles, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, styles.roles, {
					[styles.dropdown_select__title_selected]: !!fieldValue,
				})}
				onClick={titleClickHandler}
			>
				<span>{title || placeholder}</span>
				{!isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div className={styles.dropdown_select__options}>
				{options.map((option: IRolesResponse) => (
					<div
						key={option.id}
						className={cx(styles.dropdown_select__options__item, styles.roles)}
						onClick={() => optionClickHandler(option.id)}
					>
						{option.roleName.split('_')}
					</div>
				))}
			</div>
		</div>
	);
};

export default RolesSelect;
