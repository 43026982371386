import React, { FC } from 'react';
import FilterSvg from 'assets/image/filter.svg';
import ClearSvg from 'assets/image/clear.svg';
import CrossSvg from 'assets/image/cross-button.svg';
import styles from './FiltersHeader.module.scss';

type FiltersHeaderParentProps = {
	changeVisibilityFilters: () => void;
	resetForm: () => void;
};

const FiltersHeader: FC<FiltersHeaderParentProps> = ({
	changeVisibilityFilters,
	resetForm,
}: FiltersHeaderParentProps) => {
	return (
		<div className={styles.main}>
			<div className={styles.title} onClick={changeVisibilityFilters}>
				<img className={styles.title__icon} src={FilterSvg} alt="filter" />
				<span className={styles.title__text}>Filters</span>
			</div>
			<div className={styles.clear} onClick={resetForm}>
				<img className={styles.clear__icon} src={ClearSvg} alt="clear" />
				<span className={styles.clear__text}>Clear</span>
			</div>
			<div className={styles.buttonClose} onClick={changeVisibilityFilters}>
				<img className={styles.buttonClose__icon} src={CrossSvg} alt="cross" />
			</div>
		</div>
	);
};

export default FiltersHeader;
