import { OPPORTUNITY_LOCATION_TYPE, OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import moment from 'moment-mini';

export const AllOpposQuery = {
	status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.CLOSED],
	opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL],
	opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};

export const PastOpposQuery = {
	status: [OPPORTUNITY_STATUSES.CLOSED],
	opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL],
	opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};

export const UpcomingOpposQuery = {
	status: [OPPORTUNITY_STATUSES.OPEN],
	opportunityTypesList: [OPPORTUNITY_TYPES.PRIVATE, OPPORTUNITY_TYPES.PUBLIC, OPPORTUNITY_TYPES.MANUAL],
	startDay: {
		fromDate: `${moment().format('YYYY-MM-DDThh:mm:ss')}.000Z`,
	},
};

export const ManualOpposQuery = {
	status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.PENDING],
	opportunityTypesList: [OPPORTUNITY_TYPES.MANUAL],
	opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};

export const PlaceholderOpposQuery = {
	status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.PENDING],
	opportunityTypesList: [OPPORTUNITY_TYPES.MANUAL],
	opportunityLocationType: Object.values(OPPORTUNITY_LOCATION_TYPE),
};
