// TODO: Helpers

import * as Yup from 'yup';
import { MAX_NAME_LENGTH, MAX_TITLE_LENGTH } from './constants';
import { imagePathSchema } from '../../../../core/validation';

const nameSchema = Yup.string()
	.min(2, 'Challenge title should be more than 2 characters')
	.trim()
	.max(MAX_NAME_LENGTH, `Challenge title must be at most ${MAX_NAME_LENGTH} characters`)
	.required('Challenge title is required');

const titleSchema = Yup.string()
	.min(2, 'Challenge subtitle should be more than 2 characters')
	.trim()
	.max(MAX_TITLE_LENGTH, `Challenge subtitle must be at most ${MAX_TITLE_LENGTH} characters`)
	.required('Challenge subtitle is required');

const descriptionSchema = Yup.string()
	.min(2, 'Description should be more than 2 characters')
	.test('Description is required', 'Description is required', description => {
		const element = document.createElement('div');
		element.innerHTML = description || '';

		return !!element.innerText?.length;
	})
	.required('Description is required');

const startDateSchema = Yup.date()
	.nullable(true)
	.required('Start date is required')
	.typeError('Please, fill full date');

const endDateSchema = Yup.date().nullable(true).required('End date is required').typeError('Please, fill full date');

export const challengeValidationSchema = Yup.object().shape({
	name: nameSchema,
	title: titleSchema,
	description: descriptionSchema,
	startDate: startDateSchema,
	endDate: endDateSchema,
	imagePath: imagePathSchema,
	chesedIdeas: Yup.array()
		.test('Chesed ideas is required', 'Chesed ideas is required', chesedIdeas => !!chesedIdeas?.[0]?.length)
		.required('Chesed ideas is required'),
});
