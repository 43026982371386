import { createRef, FC, useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
//assets
import SearchSvg from 'assets/image/inputs/search.svg';
//types
import { IGetLocationIdByAddressRequest } from '@joc/api-gateway';
//functions
import { addressToObj, generateLocation } from 'core/functions';
//constants
import { INITIAL_VOLUNTEER_VALUES } from 'core/constants';
//styles
import './GoogleApiAutoCompleat.scss';

type InputSearchProps = {
	placeholder?: string;
	id?: string;
	classList?: Array<string>;
	fieldName: string;
	fieldValue?: IGetLocationIdByAddressRequest;
	selectHandler: (address: IGetLocationIdByAddressRequest) => void;
	setFieldTouched?: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	setFieldValue?: (
		field: string,
		value: IGetLocationIdByAddressRequest | undefined | null,
		shouldValidate?: boolean | undefined
	) => void;
};

const GoogleApiAutoCompleat: FC<InputSearchProps> = ({
	placeholder,
	selectHandler,
	setFieldTouched,
	fieldValue,
	fieldName,
	setFieldValue,
}: InputSearchProps) => {
	const autocompleteRef = createRef<HTMLInputElement>();

	const changeHandler = (value: string) => {
		if (setFieldValue) {
			value.length ? setFieldValue(fieldName, null) : setFieldValue(fieldName, INITIAL_VOLUNTEER_VALUES.address);
		}
	};

	useEffect(() => {
		if (placeholder) {
			autocompleteRef.current?.setAttribute('placeholder', placeholder || 'Search by location');
		}
	}, [autocompleteRef]);

	return (
		<div className="wrap">
			<img src={SearchSvg} className="search_icon" />
			<Autocomplete
				ref={autocompleteRef}
				className="googleAct"
				apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
				onPlaceSelected={place => selectHandler(addressToObj(place))}
				onBlur={() => setFieldTouched && setFieldTouched(fieldName, true)}
				onChange={e => changeHandler(e.currentTarget.value)}
				options={{ types: ['address'] }}
				defaultValue={generateLocation(fieldValue)}
			/>
		</div>
	);
};

export default GoogleApiAutoCompleat;
