import { FC } from 'react';
import cx from 'classnames';
import { TABLE_ITEMS_CLASSNAMES } from 'core/constants';
import ButtonConfirmCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonConfirmCircle';
import ButtonRejectCircle from 'shared/components/Buttons/ButtonActionCircle/ButtonRejectCircle';
import { changeStatusManualOpportunity } from 'redux/opportunities-service/action';
import { connect, ConnectedProps } from 'react-redux';
import { OPPORTUNITY_STATUSES } from '@joc/api-gateway';
import styles from '../CellDefault.module.scss';

type ManualOpportunityActionsParentProps = {
	opportunityId: number;
};

const ManualOpportunityActions: FC<ManualOpportunityActionsProps> = ({
	changeStatusManualOpportunity,
	opportunityId,
}: ManualOpportunityActionsProps) => {
	return (
		<div className={cx(styles.main, styles[TABLE_ITEMS_CLASSNAMES.options])}>
			<ButtonConfirmCircle
				clickHandler={() => changeStatusManualOpportunity(opportunityId, OPPORTUNITY_STATUSES.OPEN)}
			/>
			<ButtonRejectCircle
				clickHandler={() => changeStatusManualOpportunity(opportunityId, OPPORTUNITY_STATUSES.CANCELED)}
			/>
		</div>
	);
};

const mapDispatchToProps = {
	changeStatusManualOpportunity,
};

const connector = connect(null, mapDispatchToProps);

type ManualOpportunityActionsProps = ConnectedProps<typeof connector> & ManualOpportunityActionsParentProps;

export default connector(ManualOpportunityActions);
