import React, { FC, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
// components
import DashboardTable from 'components/Organization/Dashboard/DashboardTable';

// images
// redux
import { getDashboardInfo } from 'redux/dashboard-service/action';
import { selectorGetUserRoles } from 'redux/user-service/selector';
// types
import { Store } from 'redux/root';
// styles
import MainPageLayout from 'components/Organization/MainPageLayout';
import styles from './Dashboard.module.scss';

const DashboardPage: FC<DashboardPageProps> = ({ getDashboardInfo, userRoles }: DashboardPageProps) => {
	const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
	const printRef = useRef<HTMLDivElement>(null);

	const doneButtonClickHandler = () => {
		setIsShowPopup(false);
	};

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	useEffect(() => {
		getDashboardInfo();
	}, []);

	return (
		<MainPageLayout>
			<div className={styles.container}>
				<div className={styles.block_title}>
					<h1 className="title">My Dashboard</h1>
					{/* <div className={styles.block_title_action_button}>
						<UploadSvg className={styles.item_action} />
						<ButtonPrint clickHandler={handlePrint} />
						{userRoles?.find(i => i.roleName === VOLUNTEER.ADMIN || i.roleName === VOLUNTEER.MARKETER) && (
							<ButtonDefault
								title="+ Upload Status"
								classList={['primary', 'extra_md']}
								clickHandler={() => setIsShowPopup(true)}
							/>
						)}
					</div> */}
				</div>
				<DashboardTable ref={printRef} />
				{/* {isShowPopup && (
					<PopupContainer setIsShowPopup={setIsShowPopup}>
						<ButtonDefault
							classList={['lg', 'primary']}
							title="Done"
							clickHandler={doneButtonClickHandler}
						/>
					</PopupContainer>
				)} */}
			</div>
		</MainPageLayout>
	);
};

const mapStateToProps = (store: Store) => ({
	userRoles: selectorGetUserRoles(store),
});

const mapDispatchToProps = {
	getDashboardInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DashboardPageProps = ConnectedProps<typeof connector>;

export default connector(DashboardPage);
