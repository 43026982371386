import React, { FC, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-mini';

import { IChallengeResponse } from '@joc/api-gateway';
import DialogStyled from '../../../../../components/Organization/Placeholders/EditPlaceholderDialog/DialogStyled';
import ButtonCross from '../../../Buttons/ButtonCross';
import ChallengeForm from './ChallengeForm';
import { challengeValidationSchema } from '../helpers';
import { ChallengeFormValuesType } from '../types';

import styles from '../../../../../components/Organization/Placeholders/EditPlaceholderDialog/EditPlaceholderDialog.module.scss';
import { ICreateChallengeRequest } from '@joc/api-gateway/lib/api-client';
import {
	createChallenge,
	setLoadingChallenges,
	updateChallenge,
} from '../../../../../redux/challenges-service/actions';
import { Store } from '../../../../../redux/root';
import ChallengesPreview from '../ChallengesPreview';

interface ChallengeCreateModalProps {
	isModalOpen: boolean;
	// eslint-disable-next-line no-unused-vars
	onClose: (successSubmitType?: boolean) => void;
	challenge?: IChallengeResponse;
}

const ChallengeModal: FC<ChallengeCreateModalProps> = ({ isModalOpen, onClose, challenge }) => {
	const dispatch = useDispatch();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [dataToPreview, setDataToPreview] = useState<Partial<IChallengeResponse> | null>(null);
	const isLoading = useSelector((store: Store) => store.challengeRedux.isLoading);

	const closeDialog = () => onClose();

	const submitClickHandler = (values: ChallengeFormValuesType) => {
		dispatch(setLoadingChallenges(true));

		const { name, title, chesedIdeas, imagePath } = values;
		const startDate = moment(values.startDate)
			.set({
				hours: moment(values.startTime, 'HH:mm').hours(),
				minutes: moment(values.startTime, 'HH:mm').minutes(),
			})
			.toDate();

		const deadlineDate = moment(values.endDate)
			.set({
				hours: moment(values.endTime, 'HH:mm').hours(),
				minutes: moment(values.endTime, 'HH:mm').minutes(),
			})
			.toDate();

		const body = document.createElement('body');
		body.innerHTML = values.description;

		const mutatedValues: ICreateChallengeRequest = {
			name,
			title,
			description: body.outerHTML,
			startDate,
			endDate: deadlineDate,
			chesedIdeas: chesedIdeas as unknown as Array<Array<any>>,
			imagePath,
		};

		if (challenge) {
			dispatch(updateChallenge({ id: challenge.id, challenge: mutatedValues }));
		} else {
			dispatch(createChallenge(mutatedValues));
		}

		setIsSubmitted(true);
	};

	const handlePreviewOpen = (previewData: Partial<IChallengeResponse> | null) => setDataToPreview(previewData);

	useEffect(() => {
		if (isSubmitted && !isLoading) {
			onClose(true);
		}
	}, [isLoading, isSubmitted]);

	const initialData = useMemo<ChallengeFormValuesType>((): ChallengeFormValuesType => {
		const div = document.createElement('div');
		div.innerHTML = challenge?.description || '';

		return {
			name: challenge?.name || '',
			title: challenge?.title || '',
			description: challenge?.description ? div.innerHTML : '',
			startDate: challenge?.startDate || new Date(),
			startTime: challenge?.startDate ? moment(challenge?.startDate).format('HH:mm') : moment().format('HH:mm'),
			endDate: challenge?.endDate || new Date(),
			endTime: challenge?.endDate
				? moment(challenge?.endDate).format('HH:mm')
				: moment()
						.set({
							hours: moment().hours() + 1,
						})
						.format('HH:mm'),
			imagePath: challenge?.imagePath || '',
			chesedIdeas: challenge?.chesedIdeas.length ? challenge?.chesedIdeas : [''],
		};
	}, [challenge]);

	return (
		<DialogStyled open={isModalOpen} maxWidth="xl">
			<section className={styles.dialog} style={{ width: 932 }}>
				<div className={styles.dialog__header}>
					<ButtonCross clickHandler={closeDialog} parentClassName={styles.dialog__header_button} />
				</div>
				{initialData ? (
					<Formik
						enableReinitialize={true}
						initialValues={initialData}
						validationSchema={challengeValidationSchema}
						onSubmit={submitClickHandler}
						className={styles.dialog__form}
					>
						<ChallengeForm isNew={!challenge} onClose={closeDialog} onPreview={handlePreviewOpen} />
					</Formik>
				) : null}

				{dataToPreview ? (
					<ChallengesPreview previewData={dataToPreview} handlePreviewOpen={setDataToPreview} />
				) : null}
			</section>
		</DialogStyled>
	);
};

export default ChallengeModal;
