import React, { ChangeEvent, FC, useEffect, useRef } from 'react';
import { ReactComponent as IdeaIcon } from 'assets/image/idea.svg';
import { ReactComponent as RemoveIdeaIcon } from 'assets/image/remove-idea.svg';

import s from '../../../../FormInputs/InputText/index.module.scss';
import styles from './ChesedInput.module.scss';
import cx from 'classnames';
import InputErrorHint from '../../../../InputErrorHint';

type ChesedInputProps = {
	value?: string;
	focused?: boolean;
	// eslint-disable-next-line no-unused-vars
	onChange?: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onRemove?: () => void;
	placeholder?: string;
	hideRemoveIcon?: boolean;
	touched?: boolean;
};

const ChesedInput: FC<ChesedInputProps> = ({
	value,
	focused,
	onChange,
	onFocus,
	onBlur,
	onRemove,
	placeholder,
	hideRemoveIcon,
	touched,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(event.target.value);
	};

	useEffect(() => {
		if (focused) {
			inputRef.current?.focus();
		}
	}, [focused]);

	return (
		<div className={styles.chesedIdea}>
			<div className={styles.chesedIdea__row}>
				<IdeaIcon />
				<div className={s.input__text}>
					<input
						ref={inputRef}
						type="text"
						placeholder={placeholder || ''}
						value={value}
						onChange={onChangeHandler}
						onBlur={onBlur}
						onFocus={onFocus}
					/>
				</div>
				<div
					className={cx(styles.removeIdea, {
						[styles.removeIdea_hide]: hideRemoveIcon,
					})}
					onClick={onRemove}
				>
					<RemoveIdeaIcon />
				</div>
			</div>

			{hideRemoveIcon && touched && !value?.length && (
				<InputErrorHint errorText="Chesed idea is required" disableMargin={true} />
			)}
		</div>
	);
};

export default ChesedInput;
