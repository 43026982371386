import React, { FC } from 'react';
import cx from 'classnames';
import CrossSvg from 'assets/image/cross-button.svg';
import styles from './ButtonCross.module.scss';

type ButtonCrossProps = {
	clickHandler: () => void;
	parentClassName?: string;
};

const ButtonCross: FC<ButtonCrossProps> = ({ clickHandler, parentClassName }: ButtonCrossProps) => {
	return (
		<button
			className={cx(styles.main, parentClassName && parentClassName)}
			onClick={clickHandler}
			aria-label="close"
		>
			<img src={CrossSvg} />
		</button>
	);
};

export default ButtonCross;
