import { Dispatch } from 'redux';
//types
import {
	CANTMAKEIT,
	CREATE_OPPORTUNITY,
	DELETE_OPPORTUNITY,
	GET_OPPORTUNITIES,
	GET_OPPORTUNITIES_COUNT,
	GET_OPPORTUNITIES_REMINDER_TAPE,
	GET_OPPORTUNITY_BY_ID,
	CHANGE_STATUS_MANUAL_OPPORTUNITY,
	RESET_OPPORTUNITIES_RECORDS,
	SET_ERROR,
	UPDATE_OPPORTUNITY,
	GET_OPPORTUNITIES_ALL,
	UPDATE_ACTIVE_STATUS_OPPO,
	JOIN_TO_OPPORTUNITY_BY_VOLUNTEER,
	GET_OPPORTUNITIES_ALL_REPLACE,
	GET_PLACEHOLDERS_ALL,
} from 'redux/actions-types';
import {
	AttachOpportunityVolunteersRequest,
	ChangeStatusOpportunityRequest,
	CreateOpportunityRequest,
	DeleteOpportunityRequest,
	ICreateOpportunityRequest,
	IDateRange,
	IGetLocationAddressByIdResponse,
	IOpportunityActiveStatusRequest,
	IOpportunityResponse,
	IUpdateOpportunityRequest,
	OpportunityActiveStatusRequest,
	OPPORTUNITY_STATUSES,
	SearchOpportunitiesRequest,
	SearchVolunteersByGroupRequest,
	SEARCH_OPPORTUNITIES_POSSIBLE_SORT,
	SortDirection,
	StatusOpportunityVolunteersRequest,
	StatusOpportunityVolunteersRequestStatus,
	TimeRangeRequest,
	UpdateOpportunityRequest,
	ToggleOptOutStatusRequest,
} from '@joc/api-gateway';
import {
	AllOpposQuery,
	ManualOpposQuery,
	PastOpposQuery,
	UpcomingOpposQuery,
	IOpportunitiesCountsResponse,
} from 'core/types';
//API
import { API } from 'core/API';
//functions
import { convertWhatTimeToGreenwich, removeEmptyProps } from 'core/functions';

export const getOpportunitiesAllInitial =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// TODO: change request to search by admin
			const recordsOppotunitiesResponse = await API.adminSearchOpportunities(
				'',
				SearchOpportunitiesRequest.fromJS({ ...searchBody })
			);
			// console.log('recordsOppotunitiesResponse: ', recordsOppotunitiesResponse);
			dispatch({
				type: GET_OPPORTUNITIES_ALL,
				payload: { records: recordsOppotunitiesResponse.records, total: recordsOppotunitiesResponse.total },
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const getOpportunitiesAll =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const recordsOppotunitiesResponse = await API.adminSearchOpportunities(
				'',
				SearchOpportunitiesRequest.fromJS({ ...searchBody })
			);

			dispatch({
				type: GET_OPPORTUNITIES_ALL_REPLACE,
				payload: { records: recordsOppotunitiesResponse.records, total: recordsOppotunitiesResponse.total },
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const getAllOpposCounts =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const smallPagination = { skip: 0, take: 1 };
			const getOppos = (search: any) => {
				return API.adminSearchOpportunities(
					'',
					SearchOpportunitiesRequest.fromJS({ ...search, pagination: smallPagination })
				);
			};

			const updateCounts = (payload: IOpportunitiesCountsResponse) => {
				dispatch({ type: GET_OPPORTUNITIES_COUNT, payload });
			};

			const payload = {
				opportunitiesTotal: 0,
				opportunitiesPast: 0,
				opportunitiesUpcoming: 0,
				opportunitiesManual: 0,
			} as IOpportunitiesCountsResponse;

			const placeholdersResponse = await API.getAllPlacholders();

			dispatch({
				type: GET_PLACEHOLDERS_ALL,
				payload: placeholdersResponse,
			});

			const opportunitiesTotal = (await getOppos(AllOpposQuery)).total;
			payload.opportunitiesTotal = opportunitiesTotal;
			updateCounts(payload);

			const opportunitiesPast = (await getOppos(PastOpposQuery)).total;
			payload.opportunitiesPast = opportunitiesPast;
			updateCounts(payload);

			const opportunitiesUpcoming = (await getOppos(UpcomingOpposQuery)).total;
			payload.opportunitiesUpcoming = opportunitiesUpcoming;
			updateCounts(payload);

			const opportunitiesManual = (await getOppos(ManualOpposQuery)).total;
			payload.opportunitiesManual = opportunitiesManual;
			updateCounts(payload);
		} catch (error: any) {
			throw new Error(error.message);
		}
	};

export const getOpportunities =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			if (!searchBody.fullTextSearch?.value) {
				delete searchBody.fullTextSearch;
			}
			const timeRange = convertWhatTimeToGreenwich(searchBody.whatTime);
			if (timeRange?.startTime)
				searchBody.whatTime = TimeRangeRequest.fromJS({
					...searchBody.whatTime,
					startTime: timeRange.startTime,
				});
			if (timeRange?.endTime)
				searchBody.whatTime = TimeRangeRequest.fromJS({
					...searchBody.whatTime,
					endTime: timeRange.endTime,
				});
			const recordsResponse = await API.searchOpportunitiesByOrganisation(
				undefined,
				undefined,
				removeEmptyProps(searchBody)
			);
			dispatch({
				type: GET_OPPORTUNITIES,
				payload: { records: recordsResponse.records, total: recordsResponse.total },
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const getOpportunityById =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response = await API.getOpportunityById(id, '');
			dispatch({
				type: GET_OPPORTUNITY_BY_ID,
				payload: response,
			});
		} catch (error: any) {
			throw new Error(error.message);
		}
	};

export const resetOpportunities =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: RESET_OPPORTUNITIES_RECORDS,
		});
	};

export const getOpportunitiesCount =
	(orgId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response = await API.countsOpportunities(orgId);
			dispatch({ type: GET_OPPORTUNITIES_COUNT, payload: response });
		} catch (error: any) {
			throw new Error(error.message);
		}
	};

export const createOpportunity =
	(values: ICreateOpportunityRequest) =>
	async (dispatch: Dispatch): Promise<IOpportunityResponse> => {
		try {
			if (values.organisationId) {
				const response = await API.createOpportunity(
					undefined,
					undefined,
					CreateOpportunityRequest.fromJS(values)
				);
				dispatch({ type: CREATE_OPPORTUNITY, payload: response });
				return response;
			}
			throw new Error('We can not find your organisation id');
		} catch (error: any) {
			throw error;
		}
	};
export const createOpportunityBasedSmartGroup =
	(values: ICreateOpportunityRequest, groupId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const responseOpportunity = await API.createOpportunity(
				undefined,
				undefined,
				CreateOpportunityRequest.fromJS(values)
			);
			await getOpportunitiesCount(values.organisationId);
			const responseVolunteers = await API.getAllVolunteersByGroup(
				SearchVolunteersByGroupRequest.fromJS({
					groupId,
					organizationId: +values.organisationId,
				})
			);
			const attachVolunteersPromise = responseVolunteers.records.map(i =>
				API.attachVolunteerByUser(
					responseOpportunity.id,
					AttachOpportunityVolunteersRequest.fromJS({ volunteerId: +i.id })
				)
			);
			await Promise.all(attachVolunteersPromise)
				.then(() => {
					dispatch({ type: CREATE_OPPORTUNITY, payload: responseOpportunity });
				})
				.catch(err => {
					API.deleteOpportunity(responseOpportunity.id, DeleteOpportunityRequest.fromJS({}));
					throw new Error(err?.response?.message || err.message);
				});
		} catch (error: any) {
			throw new Error(error?.response?.message || error.message);
		}
	};
export const createManualOpportunity =
	(values: ICreateOpportunityRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response = await API.createManualOpportunityByVolunteer(CreateOpportunityRequest.fromJS(values));
			dispatch({ type: CREATE_OPPORTUNITY, payload: response });
		} catch (error: any) {
			throw new Error(error.message);
		}
	};

export const updateOpportunity = (id: number, values: IUpdateOpportunityRequest) => async (dispatch: Dispatch) => {
	try {
		const response = await API.adminUpdateOpportunity(id, UpdateOpportunityRequest.fromJS(values));
		dispatch({ type: UPDATE_OPPORTUNITY, payload: response });
	} catch (error: any) {
		throw new Error(error.message);
	}
};
export const updateActiveStatus = (data: IOpportunityActiveStatusRequest) => async (dispatch: Dispatch) => {
	try {
		const response = await API.updateOpportunityActiveStatus(OpportunityActiveStatusRequest.fromJS(data));
		dispatch({ type: UPDATE_ACTIVE_STATUS_OPPO, payload: response });
	} catch (error: any) {
		throw new Error(error.message);
	}
};

export const deleteOpportunity =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.deleteOpportunity(id, DeleteOpportunityRequest.fromJS({}));
			dispatch({ type: DELETE_OPPORTUNITY, payload: id });
		} catch (error: any) {
			throw new Error(error.message);
		}
	};

export const joinToOpportunity = (id: number) => async (dispatch: Dispatch) => {
	try {
		await API.attachByVolunteer(id);
		const updatedOpportunity = await API.getOpportunityById(id, '');
		dispatch({ type: UPDATE_OPPORTUNITY, payload: updatedOpportunity });
	} catch (error: any) {
		throw new Error(error.message);
	}
};

export const joinToOpportunityCurrent = (id: number) => async (dispatch: Dispatch) => {
	try {
		await API.attachByVolunteer(id);
		const updatedOpportunity = await API.getOpportunityById(id, '');
		dispatch({ type: JOIN_TO_OPPORTUNITY_BY_VOLUNTEER, payload: updatedOpportunity });
	} catch (error: any) {
		throw new Error(error.message);
	}
};

export const changeStatusVolunteers =
	(id: number, volunteerIds: Array<number>, status: StatusOpportunityVolunteersRequestStatus) =>
	async (dispatch: Dispatch) => {
		try {
			await API.changeStatusVolunteers(
				id,
				volunteerIds.map(volunteerId =>
					StatusOpportunityVolunteersRequest.fromJS({
						volunteerId,
						status,
					})
				)
			);
			const updatedOpportunity = await API.getOpportunityById(id, '');
			dispatch({ type: UPDATE_OPPORTUNITY, payload: updatedOpportunity });
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const cantMakeIt = (id: number, userVolunteerId: string) => async (dispatch: Dispatch) => {
	try {
		await API.toggleOpportunityVolunteerStatusCanMakeIt(id, ToggleOptOutStatusRequest.fromJS(undefined));
		dispatch({ type: CANTMAKEIT, payload: { id, userVolunteerId } });
	} catch (error: any) {
		throw error;
	}
};

export const getOpportunitiesReminderTape = (orgId: number, dateRange: IDateRange) => async (dispatch: Dispatch) => {
	try {
		const opportunitiesResponse = await API.searchOpportunitiesByOrganisation(
			undefined,
			undefined,
			SearchOpportunitiesRequest.fromJS({ startDay: dateRange, organisationId: orgId })
		);
		dispatch({ type: GET_OPPORTUNITIES_REMINDER_TAPE, payload: opportunitiesResponse });
	} catch (error: any) {
		throw error;
	}
};

export const changeStatusManualOpportunity =
	(opportunityId: number, opportunityStatus: OPPORTUNITY_STATUSES) => async (dispatch: Dispatch) => {
		try {
			await API.changeStatusOpportunity(
				undefined,
				ChangeStatusOpportunityRequest.fromJS({ opportunityId: +opportunityId, opportunityStatus })
			);
			dispatch({ type: CHANGE_STATUS_MANUAL_OPPORTUNITY, payload: { id: opportunityId, opportunityStatus } });
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const getOpportunitiesByVolunteer =
	(address: IGetLocationAddressByIdResponse | undefined, searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const requestBody = {
				...searchBody,
				sort: {
					sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDayStartTime,
					sortDir: SortDirection.DESC,
				},
			};
			const timeRange = convertWhatTimeToGreenwich(searchBody.whatTime);
			if (timeRange?.startTime)
				requestBody.whatTime = TimeRangeRequest.fromJS({
					...requestBody.whatTime,
					startTime: timeRange.startTime,
				});
			if (timeRange?.endTime)
				requestBody.whatTime = TimeRangeRequest.fromJS({
					...requestBody.whatTime,
					endTime: timeRange.endTime,
				});
			const recordsResponse = await API.searchOpportunitiesByVolunteer(
				undefined,
				address ? `${address.latitude},${address.longitude}` : undefined,
				removeEmptyProps(requestBody)
			);
			dispatch({
				type: GET_OPPORTUNITIES,
				payload: { records: recordsResponse.records, total: recordsResponse.total },
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};
