import React, { FC, useEffect, useRef } from 'react';
import ReactQuill, { Quill, UnprivilegedEditor } from 'react-quill';
import MagicUrl from 'quill-magic-url';

import 'react-quill/dist/quill.snow.css';

import {
	ALIGN_JUSTIFY_ICON,
	ALIGN_CENTER_ICON,
	ALIGN_LEFT_ICON,
	ALIGN_RIGHT_ICON,
	INDENT_MINUS_ICON,
	INDENT_PLUS_ICON,
	LINK_ICON,
	LIST_BULLET_ICON,
	LIST_ORDERED_ICON,
	REDO_ICON,
	UNDO_ICON,
	allowedSizes,
	STRIKE_ICON,
	UNDERLINE_ICON,
	ITALIC_ICON,
	BOLD_ICON,
	BACKGROUND_ICON,
	COLOR_ICON,
	fontSizeToLineHeight,
} from './constants';

import { formats, modules } from './editor-config';

import { CustomBreak } from './BreakLine.config';
import { CustomParagraph } from './Paragraph.config';

import './Editor.css';
import { CustomBold } from './Bold.config';

Quill.register('modules/magicUrl', MagicUrl);

Quill.register(CustomParagraph, true);
Quill.register(CustomBreak, true);

CustomBold.blotName = 'bold';
CustomBold.tagName = 'strong';
Quill.register(CustomBold, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = allowedSizes;
Quill.register(Size, true);

// Import Parchment from Quill for creating a custom format
const Parchment = Quill.import('parchment');

// Define a custom line-height format
const LineHeightStyle = new Parchment.Attributor.Style('lineHeight', 'line-height', {
	scope: Parchment.Scope.BLOCK,
	whitelist: ['20px', '24px', '28px', '32px'], // Whitelist allowed line-heights
});
Quill.register(LineHeightStyle, true);

const icons = Quill.import('ui/icons');
icons.undo = UNDO_ICON;
icons.redo = REDO_ICON;
icons.link = LINK_ICON;
icons.indent['+1'] = INDENT_PLUS_ICON;
icons.indent['-1'] = INDENT_MINUS_ICON;

icons.align[''] = ALIGN_LEFT_ICON;
icons.align.center = ALIGN_CENTER_ICON;
icons.align.right = ALIGN_RIGHT_ICON;
icons.align.justify = ALIGN_JUSTIFY_ICON;

icons.list.bullet = LIST_BULLET_ICON;
icons.list.ordered = LIST_ORDERED_ICON;
icons.strike = STRIKE_ICON;
icons.underline = UNDERLINE_ICON;
icons.italic = ITALIC_ICON;
icons.bold = BOLD_ICON;
icons.background = BACKGROUND_ICON();
icons.color = COLOR_ICON();

type EditorProps = {
	fieldValue?: string;
	// eslint-disable-next-line no-unused-vars
	onChange?: (value: string) => void;
	onBlur?: () => void;
	placeholder?: string;
};

const Editor: FC<EditorProps> = ({ fieldValue, onChange, onBlur, placeholder }) => {
	const quillRef = useRef<ReactQuill>(null);

	useEffect(() => {
		if (quillRef.current) {
			const quill = quillRef.current.getEditor();
			const toolbar = quill.getModule('toolbar');

			const changeColorHandler = (color: string, isChanged = false) => {
				const colorIcon = document.querySelector('.color-icon');
				const currentColor = colorIcon?.getAttribute('data-value');

				if (currentColor !== color) {
					if (colorIcon?.parentElement) {
						colorIcon.parentElement.innerHTML = COLOR_ICON(color).trim();
					}
					colorIcon?.remove();

					if (isChanged) {
						quill.format('color', color);
					}
				}
			};

			const changeBackgroundHandler = (background: string, isChanged = false) => {
				const backgroundIcon = document.querySelector('.background-icon');
				const currentBackground = backgroundIcon?.getAttribute('data-value');

				if (currentBackground !== background) {
					if (backgroundIcon?.parentElement) {
						backgroundIcon.parentElement.innerHTML = BACKGROUND_ICON(
							background === 'rgb(255, 255, 255 / 0%)' ? 'rgb(255, 255, 255)' : background
						).trim();
					}
					backgroundIcon?.remove();

					if (isChanged) {
						quill.format('background', background);
					}
				}
			};

			quill.once('selection-change', e => {
				quill.format('size', '14px');
				quill.format('lineHeight', fontSizeToLineHeight['14px']);
			});

			quill.on('selection-change', e => {
				if (e?.index) {
					const format = quill.getFormat();

					if (format?.color) {
						changeColorHandler(format?.color);
					} else {
						changeColorHandler('rgb(0, 0, 0)');
					}

					if (format?.background) {
						changeBackgroundHandler(format?.background);
					} else {
						changeBackgroundHandler('rgb(255, 255, 255 / 0%)');
					}
				}
			});

			toolbar.addHandler('color', (color: string) => changeColorHandler(color, true));

			toolbar.addHandler('background', (background: string) => changeBackgroundHandler(background, true));
		}
	}, []);

	const handleProcedureContentChange = (editorValue: string, delta: any, source: any, editor: UnprivilegedEditor) => {
		if (onChange) {
			onChange(editorValue);
		}

		if (source === 'user' && quillRef.current) {
			const quill = quillRef.current.getEditor();
			const selection = quill.getSelection();

			if (selection && selection.length === 0) {
				const [block, offset] = quill.getLine(selection.index);

				if (block && block.domNode.tagName === 'P') {
					const currentSize = quill.getFormat(selection.index).size || '12px'; // Default to 16px
					// @ts-ignore
					const currentLineHeight = fontSizeToLineHeight[currentSize];

					// Reset the line height to match the font size when a new block is created
					quill.formatLine(selection.index, 1, { lineHeight: currentLineHeight });
				}
			}
		}
	};

	return (
		<>
			<ReactQuill
				ref={quillRef}
				theme="snow"
				modules={modules}
				formats={formats}
				value={fieldValue}
				placeholder={placeholder}
				onBlur={onBlur}
				onChange={handleProcedureContentChange}
			/>
		</>
	);
};

export default Editor;
