import { FC, useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { getPlaceholders } from '../../../redux/placeholders-service/actions';

// components
import { PlaceholdersTable } from './PlaceholdersTable';

// styles
import styles from './Placeholders.module.scss';

export const Placeholders: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPlaceholders());
	}, []);

	return (
		<section className={styles.placeholders}>
			<PlaceholdersTable />
		</section>
	);
};
