import { VOLUNTEERS_FILTERS_VALUES } from 'core/types/VOLUNTEERS_FILTERS_VALUES';

export const INITIAL_VOLUNTEERS_FILTERS_VALUES: VOLUNTEERS_FILTERS_VALUES = {
	minAge: 0,
	maxAge: 0,
	genderId: 0,
	howFar: 0,
	organizationId: 0,
	schoolId: 0,
	organizationType: null,
};
