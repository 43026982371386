import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import OnboardingContentContainer from 'shared/components/OnboardingContentContainer';
import { ACCESS_DENIED_ERROR_TIME } from 'core/constants/ACCESS_DENIED_ERROR_TIME';
import { urls } from 'core/appUrls';
import { useHistory } from 'react-router';
import styles from './AccessDenied.module.scss';

const AccessDenied: FC = () => {
	const history = useHistory();
	const [redirectTimeCount, setRedirectTimeCount] = useState(ACCESS_DENIED_ERROR_TIME / 1000);

	useEffect(() => {
		if (redirectTimeCount > 0) setTimeout(() => setRedirectTimeCount(redirectTimeCount - 1), 1000);
		if (redirectTimeCount === 0) setTimeout(() => history.push(urls.loginSuperAdmin), 1000);
	}, [redirectTimeCount]);

	return (
		<OnboardingContentContainer disableOnboardingLogoLink>
			<Appeal styleThink isHeightDisable>
				<div className={cx('title', styles.title)}>Oops...</div>
				<div>Your access expired. You will be redirect to login in {redirectTimeCount} seconds</div>
			</Appeal>
		</OnboardingContentContainer>
	);
};

export default AccessDenied;
