import React, { FC } from 'react';
import cx from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import s from './Loader.module.scss';

// Inspired by the former Facebook spinners.
const useStylesCustom = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'relative',
		},
		top: {
			color: '#4B5EF5',
			animationDuration: '750ms',
		},
		circle: {
			strokeLinecap: 'round',
		},
	})
);

type LoaderParentProps = {
	parentClassName?: string;
	size?: number;
	disableCenterStyle?: boolean;
	stylePageCenter?: boolean;
};

type LoaderProps = {
	loadProps?: LoaderParentProps;
	circularProps?: CircularProgressProps;
};

const Loader: FC<LoaderProps> = ({ loadProps, circularProps }: LoaderProps) => {
	const classes = useStylesCustom();

	return (
		<div
			className={cx(
				s.main,
				loadProps?.disableCenterStyle && s.center_disabled,
				loadProps?.parentClassName && loadProps.parentClassName,
				loadProps?.stylePageCenter && s.page_center
			)}
		>
			<CircularProgress
				variant="indeterminate"
				disableShrink
				className={classes.top}
				classes={{
					circle: classes.circle,
				}}
				size={loadProps?.size ? loadProps.size : 40}
				thickness={4}
				{...circularProps}
			/>
		</div>
	);
};

export default Loader;
