import React, { createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import VolunteersTable from 'components/Organization/Volunteers/Table';
import TabNav from 'shared/components/TabNav';
import TabNavItem from 'shared/components/TabNav/TabNavItem';
import GenerateSmartGroup from 'components/Organization/Volunteers/GenerateSmartGroup';

import { Store } from 'redux/root';
import { connect, ConnectedProps } from 'react-redux';
import { selectorGetSmartGroupsAll } from 'redux/smartGroups-service/selector';
import { getSmartGroupsAll } from 'redux/smartGroups-service/actions';
import qs from 'qs';
import { getVolunteers, resetVolunteers } from 'redux/volunteer-service/actions';
import { useHistory, useLocation } from 'react-router-dom';
import {
	AgesRange,
	IGetLocationAddressByIdResponse,
	ISearchVolunteersByGroupRequest,
	ISearchVolunteersRequest,
	ISearchVolunteersSort,
	VOLUNTEER,
} from '@joc/api-gateway';
import { resetError, setError } from 'redux/error-service/action';
import VolunteersFilters from 'components/Organization/Volunteers/VolunteersFilters';
import { INITIAL_PAGINATION } from 'core/constants';
import { selectorGetVolunteersTotal } from 'redux/volunteer-service/selector';
import { selectorGetUserRoles, selectorGetUserAddress } from 'redux/user-service/selector';
import { getDashboardInfo } from 'redux/dashboard-service/action';
import styles from './Volunteers.module.scss';

export const VolunteersPageContext = createContext({} as VolunteersPageContextType);
export type VolunteersPageContextType = {
	isLoading: boolean;
	sort: ISearchVolunteersSort | undefined;
	isClearSearch: boolean;
	setPagination: React.Dispatch<
		React.SetStateAction<{
			skip: number;
			take: number;
		}>
	>;
	changeVisibilityFilters: () => void;
	setIsClearSearch: Dispatch<SetStateAction<boolean>>;
};

const parseQueryString = (
	search: string,
	userLocation: IGetLocationAddressByIdResponse | undefined
): ISearchVolunteersRequest | ISearchVolunteersByGroupRequest => {
	let searchParams: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest = qs.parse(search, {
		ignoreQueryPrefix: true,
	});
	const groupId = (searchParams as ISearchVolunteersByGroupRequest).groupId;
	const genderId = (searchParams as ISearchVolunteersRequest).genderId;
	const howFar = (searchParams as ISearchVolunteersRequest).howFar;
	const ages = (searchParams as ISearchVolunteersRequest).ages;
	if (ages)
		searchParams = {
			...searchParams,
			ages: ages.map(ageRange => {
				return AgesRange.fromJS({ min: +ageRange.min, max: +ageRange.max });
			}),
		};
	if (groupId) searchParams = { ...searchParams, groupId: +groupId };
	if (genderId) searchParams = { ...searchParams, genderId: +genderId };
	if (howFar)
		searchParams = {
			...searchParams,
			howFar: Object.assign(howFar, {
				latitude: userLocation && userLocation.latitude,
				longitude: userLocation && userLocation.longitude,
				distance: +howFar * 1609.34, // to mile
			}),
		};
	return searchParams;
};

const VolunteersPage: FC<VolunteersPageProps> = ({
	smartGroups,
	getVolunteers,
	resetError,
	setError,
	resetVolunteers,
	volonteersTotal,
	userRoles,
	userLocation,
}: VolunteersPageProps) => {
	const location = useLocation();
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const [requestBody, setRequestBody] = useState<ISearchVolunteersRequest | ISearchVolunteersByGroupRequest>();
	const [visibilityFilters, setVisibilityFilters] = useState({
		isClose: false,
		isShowFilter: false,
	});
	const [isClearSearch, setIsClearSearch] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [isCreatingSmartGroup, setIsCreatingSmartGroup] = useState<boolean>(false);
	const history = useHistory();
	const changeVisibilityFilters = useCallback(() => {
		setVisibilityFilters({ ...visibilityFilters, isClose: !visibilityFilters.isClose });
		setTimeout(
			() => setVisibilityFilters({ ...visibilityFilters, isShowFilter: !visibilityFilters.isShowFilter }),
			200
		);
	}, [visibilityFilters]);

	const volunteersPageContextValue: VolunteersPageContextType = useMemo(() => {
		return {
			isLoading,
			isClearSearch,
			sort: requestBody?.sort,
			setPagination,
			changeVisibilityFilters,
			setRequestBody,
			setIsClearSearch,
		};
	}, [isLoading, isClearSearch, requestBody, changeVisibilityFilters]);

	const getVolunteersRequest = useCallback(async () => {
		setIsLoading(true);
		try {
			await getVolunteers({ ...requestBody, pagination });
			setIsLoading(false);
		} catch (error: any) {
			setIsLoading(false);
			setError(error?.response?.message || error.message, error.code);
		}
	}, [pagination, requestBody]);

	const allVolunteersTabClickHandler = () => {
		history.push({ search: '' });
		setIsCreatingSmartGroup(false);
	};
	const smartGroupTabClickHandler = (id: number) => {
		const groupId = (requestBody as ISearchVolunteersByGroupRequest)?.groupId;
		if (groupId && +id === +groupId) return;
		history.push({ search: qs.stringify({ groupId: id }) });
		setIsCreatingSmartGroup(false);
	};

	const createSmartGroupClickHandler = () => {
		resetVolunteers();
		setIsCreatingSmartGroup(true);
		history.push({ search: '' });
	};

	useEffect(() => {
		if (requestBody !== undefined) {
			getVolunteersRequest();
		}
	}, [getVolunteersRequest]);

	useEffect(() => {
		resetVolunteers();
		const data = parseQueryString(location.search, userLocation);
		setPagination(INITIAL_PAGINATION);
		setRequestBody(data);
	}, [location.search]);

	useEffect(() => {
		return () => {
			resetError();
			resetVolunteers();
		};
	}, []);

	return (
		<div className={styles.container}>
			<h1 className="title">Volunteers</h1>
			<div className={styles.content}>
				<div className={styles.content__wrapper}>
					<TabNav>
						<TabNavItem
							parentClassName={styles.tabNavItem}
							counter={volonteersTotal}
							title="All"
							activeTab={
								!(requestBody as ISearchVolunteersByGroupRequest)?.groupId && !isCreatingSmartGroup
							}
							clickHandler={allVolunteersTabClickHandler}
						/>
						{smartGroups.map(group => (
							<TabNavItem
								parentClassName={styles.tabNavItem}
								key={group.id}
								title={group.groupName}
								counter={group.total}
								activeTab={
									(requestBody as ISearchVolunteersByGroupRequest)?.groupId?.toString() ===
										group.id.toString() && !isCreatingSmartGroup
								}
								isSmartGroup
								groupId={group.id}
								clickHandler={() => smartGroupTabClickHandler(group.id)}
							/>
						))}
						{userRoles?.find(i => i.roleName === VOLUNTEER.ADMIN || i.roleName === VOLUNTEER.MARKETER) && (
							<TabNavItem
								parentClassName={styles.generateGroup}
								title="+ Generate smart group"
								activeTab={isCreatingSmartGroup}
								clickHandler={createSmartGroupClickHandler}
							/>
						)}
					</TabNav>

					{isCreatingSmartGroup ? (
						<VolunteersPageContext.Provider value={volunteersPageContextValue}>
							<GenerateSmartGroup />
						</VolunteersPageContext.Provider>
					) : (
						<VolunteersPageContext.Provider value={volunteersPageContextValue}>
							<VolunteersTable />
						</VolunteersPageContext.Provider>
					)}
				</div>
				{visibilityFilters.isShowFilter && (
					<VolunteersFilters
						changeVisibilityFilters={changeVisibilityFilters}
						isCloseFilters={visibilityFilters.isClose}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	smartGroups: selectorGetSmartGroupsAll(store),
	volonteersTotal: selectorGetVolunteersTotal(store),
	userRoles: selectorGetUserRoles(store),
	userLocation: selectorGetUserAddress(store),
});
const mapDispatchToProps = {
	getSmartGroupsAll,
	getVolunteers,
	resetError,
	setError,
	resetVolunteers,
	getDashboardInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type VolunteersPageProps = ConnectedProps<typeof connector>;

export default connector(VolunteersPage);
