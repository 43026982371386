//API
import { API } from 'core/API';
//types
import { BroadcastMessageRequest } from '@joc/api-gateway';
//functions
import { sendFilesToS3Bucket } from 'core/functions';

export const allowedImageTypes = [
	'image/jpeg',
	'image/png',
	// Streamchat allowed extensions:
	// 'image/bmp',
	// 'image/gif',
	// 'image/webp',
	// 'image/heic',
	// 'image/heic-sequence',
	// 'image/heif',
	// 'image/heif-sequence',
	// 'image/svg+xml',
];

export const defaultFileMimeTypes = 'image/jpeg, image/jpg, image/png, image/bmp, video/mp4';
export const defaultPhotoTypes = 'image/jpeg, image/jpg, image/png, image/bmp';

export const getIsUploadedPhotoValid = (photo: File): boolean => {
	const MAX_PHOTO_SIZE_IN_MEGABYTES = 100;
	const megabyteInBytes = 1024 * 1024;
	const photoSizeInMegabytes = photo.size / megabyteInBytes;

	return defaultFileMimeTypes.includes(photo.type) && photoSizeInMegabytes < MAX_PHOTO_SIZE_IN_MEGABYTES;
};

export const getUploadedPhotoInvalidError = (photo: File) => {
	const MAX_PHOTO_SIZE_IN_MEGABYTES = 100;
	const megabyteInBytes = 1024 * 1024;
	const photoSizeInMegabytes = photo.size / megabyteInBytes;

	return photoSizeInMegabytes > MAX_PHOTO_SIZE_IN_MEGABYTES
		? 'File Size Limit: Please ensure each file does not exceed 100 MB'
		: '';
};

export enum BroadcastEnum {
	Volonteers = 'Volonteers',
	Organizations = 'Organizations',
}

export const MAX_PHOTOS = 10;

export const broadcastMessage = async (
	broadcastType: BroadcastEnum,
	message: string,
	files?: Array<File>,
	organizationId?: string
) => {
	try {
		const attachments = files?.length ? await sendFilesToS3Bucket(files) : undefined;

		if (broadcastType === BroadcastEnum.Organizations && organizationId) {
			await API.broadcastMessageByAdmin(
				BroadcastMessageRequest.fromJS({
					organizationId: Number(organizationId),
					message,
					attachments,
				})
			);
		} else {
			await API.broadcastMessageByAdmin(
				BroadcastMessageRequest.fromJS({
					message,
					attachments,
				})
			);
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log('error', error);
	}
};
