import React, { FC } from 'react';
import styles from './OrgContactCell.module.scss';

type OrgContactCellProps = {
	phone?: string;
	email?: string;
	site?: string;
	contactFullName?: string;
};

const OrgContactCell: FC<OrgContactCellProps> = ({ phone, email, site, contactFullName }: OrgContactCellProps) => {
	return (
		<div className={styles.contact}>
			{contactFullName && (
				<span className={styles.multiline_text} title={contactFullName}>
					{contactFullName}
				</span>
			)}
			{phone && (
				<a className={styles.text} href={`tel:${phone}`} title={email}>
					{phone}
				</a>
			)}
			{email && (
				<a className={styles.text} href={`mailto:${email}`} title={email}>
					{email}
				</a>
			)}
			{site && (
				<a className={styles.text} href={site} title={email}>
					{site}
				</a>
			)}
		</div>
	);
};

export default OrgContactCell;
