import React, { FC, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux/root';

// icons
import { ReactComponent as ClockSvg } from 'assets/image/clock-challenges.svg';

// types
import { IChallengeResponse } from '@joc/api-gateway';

// styles
import styles from './ChallengesTable.module.scss';
import moment from 'moment-mini';
import AvatarCell from '../../../Table/CellRenderers/AvatarCell';
import cx from 'classnames';
import ChallengeActions from './ChallengeActions';
import ChallengeModal from '../ChallengeModal';
import Loader from '../../../Loader';
import ChallengePopupCongrats from '../ChallengePopupCongrats';
import ResponseFailure from '../../../ResponseFailure';
import ChallengesPreview from '../ChallengesPreview';

export const ChallengesTable: FC<{ isSearching: boolean }> = ({ isSearching }) => {
	const [challengeToEdit, setChallengeToEdit] = useState<IChallengeResponse>();
	const [challengeToPreview, setChallengeToPreview] = useState<Partial<IChallengeResponse> | null>(null);

	const [challenges, setChallenges] = useState<Array<IChallengeResponse>>([]);
	const [isOpenChallengeCongratsModal, setIsOpenChallengeCongratsModal] = useState(false);

	const challengesRecords = useSelector((store: Store) => store.challengeRedux.challengesRecords);
	const isLoadingChallenges = useSelector((store: Store) => store.challengeRedux.isLoading);

	const editChallenge = (challenge: IChallengeResponse) => {
		setChallengeToEdit(challengesRecords.find(ch => ch.id === challenge.id));
	};

	const previewChallenge = (challenge: IChallengeResponse) => {
		setChallengeToPreview(challengesRecords.find(ch => ch.id === challenge.id) || null);
	};

	const handleCloseEditChallenge = (successSubmitType?: boolean) => {
		setChallengeToEdit(undefined);

		if (successSubmitType) {
			setIsOpenChallengeCongratsModal(true);
		}
	};

	useEffect(() => {
		if (challengesRecords.length) {
			const remappedChallenges = challengesRecords.map(challenge => {
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = challenge.description
					.replace(/<\/p>/gi, ' ')
					.replace(/<p>/gi, '')
					.replace(/<br\s*\/?>/gi, ' ');

				return {
					...challenge,
					description: tempDiv.textContent || tempDiv.innerText || '',
				};
			});

			setChallenges(remappedChallenges);
		} else {
			setChallenges([]);
		}
	}, [challengesRecords]);

	return (
		<section className={styles.challenges__wrapper}>
			{challenges.length ? (
				<table className={styles.challenges}>
					<thead>
						<tr>
							<th align="left" className={styles.challenges_photo}>
								Photo
							</th>
							<th align="left" className={styles.challenges_title}>
								Challenge title
							</th>
							<th align="left" className={styles.challenges_subtitle}>
								Subtitle
							</th>
							<th align="left" className={styles.challenges_description}>
								Description
							</th>
							<th align="left" className={styles.challenges_startDate}>
								Date start
							</th>
							<th align="left" className={styles.challenges_deadline}>
								Deadline
							</th>
							<th align="left" aria-label="Actions" />
						</tr>
					</thead>
					<tbody>
						{challenges
							.sort((a, b) => a.id - b.id)
							.map((challenge, index) => (
								<tr key={index}>
									<td className={styles.challenges_photo}>
										<AvatarCell imagePath={challenge.imagePath} />
									</td>
									<td
										className={cx(styles.challenges_title, styles.challenges_row_title)}
										title={challenge.name}
									>
										<span>{challenge.name}</span>
									</td>
									<td
										className={cx(styles.challenges_subtitle, styles.challenges_row_subtitle)}
										title={challenge.title}
									>
										<span>{challenge.title}</span>
									</td>
									<td
										className={cx(styles.challenges_description, styles.challenges_row_description)}
										title={challenge.description}
									>
										<span>{challenge.description}</span>
									</td>
									<td className={styles.challenges_startDate}>
										<div className={styles.challenges_startDate_wrapper}>
											<ClockSvg />
											<span>
												Date start: {moment(challenge.startDate).format('MMMM DD, YYYY')}
											</span>
										</div>
									</td>
									<td className={cx(styles.challenges_deadline)}>
										<div className={styles.challenges_deadline_wrapper}>
											<ClockSvg />
											<span>Deadline: {moment(challenge.endDate).format('MMMM DD, YYYY')}</span>
										</div>
									</td>
									<td align="center">
										<ChallengeActions
											challenge={challenge}
											onEditAction={editChallenge}
											onPreviewAction={previewChallenge}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			) : isLoadingChallenges ? (
				<Loader loadProps={{ parentClassName: styles.loader }} />
			) : (
				<ResponseFailure
					styleTable={true}
					buttonUnshow={true}
					message={
						isSearching
							? "Sorry, we couldn't find any challenges matching your search. Please try again with different keywords or check back later."
							: "Sorry, we couldn't find any challenges at this time. Create them to see them here."
					}
				/>
			)}

			{challengeToEdit ? (
				<ChallengeModal
					isModalOpen={!!challengeToEdit}
					challenge={challengeToEdit}
					onClose={handleCloseEditChallenge}
				/>
			) : null}

			{challengeToPreview ? (
				<ChallengesPreview previewData={challengeToPreview} handlePreviewOpen={setChallengeToPreview} />
			) : null}

			{isOpenChallengeCongratsModal ? (
				<ChallengePopupCongrats setIsShowPopup={setIsOpenChallengeCongratsModal} isNew={false} />
			) : null}
		</section>
	);
};
