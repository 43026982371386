/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/prop-types */
import React, { ReactNode, useEffect } from 'react';
import { ChannelListMessengerProps, useChatContext } from 'stream-chat-react';
import type { Channel } from 'stream-chat';
//components
import { SkeletonLoader } from './SkeletonLoader';
import ChatSearch from 'shared/components/ChatOk/ChatComponents/ChatSearch';
//styles
import './MessagingChannelList.css';

type Props = ChannelListMessengerProps & {
	onCreateChannel: () => void;
	isOrg?: boolean;
	channel: Channel | undefined;
	children?: ReactNode;
};

type ListHeaderWrapperProps = {
	channel?: Channel;
	isOrg?: boolean;
	children: ReactNode;
};

const MessagingChannelList: React.FC<Props> = props => {
	const { children, error = false, loading, channel } = props;

	const ListHeaderWrapper: React.FC<ListHeaderWrapperProps> = ({ children }) => {
		const { setActiveChannel } = useChatContext();

		useEffect(() => {
			if (channel) {
				setActiveChannel(channel);
			}
		}, [channel]);

		return (
			<div className="messaging__channel-list">
				<div className="messaging__channel-list__header">
					<ChatSearch />
				</div>
				{children}
			</div>
		);
	};

	if (error) {
		return (
			<ListHeaderWrapper>
				<div className="messaging__channel-list__message">
					Error loading conversations, please try again momentarily.
				</div>
			</ListHeaderWrapper>
		);
	}

	if (loading) {
		return (
			<ListHeaderWrapper>
				<div className="messaging__channel-list__message">
					<SkeletonLoader />
				</div>
			</ListHeaderWrapper>
		);
	}

	return <ListHeaderWrapper>{children}</ListHeaderWrapper>;
};

export default React.memo(MessagingChannelList);
