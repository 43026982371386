import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import qs from 'qs';
// types
import { useLocation } from 'react-router';
// componens
import InputSearch from 'shared/components/Inputs/InputSearch';
import { OppoContext } from 'shared/components/OppListContent';
// import cx from 'classnames';
// import FilterSvg from 'assets/image/filter.svg';
// import { ISearchOpportunitiesRequest, ISearchVolunteersRequest } from '@joc/api-gateway';
// styles
import styles from './TableMenu.module.scss';

type TableMenuProps = {
	changeVisibilityFilters?: () => void;
	children?: ReactNode;
	searchChangeHandler: (value: string) => void;
};

const TableMenu: FC<TableMenuProps> = ({ changeVisibilityFilters, children, searchChangeHandler }: TableMenuProps) => {
	const location = useLocation();
	const [isDisableFilters, setIsDisableFilters] = useState(false);
	// @ts-ignore
	const { isClearSearch, setIsClearSearch } = useContext(OppoContext);

	useEffect(() => {
		const queryParams = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		});
		queryParams?.groupId ? setIsDisableFilters(true) : setIsDisableFilters(false);
	}, [location.search]);

	return (
		<div className={styles.container}>
			<div className={styles.filter}>
				<InputSearch
					placeholder="Search..."
					classList={['big']}
					changeHandler={searchChangeHandler}
					isClearSearch={isClearSearch as boolean}
					setIsClearSearch={setIsClearSearch}
				/>
				{/* <div
					className={cx(styles.filter__image, isDisableFilters && styles.disabled)}
					onClick={changeVisibilityFilters}
				>
					<img src={FilterSvg} />
				</div> */}
			</div>
			{children && children}
		</div>
	);
};

export default TableMenu;
