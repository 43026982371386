import React, { FC, ReactNode } from 'react';
import cx from 'classnames/bind';
import styles from './TableHead.module.scss';

type TableHeadPropsType = {
	children: ReactNode;
	className?: string;
	callChild?: string;
};

const cxStyle = cx.bind(styles);

const TableHeader: FC<TableHeadPropsType> = ({ children, className, callChild }: TableHeadPropsType) => {
	return (
		<div
			className={cxStyle(className, {
				volunteers: callChild === 'volunteers',
				oppotunities: callChild === 'oppotunities',
				organizations: callChild === 'organizations',
				oppotunitiesVolunteer: callChild === 'oppotunitiesVolunteer',
				viewMore: callChild === 'viewMore',
				users: callChild === 'users',
				roles: callChild === 'roles',
				invites: callChild === 'invites',
			})}
		>
			{children}
		</div>
	);
};

export default TableHeader;
