import { FC, useMemo, useState } from 'react';
// redux
import { Store } from 'redux/root';
import { connect, ConnectedProps } from 'react-redux';
import { resetError } from 'redux/error-service/action';
import { selectorGetError } from 'redux/error-service/selector';
//helpers
import { getErrorMessageTitle } from './helpers';
// components
import OnboardingContentContainer from 'shared/components/OnboardingContentContainer';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ResponseFailure from 'shared/components/ResponseFailure';
import LoginFormV from 'components/Volunteer/LoginFormV';
// styles
import styles from '../Login.module.scss';

const LoginSuperAdmin: FC<LoginVolunteerProps> = ({ error, resetError }: LoginVolunteerProps) => {
	const [userNotFound, setUserNotFound] = useState(false);
	const [isAccessDenied, setIsAccessDenied] = useState(false);

	const handleButtonClickHandler = () => {
		resetError();
		setUserNotFound(false);
		setIsAccessDenied(false);
		localStorage.clear();
	};

	const errorMessageTitle = useMemo(
		() => getErrorMessageTitle(userNotFound, isAccessDenied, error.message),
		[error?.state, userNotFound, isAccessDenied]
	);

	return (
		<OnboardingContentContainer>
			{error?.state || userNotFound || isAccessDenied ? (
				<ResponseFailure
					title={isAccessDenied ? 'Access Denied' : userNotFound ? 'Sorry' : undefined}
					message={errorMessageTitle}
					buttonClickHandler={handleButtonClickHandler}
					buttonTitle="Back to login"
				/>
			) : (
				<div className={styles.content}>
					<Appeal styleLoginV>
						<LoginFormV
							userNotFound={userNotFound}
							setUserNotFound={setUserNotFound}
							setIsAccessDenied={setIsAccessDenied}
						/>
					</Appeal>
				</div>
			)}
		</OnboardingContentContainer>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});

const mapDispatchToProps = {
	resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LoginVolunteerProps = ConnectedProps<typeof connector>;

export default connector(LoginSuperAdmin);
