import { Store } from 'redux/root';
//types
import { UserToConnectType } from './types';

export const selectorGetChatUserToConnect = (store: Store): UserToConnectType | null => {
	return store.chatRedux.userToConnect;
};

export const selectorGetUserConnectionState = (store: Store): boolean => {
	return store.chatRedux.userConnectState;
};

export const selectorGetChatUnreadMessagesCount = (store: Store): number => {
	return store.chatRedux.unreadCount;
};
