import React, { FC, useState } from 'react';
// components

import WhiteContainer from 'shared/components/WhiteContainer';
import OrgInfoForm from 'components/Organization/EditForms/OrgInfoForm';
import { Store } from 'redux/root';
import { selectorGetError } from 'redux/error-service/selector';
import { resetError } from 'redux/error-service/action';
import { connect, ConnectedProps } from 'react-redux';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
// styles
import styles from './Account.module.scss';

type EditOrgParentProps = {
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditOrg: FC<EditOrgProps> = ({ error, resetError, setIsShowPopup }: EditOrgProps) => {
	const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

	return (
		// <MainPageLayout>
		<div className={styles.container}>
			{error.state ? (
				<ResponseFailure
					message={error.message}
					buttonClickHandler={resetError}
					buttonTitle="Check and try again"
					styleTable
				/>
			) : isUpdateSuccess ? (
				<CongratsPopup
					subtitle="Your organisation have been updated successfully"
					doneButtonClickHandler={() => setIsUpdateSuccess(false)}
				/>
			) : (
				<WhiteContainer title="Organization info">
					<OrgInfoForm setIsUpdateSuccess={setIsUpdateSuccess} setIsShowPopup={setIsShowPopup} />
				</WhiteContainer>
			)}
		</div>
		// </MainPageLayout>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});

const mapDispatchToProps = {
	resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditOrgProps = ConnectedProps<typeof connector> & EditOrgParentProps;

export default connector(EditOrg);
