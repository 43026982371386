import { FC, Fragment, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Store } from 'redux/root';
import 'firebase/messaging';
// redux
import { selectorGetChatUserToConnect } from 'redux/chat-service/selector';
import { selectorGetPushMessage } from 'redux/push-service/selector';
import { selectorGetUserData } from 'redux/user-service/selector';
import {
	chatClient,
	connectUserToGetStream,
	setChatUnreadMessagesCount,
	setChatUserToConnect,
} from 'redux/chat-service/actions';
import { setPushMessage } from 'redux/push-service/action';
import { getCurrentUser, setUserNotificationToken } from 'redux/user-service/actions';
import { UserToConnectType } from 'redux/chat-service/types';
// constants
import { urls } from 'core/appUrls';
// functions
import { setUserPhotoSrc } from 'core/functions';
import { fireBaseGetToken, fireBaseMessageListener } from 'core/functions/firebaseNotification';
// components
import DashboardPage from 'pages/Organization/Dashboard';
import InboxPage from 'pages/Organization/Inbox';
import SnackbarInfo from 'shared/components/Snackbars/SnackarInfo';
import NotFound from 'pages/NotFound';
import SuperAdminOppoList from 'pages/Admin/SuperAdminOppoList';
import SuperAdminVolList from 'pages/Admin/SuperAdminVolList';
import SuperAdminOrgList from 'pages/Admin/SuperAdminOrgList';
import SuperAdminUserList from 'pages/Admin/SuperAdminUserList';
import SuperAdminMarketing from 'pages/Admin/SuperAdminMarketing';
import LoginSuperAdmin from 'pages/Login/LoginSuperAdmin';
import AccessDenied from 'pages/AccessDenied';
import SuperLiveScreen from 'pages/Admin/SuperLiveScreen';
import SuperAdminInviteList from 'pages/Admin/SuperAdminInviteList';
import './components/translate/index';
// styles
import './App.css';

const App: FC<AppPropsType> = ({
	userData,
	getCurrentUser,
	setPushMessage,
	setUserNotificationToken,
	userToConnect,
	setChatUserToConnect,
	setChatUnreadMessagesCount,
	connectUserToGetStream,
}) => {
	// const [isTokenFound, setTokenFound] = useState(false);
	const history = useHistory();

	const getChatUnreadMessagesCountListener = (event: any) => {
		const { unread_count } = event;
		if (unread_count !== undefined) {
			// if (unread_count !== undefined && messagesUnreadCount !== unread_count) {
			setChatUnreadMessagesCount(unread_count);
		}
	};

	useEffect(() => {
		if (!userData) return;
		if (userData?.notificationTokens?.length) {
			try {
				fireBaseMessageListener()
					.then((payload: any) => {
						setPushMessage({ title: payload.notification.title, body: payload.notification.body });
					})
					.catch(err => console.log('failed: ', err));
			} catch (error: any) {
				console.warn(error);
			}
		}
		if (!localStorage.getItem('pushToken')) {
			fireBaseGetToken()
				.then(token => {
					localStorage.setItem('pushToken', token as string);
					return token;
				})
				.then(token => {
					if (!userData?.notificationTokens?.some(el => el.token === token)) {
						setUserNotificationToken(token as string);
					}
				})
				.catch(err => {
					localStorage.removeItem('pushToken');
					console.log('failed: ', err);
				});
		}

		// add initialisation chat
		if (userData?.chatId) {
			const userToConnect: UserToConnectType = {
				id: userData.chatId,
				name: `${userData?.firstName || 'JOC'} ${userData?.lastName || 'administrator'}`,
				image: setUserPhotoSrc(userData?.imagePath || ''),
			};
			setChatUserToConnect(userToConnect);
		}
	}, [userData?.fireBaseUserId]);

	useEffect(() => {
		const localStorageAccessToken = localStorage.getItem('accessToken');
		if (!localStorageAccessToken) {
			history.push(urls.loginSuperAdmin);
		} else if (localStorageAccessToken) {
			getCurrentUser();
		}
	}, []);

	useEffect(() => {
		if (userToConnect && chatClient) {
			connectUserToGetStream(userToConnect);
			chatClient.on('notification.message_new', getChatUnreadMessagesCountListener);
			chatClient.on('notification.mark_read', getChatUnreadMessagesCountListener);
		}
	}, [userToConnect]);

	return (
		<Fragment>
			<SnackbarInfo />
			<Switch>
				<Route exact path={urls.loginSuperAdmin} component={LoginSuperAdmin} />
				<Route exact path={urls.main} component={() => <Redirect to={urls.loginSuperAdmin} />} />
				<Route exact path={urls.inbox} component={InboxPage} />
				<Route exact path={urls.dashboard} component={DashboardPage} />
				<Route exact path={urls.supperAdminOppoList} component={SuperAdminOppoList} />
				<Route exact path={urls.live} component={SuperLiveScreen} />
				<Route exact path={urls.supperAdminUserList} component={SuperAdminUserList} />
				<Route exact path={urls.marketing} component={SuperAdminMarketing} />
				<Route exact path={urls.supperAdminVolList} component={SuperAdminVolList} />
				<Route exact path={urls.supperAdminOrgList} component={SuperAdminOrgList} />
				<Route exact path={urls.supperAdminInvitList} component={SuperAdminInviteList} />
				<Route exact path={urls.dashboard} component={DashboardPage} />
				<Route exact path={urls.accessDenied} component={AccessDenied} />
				{/* TODO: not working */}
				<Route component={NotFound} />
			</Switch>
		</Fragment>
	);
};
const mapStateToProps = (store: Store) => ({
	userData: selectorGetUserData(store),
	initialText: selectorGetPushMessage(store),
	userToConnect: selectorGetChatUserToConnect(store),
});
const mapDispatchToProps = {
	getCurrentUser,
	setPushMessage,
	setUserNotificationToken,
	setChatUnreadMessagesCount,
	setChatUserToConnect,
	connectUserToGetStream,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type AppPropsType = ConnectedProps<typeof connector>;

export default connector(App);
