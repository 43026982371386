import { ComponentType } from 'react';
import { TypingIndicator, TypingIndicatorProps } from 'stream-chat-react';
import CustomAvatar from '../CustomAvatar';
//styles
import './CustomTypingIndicator.css';

// export const CustomTypingIndicator = () => {
// const { client } = useChatContext<DefaultStreamChatGenerics>();
//
// const { typing } = useMessageInputContext<DefaultStreamChatGenerics>();
//
// if (!client || !typing) return null;
//
// const users = Object.values(typing)
// 	.filter(({ user }: any) => user?.id !== client.user?.id)
// 	.map(({ user }: any) => user?.name || user?.id);
//
// let text = '';
//
// if (users.length === 1) {
// 	text = `${users[0]} is typing`;
// } else if (users.length === 2) {
// 	text = `${users[0]} and ${users[1]} are typing`;
// } else if (users.length > 2) {
// 	text = `${users[0]} and ${users.length - 1} more are typing`;
// }
//
// return (
// 	<div className="messaging__typing-indicator">
// 		{text && (
// 			<div className="dots">
// 				<span className="dot" />
// 				<span className="dot" />
// 				<span className="dot" />
// 			</div>
// 		)}
// 		<div>{text}</div>
// 	</div>
// );
// };

export const CustomTypingIndicator: ComponentType<TypingIndicatorProps> = () => (
	<>
		<TypingIndicator Avatar={CustomAvatar} avatarSize={32} />
	</>
);
