import MainPageLayout from 'components/Organization/MainPageLayout';
import React, { FC } from 'react';

import OrgListContent from 'shared/components/OrgListContent';
import styles from './SuperAdminOrgList.module.scss';

const SuperAdminOrgList: FC = () => {
	return (
		<MainPageLayout>
			<div className={styles.container}>
				<div className={styles.block_title}>
					<h1 className="title">Organisations List</h1>
					<OrgListContent />
				</div>
			</div>
		</MainPageLayout>
	);
};

export default SuperAdminOrgList;
