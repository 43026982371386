import React, { FC, useMemo, useState } from 'react';
import moment from 'moment-mini';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { deleteDailyQuote } from 'redux/dailyQuotes-service/actions';
import { Store } from 'redux/root';

// components
import { AddQuoteModal } from '../AddQuoteModal';
import { Menu, MenuItem, IconButton } from '@material-ui/core';

// icons
import { ReactComponent as EditSvg } from 'assets/image/icons/edit.svg';
import { ReactComponent as DeleteSvg } from 'assets/image/trash-light.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/image/threeDots.svg';

// types
import { IQuoteResponse } from '@joc/api-gateway';

// styles
import styles from './DailyQuotes.module.scss';

export const DailyQuotesTable = () => {
	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [quotesToEdit, setQuotesToEdit] = useState<IQuoteResponse | undefined>(undefined);
	const [openItemId, setOpenItemId] = useState<number>(0);
	const dailyQuotesRecords = useSelector((store: Store) => store.quotesRedux.quotesRecords);

	const handleActionsButtonClose = () => {
		setAnchorEl(null);
		setOpenItemId(0);
	};

	const handleEditQuote = (quote: IQuoteResponse) => {
		handleActionsButtonClose();
		setQuotesToEdit(quote);
	};

	const handleDeleteQuote = (quote: IQuoteResponse) => {
		handleActionsButtonClose();
		dispatch(deleteDailyQuote(quote));
	};

	const closeEditQuote = () => {
		setQuotesToEdit(undefined);
	};

	const handleActionsButtonClick = (event: React.MouseEvent<HTMLElement>, quote: IQuoteResponse) => {
		setAnchorEl(event.currentTarget);
		setOpenItemId(quote.id);
	};

	return (
		<>
			<table className={styles.quotesTable}>
				<thead>
					<tr>
						<th className={styles.quotesTableDate}>Date</th>
						<th className={styles.quotesTableName}>Daily Quotes</th>
						<th aria-label="Actions" />
					</tr>
				</thead>
				<tbody>
					{dailyQuotesRecords.length
						? dailyQuotesRecords
								.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
								.map((quote, index) => (
									<tr key={index}>
										<td
											className={styles.quotesTableDate}
											title={moment(quote.startDate).format('DD MMM')}
										>
											<span>{moment(quote.startDate).format('DD MMM')}</span>
										</td>
										<td className={styles.quotesTableName} title={quote.message}>
											<span>{quote.message}</span>
										</td>
										<td align="center">
											<IconButton
												aria-label="actions"
												aria-controls={openItemId === quote.id ? 'long-menu' : undefined}
												onClick={event => handleActionsButtonClick(event, quote)}
											>
												<ThreeDotsSvg />
											</IconButton>
											<Menu
												open={openItemId === quote.id}
												anchorEl={anchorEl}
												onClose={handleActionsButtonClose}
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}}
												transformOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}}
											>
												<MenuItem
													className={styles.menuItem}
													onClick={() => handleEditQuote(quote)}
												>
													<div className={styles.actionsContainer}>
														<EditSvg
															style={{ width: '16px', height: '16px' }}
															title="Delete quote"
														/>
														<span className={styles.actionsText}>Edit</span>
													</div>
												</MenuItem>
												<MenuItem
													className={styles.menuItem}
													onClick={() => handleDeleteQuote(quote)}
												>
													<div className={styles.actionsContainer}>
														<DeleteSvg
															style={{ width: '16px', height: '16px' }}
															title="Delete quote"
														/>
														<span className={styles.actionsText}>Delete</span>
													</div>
												</MenuItem>
											</Menu>
										</td>
									</tr>
								))
						: null}
				</tbody>
			</table>
			{(quotesToEdit && (
				<AddQuoteModal isModalOpen={Boolean(quotesToEdit)} onClose={closeEditQuote} quote={quotesToEdit} />
			)) || <></>}
		</>
	);
};
