import React, { FC, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment-mini';
// types
import {
	ICreateOpportunityRequest,
	// ICreateOpportunityRequest,
	IOpportunityResponse,
	IUpdateOpportunityRequest,
	OPPORTUNITY_STATUSES,
	// OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
// redux
import { Store } from 'redux/root';
import { selectorGetError } from 'redux/error-service/selector';
import { selectorGetUserId } from 'redux/user-service/selector';
import { resetError, setError } from 'redux/error-service/action';
import { createOpportunity, updateOpportunity } from 'redux/opportunities-service/action';
// components
import ResponseFailure from 'shared/components/ResponseFailure';
import PopupCongrats from '../PopupCongrats';
import OpportunityForm from '../../OpportunityForm';

type PopupUpdateParentProps = {
	setIsShowUpdateOpportunityPopup: React.Dispatch<React.SetStateAction<boolean>>;
	opportunityData: IOpportunityResponse & { opportunityPoints?: number; volunteerRank?: number };
};

const PopupUpdate: FC<PopupUpdateProps> = ({
	setIsShowUpdateOpportunityPopup,
	setError,
	resetError,
	error,
	opportunityData,
	updateOpportunity,
	userId,
}: PopupUpdateProps) => {
	// const [isVirtualOpportunity, setIsVirtualOpportunity] = useState(false);
	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const initialValues: IUpdateOpportunityRequest | ICreateOpportunityRequest = useMemo(() => {
		return {
			opportunityName: opportunityData.opportunityName,
			opportunityDescription: opportunityData.opportunityDescription,
			isApprovalRequired: opportunityData.isApprovalRequired || false,
			startDate: new Date(opportunityData.startDate),
			endDate: new Date(opportunityData.endDate),
			startTime: moment(opportunityData.startDate).format('HH:mm'),
			endTime: moment(opportunityData.endDate).format('HH:mm'),
			userMinAge: opportunityData.userMinAge,
			userMaxAge: opportunityData.userMaxAge,
			user: opportunityData?.user?.id ? +opportunityData?.user?.id : userId,
			withWho: opportunityData.withWho || [],
			opportunityType: opportunityData.opportunityType,
			opportunityPoints: opportunityData?.opportunityPoints,
			volunteerRank: opportunityData?.volunteerRank,
			isVirtual: opportunityData.isVirtual,
			gender: opportunityData?.gender?.id,
			address: opportunityData?.address,
			opportunityUrl: opportunityData?.opportunityUrl,
		};
	}, [opportunityData]);

	const submitClickHandler = async (values: IUpdateOpportunityRequest): Promise<void> => {
		setIsLoading(true);
		try {
			await updateOpportunity(opportunityData.id, values);
			setIsLoading(false);
			setIsSubmited(true);
		} catch (error: any) {
			setIsLoading(false);
			setError(error?.response?.message || error.message, error.code);
		}
	};

	if (error.state)
		return (
			<ResponseFailure
				styleTable={true}
				message={error.message ? error.message : 'Something went wrong. Please try again'}
				buttonClickHandler={resetError}
				buttonTitle="Try again"
			/>
		);
	if (isSubmited)
		return <PopupCongrats setIsShowPopup={setIsShowUpdateOpportunityPopup} setIsSubmited={setIsSubmited} />;
	return (
		<>
			<h2 className="title">Opportunity info</h2>
			<OpportunityForm
				isLoading={isLoading}
				initialValues={initialValues}
				submitClickHandler={submitClickHandler}
				statusClose={opportunityData.status === OPPORTUNITY_STATUSES.CLOSED}
			/>
		</>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
	userId: selectorGetUserId(store),
});

const mapDispatchToProps = {
	updateOpportunity,
	setError,
	resetError,
	createOpportunity,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PopupUpdateProps = ConnectedProps<typeof connector> & PopupUpdateParentProps;

export default connector(PopupUpdate);
