import React, { FC, ReactNode } from 'react';
import Logo from '../Logo';
import styles from './index.module.scss';

type OnboardingContentContainerType = {
	children: ReactNode;
	disableOnboardingLogoLink?: boolean;
};

const OnboardingContentContainer: FC<OnboardingContentContainerType> = ({
	children,
	disableOnboardingLogoLink,
}: OnboardingContentContainerType) => {
	return (
		<div className={styles.content__container}>
			<Logo isOnboarding={!disableOnboardingLogoLink} />
			<div className={styles.content__wrapper}>{children}</div>
		</div>
	);
};

export default OnboardingContentContainer;
