import React, { FC } from 'react';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { IChallengeResponse } from '@joc/api-gateway';

import Editor from '../../../../Editor';
import ButtonOutlined from '../../../../Buttons/ButtonOutlined';
import ChesedIdeas from '../ChesedIdeas';
import Loader from '../../../../Loader';
import InputText from '../../../../FormInputs/InputText';
import InlineDatePicker from '../../../../Pickers/InlineDatePicker';
import InputErrorHint from '../../../../InputErrorHint';
import ButtonDefault from '../../../../Buttons/ButtonsDefault';
import PlaceholderImageField from '../../../../../../components/Organization/Placeholders/EditPlaceholderDialog/PlaceholderImageField';
import CustomTimePicker from '../../../../Pickers/CustomTimePicker';

import { Store } from '../../../../../../redux/root';

import { ReactComponent as Plus } from 'assets/image/plus.svg';

import { ChallengeFormValuesType } from '../../types';

import { MAX_NAME_LENGTH, MAX_TITLE_LENGTH } from '../../constants';

import styles from './ChallengeForm.module.scss';

export type ChallengeFormProps = {
	isNew: boolean;
	onClose?: () => void;
	// eslint-disable-next-line no-unused-vars
	onPreview?: (previewData: Partial<IChallengeResponse>) => void;
};

const ChallengeForm: FC<ChallengeFormProps> = ({ isNew = false, onClose, onPreview }) => {
	const isLoading = useSelector((store: Store) => store.challengeRedux.isLoading);

	const handleCancel = () => {
		if (onClose) onClose();
	};

	const { setFieldValue, setFieldTouched, dirty, isValid, values, touched } =
		useFormikContext<ChallengeFormValuesType>();

	const changeDateHandler = (field: string, value: Date) => {
		setFieldValue(field, value).then();
	};

	const addMoreIdeasHandler = () => {
		setFieldValue('chesedIdeas', [...values.chesedIdeas, '']).then();
	};

	const handlePreviewOpen = () => onPreview && onPreview(values);

	return (
		<Form className={styles.ChallengeForm__form}>
			<span className={styles.ChallengeForm__form_title}>
				{isNew ? 'Create new Challenge' : 'Edit Challenge'}
			</span>
			<PlaceholderImageField name="imagePath" label="Main Photo" maxMegabyteSize={100} toS3bucket={true} />
			<Field name="name">
				{({ field, meta }: FieldProps<string, IChallengeResponse>) => (
					<div className={styles.ChallengeForm__form_item}>
						<label className={styles.ChallengeForm__form_label} htmlFor={field.name}>
							Challenge title<span className={styles.ChallengeForm__form_required}>*</span>
						</label>
						<InputText
							id={field.name}
							field={field}
							placeholder="Challenge title"
							setFieldValue={setFieldValue}
						/>

						{(meta.error && touched.name && (
							<InputErrorHint errorText={meta.error} disableMargin={true} />
						)) || (
							<span
								className={cx(styles.ChallengeForm__form_limit, {
									[styles.ChallengeForm__form_limit_error]:
										field.value.trim().length > MAX_NAME_LENGTH,
								})}
							>
								Maximum characters: {field.value.trim().length}/{MAX_NAME_LENGTH}
							</span>
						)}
					</div>
				)}
			</Field>
			<Field name="title">
				{({ field, meta }: FieldProps<string, IChallengeResponse>) => (
					<div className={styles.ChallengeForm__form_item}>
						<label className={styles.ChallengeForm__form_label} htmlFor={field.name}>
							Challenge subtitle<span className={styles.ChallengeForm__form_required}>*</span>
						</label>
						<InputText
							id={field.name}
							field={field}
							placeholder="Challenge subtitle"
							setFieldValue={setFieldValue}
						/>

						{(meta.error && touched.title && (
							<InputErrorHint errorText={meta.error} disableMargin={true} />
						)) || (
							<span
								className={cx(styles.ChallengeForm__form_limit, {
									[styles.ChallengeForm__form_limit_error]:
										field.value.trim().length > MAX_TITLE_LENGTH,
								})}
							>
								Maximum characters: {field.value.trim().length}/{MAX_TITLE_LENGTH}
							</span>
						)}
					</div>
				)}
			</Field>
			<>
				<Field name="description">
					{({ field, meta, form }: FieldProps<string, IChallengeResponse>) => (
						<div className={styles.ChallengeForm__form_item}>
							<label className={styles.ChallengeForm__form_label} htmlFor={field.name}>
								Description<span className={styles.ChallengeForm__form_required}>*</span>
							</label>
							<Editor
								fieldValue={field.value}
								onChange={value => form.setFieldValue('description', value)}
								onBlur={() => form.setFieldTouched(field.name, true)}
								placeholder="Enter your description"
							/>
							{meta.error && touched.description && (
								<InputErrorHint errorText={meta.error} disableMargin={true} />
							)}
						</div>
					)}
				</Field>
			</>
			<div className={styles.ChallengeForm__form_pickers}>
				<Field name="startDate">
					{({ field, meta }: FieldProps) => (
						<div className={cx(styles.ChallengeForm__form_item, styles.ChallengeForm__form_pickers_date)}>
							<label className={styles.ChallengeForm__form_label} htmlFor={field.name}>
								Start Date<span className={styles.ChallengeForm__form_required}>*</span>
							</label>
							<InlineDatePicker
								fieldName={field.name}
								fieldValue={field.value}
								setFieldValue={changeDateHandler}
								availableFuture={true}
							/>
							{meta.error && touched.startDate && <InputErrorHint errorText={meta.error} />}
						</div>
					)}
				</Field>
				<Field name="startTime">
					{({ field, meta }: FieldProps) => (
						<div className={cx(styles.ChallengeForm__form_item, styles.ChallengeForm__form_pickers_time)}>
							<label className={styles.ChallengeForm__form_label} htmlFor="startDay">
								Start Time<span className={styles.ChallengeForm__form_required}>*</span>
							</label>
							<CustomTimePicker
								fieldName={field.name}
								fieldValue={field.value && field.value}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
							/>
							{meta.error && touched.endDate && <InputErrorHint errorText={meta.error} />}
						</div>
					)}
				</Field>
			</div>
			<div className={styles.ChallengeForm__form_pickers}>
				<Field name="endDate">
					{({ field, meta }: FieldProps) => (
						<div className={cx(styles.ChallengeForm__form_item, styles.ChallengeForm__form_pickers_date)}>
							<label className={styles.ChallengeForm__form_label} htmlFor={field.name}>
								Deadline Date<span className={styles.ChallengeForm__form_required}>*</span>
							</label>
							<InlineDatePicker
								fieldName={field.name}
								fieldValue={field.value}
								setFieldValue={changeDateHandler}
								availableFuture={true}
							/>
							{meta.error && touched.startDate && <InputErrorHint errorText={meta.error} />}
						</div>
					)}
				</Field>
				<Field name="endTime">
					{({ field, meta }: FieldProps) => (
						<div className={cx(styles.ChallengeForm__form_item, styles.ChallengeForm__form_pickers_time)}>
							<label className={styles.ChallengeForm__form_label} htmlFor={field.name}>
								Deadline Time<span className={styles.ChallengeForm__form_required}>*</span>
							</label>
							<CustomTimePicker
								fieldName={field.name}
								fieldValue={field.value && field.value}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
							/>
							{meta.error && touched.endDate && <InputErrorHint errorText={meta.error} />}
						</div>
					)}
				</Field>
			</div>
			<Field name="chesedIdeas">
				{({ field, form }: FieldProps<Array<string>, IChallengeResponse>) => (
					<div className={cx(styles.ChallengeForm__form_item, styles.ChallengeForm__form_item__ideas)}>
						<ChesedIdeas
							fieldValue={field.value}
							onChange={value => form.setFieldValue('chesedIdeas', value)}
						/>

						<ButtonOutlined
							title="Add more"
							clickHandler={addMoreIdeasHandler}
							parentClassName={styles.ChallengeForm__form_item__ideas_addMore}
							icon={<Plus style={{ width: '16px', height: '16px' }} />}
						/>
					</div>
				)}
			</Field>
			<div className={styles.ChallengeForm__footer}>
				{isLoading ? (
					<Loader />
				) : (
					<div className={styles.ChallengeForm__footer_actions}>
						<ButtonDefault
							classList={['secondary', 'lg']}
							parentClassName={styles.ChallengeForm__footer_button}
							title="Preview"
							clickHandler={handlePreviewOpen}
						/>

						<div className={styles.ChallengeForm__footer_actions__right}>
							<ButtonDefault
								classList={['secondary', 'lg']}
								parentClassName={styles.ChallengeForm__footer_button}
								title="Cancel"
								clickHandler={handleCancel}
							/>
							<ButtonDefault
								submitType={true}
								classList={['primary', 'lg']}
								parentClassName={cx(styles.ChallengeForm__footer_button, {
									[styles.disable_submit]: !dirty || !isValid,
								})}
								title={isNew ? 'Create' : 'Save'}
								disabled={!dirty || !isValid}
							/>
						</div>
					</div>
				)}
			</div>
		</Form>
	);
};

export default ChallengeForm;
