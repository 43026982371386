import { FC, useState } from 'react';
import cx from 'classnames';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IUpdateOpportunityRequest, OPPORTUNITY_TYPES } from '@joc/api-gateway';
// core
import {
	endTimeSchema,
	opportunityApprovalRequired,
	opportunityDateSchema,
	opportunityDescriptionSchema,
	opportunityNameSchema,
	opportunityTypesSchema,
	opportunityUserSchema,
	startTimeSchema,
	addressSchema,
} from 'core/validation';
// icons
import ArrowSvg from 'assets/image/button-arrow-dark.svg';
// components
import InputText from 'shared/components/FormInputs/InputText';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import SelectDropdown from 'shared/components/Selects/SelectDropdown/SingleSelect';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputErrorHint from 'shared/components/InputErrorHint';
import InputTextArea from 'shared/components/FormInputs/InputTextArea';
import GenderSelect from 'shared/components/Selects/SelectDropdown/GenderSelect';
import InlineDatePicker from 'shared/components/Pickers/InlineDatePicker';
import Loader from 'shared/components/Loader';
import WithWhoFilter from 'shared/components/FiltersFields/WithWhoFilter';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import CustomTimePicker from 'shared/components/Pickers/CustomTimePicker';
// styles
import styles from './OpportunityForm.module.scss';

const opportunityValidationSchemaWithAddress = Yup.object().shape({
	opportunityName: opportunityNameSchema,
	opportunityDescription: opportunityDescriptionSchema,
	opportunityType: opportunityTypesSchema,
	isApprovalRequired: opportunityApprovalRequired,
	startTime: startTimeSchema,
	endTime: endTimeSchema,
	user: opportunityUserSchema,
	startDate: opportunityDateSchema,
	address: addressSchema,
});
const opportunityValidationSchemaWithUrl = Yup.object().shape({
	opportunityName: opportunityNameSchema,
	opportunityDescription: opportunityDescriptionSchema,
	opportunityType: opportunityTypesSchema,
	isApprovalRequired: opportunityApprovalRequired,
	startTime: startTimeSchema,
	endTime: endTimeSchema,
	user: opportunityUserSchema,
	startDate: opportunityDateSchema,
	// TODO: fix it
});

type OpportunityFormProps = {
	initialValues: IUpdateOpportunityRequest;
	submitClickHandler: (values: IUpdateOpportunityRequest) => Promise<void>;
	isLoading: boolean;
	statusClose?: boolean;
};

const OpportunityForm: FC<OpportunityFormProps> = ({
	initialValues,
	isLoading,
	submitClickHandler,
	statusClose,
}: OpportunityFormProps) => {
	const [isShowAdditional, setIsShowAdditional] = useState({ isShow: false, isClose: false });
	const [isVirtualOpportunity, setIsVirtualOpportunity] = useState(initialValues?.isVirtual);
	const [isPrivateOpportunity, setIsPrivateOpportunity] = useState(
		initialValues.opportunityType === OPPORTUNITY_TYPES.PRIVATE
	);

	const changeVisibilityAdditional = () => {
		setIsShowAdditional({ isShow: isShowAdditional.isShow, isClose: !isShowAdditional.isClose });
		setTimeout(
			() => setIsShowAdditional({ isShow: !isShowAdditional.isShow, isClose: isShowAdditional.isClose }),
			200
		);
	};

	return (
		<div className={styles.form__container}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={
					isVirtualOpportunity ? opportunityValidationSchemaWithUrl : opportunityValidationSchemaWithAddress
				}
				onSubmit={values => {
					submitClickHandler(values);
				}}
			>
				{({ errors, touched, setFieldValue, setFieldTouched, values, dirty }) => (
					<Form className={styles.form}>
						<Field name="opportunityName">
							{({ field }: FieldProps) => (
								<InputContainer>
									<label className={styles.label} htmlFor="opportunityName">
										What?
									</label>
									<InputText
										id="opportunityName"
										fieldValue={field.value}
										field={field}
										placeholder="Opportunity name"
										setFieldValue={setFieldValue}
									/>
									{errors.opportunityName && touched.opportunityName && (
										<InputErrorHint errorText={errors.opportunityName} />
									)}
								</InputContainer>
							)}
						</Field>
						<Field name="opportunityDescription">
							{({ field }: FieldProps) => (
								<InputContainer textAreaStyle>
									<label className={styles.label} htmlFor="opportunityDescription">
										Description:
									</label>
									<InputTextArea
										id="opportunityDescription"
										field={field}
										placeholder="Opportunity description"
										setFieldValue={setFieldValue}
									/>
									{errors.opportunityDescription && touched.opportunityDescription && (
										<InputErrorHint errorText={errors.opportunityDescription} />
									)}
								</InputContainer>
							)}
						</Field>
						<div className={styles.form__pickers}>
							<div className={styles.form__pickers_date}>
								<Field name="startDate">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="startDate">
												When?
											</label>
											<InlineDatePicker
												fieldName={field.name}
												fieldValue={field.value}
												setFieldValue={setFieldValue}
												withEndDay
												availablePast
												availableFuture
											/>
											{errors.startDate && touched.startDate && (
												<InputErrorHint errorText={errors.startDate as string} />
											)}
										</InputContainer>
									)}
								</Field>
							</div>
							<div className={styles.form__pickers_time}>
								<Field name="startTime">
									{({ field }: FieldProps) => (
										<InputContainer parentClassnames={styles.timer}>
											<label className={styles.label} htmlFor="startTime">
												Start time:
											</label>
											<CustomTimePicker
												fieldName={field.name}
												fieldValue={field.value && field.value}
												setFieldValue={setFieldValue}
												setFieldTouched={setFieldTouched}
											/>
											{errors.startDate && touched.startDate && (
												<InputErrorHint errorText={errors.startDate} />
											)}
										</InputContainer>
									)}
								</Field>
								<Field name="endTime">
									{({ field }: FieldProps) => (
										<InputContainer parentClassnames={styles.timer}>
											<label className={styles.label}>End time:</label>
											<CustomTimePicker
												fieldName={field.name}
												fieldValue={field.value && field.value}
												setFieldValue={setFieldValue}
												setFieldTouched={setFieldTouched}
											/>
											{errors.endDate && touched.endDate && (
												<InputErrorHint errorText={errors.endDate} />
											)}
										</InputContainer>
									)}
								</Field>
							</div>
						</div>
						<div className={styles.form__opportunitie_address}>
							<ButtonDefault
								classList={['secondary', !isVirtualOpportunity ? 'secondary_active' : '']}
								title="Local"
								clickHandler={() => {
									values.isVirtual = false;
									setIsVirtualOpportunity(false);
								}}
							/>
							<ButtonDefault
								classList={['secondary', isVirtualOpportunity ? 'secondary_active' : '']}
								title="Virtual"
								clickHandler={() => {
									values.isVirtual = true;
									setIsVirtualOpportunity(true);
								}}
							/>
						</div>
						<div className={styles.form__location}>
							{isVirtualOpportunity ? (
								<Field name="opportunityUrl">
									{({ field }: FieldProps) => (
										<InputContainer>
											<InputText
												field={field}
												placeholder="Opportunity url or phone number"
												setFieldValue={setFieldValue}
												fieldValue={field.value}
											/>
										</InputContainer>
									)}
								</Field>
							) : (
								<InputContainer>
									<Field name="address">
										{({ field }: FieldProps) => (
											<GoogleApiAutoCompleat
												fieldName={field.name}
												selectHandler={address => setFieldValue(field.name, address)}
												fieldValue={field.value}
												setFieldTouched={setFieldTouched}
												placeholder="Opportunity location"
											/>
										)}
									</Field>
									{errors.address && touched.address && (
										<InputErrorHint addressErrorText={errors.address} />
									)}
								</InputContainer>
							)}
						</div>
						<Field name="isApprovalRequired">
							{({ field }: FieldProps) => (
								<InputContainer>
									<div className={styles.form__join_type}>
										<ButtonDefault
											classList={[
												'secondary',
												'lg',
												field.value === false ? 'secondary_active' : '',
											]}
											title="Anyone can join"
											clickHandler={() => setFieldValue(field.name, false)}
										/>
										<ButtonDefault
											classList={[
												'secondary',
												'lg',
												field.value === true ? 'secondary_active' : '',
											]}
											title="Approval required"
											clickHandler={() => setFieldValue(field.name, true)}
										/>
										{errors.isApprovalRequired && touched.isApprovalRequired && (
											<InputErrorHint errorText={errors.isApprovalRequired} />
										)}
									</div>
								</InputContainer>
							)}
						</Field>
						<Field name="opportunityType">
							{({ field, form }: FieldProps<string>) => (
								<div className={styles.form__opportunitie_address}>
									<ButtonDefault
										disabled={statusClose}
										classList={['secondary', !isPrivateOpportunity ? 'secondary_active' : '']}
										title="Public"
										clickHandler={() => {
											form.setFieldValue(field.name, OPPORTUNITY_TYPES.PUBLIC);
											setIsPrivateOpportunity(false);
										}}
									/>

									<ButtonDefault
										disabled={statusClose}
										classList={['secondary', isPrivateOpportunity ? 'secondary_active' : '']}
										title="Private"
										clickHandler={() => {
											form.setFieldValue(field.name, OPPORTUNITY_TYPES.PRIVATE);
											setIsPrivateOpportunity(true);
										}}
									/>
								</div>
							)}
						</Field>

						<div className={styles.additional}>
							<span
								className={cx(styles.label, styles.additional__title)}
								onClick={changeVisibilityAdditional}
							>
								Additional Info
								<img
									className={cx(
										styles.additional__title_arrow,
										isShowAdditional.isShow && styles.additional__title_arrow_active
									)}
									src={ArrowSvg}
									alt=""
								/>
							</span>
							{isShowAdditional.isShow && (
								<div
									className={cx(
										styles.additional__content,
										isShowAdditional.isClose && styles.additional__content_close
									)}
								>
									<div className={styles.form__forWho}>
										<InputContainer isDisabledMargin isDisableHeight>
											<WithWhoFilter setFieldValue={setFieldValue} fieldValues={values.withWho} />
										</InputContainer>
									</div>
									<span className={styles.label}>Volunteer details:</span>
									<div className={styles.details__content}>
										<div className={styles.ageLimit}>
											<Field name="userMinAge">
												{({ field }: FieldProps) => (
													<InputContainer isDisabledMargin>
														<SelectDropdown
															id="userMinAge"
															field={field}
															placeholder="Min age"
															options={new Array(61)
																.fill(0)
																.map((i, index) => index + 15)}
															setFieldValue={setFieldValue}
															setFieldTouched={setFieldTouched}
															classList={['md']}
														/>
														{errors.userMinAge && touched.userMinAge && (
															<InputErrorHint errorText={errors.userMinAge} />
														)}
													</InputContainer>
												)}
											</Field>
											<Field name="userMaxAge">
												{({ field }: FieldProps) => (
													<InputContainer isDisabledMargin>
														<SelectDropdown
															id="userMaxAge"
															field={field}
															placeholder="Max age"
															options={new Array(61)
																.fill(0)
																.map((i, index) => index + 15)}
															setFieldValue={setFieldValue}
															setFieldTouched={setFieldTouched}
															classList={['md']}
														/>
														{errors.userMaxAge && touched.userMaxAge && (
															<InputErrorHint errorText={errors.userMaxAge} />
														)}
													</InputContainer>
												)}
											</Field>
										</div>
										<div className={styles.moreDetails}>
											<Field name="gender">
												{({ field }: FieldProps) => (
													<InputContainer>
														<GenderSelect
															bothGenders
															fieldName={field.name}
															fieldValue={field.value}
															setFieldValue={setFieldValue}
															setFieldTouched={setFieldTouched}
															fieldTouched={touched.gender}
															classList={['md']}
														/>
														{errors.gender && touched.gender && (
															<InputErrorHint errorText={errors.gender} />
														)}
													</InputContainer>
												)}
											</Field>
										</div>
									</div>
								</div>
							)}
						</div>
						{isLoading ? (
							<Loader />
						) : (
							<ButtonDefault submitType classList={['primary', 'lg']} title="Confirm" disabled={!dirty} />
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default OpportunityForm;
