import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import dashboardReducer from './dashboard-service/reducer';
import errorReducer from './error-service/reducer';
import opportunitiesReducer from './opportunities-service/reducer';
import organizationReducer from './organization-service/reducer';
import userReducer from './user-service/reducer';
import volunteersReducer from './volunteer-service/reducer';
import invitesReducer from './invite-service/reducer';
import smartGroupsReducer from './smartGroups-service/reducer';
import pushesReducer from './push-service/reducer';
import chatReducer from './chat-service/reducer';
import companiesUsersReducer from './companiesUsers-service/reducer';
import placeholdersReduces from './placeholders-service/reducer';
import quotesReducer from './dailyQuotes-service/reducers';
import challengesReducer from './challenges-service/reducers';

const rootReducer = combineReducers({
	// Add other reducers
	userRedux: userReducer,
	organizationRedux: organizationReducer,
	opportunitiesRedux: opportunitiesReducer,
	volunteersRedux: volunteersReducer,
	quotesRedux: quotesReducer,
	invitesRedux: invitesReducer,
	dashboardRedux: dashboardReducer,
	smartGroupsRedux: smartGroupsReducer,
	errorRedux: errorReducer,
	pushesRedux: pushesReducer,
	chatRedux: chatReducer,
	companiesUsersRedux: companiesUsersReducer,
	placeholdersRedux: placeholdersReduces,
	challengeRedux: challengesReducer,
});

// Configure redux
const composeEnhancers = compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
export type Store = ReturnType<typeof rootReducer>;

export default store;
