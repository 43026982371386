import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import defaultUploadIcon from 'assets/image/input-photo.svg';
import { API } from 'core/API';
import Loader from 'shared/components/Loader';
import InputErrorHint from 'shared/components/InputErrorHint';
import styles from './UploadUserPhoto.module.scss';
import { storageUserPhotoKey, uploadInputId } from './data';

/**
 * TODO: This logic needs to be rewritten
 */
interface UploadUserPhotoProps {
	fieldName: string;
	fieldValue: string;
	setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

const storageUserPhoto = localStorage.getItem(storageUserPhotoKey);

const UploadUserPhoto: FC<UploadUserPhotoProps> = ({
	fieldName,
	fieldValue,
	setFieldValue,
	setFieldTouched,
}: UploadUserPhotoProps) => {
	const [userAvatar, setUserAvatar] = useState<string>('');
	const [customError, setCustomError] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleUploadPhoto = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		event.preventDefault();
		const { target } = event;

		try {
			if (target && target.files && target.files[0]) {
				setIsLoading(true);
				setCustomError('');

				const fileName = target.files[0].name;
				const photo = target.files[0];

				const uploadData = { data: photo, fileName };
				const { fileName: responseFileName } = await API.uploadPhoto(uploadData);

				if (responseFileName.length) {
					localStorage.setItem(storageUserPhotoKey, responseFileName);
					setUserAvatar(responseFileName);
					target.value = '';
				}
			}
		} catch (error: any) {
			setFieldTouched(fieldName, true);
			setCustomError(error?.response?.message || error.message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		!!storageUserPhoto && setFieldValue(fieldName, storageUserPhoto);

		return () => localStorage.removeItem(storageUserPhotoKey);
	}, []);

	useEffect(() => {
		if (userAvatar) {
			localStorage.setItem(storageUserPhotoKey, userAvatar);
			setFieldValue(fieldName, userAvatar);
		}
	}, [userAvatar]);

	const handleDeletePhoto = () => {
		setUserAvatar('');
		setFieldValue(fieldName, '');
		localStorage.removeItem(storageUserPhotoKey);
	};

	const uploadedImage = useMemo(
		() =>
			fieldValue && fieldValue !== 'null' ? `${process.env.REACT_APP_S3_API}/${fieldValue}` : defaultUploadIcon,
		[fieldValue]
	);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.input__wrapper}>
					<input
						value={undefined}
						id={uploadInputId}
						accept="image/*"
						type="file"
						className={styles.input}
						onChange={handleUploadPhoto}
					/>
					<div className={styles.upload__wrapper}>
						{isLoading ? (
							<Loader />
						) : (
							<img
								loading="lazy"
								alt="User Avatar"
								src={uploadedImage}
								className={styles.upload__image}
							/>
						)}
					</div>
				</div>
				<div className={styles.upload__options}>
					<label className={styles.option__item} htmlFor={uploadInputId}>
						Upload a photo
					</label>
					<label onClick={handleDeletePhoto} className={styles.option__item}>
						Delete a photo
					</label>
				</div>
			</div>
			{customError && <InputErrorHint errorText={customError} />}
		</>
	);
};

export default UploadUserPhoto;
