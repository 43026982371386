import { IBasicVolunteerResponse, IGetVolunteerByIdResponse } from '@joc/api-gateway';
import { API } from 'core/API';

export const getInitialValues = (volunteerData: IBasicVolunteerResponse) => {
	const initialValues = {
		...volunteerData,
		gender: volunteerData.gender?.id,
		gradeId: volunteerData.volunteerSchoolsGrades?.at(0)?.gradeId,
		password: '',
	};
	return initialValues;
};

export const getSchoolGrades = async (volunteer: IGetVolunteerByIdResponse) => {
	const organizationId = volunteer.organizations?.find(organization => organization.schoolId)?.id;
	if (organizationId) {
		const organization = await API.getOrganisationById(Number(organizationId));
		const grades = organization.school?.grades;
		return grades;
	}
};
