import MainPageLayout from 'components/Organization/MainPageLayout';
import { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as ShareSvg } from 'assets/image/share.svg';
import { ReactComponent as CopyIcon } from 'assets/image/copy.svg';

// icons
import { ReactComponent as PencilSvg } from 'assets/image/pencil-light.svg';
import ButtonIcon from 'shared/components/Buttons/ButtonIcon';

// components
import { LiveScreen } from 'shared/components/LiveScreen';

// styles
import styles from './SuperLiveScreen.module.scss';
import SnackbarSuccess from '../../../shared/components/Snackbars/SnackbarSuccess';

export enum AdminLinks {
	Local = 'http://localhost:3000',
	Dev = 'https://adminapp-dev.justonechesed.org',
	Prod = 'https://adminapp.justonechesed.org',
}

export enum ClientLinks {
	Local = 'http://localhost:3000',
	Dev = 'https://app-dev.justonechesed.org/onboarding/user-type',
	Prod = 'https://app.justonechesed.org',
}

const SuperLiveScreen: FC = () => {
	const [editMode, setEditMode] = useState(false);
	const [shareLink, setShareLink] = useState(`${AdminLinks.Prod}/live-screen`);
	const ref = useRef<HTMLDivElement>(null);
	const [isOpenSharePopup, setIsOpenSharePopup] = useState(false);
	const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

	const links: { [x: string]: string } = {
		[AdminLinks.Local]: ClientLinks.Local,
		[AdminLinks.Dev]: ClientLinks.Dev,
		[AdminLinks.Prod]: ClientLinks.Prod,
	};

	useEffect(() => {
		setShareLink(
			`${
				window.location.origin === ClientLinks.Local
					? window.location.origin
					: links[process.env.REACT_APP_MAIN_URL || AdminLinks.Prod]
			}/live-screen`
		);
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, []);

	const handleClickOutside = (event: Event) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			setIsOpenSharePopup(false);
		}
	};

	const handleCopyLink = async () => {
		setIsOpenSharePopup(false);
		setIsOpenSnackbar(true);
		await navigator.clipboard.writeText(shareLink);
	};

	return (
		<MainPageLayout>
			<div className={styles.container}>
				<div className={styles.block_title}>
					<div className={styles.title_wrapper}>
						<h1 className="title">Live Screen</h1>
						<ButtonIcon
							disabled={editMode}
							parentClassName={styles.upload}
							onClick={() => setEditMode(true)}
						>
							<PencilSvg className={styles.icon} /> <span>Edit</span>
						</ButtonIcon>
						<div className={styles.share_container}>
							<ButtonIcon parentClassName={styles.share} onClick={() => setIsOpenSharePopup(true)}>
								<ShareSvg className={styles.share_icon} /> <span>Share</span>
							</ButtonIcon>
							{(isOpenSharePopup && (
								<div ref={ref} className={styles.share_dd}>
									<div className={styles.share_ttl}>Share via</div>
									<div className={styles.share_viaItem} onClick={handleCopyLink}>
										<CopyIcon className={styles.viaItem__ico} />
										<div className={styles.viaItem__ttl}>Copy link</div>
									</div>
								</div>
							)) || <></>}
						</div>
					</div>
					<LiveScreen onChangeEditMode={setEditMode} editMode={editMode} />
				</div>
			</div>
			<SnackbarSuccess
				isOpenSnackbar={isOpenSnackbar}
				setIsOpenSnackbar={setIsOpenSnackbar}
				text="Link copied successfully"
			/>
		</MainPageLayout>
	);
};

export default SuperLiveScreen;
