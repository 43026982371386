import React, { FC, ReactNode } from 'react';
import styles from './TableBody.module.scss';

type TableBodyProps = {
	children: ReactNode;
};

const TableBody: FC<TableBodyProps> = ({ children }: TableBodyProps) => {
	return <div className={styles.main}>{children}</div>;
};

export default TableBody;
