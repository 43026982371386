import {
	addressSchema,
	emailSchema,
	imagePathSchema,
	organizationDescriptionSchema,
	organizationNameShema,
	phoneNumberSchema,
	websiteSchema,
} from 'core/validation';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { FC, useContext, useState } from 'react';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputText from 'shared/components/FormInputs/InputText';
import InputErrorHint from 'shared/components/InputErrorHint';
import { connect, ConnectedProps } from 'react-redux';
import { ISearchOrganisationByAdminResponse, IUpdateOrganizationRequest } from '@joc/api-gateway';
import Loader from 'shared/components/Loader';
import { setError } from 'redux/error-service/action';
import InputLogo from 'components/Organization/InputLogo';
import InputTextArea from 'shared/components/FormInputs/InputTextArea';
import { updateOrganization } from 'redux/organization-service/actions';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import { OrganizationsTableContext } from 'shared/components/Table/AdminOrgTable';
import styles from './OrgInfoForm.module.scss';

type OrgInfoFormParentProps = {
	setIsUpdateSuccess: React.Dispatch<React.SetStateAction<boolean>>;
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

const validationSchema = Yup.object().shape({
	organizationName: organizationNameShema,
	organizationLogoPath: imagePathSchema,
	organizationDescription: organizationDescriptionSchema,
	organizationPhoneNumber: phoneNumberSchema,
	organizationEmail: emailSchema,
	organizationWebSite: websiteSchema,
	address: addressSchema,
});

const OrgInfoForm: FC<OrgInfoFormProps> = ({
	setError,
	setIsUpdateSuccess,
	setIsShowPopup,
	updateOrganization,
}: OrgInfoFormProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const organizationData = useContext(OrganizationsTableContext);

	const submitClickHandler = async (values: ISearchOrganisationByAdminResponse) => {
		try {
			setIsLoading(true);
			await updateOrganization(+values.id, values as IUpdateOrganizationRequest);
			setIsLoading(false);
			setIsUpdateSuccess(true);
			setTimeout(() => setIsShowPopup(false), 2000);
		} catch (error: any) {
			setIsLoading(false);
			setError(error?.response?.message || error.response, error?.response?.code || error.code);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={organizationData}
			validationSchema={validationSchema}
			onSubmit={(values): Promise<void> => submitClickHandler(values)}
		>
			{({ errors, touched, setFieldValue, setFieldTouched, dirty }) => (
				<Form className={styles.form}>
					<Field name="organizationName">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="organizationName">
									Organization name
								</label>
								<InputText
									id="organizationName"
									field={field}
									placeholder="fill in..."
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.organizationName && touched.organizationName && (
									<InputErrorHint errorText={errors.organizationName} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="organizationLogoPath">
						{({ field }: FieldProps) => (
							<InputContainer uploadLogoStyle>
								<label className={styles.label} htmlFor="organizationLogoPath">
									Add your organization logo
								</label>
								<InputLogo
									id="organizationLogoPath"
									field={field}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									fieldTouched={touched.organizationLogoPath}
									fieldError={errors.organizationLogoPath}
								/>
							</InputContainer>
						)}
					</Field>
					<Field name="organizationDescription">
						{({ field }: FieldProps) => (
							<InputContainer textAreaStyle>
								<label className={styles.label} htmlFor="organizationDescription">
									About the Organization
								</label>
								<InputTextArea
									id="organizationDescription"
									field={field}
									placeholder="fill in..."
									setFieldValue={setFieldValue}
								/>
								{errors.organizationDescription && touched.organizationDescription && (
									<InputErrorHint errorText={errors.organizationDescription} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="organizationPhoneNumber">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="organizationPhoneNumber">
									Phone number
								</label>
								<InputPhone
									fieldName={field.name}
									fieldValue={field.value}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
								/>
								{errors.organizationPhoneNumber && touched.organizationPhoneNumber && (
									<InputErrorHint errorText={errors.organizationPhoneNumber} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="organizationEmail">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="organizationEmail">
									Email address
								</label>
								<InputText
									id="organizationEmail"
									field={field}
									placeholder="fill in..."
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.organizationEmail && touched.organizationEmail && (
									<InputErrorHint errorText={errors.organizationEmail} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="organizationWebSite">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="organizationWebSite">
									Website Url
								</label>
								<InputText
									id="organizationWebSite"
									field={field}
									placeholder="fill in..."
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.organizationWebSite && touched.organizationWebSite && (
									<InputErrorHint errorText={errors.organizationWebSite} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="address">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="organizationWebSite">
									Add the organization location
								</label>
								<GoogleApiAutoCompleat
									fieldName={field.name}
									selectHandler={address => setFieldValue(field.name, address)}
									fieldValue={field.value}
								/>
								{errors.address && touched.address && (
									<InputErrorHint addressErrorText={errors.address} />
								)}
							</InputContainer>
						)}
					</Field>
					{isLoading ? (
						<Loader loadProps={{ disableCenterStyle: true }} />
					) : (
						<ButtonDefault submitType classList={['primary', 'lg']} title="Confirm" disabled={!dirty} />
					)}
				</Form>
			)}
		</Formik>
	);
};

const mapDispatchToProps = {
	setError,
	updateOrganization,
};

const connector = connect(null, mapDispatchToProps);

type OrgInfoFormProps = ConnectedProps<typeof connector> & OrgInfoFormParentProps;

export default connector(OrgInfoForm);
