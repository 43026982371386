import React, { FC, Fragment, useState } from 'react';
// types
import { ICreateOpportunityRequest, IOpportunityResponse, IUpdateOpportunityRequest } from '@joc/api-gateway';
// styles
import { connect, ConnectedProps } from 'react-redux';
import { Store } from 'redux/root';
// import { selectorGetOrgId } from 'redux/organization-service/selector';
import { resetError, setError } from 'redux/error-service/action';
import { selectorGetError } from 'redux/error-service/selector';
import ResponseFailure from 'shared/components/ResponseFailure';
import {
	createOpportunity,
	getOpportunitiesCount,
	createOpportunityBasedSmartGroup,
} from 'redux/opportunities-service/action';
import { useLocation } from 'react-router';
import qs from 'qs';
import { OPPORTUNITY_INITIAL_VALUES } from 'core/constants';
import { OpportunityInitialValuesType } from 'core/types/OPPORTUNITIES';
import PopupUpdate from '../PopupUpdate';
import OpportunityForm from '../../OpportunityForm';
import PopupCongrats from '../PopupCongrats';

type PopupCreateParentProps = {
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	isSmartGroupMode?: boolean;
};

const PopupCreate: FC<PopupCreateProps> = ({
	setIsShowPopup,
	createOpportunity,
	getOpportunitiesCount,
	setError,
	resetError,
	error,
	isSmartGroupMode,
	createOpportunityBasedSmartGroup,
}: PopupCreateProps) => {
	const location = useLocation();
	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formValues, setFormValues] = useState(OPPORTUNITY_INITIAL_VALUES);
	const [isShowUpdatePopup, setIsShowUpdatePopup] = useState(false);
	const [createdOpportunityData, setCreatedOpportunityData] = useState<IOpportunityResponse>();

	const submitClickHandler = async (
		values: OpportunityInitialValuesType | ICreateOpportunityRequest | IUpdateOpportunityRequest
	) => {
		setIsLoading(true);
		try {
			setFormValues(values as OpportunityInitialValuesType);
			if (isSmartGroupMode) {
				const groupId = qs.parse(location.search, { ignoreQueryPrefix: true })?.groupId;
				if (!groupId?.length) throw new Error('Sorry, we can`t find your smart group id');
				await createOpportunityBasedSmartGroup({ ...(values as ICreateOpportunityRequest) }, +groupId);
				setIsLoading(false);
				setIsSubmited(true);
			} else {
				await createOpportunity({
					...(values as ICreateOpportunityRequest),
				}).then(opportunity => setCreatedOpportunityData(opportunity));
				setIsLoading(false);
				setIsSubmited(true);
			}
		} catch (error: any) {
			setIsLoading(false);
			setError(error?.response?.message || error.message, error?.response?.code || error.code);
		}
	};

	const createOneMoreClickHandler = () => {
		setFormValues(OPPORTUNITY_INITIAL_VALUES);
		setIsSubmited(false);
	};

	if (error.state)
		return (
			<ResponseFailure
				styleTable
				message={error.message ? error.message : 'Something went wrong. Please try again'}
				buttonClickHandler={() => {
					resetError();
				}}
				buttonTitle="Check and try again"
			/>
		);
	if (isSubmited)
		return (
			<PopupCongrats
				setIsShowPopup={setIsShowPopup}
				setIsSubmited={setIsSubmited}
				setIsShowUpdatePopup={setIsShowUpdatePopup}
				createOneMoreClickHandler={createOneMoreClickHandler}
			/>
		);
	if (isShowUpdatePopup && createdOpportunityData)
		return (
			<PopupUpdate
				setIsShowUpdateOpportunityPopup={setIsShowUpdatePopup}
				opportunityData={createdOpportunityData}
			/>
		);
	return (
		<Fragment>
			<h2 className="title">Create a new Opportunity</h2>
			{/* <OpportunityForm isLoading={isLoading} initialValues={formValues} submitClickHandler={submitClickHandler} /> */}
		</Fragment>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});

const mapDispatchToProps = {
	createOpportunity,
	createOpportunityBasedSmartGroup,
	getOpportunitiesCount,
	setError,
	resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PopupCreateProps = ConnectedProps<typeof connector> & PopupCreateParentProps;

export default connector(PopupCreate);
