import React, { FC } from 'react';
import TrashSvg from 'assets/image/trash-light.svg';
import styles from './ButtonTrash.module.scss';

type ButtonTrashProps = {
	clickHandler: () => void;
	title?: string;
};

const ButtonTrash: FC<ButtonTrashProps> = ({ clickHandler, title }: ButtonTrashProps) => {
	return (
		<button className={styles.main} onClick={clickHandler} title={title}>
			<img className={styles.icon} src={TrashSvg} alt="trash" />
		</button>
	);
};

export default ButtonTrash;
