import { FC, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import cx from 'classnames';
import firebase from 'firebase';
import LogoutSvg from 'assets/image/navbar/logout.svg';
import DashboardSvg from 'assets/image/navbar/dashboard.svg';
import InboxSvg from 'assets/image/navbar/inbox.svg';
import UsersSvg from 'assets/image/users-icon.svg';
import VolunteersSvg from 'assets/image/volunteers-icon.svg';
import OpportunitiesSvg from 'assets/image/navbar/chesed-opportunites.svg';
import InvitesSvg from 'assets/image/navbar/invites.svg';
import LiveScreen from 'assets/image/navbar/icon-user.svg';
import MarketingSvg from 'assets/image/navbar/marketing.svg';
import { urls } from 'core/appUrls';
import { connect, ConnectedProps } from 'react-redux';
import { Store } from 'redux/root';
import { selectorGetHiddenNavbarModeState, selectorGetUserRoles } from 'redux/user-service/selector';
import { VOLUNTEER } from '@joc/api-gateway';
import { StreamChat } from 'stream-chat';
import { deleteUserNotificationToken, userResetData } from 'redux/user-service/actions';
import { resetChat } from 'redux/chat-service/actions';
import NavBarItem from './NavBarItem';
import s from './index.module.scss';

const menu = {
	dashboard: { icon: DashboardSvg, title: 'My Dashboard', link: urls.dashboard },
	opportunities: { icon: OpportunitiesSvg, title: 'Chesed Opportunities', link: urls.supperAdminOppoList },
	volunteers: { icon: VolunteersSvg, title: 'Volunteers', link: urls.supperAdminVolList },
	users: { icon: UsersSvg, title: 'Users', link: urls.supperAdminUserList },
	marketing: { icon: MarketingSvg, title: 'Marketing', link: urls.marketing },
	organisations: { icon: InboxSvg, title: 'Organizations', link: urls.supperAdminOrgList },
	invites: { icon: InvitesSvg, title: 'Invites', link: urls.supperAdminInvitList },
	inbox: { icon: InboxSvg, title: 'Chat', link: urls.inbox },
	live: { icon: LiveScreen, title: 'Live screen', link: urls.live },
	// statistics: { icon: StatisticsSvg, title: 'Statistics', link: urls.statistics },
	// forms: { icon: FormSvg, title: 'Legal & forms', link: urls.form },
	logout: { icon: LogoutSvg, title: 'Logout' },
};

const apiKey = process.env.REACT_APP_STREAM_KEY;
const chatClient = StreamChat.getInstance(apiKey!);

const Navbar: FC<NavbarProps> = ({ hiddenState, userRoles }: NavbarProps) => {
	const history = useHistory();
	const [opportunitiesActive, setOpportunitiesActive] = useState(false);

	const logoutClickHandler = async () => {
		try {
			deleteUserNotificationToken();
			resetChat();
			userResetData();
			await firebase.auth().signOut();
			await chatClient.disconnectUser();
			localStorage.clear();
			history.push(urls.loginSuperAdmin);
		} catch (error: any) {
			console.error(error);
		}
	};

	return (
		<div
			className={cx(s.sidebar, {
				[s.sidebar__hide]: hiddenState,
			})}
		>
			<nav className={s.sidebar__nav}>
				<NavLink exact to={menu.dashboard.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.dashboard.icon} title={menu.dashboard.title} />
				</NavLink>

				{!!userRoles?.filter(i => i.roleName !== VOLUNTEER.MARKETER).length && (
					<NavLink
						exact
						to={menu.opportunities.link}
						className={cx(s.sidebar__item, opportunitiesActive && s.active)}
						activeClassName={s.active}
					>
						<NavBarItem icon={menu.opportunities.icon} title={menu.opportunities.title} />
					</NavLink>
				)}
				<hr />

				<NavLink exact to={menu.users.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.users.icon} title={menu.users.title} />
				</NavLink>

				<NavLink exact to={menu.volunteers.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.volunteers.icon} title={menu.volunteers.title} />
				</NavLink>
				{/* {!!userRoles?.filter(i => i.roleName !== VOLUNTEER.COORDINATOR).length && (
					<NavLink exact to={menu.marketing.link} className={s.sidebar__item} activeClassName={s.active}>
						<NavBarItem icon={menu.marketing.icon} title={menu.marketing.title} />
					</NavLink>
				)} */}

				<NavLink exact to={menu.marketing.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.marketing.icon} title={menu.marketing.title} />
				</NavLink>

				<NavLink exact to={menu.organisations.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.organisations.icon} title={menu.organisations.title} />
				</NavLink>

				<NavLink exact to={menu.invites.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.invites.icon} title={menu.invites.title} />
				</NavLink>

				<NavLink exact to={menu.inbox.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.inbox.icon} title={menu.inbox.title} />
				</NavLink>

				<NavLink exact to={menu.live.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.live.icon} title={menu.live.title} />
				</NavLink>
				{/* <NavLink exact to={menu.statistics.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.statistics.icon} title={menu.statistics.title} />
				</NavLink> */}
				{/* <NavLink exact to={menu.forms.link} className={s.sidebar__item} activeClassName={s.active}>
					<NavBarItem icon={menu.forms.icon} title={menu.forms.title} />
				</NavLink> */}
			</nav>
			<div
				className={cx(s.logout, {
					[s.logout__hide]: hiddenState,
				})}
				onClick={logoutClickHandler}
			>
				<NavBarItem icon={menu.logout.icon} title={menu.logout.title} />
			</div>
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	hiddenState: selectorGetHiddenNavbarModeState(store),
	userRoles: selectorGetUserRoles(store),
});

const connector = connect(mapStateToProps);
type NavbarProps = ConnectedProps<typeof connector>;

export default connector(Navbar);
