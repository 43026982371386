import React, { FC, Fragment } from 'react';
import cx from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Store } from 'redux/root';
import { selectorGetHiddenNavbarModeState } from 'redux/user-service/selector';
import s from './NavBarItem.module.scss';

type NavBarItemParentProps = {
	icon: string;
	title: string;
};

const NavBarItem: FC<NavBarItemProps> = ({ icon, title, hiddenState }: NavBarItemProps) => {
	return (
		<Fragment>
			<div className={s.img}>
				<img src={icon} alt="icon" />
			</div>
			<span
				className={cx(s.title, {
					[s.title__hide]: hiddenState,
				})}
			>
				{title}
			</span>
		</Fragment>
	);
};

const mapStateToProps = (store: Store) => ({
	hiddenState: selectorGetHiddenNavbarModeState(store),
});

const connector = connect(mapStateToProps);
type NavBarItemProps = ConnectedProps<typeof connector> & NavBarItemParentProps;

export default connector(NavBarItem);
