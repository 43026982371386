import React, { FC, useRef, createContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
// types
import {
	ISearchOrganisationByAdminResponse,
	ISearchOrganizationByAdminRequest,
	ISearchOrganizationSort,
	ORGANISATION_ACTIVE_STATUS,
	SEARCH_ORGANIZATION_POSSIBLE_SORT,
	SortDirection,
	IFullTextSearchOrganisationByAdmin,
	SEARCH_ORGANIZATION_BY_ADMIN_POSSIBLE_SEARCH,
} from '@joc/api-gateway';
import { Store } from 'redux/root';
// redux
import { resetError } from 'redux/error-service/action';
import { resetOrganizations } from 'redux/organization-service/actions';
// core
import { formatDate, generateLocation, setFullName, setSearchOrSortQueryString } from 'core/functions';
import { SetPagination } from 'core/types';
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES, INITIAL_PAGINATION } from 'core/constants';
// components
import TableHead from 'shared/components/Table/TableHead';
import TableRow from 'shared/components/Table/TableRow';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableBody from 'shared/components/Table/TableBody';
import TableMain from 'shared/components/Table/TableMain';
import TableMenu from 'components/Organization/TableMenu';
import Loader from 'shared/components/Loader';
import Observer from 'shared/components/Observer';
import ResponseFailure from 'shared/components/ResponseFailure';
import OrgActionCell from 'components/Organization/Volunteers/Table/OrgActionCell';
import ContactCell from 'shared/components/Table/CellRenderers/ContactCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import OpportunityNameCell from 'shared/components/Table/CellRenderers/OpportunityNameCell';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import OrgContactCell from 'shared/components/Table/CellRenderers/OrgContactCell';
// styles
import styles from './AdminOrgTable.module.scss';

export const OrganizationsTableContext = createContext({} as ISearchOrganisationByAdminResponse);

type OrganizationsTableParentProps = {
	isLoading: boolean;
	changeVisibilityFilters: () => void;
	sort?: ISearchOrganizationSort;
	setPagination: SetPagination;
	setRequestBody: React.Dispatch<React.SetStateAction<ISearchOrganizationByAdminRequest | undefined>>;
};

const OrganizationsTable: FC<OrganizationsTableParentProps> = ({
	isLoading,
	changeVisibilityFilters,
	sort,
	setPagination,
	setRequestBody,
}: OrganizationsTableParentProps) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const printRef = useRef<HTMLTableElement>(null);

	const organizationsRecordsList = useSelector((store: Store) => store.organizationRedux.organizationsRecords);
	const organizationsRecordsTotal = useSelector((store: Store) => store.organizationRedux.organizationsTotal);
	const errorState = useSelector((store: Store) => store.errorRedux.error.state);
	const errorMessage = useSelector((store: Store) => store.errorRedux.error.message);

	const buttonFailureClickHandler = async () => {
		dispatch(resetError());
		history.push({ search: '' });
		setRequestBody(undefined);
		setPagination(INITIAL_PAGINATION);
	};

	const appendQueryString = (
		newQueryParams: IFullTextSearchOrganisationByAdmin | ISearchOrganizationSort,
		searchBy: string
	): void => {
		const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
		history.push({ search: searchQuery });
	};

	const searchChangeHandler = (value: string): void => {
		const values = Object.values(SEARCH_ORGANIZATION_BY_ADMIN_POSSIBLE_SEARCH);
		const fields = values.filter(value => value !== SEARCH_ORGANIZATION_BY_ADMIN_POSSIBLE_SEARCH.Id);
		const fullTextSearchParams: IFullTextSearchOrganisationByAdmin = { value, fields };
		if (fullTextSearchParams.value.length) {
			appendQueryString(fullTextSearchParams, 'fullTextSearch');
		} else {
			dispatch(resetOrganizations());
			const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
			delete searchParams.fullTextSearch;
			history.push({ search: qs.stringify(searchParams) });
			setRequestBody(undefined);
			setPagination(INITIAL_PAGINATION);
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<TableMenu
					searchChangeHandler={searchChangeHandler}
					changeVisibilityFilters={changeVisibilityFilters}
				/>
				{(errorState && !isLoading) || (!isLoading && !organizationsRecordsList.length) ? (
					<ResponseFailure
						styleTable={true}
						message={errorMessage || 'We couldn`t find the volunteers you were looking for this time.'}
						buttonClickHandler={buttonFailureClickHandler}
						buttonTitle="Get all"
					/>
				) : !organizationsRecordsList.length && isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<TableMain ref={printRef}>
						<TableHead callChild="organizations">
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.logo}
								text={TABLE_HEADERS.logo}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.chesedName}
								text={TABLE_HEADERS.orgName}
								clickHandler={() => {
									const newQueryParams: ISearchOrganizationSort = {
										sortBy: SEARCH_ORGANIZATION_POSSIBLE_SORT.OrganizationName,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.date}
								text={TABLE_HEADERS.date}
								// clickHandler={() => {
								// 	const newQueryParams: ISearchOrganizationSort = {
								// 		sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.Opportunities_startDay,
								// 		sortDir:
								// 			sort?.sortDir === SortDirection.ASC
								// 				? SortDirection.DESC
								// 				: SortDirection.ASC,
								// 	};
								// 	appendQueryString(newQueryParams, 'sort');
								// }}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.location}
								text={TABLE_HEADERS.location}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.type}
								text={TABLE_HEADERS.typeOrg}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.contacts}
								text={TABLE_HEADERS.contacts}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.owner}
								text={TABLE_HEADERS.owner}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.totalVolunteers}
								text={TABLE_HEADERS.totalVolunteers}
								// clickHandler={() => {
								// 	const newQueryParams: ISearchOpportunitiesSort = {
								// 		sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.Opportunities_startDay,
								// 		sortDir:
								// 			sort?.sortDir === SortDirection.ASC
								// 				? SortDirection.DESC
								// 				: SortDirection.ASC,
								// 	};
								// 	appendQueryString(newQueryParams, 'sort');
								// }}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.totalUsers}
								text={TABLE_HEADERS.totalUsers}
								// isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.totalOppos}
								text={TABLE_HEADERS.totalOppo}
								// isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.totalOppoHours}
								text={TABLE_HEADERS.totalOppoHours}
								// isSortDisabled
							/>
							<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />
							<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />
						</TableHead>
						<TableBody>
							{organizationsRecordsList.map(organization => {
								return (
									<TableRow
										key={organization.id}
										callChild="organizations"
										suspend={
											organization.organizationActiveStatus.status ===
											ORGANISATION_ACTIVE_STATUS.SUSPENDED
										}
									>
										<AvatarCell imagePath={organization.organizationLogoPath} />
										<OpportunityNameCell
											opportunityName={organization.organizationName}
											opportunityType={+organization.id}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.date}
											text={formatDate(organization?.createDate)}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.location}
											text={generateLocation(organization.address)}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.type}
											text={organization.organisationGeneralType}
										/>
										<OrgContactCell
											phone={organization.organizationPhoneNumber}
											email={organization.organizationEmail}
											site={organization.organizationWebSite}
										/>
										<OrgContactCell
											contactFullName={setFullName(
												organization.owner.firstName,
												organization.owner.lastName
											)}
											phone={organization.owner?.phoneNumber}
											email={organization.owner?.email}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.totalVolunteers}
											text={organization.totalVolunteers}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.totalUsers}
											text={organization.totalUsers}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.totalOppos}
											text={organization.totalOpportunities}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.totalOppoHours}
											text={Math.floor(organization.totalOpportunitiesHours)}
										/>
										<div className={styles.actions__content}>
											<OrganizationsTableContext.Provider value={organization}>
												<OrgActionCell organizationId={organization.id} />
											</OrganizationsTableContext.Provider>
										</div>
										<ContactCell
											chatId={organization.chatId || ''}
											orgId={organization.id}
											users={true}
											organizations={true}
										/>
									</TableRow>
								);
							})}
						</TableBody>
					</TableMain>
				)}
				{organizationsRecordsList.length !== organizationsRecordsTotal && !errorState && (
					<Observer
						paginationSkip={organizationsRecordsList.length}
						setPagination={setPagination}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default OrganizationsTable;
