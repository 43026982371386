import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase';
import App from 'App';
import store from './redux/root';
import './components/translate';
import reportWebVitals from './reportWebVitals';
import './index.scss';

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_Id,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);
firebase.auth();
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : '';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
	<Provider store={store}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</MuiPickersUtilsProvider>
	</Provider>,
	document.getElementById('root')
);

reportWebVitals();
