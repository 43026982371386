import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import React, { FC, useState } from 'react';
import firebase from 'firebase';
// import CreateAccount from 'components/Organization/Login/CreateAccount';
import { getUserByEmailAndPassword, getUserBySocialProvider } from 'redux/user-service/actions';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputText from 'shared/components/FormInputs/InputText';
import InputErrorHint from 'shared/components/InputErrorHint';
import InputPassword from 'shared/components/FormInputs/InputPassword';
import { useHistory } from 'react-router-dom';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import { urls } from 'core/appUrls';
import { connect, ConnectedProps } from 'react-redux';
import Loader from 'shared/components/Loader';
import { setError } from 'redux/error-service/action';
import UserNotFound from 'shared/components/UserNotFound';
import { emailSchema, passwordSchema } from 'core/validation';
// import SocialButton from './SocialButton';
// import OptionsSeparator from './OptionsSeparator';
import styles from './LoginFormV.module.scss';

export type SocialProvider = firebase.auth.AuthProvider | firebase.auth.AuthProvider | firebase.auth.AuthProvider;

type loginValues = {
	email: string;
	password: string;
};

type LoginFormVParentProps = {
	userNotFound: boolean;
	setUserNotFound: React.Dispatch<React.SetStateAction<boolean>>;
	setIsAccessDenied: React.Dispatch<React.SetStateAction<boolean>>;
};

const validationSchema = Yup.object().shape({
	email: emailSchema,
	password: passwordSchema,
});

const LoginFormV: FC<LoginFormVProps> = ({
	getUserBySocialProvider,
	setError,
	getUserByEmailAndPassword,
	userNotFound,
	setUserNotFound,
	setIsAccessDenied,
}: LoginFormVProps) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({
		email: '',
		password: '',
	});
	// const [socialProviders] = useState({
	// 	facebookProvider: new firebase.auth.FacebookAuthProvider().setCustomParameters({
	// 		prompt: 'select_account',
	// 	}),
	// 	googleProvider: new firebase.auth.GoogleAuthProvider().setCustomParameters({
	// 		prompt: 'select_account',
	// 	}),
	// 	appleProvider: new firebase.auth.OAuthProvider('apple.com').setCustomParameters({
	// 		prompt: 'select_account',
	// 	}),
	// });

	// const loginSocial = async (provider: SocialProvider): Promise<void> => {
	// 	setIsLoading(true);
	// 	try {
	// 		await getUserBySocialProvider(provider);
	// 		setIsLoading(false);
	// 		history.push(urls.main);
	// 	} catch (error: any) {
	// 		setIsLoading(false);
	// 		if (error?.response?.code === 404 || error?.response?.code === 422) {
	// 			setUserNotFound(true);
	// 		} else {
	// 			setError(error?.response?.message || error.message, error.code);
	// 		}
	// 	}
	// };

	const loginEmailAndPassword = async (values: loginValues): Promise<void> => {
		try {
			setIsLoading(true);
			await getUserByEmailAndPassword(values.email, values.password);
			setIsLoading(false);
			history.push(urls.dashboard);
		} catch (error: any) {
			setIsLoading(false);
			// TODO: need refactor
			if (error?.code === 'auth/user-not-found' || error?.response?.code === 404) {
				setUserNotFound(true);
			} else if (error.message === 'Access Denied' || error?.response?.code === 422) {
				setIsAccessDenied(true);
			} else if (error.code === 'auth/too-many-requests') {
				setError(error.message, error.code);
			} else {
				setError('Incorrect username or password.', error.code);
			}
		}
	};

	if (userNotFound) return <UserNotFound redirectTo={urls.notFound} />;
	return (
		<div className={styles.container}>
			<h1 className={cx('title', styles.title)}>Welcome</h1>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={values => {
					loginEmailAndPassword(values);
				}}
			>
				{({ errors, touched, setFieldValue }) => (
					<Form className={styles.form}>
						<Field name="email">
							{({ field }: FieldProps) => (
								<InputContainer isDisabledMargin>
									<InputText field={field} placeholder="Email" setFieldValue={setFieldValue} />
									{errors.email && touched.email && <InputErrorHint errorText={errors.email} />}
								</InputContainer>
							)}
						</Field>
						<Field name="password">
							{({ field }: FieldProps) => (
								<InputContainer isDisabledMargin>
									<InputPassword field={field} setFieldValue={setFieldValue} />
									{errors.password && touched.password && (
										<InputErrorHint errorText={errors.password} />
									)}
								</InputContainer>
							)}
						</Field>

						<div className={styles.button__container}>
							{isLoading ? (
								<Loader />
							) : (
								<ButtonDefault submitType classList={['primary', 'extra_lg']} title="Sign In" />
							)}
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

const mapDispatchToProps = {
	getUserBySocialProvider,
	setError,
	getUserByEmailAndPassword,
};

const connector = connect(null, mapDispatchToProps);

type LoginFormVProps = ConnectedProps<typeof connector> & LoginFormVParentProps;

export default connector(LoginFormV);
