import { IOpportunityResponse } from '@joc/api-gateway';
import { IOpportunitiesCountsResponse } from 'core/types/OPPORTUNITIES';
import { Store } from 'redux/root';

export const selectorGetOpportunitiesRecords = (store: Store): Array<IOpportunityResponse> => {
	return store.opportunitiesRedux.opportunitiesAll.records;
};

export const selectorGetOpportunitiesTotal = (store: Store): number => {
	return store.opportunitiesRedux.opportunitiesAll.total;
};

export const selectorGetOpportunitiesCount = (store: Store): IOpportunitiesCountsResponse => {
	return store.opportunitiesRedux.opportunitiesCount;
};

export const selectorGetManualOpportunitiesCount = (store: Store): number => {
	return store.opportunitiesRedux.opportunitiesCount.opportunitiesManual;
};

export const selectorGetPastOpportunitiesCount = (store: Store): number => {
	return store.opportunitiesRedux.opportunitiesCount.opportunitiesPast;
};

export const selectorGetTotalOpportunitiesCount = (store: Store): number => {
	return store.opportunitiesRedux.opportunitiesCount.opportunitiesTotal;
};

export const selectorGetUpcomingOpportunitiesCount = (store: Store): number => {
	return store.opportunitiesRedux.opportunitiesCount.opportunitiesUpcoming;
};

export const selectorGetOpportunityById = (store: Store): IOpportunityResponse | undefined => {
	if (store.opportunitiesRedux.currentOpportunity) return store.opportunitiesRedux.currentOpportunity;
};

export const selectorGetOpportunitiesReminderTape = (store: Store): Array<IOpportunityResponse> => {
	return store.opportunitiesRedux.opportunitiesReminderTape;
};
