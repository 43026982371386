import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import { IGroupsRequest } from '@joc/api-gateway';
import { resetError, setError } from 'redux/error-service/action';
import Loader from 'shared/components/Loader';
import { Store } from 'redux/root';
import { VolunteersPageContext, VolunteersPageContextType } from 'pages/Organization/Volunteers';
import { INITIAL_PAGINATION } from 'core/constants';
import { createSmartGroup } from 'redux/smartGroups-service/actions';
import { connect, ConnectedProps } from 'react-redux';
import { selectorGetError } from 'redux/error-service/selector';
import ResponseFailure from 'shared/components/ResponseFailure';
// import { selectorGetOrgId } from 'redux/organization-service/selector';
import RemoveFilterButton from '../RemoveFilterButton';
import GroupNameInput from './GroupNameInput';
import OwnField from './OwnField';
import styles from './CreateFilterForm.module.scss';
import GenderField from './GenderField';
import LocationField from './LocationField';
import AgeField from './AgeField';
import SelectFilterType from './SelectFilterType';

const formComponentRender = (
	renderFilterName: string,
	filterId: number,
	filterTypes: Array<GroupType>,
	setFilterTypes: React.Dispatch<React.SetStateAction<GroupType[]>>
) => {
	switch (renderFilterName) {
		case 'Age': {
			return <AgeField filterId={filterId} filterTypes={filterTypes} setFilterTypes={setFilterTypes} />;
		}
		case 'Location': {
			return <LocationField filterId={filterId} filterTypes={filterTypes} setFilterTypes={setFilterTypes} />;
		}
		case 'Gender': {
			return <GenderField filterId={filterId} filterTypes={filterTypes} setFilterTypes={setFilterTypes} />;
		}
		// case SMART_GROUP_FILTER_TYPES.recent.typeName: {
		// 	return <RecentField setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} errors={errors} />;
		// }
		case 'Create your own': {
			return <OwnField filterId={filterId} filterTypes={filterTypes} setFilterTypes={setFilterTypes} />;
		}
		default: {
			return 'default';
		}
	}
};

export type GroupType = {
	id: number;
	placeholder?: string;
	filterType?: string;
	data?: any;
};

const CreateFilterForm: FC<CreateFilterFormProps> = ({
	error,
	resetError,
	createSmartGroup,
}: CreateFilterFormProps) => {
	const [filterTypes, setFilterTypes] = useState<Array<GroupType>>([]);
	const [groupName, setGroupName] = useState('');
	// const groupValues: IGroupsRequest = useMemo(() => {
	// 	// TODO: fix to many age and location filters
	// 	const rest = filterTypes.map(i => {
	// 		let newVal = {};
	// 		if (i.filterType && i.data) {
	// 			if (i.filterType === 'volunteers') {
	// 				newVal = { [i.filterType]: [...i.data.flat()] };
	// 			}
	// 			if (i.filterType === 'ages') {
	// 				newVal = { [i.filterType]: [...i.data] };
	// 			}
	// 			if (i.filterType === 'locations') {
	// 				newVal = { [i.filterType]: [i.data] };
	// 			} else {
	// 				newVal = { [i.filterType]: i.data };
	// 			}
	// 		}
	// 		console.log(newVal);
	// 		return newVal;
	// 	});
	// 	// if (orgId) return Object.assign({ groupName }, { organizationId: +orgId }, ...rest);
	// }, [groupName, filterTypes]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		resetError();
	}, []);

	// if (error.state)
	// 	return (
	// 		<ResponseFailure
	// 			styleTable
	// 			message={error.message}
	// 			buttonTitle="Try again"
	// 			buttonClickHandler={resetError}
	// 		/>
	// 	);

	return (
		<div className={styles.container}>
			<h2 className="title">Create new smart filter</h2>
			<div className={styles.form}>
				<InputContainer parentClassnames={styles.groupName}>
					<label className={styles.label} htmlFor="groupName">
						Filter name
					</label>
					<GroupNameInput
						id="groupName"
						placeholder="Type anything"
						value={groupName}
						setValue={name => setGroupName(name)}
					/>
				</InputContainer>

				{!!filterTypes.length &&
					filterTypes.map(filter => {
						return (
							<div key={filter.id}>
								<div className={styles.filterType}>
									<InputContainer parentClassnames={styles.filterType__inputContainer}>
										<label className={styles.label} htmlFor="filterType">
											Filter type
										</label>
										<div className={styles.inputContainer__row}>
											<SelectFilterType
												parentClassName={styles.filterType__select}
												filterId={filter.id}
												placeholder="Select"
												selectedTitle={filter.placeholder && filter.placeholder}
												filterTypes={filterTypes}
												setFilterTypes={setFilterTypes}
											/>
											<RemoveFilterButton
												clickHandler={() =>
													setFilterTypes(filterTypes.filter(i => i.id !== filter.id))
												}
											/>
										</div>
									</InputContainer>
								</div>
								{filter.placeholder &&
									formComponentRender(filter.placeholder, filter.id, filterTypes, setFilterTypes)}
							</div>
						);
					})}
				<div className={styles.actions}>
					{isLoading ? (
						<Loader loadProps={{ parentClassName: styles.loader }} />
					) : (
						<>
							<ButtonDefault
								classList={['secondary', 'lg']}
								title="+ Add filter type"
								clickHandler={() => setFilterTypes([...filterTypes, { id: +new Date() }])}
								disabled={!!filterTypes.filter(i => !i.data).length}
							/>
							<ButtonDefault
								classList={['primary', 'lg']}
								title="Create filter"
								// disabled={!!(!groupValues.groupName.length || Object.keys(groupValues).length <= 1)}
								// clickHandler={() => createSmartGroup(groupValues)}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});
const mapDispatchToProps = {
	createSmartGroup,
	setError,
	resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CreateFilterFormProps = ConnectedProps<typeof connector>;

export default connector(CreateFilterForm);
