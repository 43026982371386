import { ISearchUsersByAdminResponse, IUserResponse } from '@joc/api-gateway';
import update from 'immutability-helper';

import {
	DELETE_USER,
	GET_COMPANIES_USERS_ADMIN,
	GET_COMPANIES_USERS_ALL,
	GET_COMPANIES_USERS_COORDINATORS,
	GET_COMPANIES_USERS_MARKETERS,
	RESET_COMPANIES_USERS,
	SET_USER_POSITION,
	SET_USER_ROLE,
	UNSET_USER_ROLE,
	UPDATE_USER_DATA,
} from 'redux/actions-types';
import { CompaniesUsersActions, CompaniesUsersReducer } from './types';

const initialState: CompaniesUsersReducer = {
	records: [],
	total: 0,
};

const companiesUsersReducer = (state = initialState, action: CompaniesUsersActions) => {
	const { type, payload } = action;

	switch (type) {
		case GET_COMPANIES_USERS_ALL: {
			return {
				...state,
				records: [...state.records, ...(payload as ISearchUsersByAdminResponse).records],
				total: (payload as ISearchUsersByAdminResponse).total,
			};
		}
		case GET_COMPANIES_USERS_ADMIN: {
			// console.log('payload-reducer', payload);
			return {
				...state,
				records: [...state.records, ...(payload as ISearchUsersByAdminResponse).records],
				total: (payload as ISearchUsersByAdminResponse).total,
			};
		}
		case GET_COMPANIES_USERS_COORDINATORS: {
			return {
				...state,
				records: [...state.records, ...(payload as ISearchUsersByAdminResponse).records],
				total: (payload as ISearchUsersByAdminResponse).total,
			};
		}
		case GET_COMPANIES_USERS_MARKETERS: {
			return {
				...state,
				records: [...state.records, ...(payload as ISearchUsersByAdminResponse).records],
				total: (payload as ISearchUsersByAdminResponse).total,
			};
		}
		case RESET_COMPANIES_USERS: {
			return initialState;
		}
		case SET_USER_ROLE: {
			const indexUser = state.records.findIndex(i => +i.id === +(payload as IUserResponse).id);
			return update(state, { records: { [indexUser]: { $merge: payload as IUserResponse } } });
		}
		case UNSET_USER_ROLE: {
			const indexUser = state.records.findIndex(i => +i.id === +(payload as IUserResponse).id);
			return update(state, { records: { [indexUser]: { $merge: payload as IUserResponse } } });
		}
		case SET_USER_POSITION: {
			const indexUser = state.records.findIndex(i => +i.id === +(payload as IUserResponse).id);
			return update(state, { records: { [indexUser]: { $merge: payload as IUserResponse } } });
		}
		case UPDATE_USER_DATA: {
			const index = state.records.findIndex(item => +item.id === +(payload as IUserResponse).id);
			return update(state, {
				records: { [index]: { $set: payload as IUserResponse } },
			});
		}
		case DELETE_USER: {
			const index = state.records.findIndex(item => +item.id === Number(payload));
			return {
				...state,
				records: state.records.splice(index, 1),
				total: state.total - 1,
			};
		}

		default:
			return state;
	}
};

export default companiesUsersReducer;
