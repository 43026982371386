import React, { createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
import { FieldInputProps } from 'formik';
import { ORGANISATION_GENERAL_TYPES } from '@joc/api-gateway/lib/api-client';
import styles from './index.module.scss';

type SingleSelectTypes = {
	field: FieldInputProps<string | number>;
	id?: string;
	placeholder?: string;
	setFieldValue: (field: string, value: string | number) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	options: Array<string | number>;
	classList?: Array<string | number>;
};

const SingleSelect: FC<SingleSelectTypes> = ({
	field,
	id,
	placeholder,
	setFieldValue,
	setFieldTouched,
	options,
	classList,
}: SingleSelectTypes) => {
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const selectRef = useRef<HTMLDivElement>(null);
	const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

	const optionClickHandler = (option: string | number): void => {
		setIsShowOptions(false);
		setFieldValue(field.name, option);
	};

	const handleClickOutside = (event: Event) => {
		isShowOptions && setFieldTouched(field.name, true);
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};
	const titleClickHandler = () => {
		const valueIndex = options.findIndex(i => i === field.value);
		const target = optionRef[valueIndex]?.current;
		if (target)
			// set timeout cause in a first time the scroll doesn't work
			setTimeout(() => {
				target.scrollIntoView();
			}, 0);

		if (isShowOptions) setFieldTouched(field.name, true);
		setIsShowOptions(!isShowOptions);
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	return (
		<div
			id={id}
			className={cx(styles.dropdown_select, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!field.value,
				})}
				onClick={titleClickHandler}
			>
				<span>{field.value ? field.value : placeholder}</span>
				{!isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div
				className={cx(styles.dropdown_select__options, {
					[styles.dropdown_select__options_center]: classList?.includes('option_center'),
				})}
			>
				{options.map((option: string | number | ORGANISATION_GENERAL_TYPES, index) => (
					<div
						key={option}
						ref={optionRef[index]}
						className={styles.dropdown_select__options__item}
						onClick={() => optionClickHandler(option)}
					>
						{option}
					</div>
				))}
			</div>
		</div>
	);
};

export default SingleSelect;
