import { IUserResponse } from '@joc/api-gateway';
import { CHANGE_NAVBAR_MODE, GET_USER, RESET_USER_DATA } from 'redux/actions-types';
import { IUserReducer, UserActionTypes } from './types';

const initState: IUserReducer = {
	userData: null,
	navbarHiddenMode: false,
};

const userReducer = (state = initState, action: UserActionTypes): IUserReducer => {
	const { type, payload }: UserActionTypes = action;
	switch (type) {
		case GET_USER:
			return { ...state, userData: payload as IUserResponse };
		case RESET_USER_DATA:
			return initState;
		case CHANGE_NAVBAR_MODE:
			return { ...state, navbarHiddenMode: !state.navbarHiddenMode };
		default:
			return state;
	}
};

export default userReducer;
