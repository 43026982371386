import React, { FC } from 'react';
import qs from 'qs';
import { connect, ConnectedProps } from 'react-redux';
// redux
import {
	// selectorGetOpportunitiesAllTotal,
	// selectorGetOpportunitiesManualTotal,
	// selectorGetOpportunitiesPendingTotal,
	// selectorGetOpportunitiesVacanciesTotal,
	// selectorGetSmartGroupsTotal,
	// selectorGetVolunteersTotal,
	selectorGetDashboard,
} from 'redux/dashboard-service/selector';
import { selectorGetUserRoles } from 'redux/user-service/selector';
// types
import { Store } from 'redux/root';
// consts
import { urls } from 'core/appUrls';
// components
import DashboardItem from '../DashboardItem';
// styles
import styles from './index.module.scss';

const DashboardTable = React.forwardRef<HTMLDivElement, DashboardTableType>(
	(
		{
			// opportunitiesTotal,
			// opportunitiesPendingVolunteers,
			// opportunitiesVacancies,
			// opportunitiesManual,
			// volunteersGroups,
			// volunteersTotal,
			// organizationsTotal,
			// userRoles,
			dashboardData,
		}: DashboardTableType,
		ref
	) => {
		// const toPendingOpportunities = qs.stringify({
		// 	volunteerStatus: [StatusOpportunityVolunteersRequestStatus.PENDING],
		// });
		// const toVacanciesOpportunities = qs.stringify({ showVacancies: true });
		// const toManualOpportunities = qs.stringify({
		// 	opportunityTypesList: [OPPORTUNITY_TYPES.MANUAL],
		// 	status: [OPPORTUNITY_STATUSES.OPEN, OPPORTUNITY_STATUSES.PENDING],
		// });

		return (
			<div ref={ref} className={styles.container}>
				<div className={styles.dashboard__grid}>
					<div className={styles.dashboard__main}>
						<div className={styles.dashboard__row}>
							<div className={styles.dashboard__rowtitle}>Chesed Opportunites</div>
							<div className={styles.dashboard__rowmain}>
								<DashboardItem
									title="Total"
									heartsvg
									count={dashboardData.totalOpp}
									urlTo={urls.supperAdminOppoList}
								/>
								{/* <DashboardItem
									title="Pending Volunteer Approval"
									count={opportunitiesPendingVolunteers}
									urlTo={`${urls.opportunites}?${toPendingOpportunities}`}
								/>
								<DashboardItem
									title="Vacancies"
									ringsvg
									count={opportunitiesVacancies}
									urlTo={`${urls.opportunites}?${toVacanciesOpportunities}`}
								/> */}
								{/* <DashboardItem
									title="Manual"
									hammersvg
									count={opportunitiesManual}
									urlTo={`${urls.opportunites}?${toManualOpportunities}`}
								/> */}
							</div>
						</div>
						<div className={styles.dashboard__row}>
							<div className={styles.dashboard__rowtitle}>Volunteers</div>
							<div className={styles.dashboard__rowmain}>
								<DashboardItem
									title="Total"
									usersvg
									count={dashboardData.totalVol}
									urlTo={urls.supperAdminVolList}
								/>
								{/* <DashboardItem
									title="Groups"
									usergroupsvg
									count={volunteersGroups}
									urlTo={urls.volunteers}
								/> */}
							</div>
						</div>
						<div className={styles.dashboard__row}>
							<div className={styles.dashboard__rowtitle}>Organizations</div>
							<div>
								<DashboardItem
									title="Total"
									usersvg
									count={dashboardData.totalOrg}
									urlTo={urls.supperAdminOrgList}
								/>
							</div>
						</div>
					</div>
					{/* {userRoles?.find(i => i.roleName === VOLUNTEER.ADMIN || i.roleName === VOLUNTEER.COORDINATOR) && (
						<div className={styles.dashboard__sidebar}>
							<ReminderTape />
						</div>
					)} */}
				</div>
			</div>
		);
	}
);

const mapStateToProps = (store: Store) => ({
	// opportunitiesTotal: selectorGetOpportunitiesAllTotal(store),
	// opportunitiesPendingVolunteers: selectorGetOpportunitiesPendingTotal(store),
	// opportunitiesVacancies: selectorGetOpportunitiesVacanciesTotal(store),
	// opportunitiesManual: selectorGetOpportunitiesManualTotal(store),
	// volunteersTotal: selectorGetVolunteersTotal(store),
	// volunteersGroups: selectorGetSmartGroupsTotal(store),
	userRoles: selectorGetUserRoles(store),
	dashboardData: selectorGetDashboard(store),
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });
type DashboardTableType = ConnectedProps<typeof connector>;

DashboardTable.displayName = 'DashboardTable';

export default connector(DashboardTable);
