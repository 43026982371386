import React, { FC, useEffect, useRef } from 'react';
import { FieldInputProps } from 'formik';
import s from './index.module.scss';

type InputNumberProps = {
	placeholder?: string;
	isDisabled?: boolean;
	field: FieldInputProps<string>;
	id?: string;
	// fieldName?: string;
	setFieldValue: (field: string, value: number | undefined) => void;
	fieldValue?: number;
	minus?: boolean;
};

let timeout: NodeJS.Timeout;

const InputNumber: FC<InputNumberProps> = ({
	placeholder,
	isDisabled,
	field,
	id,
	setFieldValue,
	fieldValue,
	minus,
}: InputNumberProps) => {
	const ref = useRef<HTMLInputElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		if (!!fieldValue && !!field && !!setFieldValue) {
			window.clearTimeout(timeout);
			// console.log('asfdgsdghsfhdfjh', fieldValue);
			timeout = setTimeout(() => {
				setFieldValue(field?.name, fieldValue || undefined);
			}, 1500);
		}
	}, [fieldValue, setFieldValue]);

	// useEffect(() => {
	// 	console.log('asfdgsdghsfhdfjh', fieldValue);
	// }, []);

	return (
		<div className={s.input__text}>
			<input
				id={id && id}
				type="number"
				placeholder={placeholder || ''}
				{...field}
				ref={ref}
				disabled={isDisabled}
				value={fieldValue ? field.value : 0}
				min={minus ? undefined : 0}
			/>
			{field && !!field.value && !isDisabled && <div className={s.input__text_edit} onClick={setFocus} />}
		</div>
	);
};

export default InputNumber;
