import React, { FC, useState } from 'react';
import DialogStyled from 'components/Organization/Placeholders/EditPlaceholderDialog/DialogStyled';
import styles from './AddQuoteModal.module.scss';
import ButtonCross from '../../../Buttons/ButtonCross';
import { IQuoteResponse } from '@joc/api-gateway';
import cx from 'classnames';
import { AddQuoteTab } from './AddQuoteTab';
import { ImportQuotesTab } from './ImportQuotesTab';

interface AddQuoteModalProps {
	isModalOpen: boolean;
	onClose: () => void;
	quote?: IQuoteResponse;
}

export const AddQuoteModal: FC<AddQuoteModalProps> = ({ isModalOpen, onClose, quote }) => {
	const [selectedTab, setSelectedTab] = useState<'single' | 'multiple'>('single');

	const closeDialog = () => onClose();

	return (
		<DialogStyled open={isModalOpen} maxWidth="xl">
			<section className={styles.dialog}>
				<div className={styles.dialog__header}>
					<ButtonCross clickHandler={closeDialog} parentClassName={styles.dialog__header_button} />
				</div>

				<div className={styles.dialog__form_header}>
					<span className={styles.dialog__form_header_title}>Add Daily Quotes</span>

					<div className={styles.dialog__form_header_tabs}>
						<button
							className={cx(
								styles.dialog__form_header_tabs_tab,
								selectedTab === 'single' ? styles.dialog__form_header_tabs_active : ''
							)}
							onClick={() => setSelectedTab('single')}
						>
							Add Single
						</button>
						<button
							className={cx(
								styles.dialog__form_header_tabs_tab,
								selectedTab === 'multiple' ? styles.dialog__form_header_tabs_active : ''
							)}
							onClick={() => setSelectedTab('multiple')}
						>
							Add by list
						</button>
					</div>
				</div>

				{selectedTab === 'single' ? (
					<AddQuoteTab quote={quote} onClose={onClose} />
				) : (
					<ImportQuotesTab onClose={onClose} />
				)}
			</section>
		</DialogStyled>
	);
};
