import React, { FC } from 'react';
import InputSearch from 'shared/components/Inputs/InputSearch';
import { ReactComponent as FilterSvg } from 'assets/image/filter.svg';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import { GroupType } from '../../..';
import styles from './Actions.module.scss';

type ActionsProps = {
	filterId: number;
	filterTypes: Array<GroupType>;
	setFilterTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
	closeTableClickHandler: () => void;
	checkedVolunteersId: Array<number>;
	currentCheckedVolunteersId: Array<number>;
	setCheckedVolunteersId: React.Dispatch<React.SetStateAction<number[]>>;
};

const Actions: FC<ActionsProps> = ({
	filterId,
	filterTypes,
	setFilterTypes,
	closeTableClickHandler,
	setCheckedVolunteersId,
	checkedVolunteersId,
	currentCheckedVolunteersId,
}: ActionsProps) => {
	const cancelClickHandler = () => {
		setCheckedVolunteersId(checkedVolunteersId);
		closeTableClickHandler();
	};

	const createGroupClickHandler = () => {
		if (currentCheckedVolunteersId.length) {
			const newFilterTypes = filterTypes.map(i => {
				if (i.id === filterId) return { ...i, data: currentCheckedVolunteersId };
				return i;
			});
			setCheckedVolunteersId(currentCheckedVolunteersId);
			setFilterTypes(newFilterTypes);
		}
		closeTableClickHandler();
	};

	return (
		<div className={styles.wrapper}>
			<ButtonDefault title="Cancel" classList={['secondary', 'md']} clickHandler={cancelClickHandler} />
			<ButtonDefault
				disabled={!currentCheckedVolunteersId.length}
				title="Create group"
				classList={['secondary', 'md']}
				clickHandler={createGroupClickHandler}
			/>
		</div>
	);
};

export default Actions;
