import qs from 'qs';
import { RouteComponentProps } from 'react-router';
import axios from 'axios';
import moment from 'moment-mini';
//API
import { API } from 'core/API';
//types
import {
	OPPORTUNITY_VOLUNTEER_STATUS,
	IFullTextSearchVolunteers,
	IFullTextSearchOpportunity,
	IFullTextSearchOrganisation,
	IFullTextSearchUsersForAdmin,
	IGetLocationAddressByIdResponse,
	IGetLocationIdByAddressRequest,
	IGetVolunteerByIdResponse,
	ISearchOpportunitiesSort,
	ISearchOrganizationSort,
	ISearchUsersSort,
	ISearchVolunteersSort,
	IInvitedUserSort,
	IFullTextSearchInvitedUser,
	ITimeRange,
	TimeRangeRequest,
	ISearchVolunteersRequest,
	ISearchVolunteersByGroupRequest,
	AgesRange,
	HowFar,
} from '@joc/api-gateway';
import { VOLUNTEERS_FILTERS_VALUES } from 'core/types/VOLUNTEERS_FILTERS_VALUES';
import { ORGANIZATION_TYPES } from 'core/types/ORGANIZATION_TYPES';
import { CreateUploadLinkRequest, IFullTextSearchOrganisationByAdmin } from '@joc/api-gateway/lib/api-client';
//assets
import SuccessSvg from 'assets/image/Success.svg';
import CanceledSvg from 'assets/image/Canceled.svg';
import PendingSvg from 'assets/image/Pending.svg';
import UserPhotoTemplate from 'assets/image/default-photo.svg';
import BabesSvg from 'assets/image/selects/babes.svg';
import KidsSvg from 'assets/image/selects/kids.svg';
import TeensSvg from 'assets/image/selects/teens.svg';
import AdultsSvg from 'assets/image/selects/adults2.svg';
import SeniorsSvg from 'assets/image/selects/seniors.svg';
//constants
import { VOLUNTEER_BADGE_DATE_DIFF } from 'core/constants';

export * from './photo';

export const generateLocation = (
	address: IGetLocationAddressByIdResponse | IGetLocationIdByAddressRequest | undefined
): string => {
	let formattedAddress = '';

	if (address?.buildingNumber) formattedAddress += `${address.buildingNumber} `;
	if (address?.streetName) formattedAddress += `${address.streetName}, `;
	if (address?.cityName) formattedAddress += `${address.cityName}, `;
	if (address?.stateName) formattedAddress += address.stateName;
	if (address?.zipCode) formattedAddress += ` ${address.zipCode}`;
	if (address?.countryName)
		formattedAddress += formattedAddress.length ? `, ${address.countryName}` : address.countryName;

	return formattedAddress;
};

// TODO: fix math abs
export const calculateUserAge = (birthday: Date): string => {
	const age = Math.abs(moment().diff(moment(birthday), 'years')).toString();
	return +age ? age : 'No age';
};

export const setUserPhotoSrc = (imagePath: string | undefined): string => {
	if (imagePath?.length) {
		if (
			imagePath.split('/')[imagePath.split('/').length - 1] === 'null' ||
			imagePath.split('/')[imagePath.split('/').length - 1] === 'undefined'
		)
			return UserPhotoTemplate;
		if (imagePath.length) return `${process.env.REACT_APP_S3_API}/${imagePath}`;
	}
	return UserPhotoTemplate;
};

export const formatDate = (date: Date | string): string => moment(date).format('MM-DD-YY');

export const convertTimeToHoursAndMinutes = (date: Date): string => {
	return moment(date).format('h:mm A');
};

export const isShowNewBadge = (userCreatedDate: Date): boolean => {
	const currentDate = moment();
	return moment(currentDate).diff(moment(userCreatedDate), 'days') <= VOLUNTEER_BADGE_DATE_DIFF;
};

export const generateGenderName = (genderName: string | undefined): string =>
	genderName ? genderName.slice(0, 1).toUpperCase() : 'No data';

export const setWithWhoFilterVision = (who: string) => {
	switch (who) {
		case 'BABIES':
			return { name: 'Babies', age: '0-3', src: BabesSvg };
		case 'KIDS':
			return { name: 'Kids', age: '4-11', src: KidsSvg };
		case 'TEENS':
			return { name: 'Teens', age: '12-17', src: TeensSvg };
		case 'ADULTS':
			return { name: 'Adults', age: '18-64', src: AdultsSvg };
		case 'SENIORS':
			return { name: 'Seniors', age: '65+', src: SeniorsSvg };
		case 'OTHER':
			return { name: 'Others', age: '', src: '' };
		default:
			return { name: '', age: '', src: '' };
	}
};

export const setFullName = (firstName: string | undefined, lastName: string | undefined): string => {
	let fullName = '';
	if (firstName) fullName += firstName;
	if (lastName) fullName += ` ${lastName}`;
	return fullName;
};

type NewParams =
	| IFullTextSearchVolunteers
	| IFullTextSearchOpportunity
	| IFullTextSearchOrganisation
	| IFullTextSearchUsersForAdmin
	| IFullTextSearchOrganisationByAdmin
	| ISearchUsersSort
	| ISearchOrganizationSort
	| ISearchOpportunitiesSort
	| ISearchVolunteersSort
	| IFullTextSearchInvitedUser
	| IInvitedUserSort;

export const setSearchOrSortQueryString = (queryString: string, newParams: NewParams, searchBy: string): string => {
	const searchParams = qs.parse(queryString, {
		ignoreQueryPrefix: true,
	});
	const newQueryParams = { ...searchParams, [searchBy]: { ...newParams } };
	return qs.stringify(newQueryParams);
};

export const addressToObj = (place: google.maps.places.PlaceResult): IGetLocationIdByAddressRequest => {
	const addressObj: IGetLocationIdByAddressRequest = {
		countryName: '',
		stateName: '',
		cityName: '',
		streetName: '',
		buildingNumber: '',
		zipCode: '',
		pobox: '',
		longitude: 0,
		latitude: 0,
	};
	if (place.address_components?.length) {
		place.address_components.forEach((el: google.maps.GeocoderAddressComponent) =>
			el.types.find((typeEl: string) => {
				switch (typeEl) {
					case 'street_number':
						addressObj.buildingNumber = el.long_name;
						break;
					case 'route':
						addressObj.streetName = el.long_name;
						break;
					case 'locality':
						addressObj.cityName = el.long_name;
						break;
					case 'administrative_area_level_1':
						addressObj.stateName = el.long_name;
						break;
					case 'country':
						addressObj.countryName = el.long_name;
						break;
					case 'postal_code':
						addressObj.zipCode = el.long_name;
						break;
					default:
						'';
				}
			})
		);
		addressObj.longitude = place?.geometry?.location?.lng();
		addressObj.latitude = place?.geometry?.location?.lat();
	}
	return addressObj;
};

export const setVolunteerStatus = (status: string) => {
	switch (status) {
		case OPPORTUNITY_VOLUNTEER_STATUS.PENDING:
			return { text: 'Pending', src: PendingSvg };
		case OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT:
			return { text: 'Can’t make it', src: CanceledSvg };
		case OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM:
			return { text: 'Ready to volunteer', src: SuccessSvg };
		default:
			return { text: '', src: '' };
	}
};

export const checkIsClockShow = (date: Date, startTime: string, endTime: string) => {
	const currentDate = moment();
	const opportunityDateStart = moment(date).set({
		hour: +startTime.split(':')[0],
		minutes: +startTime.split(':')[1],
	});
	const opportunityDateEnd = moment(date).set({ hour: +endTime.split(':')[0], minutes: +endTime.split(':')[1] });
	return (
		moment(currentDate, 'X').diff(moment(opportunityDateStart, 'X'), 'minutes') >= -15 &&
		moment(currentDate, 'X').diff(moment(opportunityDateEnd, 'X'), 'minutes') <= 1
	);
};

export const sortVolunteersByName = (volunteers: Array<IGetVolunteerByIdResponse>): Array<IGetVolunteerByIdResponse> =>
	volunteers.sort((a, b) => {
		if (a.firstName && b.firstName && a.lastName && b.lastName)
			return a.firstName.localeCompare(b.firstName) !== 0
				? a.firstName.localeCompare(b.firstName)
				: a.lastName.localeCompare(b.lastName);
		return 0;
	});

export const converTimeToMinutesAndSeconds = (startTime: number) => {
	const h = Math.floor(startTime / 3600)
		.toString()
		.padStart(2, '0');
	const m = Math.floor((startTime % 3600) / 60)
		.toString()
		.padStart(2, '0');
	const s = Math.floor(startTime % 60)
		.toString()
		.padStart(2, '0');
	return h === '00' ? `${m}:${s}` : `${h}:${m}:${s}`;
};

export const removeEmptyProps = (obj: any, isRemoveFalse = false) => {
	if (!obj) {
		return {};
	}

	const newObj = { ...obj };
	Object.entries(newObj)
		.filter(item => {
			const val = item[1];
			return (
				(typeof val === 'boolean' && isRemoveFalse && !val) ||
				(typeof val !== 'number' && typeof val !== 'boolean' && !val) || // if empty string/null/undefined
				(Array.isArray(val) && val.length === 0)
			); // if empty array
		})
		.forEach(item => delete newObj[item[0]]);
	return newObj;
};

export const convertWhatTimeToGreenwich = (whatTime: ITimeRange | undefined): ITimeRange => {
	const timeRange: ITimeRange = {};
	const dateStr = moment().format('YYYY.MM.DD');
	const startTime =
		whatTime?.startTime && moment(`${dateStr} ${whatTime.startTime}`, 'YYYY.MM.DD HH:mm').utc().format('HH:mm:ss');
	TimeRangeRequest.fromJS({
		startTime,
	});
	if (startTime) timeRange.startTime = startTime;
	const endTime =
		whatTime?.endTime && moment(`${dateStr} ${whatTime.endTime}`, 'YYYY.MM.DD HH:mm').utc().format('HH:mm:ss');
	TimeRangeRequest.fromJS({
		endTime,
	});
	if (endTime) timeRange.endTime = endTime;

	return timeRange;
};

export const VolunteersFiltersSubmitClickHandler = (
	values: VOLUNTEERS_FILTERS_VALUES | undefined,
	history: RouteComponentProps['history']
): void => {
	const newParams = qs.parse(location.search, { ignoreQueryPrefix: true });

	if (values?.minAge && values?.maxAge) {
		newParams.ages = [{ min: values.minAge.toString(), max: values.maxAge.toString() }];
	}
	if (values?.minAge && !values?.maxAge) {
		newParams.ages = [{ min: values.minAge.toString(), max: '100' }];
	}
	if (!values?.minAge && values?.maxAge) {
		newParams.ages = [{ min: '0', max: values.maxAge.toString() }];
	}
	if (!values?.minAge && !values?.maxAge) {
		delete newParams.ages;
	}
	if (!values?.organizationType) {
		delete newParams.organizationType;
	}

	if (values?.organizationId) {
		newParams.organizationId = values.organizationId.toString();
		newParams.organizationType = ORGANIZATION_TYPES.organization;
	} else if (values?.schoolId) {
		newParams.organizationId = values.schoolId.toString();
		newParams.organizationType = ORGANIZATION_TYPES.school;
	} else {
		delete newParams.organizationId;
	}

	values?.genderId ? (newParams.genderId = values.genderId.toString()) : delete newParams.genderId;
	values?.howFar ? (newParams.howFar = values.howFar.toString()) : delete newParams.howFar;

	history.push({ search: qs.stringify(newParams) });
};

export const parseQueryStringVolunteersSearch = (
	search: string,
	userLocation: IGetLocationAddressByIdResponse | undefined
): ISearchVolunteersRequest | ISearchVolunteersByGroupRequest => {
	let searchParams: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest | any = qs.parse(search, {
		ignoreQueryPrefix: true,
	});
	const groupId = (searchParams as ISearchVolunteersByGroupRequest).groupId;
	const genderId = (searchParams as ISearchVolunteersRequest).genderId;
	const howFar = (searchParams as ISearchVolunteersRequest).howFar;
	const ages = (searchParams as ISearchVolunteersRequest).ages;
	const organizationId = (searchParams as ISearchVolunteersRequest).organizationId;
	if (ages)
		searchParams = {
			...searchParams,
			ages: ages.map(ageRange => {
				return AgesRange.fromJS({ min: +ageRange.min, max: +ageRange.max });
			}),
		};
	if (groupId) searchParams = { ...searchParams, groupId: +groupId };
	if (genderId) searchParams = { ...searchParams, genderId: +genderId };
	if (howFar)
		searchParams = {
			...searchParams,
			howFar: HowFar.fromJS({
				latitude: userLocation?.latitude || 0,
				longitude: userLocation?.longitude || 0,
				distance: +howFar * 1609.524, // to mile
			}),
		};
	if (organizationId) searchParams = { ...searchParams, organizationId: +organizationId };

	return searchParams;
};

export const convertTimeFromSecondsToHours = (time: number | undefined) => (time ? Math.round(time / 3600) : 0);

export const sendFilesToS3Bucket = async (files: Array<File>, withoutS3prefix = false): Promise<Array<string>> => {
	const presignedUrlsResponse = await Promise.all(
		files.map(file =>
			API.createPreSignedUrl(
				CreateUploadLinkRequest.fromJS({
					fileName: file.name,
					fileType: file.type,
				})
			)
		)
	);

	await Promise.all(
		presignedUrlsResponse.map((url, i) =>
			axios.put(url.upoloadLink, files[i], {
				headers: {
					'Access-Control-Allow-Origin': null,
					'Content-Type': files[i].type,
				},
			})
		)
	);

	if (withoutS3prefix) {
		return presignedUrlsResponse.map(url => url.newFileName);
	}
	return presignedUrlsResponse.map(url => `${process.env.REACT_APP_S3_API}/${url.newFileName}`);
};
