import { IGetLocationIdByAddressRequest } from '@joc/api-gateway';
import { FormikErrors } from 'formik';
import React, { FC, Fragment } from 'react';
import styles from './InputErrorHint.module.scss';
import cx from 'classnames';

type InputErrorHintPropsType = {
	errorText?: string | Array<string>;
	addressErrorText?: FormikErrors<IGetLocationIdByAddressRequest | string>;
	disableMargin?: boolean;
};

type AddressErrors = {
	[key: string]: string;
};

const InputErrorHint: FC<InputErrorHintPropsType> = ({
	errorText,
	addressErrorText,
	disableMargin,
}: InputErrorHintPropsType) => {
	if (addressErrorText && typeof addressErrorText !== 'string')
		return (
			<Fragment>
				{Object.keys(addressErrorText).map((i: string) => (
					<span
						key={i}
						className={cx(styles.main, {
							[styles.main__disable_margin]: disableMargin,
						})}
					>
						{(addressErrorText as AddressErrors)[i]}
					</span>
				))}
			</Fragment>
		);
	if (Array.isArray(errorText))
		return (
			<Fragment>
				{errorText.map(i => (
					<span
						key={i}
						className={cx(styles.main, {
							[styles.main__disable_margin]: disableMargin,
						})}
					>
						{i}
					</span>
				))}
			</Fragment>
		);
	return (
		<span
			className={cx(styles.main, {
				[styles.main__disable_margin]: disableMargin,
			})}
		>
			{errorText || addressErrorText}
		</span>
	);
};

export default InputErrorHint;
