import { Dispatch } from 'redux';
import {
	// findAllOrganisations,
	// AllOrganizationRequest,
	IUpdateOrganizationRequest,
	UpdateOrganizationRequest,
	OrganisationActiveStatusRequest,
	IOrganisationActiveStatusRequest,
	USER_ROLES,
	SearchUsersByAdminResponse,
	SearchUsersForAdminRequest,
	SearchOrganizationByAdminRequest,
} from '@joc/api-gateway';
import {
	RESET_ORGANIZATIONS,
	GET_ORGANIZATIONS_ALL,

	// GET_ORG_VIDEOS,
	// RESET_CREATE_ORG_INFO,
	// RESET_ORG_INFO,
	SET_ERROR,

	// SET_ORG_ABOUT_YOU,
	// SET_ORG_CONTACT,
	// SET_ORG_INVITED_COLLEAGUES,
	// UPLOAD_ORG_VIDEO,
	UPDATE_ORG_INFO,
	ADD_VIDEO_COUNT_WATCH,
	UPDATE_ACTIVE_STATUS_ORG,

	// RESET_ORGANIZATION_USERS,
	RESET_ORGANIZATION_USERS,
	DELETE_ORGANIZATION,
} from 'redux/actions-types';
import { API } from 'core/API';
// import { AboutOrg, AboutYouOrg, ContactOrg } from './types';

export const getOrganizations =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const orgsResponse = await API.searchOrganizationsByAdmin(
				SearchOrganizationByAdminRequest.fromJS(searchBody)
			);

			dispatch({
				type: GET_ORGANIZATIONS_ALL,
				payload: { records: orgsResponse.records, total: orgsResponse.total },
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const getOrgUsers = async (body: SearchUsersForAdminRequest): Promise<SearchUsersByAdminResponse> => {
	try {
		const getUsersRespons = await API.userAdminSearch(
			SearchUsersForAdminRequest.fromJS({
				...body,
				roleNames: [USER_ROLES.ADMIN, USER_ROLES.COORDINATOR, USER_ROLES.MARKETER],
				sort: { sortBy: 'users.firstName', sortDir: 'ASC' },
			})
		);

		return getUsersRespons;
	} catch (error: any) {
		throw error;
	}
};

export const updateOrganization =
	(id: number, requestBody: IUpdateOrganizationRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const updatedOrganizationResponse = await API.updateOrganisation(
				id,
				undefined,
				UpdateOrganizationRequest.fromJS(requestBody)
			);
			dispatch({
				type: UPDATE_ORG_INFO,
				payload: updatedOrganizationResponse,
			});
		} catch (error: any) {
			throw error;
		}
	};

export const updateActiveStatus = (body: IOrganisationActiveStatusRequest) => async (dispatch: Dispatch) => {
	try {
		const updateStatusResponse = await API.updateOrganisationActiveStatus(
			OrganisationActiveStatusRequest.fromJS(body)
		);
		dispatch({
			type: UPDATE_ACTIVE_STATUS_ORG,
			payload: updateStatusResponse,
		});
	} catch (error: any) {
		throw error;
	}
};

export const deleteOrganization =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.deleteOrganization(id);
			dispatch({ type: DELETE_ORGANIZATION, payload: id });
		} catch (error: any) {
			throw new Error(error.message);
		}
	};

export const resetOrgUsers =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: RESET_ORGANIZATION_USERS,
		});
	};

export const resetOrganizations =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: RESET_ORGANIZATIONS,
		});
	};

export const addOrgVideoCountWatch = (id: number) => async (dispatch: Dispatch) => {
	try {
		await API.setCountWatchedOrganizationVideo(id, undefined);
		dispatch({ type: ADD_VIDEO_COUNT_WATCH, payload: id });
	} catch (error: any) {
		console.error(error);
	}
};

// export const setCurrentOrgVideo =
// 	(id: number, orgId: string) =>
// 	async (dispatch: Dispatch): Promise<void> => {
// 		try {
// 			await API.setOrganizationVideo(id, undefined);
// 			dispatch({ type: SET_CURRENT_VIDEO, payload: id });
// 		} catch (error: any) {
// 			throw error;
// 		}
// 	};

// export const unsetCurrentOrgVideo =
// 	(orgId: string) =>
// 	async (dispatch: Dispatch): Promise<void> => {
// 		try {
// 			await API.removeOrganizationVideo(undefined);
// 			dispatch({ type: UNSET_CURRENT_VIDEO, payload: 0 });
// 			// await getOrgInfo(orgId);
// 		} catch (error: any) {
// 			throw error;
// 		}
// 	};

// export const deleteOrgVideo =
// 	(id: number) =>
// 	async (dispatch: Dispatch): Promise<void> => {
// 		try {
// 			await API.deleteVideo(id, undefined);
// 			dispatch({ type: DELETE_CURRENT_VIDEO, payload: id });
// 		} catch (error: any) {
// 			throw error;
// 		}
// 	};
