import MainPageLayout from 'components/Organization/MainPageLayout';
import React, { FC } from 'react';
import UserListContent from './UserListContent';
import styles from './SuperAdminUserList.module.scss';

const SuperAdminUserList: FC = () => {
	return (
		<MainPageLayout>
			<div className={styles.container}>
				<div className={styles.block_title}>
					<h1 className="title">Users List</h1>
					<UserListContent />
				</div>
			</div>
		</MainPageLayout>
	);
};

export default SuperAdminUserList;
