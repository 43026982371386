import { FC } from 'react';
import cx from 'classnames';
// types
import { ISortedRolesItem } from 'pages/Admin/SuperAdminUserList/UsersTable';
// styles
import styles from './RoleCell.module.scss';

type RoleCellProps = {
	parentClassName?: string;
	roles: Array<ISortedRolesItem>;
};

const RoleCell: FC<RoleCellProps> = ({ parentClassName, roles }) => {
	const getOrgName = (role: ISortedRolesItem, index: number) =>
		index === roles.length - 1 ? role.organization?.organizationName : `${role.organization?.organizationName}, `;

	return (
		<div className={cx(styles.main, parentClassName && styles[parentClassName])}>
			{roles.map(
				(role, index) =>
					role.organization?.organizationName && (
						<div key={index}>
							<h3 className={styles.organizationName}>{getOrgName(role, index)}</h3>
						</div>
					)
			)}
		</div>
	);
};
export default RoleCell;
