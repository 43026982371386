import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserRole } from 'redux/companiesUsers-service/actions';
import { Field, FieldProps, Form, Formik } from 'formik';
import { creatOrgganizationToEditRolePopup } from 'core/validation';
// components
import InputSearchOrg from 'components/Organization/InputSearchOrg';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputErrorHint from 'shared/components/InputErrorHint';
import PopupContainer from 'shared/components/PopupContainer';
import RolesSelect from 'shared/components/Selects/SelectDropdown/RolesSelect';
import WhiteContainer from 'shared/components/WhiteContainer';
// styles
import styles from './CreatePositionPopup.module.scss';

type EditRolePopupProps = {
	setIsShowPopup: () => void;
	userId: number;
};

const CreatePositionPopup: FC<EditRolePopupProps> = ({ setIsShowPopup, userId }: EditRolePopupProps) => {
	const dispatch = useDispatch();

	const [initValues] = useState({
		organisationId: '',
		invitedUserRole: 3,
	});

	const setNewOrganization = async (orgId: string, roleId: number) => {
		try {
			await dispatch(setUserRole(userId, orgId, roleId));
		} catch (error: any) {
			throw error;
		}
	};

	return (
		<PopupContainer setIsShowPopup={setIsShowPopup}>
			<WhiteContainer title="Create new position">
				<Formik
					enableReinitialize
					initialValues={initValues}
					validationSchema={creatOrgganizationToEditRolePopup}
					onSubmit={values => {
						setNewOrganization(values.organisationId, values.invitedUserRole);
						setIsShowPopup();
					}}
				>
					{({ errors, touched, setFieldValue, dirty, setFieldTouched, submitForm, isValid }) => (
						<>
							<Form className={styles.form}>
								<Field name="organisationId">
									{({ field }: FieldProps) => (
										<InputContainer parentClassnames={styles.top}>
											<label className={styles.label} htmlFor="organisationId">
												Organization name
											</label>
											<InputSearchOrg
												fieldName={field.name}
												placeholder="Search organization"
												fieldValue={field.value}
												selectHandler={id => setFieldValue(field.name, id)}
											/>
										</InputContainer>
									)}
								</Field>
								{/* <Field name="positionId">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label htmlFor="positionId">Position</label>
											<PositionSelect
												id="positionId"
												fieldValue={field.value}
												fieldName={field.name}
												placeholder="Position"
												setFieldValue={setFieldValue}
												setFieldTouched={setFieldTouched}
											/>
											{errors.positionId && touched.positionId && (
												<InputErrorHint errorText={errors.positionId} />
											)}
										</InputContainer>
									)}
								</Field> */}
								<Field name="invitedUserRole">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="invitedUserRole">
												Role
											</label>
											<RolesSelect
												id="invitedUserRole"
												fieldName={field.name}
												fieldValue={field.value}
												setFieldValue={setFieldValue}
												setFieldTouched={setFieldTouched}
											/>
											{errors.invitedUserRole && touched.invitedUserRole && (
												<InputErrorHint errorText={errors.invitedUserRole} />
											)}
										</InputContainer>
									)}
								</Field>
							</Form>
							<div className={styles.button}>
								<ButtonDefault
									title="Create"
									classList="secondary, lg"
									submitType
									disabled={!dirty && isValid}
									clickHandler={submitForm}
								/>
							</div>
						</>
					)}
				</Formik>
			</WhiteContainer>
		</PopupContainer>
	);
};

export default CreatePositionPopup;
