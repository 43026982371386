import { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetError, setError } from 'redux/error-service/action';
import { ISearchOrganizationByAdminRequest } from '@joc/api-gateway/lib/api-client';
import { getOrganizations, resetOrganizations } from 'redux/organization-service/actions';
import { INITIAL_PAGINATION } from 'core/constants/INITIAL_PAGINATION';
import qs from 'qs';
import { useLocation } from 'react-router';
import OrganizationsTable from 'shared/components/Table/AdminOrgTable';
import styles from './OrgListContent.module.scss';

const parseQueryString = (
	search: string
	// userLocation: IGetLocationAddressByIdResponse | undefined
) => {
	const searchParams: ISearchOrganizationByAdminRequest = qs.parse(search, {
		ignoreQueryPrefix: true,
	});
	return searchParams;
};

const OrgListContent: FC = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const [requestBody, setRequestBody] = useState<ISearchOrganizationByAdminRequest | undefined>();
	const location = useLocation();

	const getOrganizationsRequest = useCallback(async () => {
		setIsLoading(true);
		try {
			await dispatch(getOrganizations({ ...requestBody, pagination }));
			setIsLoading(false);
		} catch (error: any) {
			setIsLoading(false);
			dispatch(setError(error?.response?.message || error.message, error.code));
		}
	}, [requestBody, pagination]);

	useEffect(() => {
		getOrganizationsRequest();
	}, [getOrganizationsRequest]);

	useEffect(() => {
		if (!location.search.length) return;
		dispatch(resetError);
		dispatch(resetOrganizations());
		const data = parseQueryString(location.search);
		setPagination(INITIAL_PAGINATION);
		setRequestBody(data);
	}, [location.search]);

	useEffect(() => {
		return () => {
			dispatch(resetOrganizations());
		};
	}, []);

	return (
		<div className={styles.container}>
			<OrganizationsTable
				isLoading={isLoading}
				setPagination={setPagination}
				changeVisibilityFilters={() => console.log('oppoList')}
				setRequestBody={setRequestBody}
			/>
		</div>
	);
};

export default OrgListContent;
