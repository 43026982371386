import React, { FC, useState } from 'react';
import cx from 'classnames';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import styles from './ChallengePopupCongrats.module.scss';

type ChallengePopupCongratsProps = {
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	isNew?: boolean;
};

const ChallengePopupCongrats: FC<ChallengePopupCongratsProps> = ({ setIsShowPopup, isNew }) => {
	const [isClosePopup, setIsClosePopup] = useState<boolean>(false);

	const doneButtonClickHandler = () => {
		setIsClosePopup(true);
		setTimeout(() => setIsShowPopup(false), 200);
	};

	return (
		<div
			className={cx(styles.container, {
				[styles.close]: isClosePopup,
			})}
		>
			<Appeal isHeightDisable>
				<div className={styles.appeal}>
					<span className="title">Congrats!</span>
					{isNew ? (
						<span className={styles.subtitle}>New Challenge has been created.</span>
					) : (
						<span className={styles.subtitle}>Challenge has been updated successfully.</span>
					)}
					<div className={styles.options}>
						<ButtonDefault
							classList={['lg', 'primary']}
							title="Done"
							clickHandler={doneButtonClickHandler}
						/>
					</div>
				</div>
			</Appeal>
		</div>
	);
};

export default ChallengePopupCongrats;
