import React, { FC, ReactNode } from 'react';
import cx from 'classnames/bind';
import styles from './TableRow.module.scss';

type TableRowPropsType = {
	children: ReactNode;
	className?: string;
	callChild?: string;
	suspend?: boolean;
};

const cxStyle = cx.bind(styles);

const TableRow: FC<TableRowPropsType> = ({ children, className, callChild, suspend }: TableRowPropsType) => {
	return (
		<div
			className={cxStyle(className, {
				volunteers: callChild === 'volunteers',
				oppotunities: callChild === 'oppotunities',
				organizations: callChild === 'organizations',
				oppotunitiesVolunteer: callChild === 'oppotunitiesVolunteer',
				viewMore: callChild === 'viewMore',
				suspend: suspend === true,
				users: callChild === 'users',
				roles: callChild === 'roles',
				invites: callChild === 'invites',
			})}
		>
			{children}
		</div>
	);
};

export default TableRow;
