import { Quill } from 'react-quill';
import { COLOR_ICON, fontSizeToLineHeight } from './constants';

export function undoHandler() {
	// @ts-ignore
	this.quill.history.undo();
}

export function redoHandler() {
	// @ts-ignore
	this.quill.history.redo();
}

export function colorHandler(color: string) {
	const icons = Quill.import('ui/icons');
	icons.color = COLOR_ICON(color);

	const toolbar = document.querySelector('.ql-toolbar');
	if (toolbar) {
		const colorButton = toolbar.querySelector(
			`.ql-toolbar.ql-snow .ql-color-picker .ql-picker-label[data-value="${color}"]`
		);

		if (colorButton) {
			colorButton.innerHTML = COLOR_ICON(color);
		}
	}

	if (color) {
		// @ts-ignore
		this.quill.format('color', color);
	}
}

export function sizeHandler(size: string) {
	if (size) {
		// @ts-ignore
		this.quill.format('size', size);
		// @ts-ignore
		this.quill.format('lineHeight', fontSizeToLineHeight[size]);
	}
}
