import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useDebounceValue } from 'core/customHooks';
import styles from './index.module.scss';

type InputSearchProps = {
	placeholder: string;
	id?: string;
	classList?: Array<string>;
	parentClass?: string;
	value?: string;
	changeHandler: (value: string) => void;
	autoFocus?: boolean;
	isClearSearch?: boolean;
	setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
};

const InputSearch: FC<InputSearchProps> = ({
	placeholder = '',
	id,
	classList,
	changeHandler,
	parentClass,
	value,
	autoFocus = false,
	isClearSearch,
	setIsClearSearch,
}: InputSearchProps) => {
	const [inputValue, setInputValue] = useState<string | undefined>(undefined);

	const debouncedValue = useDebounceValue(inputValue, 500);

	const ref = useRef<HTMLInputElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		if (debouncedValue !== undefined) changeHandler(debouncedValue);
	}, [debouncedValue]);

	useEffect(() => {
		if (isClearSearch) setInputValue('');
	}, [isClearSearch]);

	return (
		<div
			className={cx(styles.input__search, parentClass, {
				[styles.big]: classList?.includes('big'),
			})}
		>
			<div className={styles.input__search_icon} onClick={setFocus} />
			<input
				autoFocus={autoFocus}
				id={id && id}
				type="text"
				value={value || inputValue || ''}
				placeholder={placeholder}
				ref={ref}
				onChange={e => {
					setInputValue(e.target.value);
					setIsClearSearch && setIsClearSearch(false);
				}}
			/>
		</div>
	);
};

export default InputSearch;
