import React, { FC, useMemo, useState } from 'react';
import cx from 'classnames';
import { getUserPhotoSrc } from 'core/functions';
import styles from './AvatarCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';
import { IMAGE_WITH_POPUP_TYPE } from 'core/types';

type AvatarCellProps = {
	imagePath: string | undefined;
	opportunityType?: IMAGE_WITH_POPUP_TYPE;
};

const AvatarCell: FC<AvatarCellProps> = ({ imagePath, opportunityType }: AvatarCellProps) => {
	const [isImageLoadError, setIsImageLoadError] = useState(false);

	const onError = () => setIsImageLoadError(true);

	const parsedImagePath = useMemo(
		() => getUserPhotoSrc(isImageLoadError ? null : imagePath, opportunityType),
		[imagePath, opportunityType, isImageLoadError]
	);

	return (
		<div className={cx(styles.avatar, defaultStyles.main)}>
			<img src={parsedImagePath} alt="avatar" onError={onError} />
		</div>
	);
};

export default AvatarCell;
