import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { changeNavbarMode } from 'redux/user-service/actions';
import styles from './NavBarVisibilityButton.module.scss';

const NavBarVisibilityButton: FC<NavBarVisibilityButtonProps> = ({ changeNavbarMode }: NavBarVisibilityButtonProps) => {
	return (
		<button className={styles.main} onClick={changeNavbarMode} aria-label="change-navbar-mode">
			<span className={styles.line} />
			<span className={styles.line} />
			<span className={styles.line} />
		</button>
	);
};

const mapDispatchToProps = {
	changeNavbarMode,
};

const connector = connect(null, mapDispatchToProps);

type NavBarVisibilityButtonProps = ConnectedProps<typeof connector>;

export default connector(NavBarVisibilityButton);
