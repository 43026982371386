// types
import { RolesResponse } from '@joc/api-gateway';
import { ISortedRolesItem } from '../../UsersTable';

export const getAllUserRolesInEachOrg = (userOrgRoles: Array<RolesResponse> | undefined) => {
	const rolesInEachOrg: Array<ISortedRolesItem> = [];

	userOrgRoles?.forEach(orgWithRole => {
		const newOrgRole = { roleId: orgWithRole.id, roleName: orgWithRole.roleName };
		const existingOrg = rolesInEachOrg.find(newRole => newRole.organizationId === orgWithRole.organizationId!);

		if (existingOrg) {
			existingOrg.roleList = [...existingOrg.roleList, newOrgRole];
		} else {
			const orgWithRoles = {
				organizationId: orgWithRole.organizationId,
				organization: orgWithRole.organization,
				roleList: [newOrgRole],
			};
			rolesInEachOrg.push(orgWithRoles);
		}
	});

	return rolesInEachOrg;
};

export const getSortedUserOrgsList = (roles: Array<ISortedRolesItem> = [], isSortASC?: boolean) =>
	roles.sort((org1, org2) => {
		if (org1?.organization?.organizationName && org2?.organization?.organizationName) {
			if (
				org1?.organization?.organizationName.toUpperCase() < org2?.organization?.organizationName.toUpperCase()
			) {
				console.log('first');
				console.log(isSortASC ? -1 : 1);

				return isSortASC ? -1 : 1;
			}
			if (
				org1?.organization?.organizationName.toUpperCase() > org2?.organization?.organizationName.toUpperCase()
			) {
				console.log('second');
				console.log(isSortASC ? 1 : -1);

				return isSortASC ? 1 : -1;
			}
		}
		console.log('far');
		if (!org1?.organization?.organizationName) return 1;
		if (!org2?.organization?.organizationName) return -1;

		console.log('last');

		return 0;
	});
