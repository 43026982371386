import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import Picker from '@emoji-mart/react';
import { Emoji } from 'emoji-mart/dist-es/utils/data';
//styles
import './CustomEmojiPicker.scss';

type CustomEmojiPickerProps = {
	onSelectEmoji: (emoji: Emoji) => void;
	emojiPickerRef: MutableRefObject<HTMLDivElement | null>;
	isOpen: boolean;
	changeIsOpenStatus: Dispatch<SetStateAction<boolean>>;
};

const CustomEmojiPicker: React.FC<CustomEmojiPickerProps> = ({
	onSelectEmoji,
	emojiPickerRef,
	isOpen,
	changeIsOpenStatus,
}: CustomEmojiPickerProps) =>
	isOpen ? (
		<div className="emojiPicker">
			<Picker onEmojiSelect={onSelectEmoji} emojiPickerRef={emojiPickerRef} onClickOutside={changeIsOpenStatus} />
		</div>
	) : (
		<></>
	);

export default CustomEmojiPicker;
