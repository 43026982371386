import { createRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
import { API } from 'core/API';
import { IGendersResponse } from '@joc/api-gateway';
import Loader from 'shared/components/Loader';
import styles from './index.module.scss';

type GenderSelectTypes = {
	placeholder?: string;
	bothGenders?: boolean;
	fieldValue?: number;
	fieldName: string;
	setFieldValue: (field: string, value: number) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	fieldTouched?: boolean;
	classList?: Array<string>;
	parentClassName?: string;
	isDisabled?: boolean;
};

const GenderSelect: FC<GenderSelectTypes> = ({
	placeholder = 'Select gender',
	setFieldValue,
	setFieldTouched,
	classList,
	fieldValue,
	fieldName,
	fieldTouched,
	bothGenders,
	parentClassName,
	isDisabled,
}: GenderSelectTypes) => {
	const [options, setOptions] = useState<Array<IGendersResponse>>([]);
	const [error, setError] = useState(false);
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const title = useMemo(() => {
		if (options.length && fieldValue) {
			return options.find(option => option.id.toString() === fieldValue.toString())?.genderName;
		}
		if (bothGenders && fieldTouched) return 'Both genders';
		return '';
	}, [fieldValue, options, fieldTouched]);
	const selectRef = useRef<HTMLDivElement>(null);
	const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

	const optionClickHandler = (id: number): void => {
		setFieldValue(fieldName, id);
		setIsShowOptions(false);
	};

	const handleClickOutside = (event: Event) => {
		isShowOptions && setFieldTouched && setFieldTouched(fieldName, true);
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};
	const titleClickHandler = () => {
		if (!isDisabled) {
			const valueIndex = options.findIndex(i => i.id === fieldValue);
			const target = optionRef[valueIndex]?.current;
			if (target)
				// set timeout cause in a first time the scroll doesn't work
				setTimeout(() => {
					target.scrollIntoView();
				}, 0);
			if (isShowOptions) setFieldTouched(fieldName, true);
			setIsShowOptions(!isShowOptions);
		}
	};

	const getGenders = useCallback(async () => {
		try {
			setError(false);
			const response = await API.getAllGenders();
			if (response.length) {
				if (bothGenders) return setOptions([...response, { id: 3, genderName: 'Both genders' }]);
				return setOptions(response);
			}
		} catch (error: any) {
			setError(true);
		}
	}, [bothGenders]);

	useEffect(() => {
		getGenders();
	}, []);

	useEffect(() => {
		if (fieldValue) setFieldValue(fieldName, fieldValue);
	}, [fieldValue]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	if (error) return <div onClick={() => getGenders()}>Something went wrong, plese click to try again</div>;
	if (!options.length) return <Loader />;
	return (
		<div
			className={cx(styles.dropdown_select, parentClassName && parentClassName, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
				[styles.disabled]: isDisabled,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!fieldValue || (bothGenders && fieldTouched),
				})}
				onClick={titleClickHandler}
			>
				<span className={cx({ [styles.disabled_span]: isDisabled })}>{title || placeholder}</span>
				{!isDisabled && !isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div className={styles.dropdown_select__options}>
				{options.map((option: IGendersResponse) => (
					<div
						key={option.id}
						className={styles.dropdown_select__options__item}
						onClick={() => optionClickHandler(option.id)}
					>
						{option.genderName}
					</div>
				))}
			</div>
		</div>
	);
};

export default GenderSelect;
