import React, { FC, useEffect, useMemo } from 'react';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableRow from 'shared/components/Table/TableRow';
// types
import { IGetVolunteerByIdResponse, OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import TableBody from 'shared/components/Table/TableBody';
import TableMain from 'shared/components/Table/TableMain';
// core
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES } from 'core/constants';
import {
	calculateUserAge,
	convertTimeFromSecondsToHours,
	generateLocation,
	sortVolunteersByName,
} from 'core/functions';
// styles
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import CheckboxVolunteerCell from 'shared/components/Table/CellRenderers/CheckboxVolunteerCell';
import TableHeadCheckboxVolunteerCell from 'shared/components/Table/TableHead/TableHeadCell/TableHeadCheckboxVolunteerCell';
// import { selectorGetOrgId } from 'redux/organization-service/selector';
import { Store } from 'redux/root';
import { connect, ConnectedProps } from 'react-redux';
import PossibleVolunteersActionsCell from 'shared/components/Table/CellRenderers/PossibleVolunteersActionsCell';
import checkboxCellStyles from 'shared/components/Table/CellRenderers/CheckboxVolunteerCell/CheckboxVolunteerCell.module.scss';
import styles from './Table.module.scss';

type PossibleVolunteersTableParentProps = {
	opportunityId: number;
	volunteersArray: Array<IGetVolunteerByIdResponse>;
	opportunityStatus: OPPORTUNITY_STATUSES;
	smartGroupVolunteers?: Array<number>;
	opportunityType: OPPORTUNITY_TYPES;
	setSmartGroupVolunteers?: (checked?: boolean, value?: number | undefined) => void;
	setSmartGroupVolunteersAll?: () => void;
	volunteersArrayMembers?: Array<IGetVolunteerByIdResponse>;
};

const PossibleVolunteersTable: FC<PossibleVolunteersTableParentProps> = ({
	volunteersArray,
	opportunityId,
	opportunityStatus,
	smartGroupVolunteers,
	setSmartGroupVolunteers,
	setSmartGroupVolunteersAll,
	opportunityType,
	volunteersArrayMembers,
}: PossibleVolunteersTableParentProps) => {
	// const isCheckedAll = useMemo(() => {
	// 	return !!(
	// 		volunteersArrayMembers &&
	// 		// !!smartGroupVolunteers.length &&
	// 		// volunteersArrayMembers.length === smartGroupVolunteers.length
	// 	);
	// }, [volunteersArray]);

	return (
		<div className={styles.table}>
			<TableMain>
				<TableHead className={styles.table__header} callChild="viewMore">
					{opportunityStatus === OPPORTUNITY_STATUSES.CLOSED && (
						<TableHeadCheckboxVolunteerCell
						// clickHandler={setSmartGroupVolunteersAll}
						// isChecked={isCheckedAll}
						/>
					)}
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.avatar}
						text={TABLE_HEADERS.avatar}
						isSortDisabled
					/>
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
						text={TABLE_HEADERS.fullName}
						isSortDisabled
					/>
					<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.age} text={TABLE_HEADERS.age} isSortDisabled />
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.gender}
						text={TABLE_HEADERS.gender}
						isSortDisabled
					/>
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.location}
						text={TABLE_HEADERS.location}
						isSortDisabled
					/>
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.totalTimeSpent}
						text={TABLE_HEADERS.totalTimeSpent}
						isSortDisabled
					/>
					{/* {opportunityStatus === OPPORTUNITY_STATUSES.OPEN && +opportunityTypes !== 3 && (
						<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.volunteers_actions} isSortDisabled />
					)} */}
				</TableHead>
				<TableBody>
					{!!volunteersArray.length &&
						sortVolunteersByName([...volunteersArray]).map((volunteer: IGetVolunteerByIdResponse) => (
							<TableRow key={volunteer.id} className={styles.table__row} callChild="viewMore">
								{opportunityStatus === OPPORTUNITY_STATUSES.CLOSED && setSmartGroupVolunteers && (
									// (volunteer?.organizations?.map(org => +org.id).includes(orgId) ? (
									// 	<CheckboxVolunteerCell
									// 		volunteerId={+volunteer.id}
									// 		defaultChecked={!!smartGroupVolunteers?.includes(+volunteer.volunteerId)}
									// 		changeHandle={setSmartGroupVolunteers}
									// 	/>
									// ) :
									<div className={checkboxCellStyles.main} />
								)}
								<AvatarCell imagePath={volunteer.imagePath} />
								<FullNameCell
									firstName={volunteer.firstName}
									lastName={volunteer.lastName}
									// isShowMemberBadge={
									// 	!!(
									// 		orgId &&
									// 		orgId.toString() === volunteer.organizations?.find(i => +i.id === orgId)?.id
									// 	)
									// }
									createDate={volunteer.createDate}
								/>
								<CellDefault
									parentClassName={TABLE_ITEMS_CLASSNAMES.age}
									text={calculateUserAge(volunteer.birthDate)}
								/>
								<CellDefault
									parentClassName={TABLE_ITEMS_CLASSNAMES.gender}
									text={volunteer.gender?.genderName.slice(0, 1).toUpperCase() || 'No data'}
								/>
								<CellDefault
									parentClassName={TABLE_ITEMS_CLASSNAMES.location}
									text={(volunteer.address && generateLocation(volunteer.address)) || 'No data'}
								/>
								<CellDefault
									parentClassName={TABLE_ITEMS_CLASSNAMES.totalTimeSpent}
									text={convertTimeFromSecondsToHours(volunteer.spendTime)}
								/>
								{/* {opportunityStatus === OPPORTUNITY_STATUSES.OPEN && +opportunityTypes !== 3 && (
									<PossibleVolunteersActionsCell
										volunteerId={volunteer.id}
										opportunityId={opportunityId}
										volunteerStatus={volunteer.status}
										volunteerChatId={volunteer.chatId}
									/>
								)} */}
							</TableRow>
						))}
				</TableBody>
			</TableMain>
		</div>
	);
};

export default PossibleVolunteersTable;
