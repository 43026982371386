import {
	GET_DASHBOARD_INFO,
	GET_LIVESCREEN_INFO,
	RESET_LIVESCREEN_INFO,
	UPDATE_LIVESCREEN_INFO,
} from '../actions-types';
import { DashboardActionTypes, DashboardReducer } from './types';
import { DashboardSettingsResponse } from '@joc/api-gateway/lib/api-client';

const initState: DashboardReducer = {
	totalOrg: 0,
	totalVol: 0,
	totalOpp: 0,
	liveScreen: null,
};

const dashboardReducer = (state = initState, action: DashboardActionTypes): DashboardReducer => {
	const { type, payload }: DashboardActionTypes = action;

	switch (type) {
		case GET_DASHBOARD_INFO: {
			return payload;
		}
		case GET_LIVESCREEN_INFO: {
			return { ...state, liveScreen: payload };
		}

		case UPDATE_LIVESCREEN_INFO: {
			return { ...state, liveScreen: DashboardSettingsResponse.fromJS({ ...state.liveScreen, ...payload }) };
		}

		case RESET_LIVESCREEN_INFO: {
			return { ...state, liveScreen: null };
		}

		default:
			return state;
	}
};

export default dashboardReducer;
