import { FC } from 'react';
// constants
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES } from 'core/constants';
// components
import Checkbox from 'shared/components/Checkbox';
// styles
import TableHeadCell from '..';

type TableHeadCheckboxVolunteerCellProps = {
	clickHandler?: () => void;
	isChecked?: boolean;
};

const TableHeadCheckboxVolunteerCell: FC<TableHeadCheckboxVolunteerCellProps> = ({
	clickHandler,
	isChecked,
}: TableHeadCheckboxVolunteerCellProps) => (
	<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.checkbox} text={TABLE_HEADERS.select}>
		<Checkbox changeHandle={clickHandler} defaultChecked={isChecked} />
	</TableHeadCell>
);

export default TableHeadCheckboxVolunteerCell;
