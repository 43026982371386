import { Quill } from 'react-quill';

const Block = Quill.import('blots/block');

export class CustomParagraph extends Block {
	static tagName = 'p';

	static create() {
		const node = super.create();

		// Apply your custom inline styles here
		node.setAttribute('style', 'font-style: normal; margin: unset;');
		return node;
	}

	static formats(domNode: HTMLElement) {
		return domNode.getAttribute('style');
	}

	format(name: string, value: any) {
		if (name === 'align') {
			this.domNode.style.textAlign = value || 'left';
		} else {
			super.format(name, value);
		}
	}
}
