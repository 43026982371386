import React, { FC, useState } from 'react';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { connect, ConnectedProps } from 'react-redux';
import { saveAs } from 'file-saver';
// redux
import { Store } from 'redux/root';
import { setError } from 'redux/error-service/action';
import { selectorGetOpportunitiesRecords } from 'redux/opportunities-service/selector';
import ButtonPrint from 'shared/components/Buttons/ButtonPrint';
import ButtonDownload from 'shared/components/Buttons/ButtonDownload';
import PopupCreate from '../../OpportunityPopups/PopupCreate';
import PopupMain from '../../OpportunityPopups/PopupMain';
// type
import { OpportunitiesRequestBody } from '..';
// styles
import styles from './Actions.module.scss';

type ActionsParentProps = {
	printRef: React.RefObject<HTMLTableElement>;
	setIsPrintableTable: React.Dispatch<React.SetStateAction<boolean>>;
	requestBody: OpportunitiesRequestBody | undefined;
};

const Actions: FC<ActionsProps> = ({
	printRef,
	setIsPrintableTable,
	opportunitiesRecords,
	requestBody,
}: ActionsProps) => {
	const [isShowCreatePopup, setIsShowCreatePopup] = useState(false);

	const handlePrint = useReactToPrint({
		onBeforeGetContent: () => setIsPrintableTable(true),
		onAfterPrint: () => setIsPrintableTable(false),
		content: () => printRef.current,
	});

	const buttonDownloadClickHandler = async () => {
		try {
			// check for addinitional errors
			const accessToken = localStorage.getItem('accessToken');
			if (!accessToken) throw new Error('Sorry, we can`t find your access token');
			// request to download xlsx file
			const opportunitiesResponse = await axios(
				`${process.env.REACT_APP_JOC_API}/opportunities/export-opportunities-by-admin`,
				{
					method: 'POST',
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					data: { ...requestBody },
				}
			);
			// file saver
			saveAs(
				opportunitiesResponse.data,
				`${!!requestBody?.oppoFilterType ? requestBody?.oppoFilterType : 'ALL'}_opportunities_list`
			);
		} catch (error: any) {
			setError(error?.response?.message || error.message, error.code);
		}
	};
	return (
		<div className={styles.wrapper}>
			<ButtonDownload clickHandler={buttonDownloadClickHandler} disabled={!opportunitiesRecords.length} />
			<ButtonPrint clickHandler={handlePrint} disabled={!opportunitiesRecords.length} />
			{/* <ButtonDefault
				title="+ Create"
				classList={['primary', 'sm']}
				clickHandler={() => setIsShowCreatePopup(true)}
			/> */}
			{isShowCreatePopup && (
				<PopupMain setIsShowPopup={setIsShowCreatePopup}>
					<PopupCreate setIsShowPopup={setIsShowCreatePopup} />
				</PopupMain>
			)}
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	opportunitiesRecords: selectorGetOpportunitiesRecords(store),
});

const mapDispatchToProps = {
	setError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ActionsProps = ConnectedProps<typeof connector> & ActionsParentProps;

export default connector(Actions);
