import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { getLiveScreen, resetLiveScreen, updateLiveScreen } from 'redux/dashboard-service/action';
import { selectorGetLiveScreen } from 'redux/dashboard-service/selector';
import { SET_ERROR } from 'redux/actions-types';
import { ILiveScreenInfo } from 'redux/dashboard-service/types';
// Formik
import { Formik } from 'formik';
// Components
import Loader from '../Loader';
import { LiveScreenForm } from './LiveScreenForm';
// Api
import { API } from 'core/API';
// Styles
import styles from 'components/Organization/InputLogo/index.module.scss';
import CongratsPopup from 'components/Organization/CongratsPopup';

const validationScheme = Yup.object().shape({
	title: Yup.string().required(),
	subTitle: Yup.string().required(),
});

type LiveScreenProps = {
	// eslint-disable-next-line no-unused-vars
	onChangeEditMode: (value: boolean) => void;
	editMode: boolean;
};

export const LiveScreen: FC<LiveScreenProps> = ({ onChangeEditMode, editMode }) => {
	const dispatch = useDispatch();

	const liveScreenInfo = useSelector(selectorGetLiveScreen);
	const [file, setFile] = useState<Blob | null>(null);
	const [isCongrats, setIsCongrats] = useState(false);

	const [formValue, setFormValue] = useState<ILiveScreenInfo>({
		title: '',
		subTitle: '',
		mediaPath: '',
	});

	useEffect(() => {
		dispatch(getLiveScreen());

		return () => {
			dispatch(resetLiveScreen());
		};
	}, []);

	useEffect(() => {
		initFormData();
	}, [liveScreenInfo]);

	const initFormData = () => {
		setFormValue({
			title: '',
			subTitle: '',
			mediaPath: '',
		});
		if (liveScreenInfo) {
			const data = {
				title: liveScreenInfo.title,
				subTitle: liveScreenInfo.subTitle,
				mediaPath: liveScreenInfo.mediaPath,
			};
			setFormValue(data);
		}
	};

	const onCancelEditMode = () => {
		initFormData();
		setFile(null);
		onChangeEditMode(false);
	};

	const handleSubmit = async (values: ILiveScreenInfo) => {
		try {
			if (file) {
				const fileName = (file as any).name;
				const responseUpload = await API.uploadFile({ data: file, fileName });
				values.mediaPath = responseUpload.fileName;
				setFile(null);
			} else {
				values.mediaPath = '';
			}

			values.title = values.title.trim();
			values.subTitle = values.subTitle.trim();

			await dispatch(updateLiveScreen(values));
			onChangeEditMode(false);
			setIsCongrats(true);
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error.message } });
		}
	};

	if (!liveScreenInfo) {
		return (
			<div className={styles.loader}>
				<Loader />
			</div>
		);
	}

	return (
		<>
			<Formik
				enableReinitialize={true}
				validationSchema={validationScheme}
				initialValues={formValue}
				onSubmit={() => {}}
			>
				<LiveScreenForm
					file={file}
					setFile={setFile}
					mediaPath={liveScreenInfo.mediaPath}
					editMode={editMode}
					onCancelEditMode={onCancelEditMode}
					onSaveChanges={handleSubmit}
				/>
			</Formik>
			{isCongrats && (
				<CongratsPopup
					title="Success!"
					subtitle="Information has been updated."
					doneButtonClickHandler={() => setIsCongrats(false)}
					isFullHeight={true}
					isLiveScreen={true}
				/>
			)}
		</>
	);
};
