import React, { FC, useEffect, useRef } from 'react';
import { FieldInputProps } from 'formik';
import styles from './index.module.scss';

type InputPasswordProps = {
	placeholder?: string;
	field: FieldInputProps<string>;
	setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
	isShow?: boolean;
};

let timeout: NodeJS.Timeout;

const InputPassword: FC<InputPasswordProps> = ({
	placeholder = 'Password',
	field,
	setFieldValue,
	isShow,
}: InputPasswordProps) => {
	const ref = useRef<HTMLInputElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		window.clearTimeout(timeout);
		timeout = setTimeout(() => {
			setFieldValue(field?.name, field.value.trim());
		}, 700);
	}, [field.value]);

	return (
		<div className={styles.input__password}>
			<input type={isShow ? 'text' : 'password'} placeholder={placeholder} {...field} ref={ref} />
			{!!field.value && <div className={styles.input__password_edit} onClick={setFocus} />}
		</div>
	);
};

export default InputPassword;
