import { OrganisationResponse } from '@joc/api-gateway';

export const getListVolunteers = (arr: Array<OrganisationResponse>) => {
	const initArr: Array<string> = [];
	const resultList = arr.reduce(
		(accumulator, currentValue) => [...accumulator, currentValue.organizationName],
		initArr
	);
	return resultList.join(', ');
};
