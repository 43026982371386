import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import ClockSvg from 'assets/image/time.svg';
import { TIME_PICKER_TEMPLATES } from 'core/constants';
import moment from 'moment-mini';
import CustomTimePickerField from './CustomTimePickerField';
import styles from './CustomTimePicker.module.scss';

const convertFieldValueToPickerValue = (fieldValue: string): PickerValuesType => {
	const date = moment().format('YYYY-MM-DD');
	const formattedDate = moment(`${date} ${fieldValue}`).format('h:m A');
	const time = formattedDate.split(':');
	const hours = +time[0];
	const minutes = +time[1].split(' ')[0];
	const meridiem = +moment(`${date} ${fieldValue}`).hours() < 12 ? MERIDIEM.AM : MERIDIEM.PM;
	return { hours, minutes, meridiem };
};

const convertPickerValueToFieldValue = (pickerValue: PickerValuesType) => {
	const time = `${pickerValue.hours}:${pickerValue.minutes} ${pickerValue.meridiem}`;
	const date = moment().format('YYYY-MM-DD');
	return moment(`${date} ${time}`).format('HH:mm');
};

export enum MERIDIEM {
	AM = 'AM',
	PM = 'PM',
}

export type PickerValuesType = {
	hours: number | null;
	minutes: number | null;
	meridiem: MERIDIEM;
};

type InlineTimePickerProps = {
	parentClassNames?: string;
	fieldName: string;
	setFieldValue: (field: string, value: string) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	fieldValue: string;
};

const CustomTimePicker: FC<InlineTimePickerProps> = ({
	fieldName,
	setFieldValue,
	setFieldTouched,
	fieldValue,
}: InlineTimePickerProps) => {
	const formRef = useRef<HTMLDivElement>(null);
	const [isShowPicker, setIsShowPicker] = useState(false);
	const [isClosePicker, setIsClosePicker] = useState(false);
	const [timePickerValue, setTimePickerValue] = useState<PickerValuesType>({
		hours: null,
		minutes: null,
		meridiem: MERIDIEM.AM,
	});

	const title = useMemo(() => {
		const hours = timePickerValue.hours
			? timePickerValue.hours <= 9
				? `0${timePickerValue.hours}`
				: timePickerValue.hours
			: '__';

		const minutes =
			timePickerValue.minutes !== null
				? timePickerValue.minutes <= 9
					? `0${timePickerValue.minutes}`
					: timePickerValue.minutes
				: '__';
		return timePickerValue.hours && (timePickerValue.minutes || timePickerValue.minutes === 0)
			? `${hours} : ${minutes} ${timePickerValue.meridiem}`
			: '';
	}, [timePickerValue]);

	const handleClickOutside = (event: Event) => {
		isShowPicker && setFieldTouched(fieldName, true);
		if (formRef.current && !formRef.current.contains(event.target as Node)) {
			setIsClosePicker(true);
			setTimeout(() => {
				setIsShowPicker(false);
				setIsClosePicker(false);
			}, 200);
		}
	};

	useEffect(() => {
		if (
			timePickerValue.minutes !== null &&
			!isNaN(timePickerValue.minutes) &&
			timePickerValue.hours !== null &&
			!isNaN(timePickerValue.hours)
		) {
			setFieldValue(fieldName, convertPickerValueToFieldValue(timePickerValue));
		}
	}, [timePickerValue]);

	useEffect(() => {
		fieldValue.length && setTimePickerValue(convertFieldValueToPickerValue(fieldValue));
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowPicker]);

	return (
		<div className={cx(styles.timePicker__container)}>
			<div className={styles.timePicker__inputContainer}>
				<input
					type="text"
					className={styles.timePicker__input}
					value={title}
					placeholder="Select time"
					readOnly
				/>
				<div className={styles.timePicker__input_icon}>
					<img src={ClockSvg} onClick={() => setIsShowPicker(true)} />
				</div>
			</div>
			{isShowPicker && (
				<div ref={formRef} className={cx(styles.form, isClosePicker && styles.close)}>
					<CustomTimePickerField
						fieldName="hours"
						setFieldValue={value => setTimePickerValue({ ...timePickerValue, hours: value as number })}
						template={TIME_PICKER_TEMPLATES.hours}
						fieldValue={timePickerValue.hours}
					/>
					<CustomTimePickerField
						fieldName="minutes"
						fieldValue={timePickerValue.minutes}
						setFieldValue={value => setTimePickerValue({ ...timePickerValue, minutes: value as number })}
						template={TIME_PICKER_TEMPLATES.minutes}
					/>
					<CustomTimePickerField
						fieldValue={timePickerValue.meridiem}
						setFieldValue={value => setTimePickerValue({ ...timePickerValue, meridiem: value as MERIDIEM })}
						template={[MERIDIEM.AM, MERIDIEM.PM]}
						meridiemType
					/>
				</div>
			)}
		</div>
	);
};

export default CustomTimePicker;
