import { Dispatch } from 'redux';
import { API } from '../../core/API';
import { DELETE_PLACEHOLDER, GET_PLACEHOLDERS_ALL, SET_ERROR, UPDATE_PLACEHOLDER } from '../actions-types';
import { UpdatePlaceholderRequest } from '@joc/api-gateway';
import { IPlaceholderRequest } from '@joc/api-gateway/lib/api-client';

export const getPlaceholders =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const placeholdersResponse = await API.getAllPlacholders();

			dispatch({
				type: GET_PLACEHOLDERS_ALL,
				payload: placeholdersResponse,
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const updatePlaceholders =
	(id: number, requestBody: IPlaceholderRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const updatedPlaceholderResponse = await API.updatePlaceholder(
				id,
				UpdatePlaceholderRequest.fromJS(requestBody)
			);
			dispatch({
				type: UPDATE_PLACEHOLDER,
				payload: updatedPlaceholderResponse,
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const deleteOrganization =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.deletePlaceholder(id);
			dispatch({ type: DELETE_PLACEHOLDER, payload: id });
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};
