import { FC } from 'react';
import { Store } from 'redux/root';
import { connect, ConnectedProps } from 'react-redux';
import { selectorGetError } from 'redux/error-service/selector';
// components
import Appeal from '../Appeal/DefaultRenderer';
import ButtonDefault from '../Buttons/ButtonsDefault';
import styles from './ResponseFailure.module.scss';

type ResponseFailureParentProps = {
	message: string;
	buttonTitle?: string;
	buttonClickHandler?: () => void;
	styleTable?: boolean;
	buttonUnshow?: boolean;
	title?: string;
};

const ResponseFailure: FC<ResponseFailureProps> = ({
	title,
	message,
	buttonTitle,
	styleTable,
	buttonClickHandler,
	error,
	buttonUnshow,
}: ResponseFailureProps) => {
	return (
		<Appeal styleTable={styleTable} styleThink>
			<div className={styles.content}>
				<span className="title">{title || 'Oops...'}</span>
				<span className={styles.content__message}>{message}</span>
				{!buttonUnshow && (
					<ButtonDefault
						classList={['primary', 'md']}
						title={buttonTitle || 'Try again'}
						clickHandler={buttonClickHandler}
					/>
				)}
			</div>
		</Appeal>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});

const connector = connect(mapStateToProps);

type ResponseFailureProps = ConnectedProps<typeof connector> & ResponseFailureParentProps;

export default connector(ResponseFailure);
