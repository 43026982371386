import React, { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
import styles from './index.module.scss';

type AgeFieldSelectProps = {
	id?: string;
	placeholder: string;
	options: Array<number>;
	classList?: Array<string>;
	ageFieldValue: number;
	setAgeField: (age: number) => void;
};

const AgeFieldSelect: FC<AgeFieldSelectProps> = ({
	id,
	placeholder,
	options,
	classList,
	ageFieldValue,
	setAgeField,
}: AgeFieldSelectProps) => {
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const selectRef = useRef<HTMLDivElement>(null);

	const optionClickHandler = (option: number): void => {
		setIsShowOptions(false);
		setAgeField(option);
	};

	const handleClickOutside = (event: Event) => {
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	return (
		<div
			id={id}
			className={cx(styles.dropdown_select, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!ageFieldValue,
				})}
				onClick={() => {
					setIsShowOptions(!isShowOptions);
				}}
			>
				<span>{ageFieldValue || placeholder}</span>
				{!isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div
				className={cx(styles.dropdown_select__options, {
					[styles.dropdown_select__options_center]: classList?.includes('option_center'),
				})}
			>
				{options.map((option: number) => (
					<div
						key={option}
						className={styles.dropdown_select__options__item}
						onClick={() => optionClickHandler(option)}
					>
						{option}
					</div>
				))}
			</div>
		</div>
	);
};

export default AgeFieldSelect;
