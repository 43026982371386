import update from 'immutability-helper';
import {
	IOrganisationActiveStatusResponse,
	IOrganisationResponse,
	OrganisationActiveStatusResponse,
} from '@joc/api-gateway';
import {
	GET_ORGANIZATIONS_ALL,
	RESET_ORGANIZATIONS,
	UPDATE_ORG_INFO,
	UPDATE_ACTIVE_STATUS_ORG,
	DELETE_ORGANIZATION,
} from '../actions-types';
import { IOrganizationReducer, IOrganizationsPayload, OrgActionTypes } from './types';

const initState: IOrganizationReducer = {
	organizationsRecords: [],
	organizationsTotal: 0,
};

const organizations = (state = initState, action: OrgActionTypes): IOrganizationReducer => {
	const { type, payload }: OrgActionTypes = action;

	switch (type) {
		case GET_ORGANIZATIONS_ALL: {
			return {
				...state,
				organizationsRecords: [...state.organizationsRecords, ...(payload as IOrganizationsPayload).records],
				organizationsTotal: (payload as IOrganizationsPayload).total,
			};
		}

		case UPDATE_ORG_INFO: {
			const index = state.organizationsRecords.findIndex(
				item => +item.id === +(payload as IOrganisationResponse).id
			);
			return update(state, {
				organizationsRecords: { [index]: { $merge: payload } },
			});
		}

		case RESET_ORGANIZATIONS: {
			return initState;
		}

		case UPDATE_ACTIVE_STATUS_ORG: {
			const index = state.organizationsRecords.findIndex(
				item => +item.id === +(payload as IOrganisationActiveStatusResponse).organisationId
			);
			return update(state, {
				organizationsRecords: {
					[index]: {
						organizationActiveStatus: { $merge: payload as OrganisationActiveStatusResponse },
					},
				},
			});
		}

		case DELETE_ORGANIZATION:
			return {
				...state,
				organizationsRecords: state.organizationsRecords.filter(i => Number(i.id) !== Number(payload)),
				organizationsTotal: state.organizationsTotal - 1,
			};

		default:
			return state;
	}
};

export default organizations;
