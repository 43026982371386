import React, { FC, useCallback, useEffect, useRef } from 'react';
import { INITIAL_PAGINATION } from 'core/constants';
import { SetPagination } from 'core/types';
import Loader from '../Loader';

type ObserverParentProps = {
	paginationSkip: number;
	setPagination: SetPagination;
	isLoading: boolean;
};

const Observer: FC<ObserverParentProps> = ({ paginationSkip, setPagination, isLoading }: ObserverParentProps) => {
	const observerRef = useRef<HTMLTableRowElement>(null);
	const addMoreOpportunities = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			entries.map((entry: IntersectionObserverEntry) => {
				if (entries[0].intersectionRatio <= 0) return;
				if (entry.isIntersecting) {
					setPagination && setPagination({ skip: paginationSkip, take: INITIAL_PAGINATION.take });
				}
			});
		},
		[paginationSkip]
	);

	useEffect(() => {
		const observerOptions = {
			threshold: 1,
		};
		const observer = new IntersectionObserver(addMoreOpportunities, observerOptions);
		if (observerRef.current && paginationSkip) observer.observe(observerRef.current);

		return () => {
			if (observerRef.current) observer.unobserve(observerRef.current);
		};
	}, [observerRef.current, paginationSkip]);

	return (
		<div>
			<div ref={observerRef} />
			{isLoading && <Loader />}
		</div>
	);
};

export default Observer;
