import { FC, useContext, useMemo, useState, SetStateAction, Dispatch, useEffect } from 'react';
// formik
import { Field, FieldProps, Form, Formik } from 'formik';
// API
import { API } from 'core/API';
// redux
import { useDispatch } from 'react-redux';
import { updateVolunteer } from 'redux/volunteer-service/actions';
import { setError } from 'redux/error-service/action';
// validation
import { userVolunteerInfoFormValidationSchema } from 'core/validation';
// types
import {
	IBasicVolunteerResponse,
	IGetVolunteerByIdResponse,
	IGradeResponse,
	IUpdatePasswordRequest,
	IUpdateVolunteerRequest,
} from '@joc/api-gateway';
// context
import { VolunteerTableDataContext } from 'components/Organization/Volunteers/Table';
// helpers
import { getInitialValues, getSchoolGrades } from './helpers';
// components
import Loader from 'shared/components/Loader';
import GenderSelect from 'shared/components/Selects/SelectDropdown/GenderSelect';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InlineDatePicker from 'shared/components/Pickers/InlineDatePicker';
import UploadUserPhoto from 'components/Volunteer/UploadUserPhoto';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import InputText from 'shared/components/FormInputs/InputText';
import InputErrorHint from 'shared/components/InputErrorHint';
import GradeSelect from 'shared/components/Selects/SelectDropdown/GradeSelect';
import InputPassword from 'shared/components/FormInputs/InputPassword';
// styles
import styles from './VolunteerInfoForm.module.scss';

interface IVolunteerInfoFormProps {
	setIsUpdateSuccess: Dispatch<SetStateAction<boolean>>;
}

const VolunteerInfoForm: FC<IVolunteerInfoFormProps> = ({ setIsUpdateSuccess }) => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [grades, setGrades] = useState<Array<IGradeResponse>>();
	const [volunteerInfo, setVolunteerInfo] = useState<IGetVolunteerByIdResponse>();

	const volunteerData: IBasicVolunteerResponse = useContext(VolunteerTableDataContext);
	const initialValues = useMemo(() => getInitialValues(volunteerData), [volunteerData]);

	const handleGetVolunteerAndGrades = async () => {
		setIsLoading(true);
		try {
			const volunteerInfoData = await API.getVolunteerById(Number(volunteerData.id));
			const grades = await getSchoolGrades(volunteerInfoData);

			if (volunteerInfoData) setVolunteerInfo(volunteerInfoData);
			if (grades) setGrades(grades);
		} catch (error: any) {
			dispatch(setError(error?.response?.message || error.response, error?.response?.code || error?.code));
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		handleGetVolunteerAndGrades();
	}, []);

	if (!volunteerInfo) return <Loader loadProps={{ disableCenterStyle: true }} />;

	const handleSubmit = async (values: IUpdateVolunteerRequest & IUpdatePasswordRequest) => {
		setIsLoading(true);
		try {
			const { id, userId } = volunteerInfo;
			dispatch(updateVolunteer(Number(id), Number(userId), values));
			setIsUpdateSuccess(true);
		} catch (error: any) {
			setIsLoading(false);
			dispatch(setError(error?.response?.message || error.response, error?.response?.code || error?.code));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={userVolunteerInfoFormValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, setFieldValue, setFieldTouched, dirty }) => (
				<Form className={styles.form}>
					<Field name="imagePath">
						{({ field }: FieldProps) => (
							<InputContainer parentClassnames={styles.upload_photo}>
								<UploadUserPhoto
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									fieldName={field.name}
									fieldValue={field.value}
								/>
							</InputContainer>
						)}
					</Field>
					<Field name="firstName">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="firstName">
									First Name
								</label>
								<InputText
									id="firstName"
									field={field}
									placeholder="First name"
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.firstName && touched.firstName && (
									<InputErrorHint errorText={errors.firstName} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="lastName">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="lastName">
									Last Name
								</label>
								<InputText
									id="lastName"
									field={field}
									placeholder="Last name"
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.lastName && touched.lastName && <InputErrorHint errorText={errors.lastName} />}
							</InputContainer>
						)}
					</Field>
					<Field name="email">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="email">
									Email
								</label>
								<InputText
									id="email"
									isDisabled
									field={field}
									placeholder="Email"
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.email && touched.email && <InputErrorHint errorText={errors.email} />}
							</InputContainer>
						)}
					</Field>
					<Field name="phoneNumber">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="phoneNumber">
									Phone Number
								</label>
								<InputPhone
									fieldName={field.name}
									fieldValue={field.value}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
								/>
								{errors.phoneNumber && touched.phoneNumber && (
									<InputErrorHint errorText={errors.phoneNumber} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="birthDate">
						{({ field }: FieldProps) => (
							<InputContainer isSmallHeight>
								<label className={styles.label} htmlFor="birthDate">
									Date of Birth
								</label>
								<InlineDatePicker
									fieldName={field.name}
									setFieldValue={setFieldValue}
									parentClassNames={styles.birthDate}
									fieldValue={field.value}
									placeholder="Date of birth"
									availablePast
								/>
								{errors.birthDate && touched.birthDate && (
									<InputErrorHint errorText={errors.birthDate as string} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="gender">
						{({ field }: FieldProps) => (
							<InputContainer isSmallHeight>
								<label className={styles.label} htmlFor="gender">
									Gender
								</label>
								<GenderSelect
									placeholder="Gender"
									setFieldTouched={setFieldTouched}
									setFieldValue={setFieldValue}
									fieldName={field.name}
									fieldValue={field.value}
									parentClassName={styles.gender}
								/>
								{errors.gender && touched.gender && <InputErrorHint errorText={errors.gender} />}
							</InputContainer>
						)}
					</Field>
					<Field name="address">
						{({ field }: FieldProps) => (
							<InputContainer isSmallHeight>
								<label className={styles.label} htmlFor="address">
									Address
								</label>
								<GoogleApiAutoCompleat
									placeholder="Address"
									fieldName={field.name}
									selectHandler={address => setFieldValue(field.name, address)}
									setFieldTouched={setFieldTouched}
									setFieldValue={setFieldValue}
									fieldValue={field.value}
								/>
								{errors.address && touched.address && (
									<InputErrorHint addressErrorText={errors.address} />
								)}
							</InputContainer>
						)}
					</Field>

					{grades ? (
						<Field name="gradeId">
							{({ field }: FieldProps) => (
								<InputContainer isSmallHeight>
									<label className={styles.label} htmlFor="gradeId">
										School Grade
									</label>
									<GradeSelect
										grades={grades}
										fieldValue={field.value}
										fieldName={field.name}
										setFieldValue={setFieldValue}
										setFieldTouched={setFieldTouched}
									/>
								</InputContainer>
							)}
						</Field>
					) : null}

					<Field name="password">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="password">
									Password
								</label>
								<InputPassword field={field} setFieldValue={setFieldValue} isShow />
								{errors.password && touched.password && <InputErrorHint errorText={errors.password} />}
							</InputContainer>
						)}
					</Field>

					{isLoading ? (
						<Loader loadProps={{ disableCenterStyle: true }} />
					) : (
						<ButtonDefault submitType classList={['primary', 'lg']} title="Confirm" disabled={!dirty} />
					)}
				</Form>
			)}
		</Formik>
	);
};

export default VolunteerInfoForm;
