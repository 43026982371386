import React, { FC, Fragment, useMemo, useState } from 'react';
import InputText from 'shared/components/Inputs/InputText';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
// import { selectorGetOrgId } from 'redux/organization-service/selector';
import { Store } from 'redux/root';
import { createSmartGroup } from 'redux/smartGroups-service/actions';
import { connect, ConnectedProps } from 'react-redux';
import { resetError, setError } from 'redux/error-service/action';
import { selectorGetError } from 'redux/error-service/selector';
import styles from './CreateVolunteersSmartGroup.module.scss';

type CreateVolunteersSmartGroupParentProps = {
	smartGroupVolunteers: Array<number>;
	closeHandle: () => void;
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateVolunteersSmartGroup: FC<CreateVolunteersSmartGroupProps> = ({
	smartGroupVolunteers,
	closeHandle,
	createSmartGroup,
	setIsShowPopup,
	setError,
	resetError,
	error,
}: CreateVolunteersSmartGroupProps) => {
	const [isLoading, setisLoading] = useState(false);
	const [isCreateSmartGroupSuccess, setIsCreateSmartGroupSuccess] = useState(false);
	const [smartGroupName, setSmartGroupName] = useState('');
	const newSmartGroup = useMemo(() => {
		if (smartGroupName.length && smartGroupVolunteers.length)
			return { groupName: smartGroupName, volunteers: smartGroupVolunteers };
		return null;
	}, [smartGroupVolunteers, smartGroupName]);

	const confirmClickHandler = () => {
		try {
			if (!newSmartGroup) throw new Error('Please, check selected volunteers and try again');
			setisLoading(true);
			createSmartGroup(newSmartGroup);
			setisLoading(false);
			setIsCreateSmartGroupSuccess(true);
		} catch (error: any) {
			setisLoading(false);
			setError(error?.response?.message || error.message, error?.response?.code || error.code);
			console.error(error);
		}
	};

	if (error.state)
		return (
			<ResponseFailure
				buttonClickHandler={() => {
					closeHandle();
					resetError();
				}}
				message={error.message}
				buttonTitle="Try again"
			/>
		);
	if (isCreateSmartGroupSuccess)
		return (
			<CongratsPopup
				doneButtonClickHandler={() => setIsShowPopup(false)}
				subtitle="New smart group have been created. You can find it on volunteers page"
				absoluteDisable
			/>
		);
	return (
		<Appeal styleThink isHeightDisable>
			<span className={styles.title}>Please, enter the name of new smart group</span>
			<InputText setValue={setSmartGroupName} value={smartGroupName} placeholder="fill in..." />

			<div className={styles.actions}>
				{isLoading ? (
					<Loader />
				) : (
					<Fragment>
						<ButtonDefault
							classList={['lg', 'primary']}
							title="Create"
							disabled={!newSmartGroup}
							clickHandler={confirmClickHandler}
						/>
						<ButtonDefault
							classList={['lg', 'secondary']}
							title="Back to the table"
							clickHandler={closeHandle}
						/>
					</Fragment>
				)}
			</div>
		</Appeal>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});

const mapDispatchToProps = {
	createSmartGroup,
	setError,
	resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CreateVolunteersSmartGroupProps = ConnectedProps<typeof connector> & CreateVolunteersSmartGroupParentProps;

export default connector(CreateVolunteersSmartGroup);
