import { CREATE_QUOTES, DELETE_QUOTE, GET_QUOTES_ALL, RESET_QUOTES, UPDATE_QUOTE } from '../actions-types';
import { IQuotesPayload, IQuotesReducer, QuotesActionTypes } from './types';
import { IQuoteResponse } from '@joc/api-gateway';

const initState: IQuotesReducer = {
	quotesRecords: [],
	quotesTotal: 0,
};

const quotesReducer = (state = initState, action: QuotesActionTypes): IQuotesReducer => {
	const { type, payload }: QuotesActionTypes = action;

	switch (type) {
		case GET_QUOTES_ALL: {
			return {
				...state,
				quotesRecords: (payload as IQuotesPayload).records,
				quotesTotal: (payload as IQuotesPayload).total,
			};
		}

		case CREATE_QUOTES: {
			return {
				...state,
				quotesRecords: [...state.quotesRecords, ...(payload as Array<IQuoteResponse>)],
				quotesTotal: state.quotesTotal + payload.length,
			};
		}

		case UPDATE_QUOTE: {
			return {
				...state,
				quotesRecords: state.quotesRecords.map(quote =>
					Number(quote.id) === Number(payload.id) ? payload : quote
				),
				quotesTotal: state.quotesTotal,
			};
		}

		case DELETE_QUOTE: {
			return {
				...state,
				quotesRecords: state.quotesRecords.filter(quote => quote.id !== payload.id),
				quotesTotal: state.quotesTotal - 1,
			};
		}

		case RESET_QUOTES: {
			return initState;
		}

		default:
			return state;
	}
};

export default quotesReducer;
