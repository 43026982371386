import { FC } from 'react';
import { Channel } from 'stream-chat';
import { Avatar } from 'stream-chat-react';
//redux
import { chatClient } from 'redux/chat-service/actions';
//functions
import { setUserPhotoSrc } from 'core/functions';
//types
import { UsersResponseType } from '.';

export type ChannelProps = Channel;

type ChannelSearchResultProps = {
	setActiveChannel: any;
	member: UsersResponseType;
	userId: string;
};

export const ChatSearchJocApiResult: FC<ChannelSearchResultProps> = ({ setActiveChannel, member, userId }) => {
	const createChannel = async () => {
		const channel = await chatClient.channel('messaging', {
			members: [member.id as string, userId as string],
			name: member.name as string,
		});
		await channel.watch();
		setActiveChannel(channel);
	};

	return (
		<li className="messaging-create-channel__user-result" onClick={createChannel}>
			<Avatar image={setUserPhotoSrc(member.imagePath)} size={50} />
			<div className="messaging-create-channel__user-result__details">
				<span>{member.name}</span>
			</div>
		</li>
	);
};
