import React, { FC } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router';
import ButtonDefault from '../Buttons/ButtonsDefault';
import styles from './UserNotFound.module.scss';

type UserNotFoundProps = {
	redirectTo: string;
};

const UserNotFound: FC<UserNotFoundProps> = ({ redirectTo }: UserNotFoundProps) => {
	const history = useHistory();

	return (
		<div className={styles.wrapper}>
			<h1 className={cx('title', styles.title)}>Oops...</h1>
			<span className={styles.subtitle}>User not found. Please, create an account and end the registration.</span>
			<ButtonDefault
				classList={['primary', styles.button]}
				title="Go to signup"
				clickHandler={() => history.push(redirectTo)}
			/>
		</div>
	);
};

export default UserNotFound;
