import React, { FC } from 'react';
import styles from './ButtonSuspending.module.scss';

type ButtonSuspendingProps = {
	clickHandler: () => void;
	title: string;
	href: string;
};

const ButtonSuspending: FC<ButtonSuspendingProps> = ({ title, clickHandler, href }: ButtonSuspendingProps) => {
	return (
		<button className={styles.main} onClick={clickHandler} title={title}>
			<img className={styles.icon} src={href} alt="icon" />
		</button>
	);
};

export default ButtonSuspending;
