import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import LogoSvg from 'assets/image/logo.svg';
import { urls } from 'core/appUrls';
import styles from './Logo.module.scss';

type LogoProps = {
	isOnboarding?: boolean;
};

const Logo: FC<LogoProps> = ({ isOnboarding }: LogoProps) => {
	return (
		<div className={styles.logo__container}>
			<Link className={styles.logo} to={isOnboarding ? urls.loginSuperAdmin : urls.dashboard}>
				<img src={LogoSvg} alt="logo" />
			</Link>
		</div>
	);
};

export default Logo;
