/* eslint-disable react/prop-types */
import React from 'react';
import { ThreadHeaderProps } from 'stream-chat-react';

import './MessagingThread.css';

import { CloseThreadIcon } from '../../../RandomImage';

const MessagingThread: React.FC<ThreadHeaderProps> = ({ closeThread, thread }) => {
	const getReplyCount = () => {
		if (!thread?.reply_count) return '';
		if (thread.reply_count === 1) return '1 reply';
		return `${thread.reply_count} Replies`;
	};

	return (
		<div className="custom-thread-header">
			<div className="custom-thread-header__left">
				<p className="custom-thread-header__left-title">Thread</p>
				<p className="custom-thread-header__left-count">{getReplyCount()}</p>
			</div>
			<CloseThreadIcon {...{ closeThread }} />
		</div>
	);
};

export default MessagingThread;
