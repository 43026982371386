import { FC, useEffect, useMemo, useState } from 'react';
import { ChannelSort } from 'stream-chat';
import {
	Chat,
	Channel,
	ChannelList,
	MessageList,
	MessageInput,
	Window,
	Thread,
	LoadMorePaginator,
	LoadMoreButton,
} from 'stream-chat-react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router';
//redux
import { selectorGetChatId, selectorGetUserFullName } from 'redux/user-service/selector';
import { selectorGetError } from 'redux/error-service/selector';
import { selectorGetChatUserToConnect, selectorGetUserConnectionState } from 'redux/chat-service/selector';
import { resetError, setError } from 'redux/error-service/action';
import { chatClient } from 'redux/chat-service/actions';
// types
import { InitChatRequestInfoType } from 'core/types';
//components
import {
	CreateChannel,
	CustomMessage,
	MessagingChannelHeader,
	MessagingChannelList,
	MessagingChannelPreview,
	MessagingThread,
	MessagingInput,
} from './ChatComponents';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import { CustomTypingIndicator } from './ChatComponents/CustomTypingIndicator/CustomTypingIndicator';
import EmojiPicker from '@emoji-mart/react';
import customAvatar from './ChatComponents/CustomAvatar';
import { SearchIndex } from 'emoji-mart';
//styles
import 'stream-chat-react/dist/css/index.css';
import './Chat.global.scss';
import styles from './Chat.module.scss';
import { useTranslation } from 'react-i18next';

const options = { state: true, watch: true, presence: true, limit: 8 };

const sort: ChannelSort = {
	last_message_at: -1,
	created_at: -1,
	updated_at: -1,
	cid: 1,
};

const ChatOk: FC = () => {
	const history = useHistory();

	const { t } = useTranslation();

	const [createdActiveChannel, setCreatedActiveChannel] = useState<any>();
	const [isCreating, setIsCreating] = useState(false);
	const [isMobileNavVisible, setMobileNav] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [theme] = useState('light');

	const chatClientConnectionState = useSelector(selectorGetUserConnectionState);
	const userChatId = useSelector(selectorGetChatId);
	const userToConnect = useSelector(selectorGetChatUserToConnect);
	const userName = useSelector(selectorGetUserFullName);
	const error = useSelector(selectorGetError);

	const dispatch = useDispatch();

	const location = useLocation();

	const filters = useMemo(
		() => (userToConnect ? { type: 'messaging', members: { $in: [userToConnect.id!] } } : undefined),
		[userToConnect]
	);

	const toggleMobile = () => setMobileNav(!isMobileNavVisible);
	const retryClickHandler = () => dispatch(resetError());

	const initChat = async () => {
		try {
			if (!userToConnect) throw new Error('Sorry, we can`t connect you to chat');

			const initChatRequestInfo = qs.parse(location.search, {
				ignoreQueryPrefix: true,
			}) as unknown as InitChatRequestInfoType;

			const chatMembersIds: Array<string> = initChatRequestInfo?.members;

			if (chatMembersIds && chatMembersIds.length) {
				if (initChatRequestInfo.type) {
					try {
						if (!userChatId) throw new Error('Sorry, we can`t find your organization id');

						if (initChatRequestInfo.type === 'all') {
							const createdChatWithAllMembers = await chatClient.channel('messaging', {
								members: [...chatMembersIds, userChatId],
								...(chatMembersIds.length > 1 && { name: `JOC administrator ${userName}` }),
							});

							await createdChatWithAllMembers.watch();

							if (initChatRequestInfo?.message?.length)
								await createdChatWithAllMembers.sendMessage({
									text: initChatRequestInfo.message as string,
								});

							setCreatedActiveChannel(createdChatWithAllMembers);
						}
						history.push({ search: '' });
					} catch (error: any) {
						throw error;
					}
				}
			}
			setIsLoading(false);
		} catch (error: any) {
			dispatch(setError(error?.response?.message || error.message, error.code));
		}
	};

	useEffect(() => {
		if (userToConnect?.id) initChat();
	}, [userToConnect]);

	if (isLoading || !chatClientConnectionState)
		return (
			<div className={styles.test}>
				<Loader loadProps={{ stylePageCenter: true }} />
			</div>
		);

	if (error.state)
		return <ResponseFailure message={error.message} buttonTitle="ok" buttonClickHandler={retryClickHandler} />;

	return (
		<div className={cx(styles.chatWrapper, styles.org)}>
			<Chat client={chatClient} theme={`messaging ${theme}`}>
				<div id="mobile-channel-list" onClick={toggleMobile}>
					<ChannelList
						filters={filters}
						sort={sort}
						options={options}
						List={props => (
							<MessagingChannelList
								channel={createdActiveChannel}
								{...props}
								onCreateChannel={() => setIsCreating(!isCreating)}
							/>
						)}
						Preview={props => (
							<MessagingChannelPreview
								{...props}
								latestMessage={props?.latestMessage || ''}
								setIsCreating={setIsCreating}
								setCreatedActiveChannel={setCreatedActiveChannel}
							/>
						)}
						EmptyStateIndicator={() => <p>You have no open chats</p>}
						Paginator={props => (
							<LoadMorePaginator
								{...props}
								LoadMoreButton={props => (
									<LoadMoreButton {...props}>{t('button.loadMore')}</LoadMoreButton>
								)}
							/>
						)}
					/>
				</div>
				<div className="messageListWrap">
					<Channel
						maxNumberOfFiles={10}
						multipleUploads
						TypingIndicator={CustomTypingIndicator}
						EmojiPicker={EmojiPicker}
						Avatar={customAvatar}
						ThreadHeader={MessagingThread}
					>
						{isCreating && (
							<CreateChannel toggleMobile={toggleMobile} onClose={() => setIsCreating(false)} />
						)}
						<Window>
							<MessagingChannelHeader theme={theme} toggleMobile={toggleMobile} />
							<MessageList
								messageActions={['delete', 'edit', 'flag', 'mute', 'react', 'reply']}
								Message={CustomMessage}
							/>
							<MessageInput focus Input={MessagingInput} emojiSearchIndex={SearchIndex} />
						</Window>
						<Thread Message={CustomMessage} Input={MessagingInput} />
					</Channel>
				</div>
			</Chat>
		</div>
	);
};

export default ChatOk;
