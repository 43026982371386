import {
	SearchOpportunitiesRequest,
	SearchOrganizationByAdminRequest,
	SearchVolunteersRequest,
} from '@joc/api-gateway';
import { API } from 'core/API';
import { AllOpposQuery } from 'core/types/OPPORTUNITIES_QUERIES';
import { Dispatch } from 'redux';
import {
	GET_DASHBOARD_INFO,
	GET_LIVESCREEN_INFO,
	RESET_LIVESCREEN_INFO,
	SET_ERROR,
	UPDATE_LIVESCREEN_INFO,
} from 'redux/actions-types';
import { ILiveScreenInfo } from './types';
import { DashboardSettingsUpdateRequest } from '@joc/api-gateway/lib/api-client';

export const getDashboardInfo =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// TODO: Implement on backend counts endpoints and integrate its on frontend
			const searchBody = { pagination: { skip: 0, take: 1 } };
			const responseOrganizations = await API.searchOrganizationsByAdmin(
				SearchOrganizationByAdminRequest.fromJS(searchBody)
			);
			const responseVolunteers = await API.adminSearchVolunteers(SearchVolunteersRequest.fromJS(searchBody));
			const responseOpportunities = await API.adminSearchOpportunities(
				'',
				SearchOpportunitiesRequest.fromJS({ ...searchBody, ...AllOpposQuery })
			);

			dispatch({
				type: GET_DASHBOARD_INFO,
				payload: {
					totalOrg: responseOrganizations.total,
					totalVol: responseVolunteers.total,
					totalOpp: responseOpportunities.total,
				},
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error.message } });
		}
	};

export const getLiveScreen =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const data = await API.getDashboard();
			dispatch({ type: GET_LIVESCREEN_INFO, payload: data });
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error.message } });
		}
	};

export const updateLiveScreen =
	(values: ILiveScreenInfo) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.updateDashboard(values as DashboardSettingsUpdateRequest);
			dispatch({ type: UPDATE_LIVESCREEN_INFO, payload: { title: values.title, subTitle: values.subTitle } });
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error.message } });
		}
	};

export const resetLiveScreen =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch({ type: RESET_LIVESCREEN_INFO, payload: null });
	};
