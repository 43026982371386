import { urls } from 'core/appUrls';
import React, { FC } from 'react';
import AccountMenuDropDown from 'shared/components/AccountMenuDropDown';
import AccountMenuDropdownItem from 'shared/components/AccountMenuDropDown/AccountMenuDropdownItem';
import Logout from 'assets/image/logout.svg';
import UserSvg from 'assets/image/user2.svg';
import HeaderWrapper from 'shared/components/HeaderWrapper';
import { selectorGetUserRoles } from 'redux/user-service/selector';
import { Store } from 'redux/root';
import { connect, ConnectedProps } from 'react-redux';
import { VOLUNTEER } from '@joc/api-gateway';
import NavBarVisibilityButton from './NavBarVisibilityButton';

const HeaderOrg: FC<HeaderOrgProps> = ({ userRoles }: HeaderOrgProps) => {
	// const dropdown = (
	// 	// <AccountMenuDropDown>
	// 	// 	{/* <AccountMenuDropdownItem link={urls.editAccount} text="Edit My Account" img={UserSvg} />
	// 	// 	{userRoles?.find(role => role.roleName === VOLUNTEER.ADMIN || role.roleName === VOLUNTEER.MARKETER) && (
	// 	// 		<AccountMenuDropdownItem link={urls.editorg} text="Edit Organization Info" img={UserSvg} />
	// 	// 	)} */}
	// 	// 	{/* <AccountMenuDropdownItem link={urls.inviteColleagues} text="Invite Colleagues" img={UserSvg} /> */}
	// 	// 	<AccountMenuDropdownItem logout text="Logout" img={Logout} />
	// 	// </AccountMenuDropDown>
	// );

	return (
		<HeaderWrapper chatLink={urls.inbox} dropdown={null}>
			<NavBarVisibilityButton />
		</HeaderWrapper>
	);
};

const mapStateToProps = (store: Store) => ({
	userRoles: selectorGetUserRoles(store),
});

const connector = connect(mapStateToProps);

type HeaderOrgProps = ConnectedProps<typeof connector>;

export default connector(HeaderOrg);
