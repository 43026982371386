import { Dispatch } from 'redux';
import { API } from '../../core/API';
import {
	CreateChallengeRequest,
	IUpdateChallengeRequest,
	ICreateChallengeRequest,
	SearchChallengesRequest,
	UpdateChallengeRequest,
} from '@joc/api-gateway';
import {
	GET_CHALLENGES_ALL,
	CREATE_CHALLENGE,
	DELETE_CHALLENGE,
	LOADING_STATUS_CHALLENGES,
	UPDATE_CHALLENGE,
	SET_ERROR,
} from '../actions-types';

export const getChallenges =
	(searchBody?: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const challengesResponse = await API.searchByAdmin(SearchChallengesRequest.fromJS({ search: searchBody }));
			dispatch({
				type: GET_CHALLENGES_ALL,
				payload: { records: challengesResponse.records, total: challengesResponse.total },
			});
			dispatch({
				type: LOADING_STATUS_CHALLENGES,
				payload: false,
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const createChallenge =
	(challenge: ICreateChallengeRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const challengeResponse = await API.createChallenge(CreateChallengeRequest.fromJS(challenge));

			dispatch({ type: CREATE_CHALLENGE, payload: challengeResponse });
			dispatch({
				type: LOADING_STATUS_CHALLENGES,
				payload: false,
			});
		} catch (error: any) {
			throw error;
		}
	};

export const updateChallenge =
	({ id, challenge }: { id: number; challenge: IUpdateChallengeRequest }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const challengeResponse = await API.updateChallenge(id, UpdateChallengeRequest.fromJS(challenge));

			dispatch({ type: UPDATE_CHALLENGE, payload: challengeResponse });
			dispatch({
				type: LOADING_STATUS_CHALLENGES,
				payload: false,
			});
		} catch (error: any) {
			throw error;
		}
	};

export const deleteChallenge =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.deleteChallenge(id);

			dispatch({ type: DELETE_CHALLENGE, payload: id });
			dispatch({
				type: LOADING_STATUS_CHALLENGES,
				payload: false,
			});
		} catch (error: any) {
			throw error;
		}
	};

export const setLoadingChallenges =
	(isLoading: boolean) =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: LOADING_STATUS_CHALLENGES,
			payload: isLoading,
		});
	};
