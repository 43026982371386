import React, { Dispatch, FC, SetStateAction } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'redux/root';
import qs from 'qs';
// types
import {
	IFullTextSearchInvitedUser,
	IInvitedUserResponse,
	IInvitedUserSort,
	InvitedUserSort,
	INVITED_USER_POSSIBLE_SEARCH,
	INVITED_USER_POSSIBLE_SORT,
	ISearchInvitedUserRequest,
	SortDirection,
} from '@joc/api-gateway';
import { SetPagination } from 'core/types';
import { setSearchOrSortQueryString } from 'core/functions';
import { resetError } from 'redux/error-service/action';
// components
import TableMenu from 'components/Organization/TableMenu';
import Loader from 'shared/components/Loader';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import TableMain from 'shared/components/Table/TableMain';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES, INITIAL_PAGINATION } from 'core/constants';
import TableBody from 'shared/components/Table/TableBody';
// import invites from 'redux/invite-service/reducer';
import TableRow from 'shared/components/Table/TableRow';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import Observer from 'shared/components/Observer';
import ResponseFailure from 'shared/components/ResponseFailure';
import InvitesActionCell from '../InvitesActionCell';
// styles
import styles from './InvitesTable.module.scss';

// const parseQueryString = (
// 	search: string
// 	// userLocation: IGetLocationAddressByIdResponse | undefined
// ): ISearchInvitedUserRequest => {
// 	const searchParams: ISearchInvitedUserRequest = qs.parse(search, {
// 		ignoreQueryPrefix: true,
// 	});
// 	return searchParams;
// };

type InvitesTableParentProps = {
	setPagination: SetPagination;
	isLoading: boolean;
	sort: InvitedUserSort | undefined;
	setRequestBody: Dispatch<SetStateAction<ISearchInvitedUserRequest | undefined>>;
	// setRequestBody?: React.Dispatch<React.SetStateAction<ISearchInvitedUserRequest | undefined>>;
};

const InvitesTable: FC<InvitesTableParentProps> = ({
	setPagination,
	isLoading,
	sort,
	setRequestBody,
}: InvitesTableParentProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const invitesRecordsList = useSelector((store: Store) => store.invitesRedux.invites.records);
	const invitesRecordsTotal = useSelector((store: Store) => store.invitesRedux.invites.total);
	const errorState = useSelector((store: Store) => store.errorRedux.error.state);

	const errorMessage = useSelector((store: Store) => store.errorRedux.error.message);
	// const invitesRecordsListJoined = useSelector((store: Store) => store.invitesRedux.invites.records);
	// const errorCode = useSelector((store: Store) => store.errorRedux.error.code);

	const appendQueryString = (
		newQueryParams: IFullTextSearchInvitedUser | IInvitedUserSort,
		searchBy: string
	): void => {
		const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
		history.push({ search: searchQuery });
	};

	const searchInvitesChangeHandler = (value: string): void => {
		const fullTextSearchParams: IFullTextSearchInvitedUser = {
			value,
			fields: Object.values(INVITED_USER_POSSIBLE_SEARCH),
		};
		if (fullTextSearchParams.value.length) {
			appendQueryString(fullTextSearchParams, 'fullTextSearch');
		} else {
			const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
			delete searchParams.fullTextSearch;
			history.push({ search: qs.stringify(searchParams) });
		}
	};

	const buttonFailureClickHandler = () => {
		dispatch(resetError());
		history.push({ search: '' });
		setRequestBody(undefined);
		setPagination(INITIAL_PAGINATION);
	};

	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<TableMenu searchChangeHandler={searchInvitesChangeHandler}>
					{/* <Actions printRef={printRef} setIsPrintableTable={setIsPrintableTable} requestBody={requestBody} /> */}
				</TableMenu>
				{/* {(errorState && !isLoading) || (!isLoading && !companiesUsersList.length) ? ( */}
				{!isLoading && !invitesRecordsList.length ? (
					<ResponseFailure
						styleTable
						message={errorMessage || 'We couldn`t find the invites you were looking for this time.'}
						buttonClickHandler={buttonFailureClickHandler}
						buttonTitle="Get all"
					/>
				) : isLoading && !invitesRecordsList.length && isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					// <TableMain ref={printRef}>
					<TableMain>
						<TableHead callChild="invites">
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.avatar}
								text={TABLE_HEADERS.avatar}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
								text={TABLE_HEADERS.fullName}
								clickHandler={() => {
									const newQueryParams: IInvitedUserSort = {
										sortBy: INVITED_USER_POSSIBLE_SORT.FirstNameLastName,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.organization}
								text={TABLE_HEADERS.organization}
								isSortDisabled
								// TODO: sort
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.email}
								text={TABLE_HEADERS.email}
								clickHandler={() => {
									const newQueryParams: IInvitedUserSort = {
										sortBy: INVITED_USER_POSSIBLE_SORT.Email,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.role}
								text={TABLE_HEADERS.role}
								isSortDisabled
								// TODO: sort
							/>
							{/* {orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL && ( */}
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.grade}
								text={TABLE_HEADERS.grade}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.spendTime}
								text={TABLE_HEADERS.spendTime}
								isSortDisabled
								// TODO: sort
							/>
							<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} isSortDisabled />
						</TableHead>
						<TableBody>
							{invitesRecordsList.map((invites: IInvitedUserResponse) => (
								<TableRow
									key={invites.id + invites.lastName + invites.email}
									callChild="invites"
									// suspend  -- ??
								>
									<AvatarCell imagePath={invites.imagePath} />
									<FullNameCell firstName={invites.firstName} lastName={invites.lastName} />
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.organization}
										text={invites.organisation.organizationName || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.email}
										text={invites.email || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.role}
										text={invites.role.roleName || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.grade}
										text={invites.grade.gradeName || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.spendTime}
										// TODO: check out SpendTime of school after we will do pagination
										text={invites.timeSpend || ''}
									/>
									<InvitesActionCell inviteData={invites} />
								</TableRow>
							))}
						</TableBody>
					</TableMain>
				)}
				{invitesRecordsList.length !== invitesRecordsTotal && !errorState && (
					<Observer
						paginationSkip={invitesRecordsList.length}
						setPagination={setPagination}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default InvitesTable;
