import React, { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
// images
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
// consts
import { SMART_GROUP_FILTER_TYPES } from 'core/constants';
// styles
import styles from './SelectFilterType.module.scss';
import { GroupType } from '..';

type SelectFilterParentProps = {
	filterId: number;
	placeholder: string;
	filterTypes: Array<GroupType>;
	setFilterTypes: React.Dispatch<React.SetStateAction<Array<GroupType>>>;
	selectedTitle?: string;
	parentClassName?: string;
};

const SelectFilterType: FC<SelectFilterParentProps> = ({
	filterId,
	placeholder,
	filterTypes,
	setFilterTypes,
	selectedTitle,
	parentClassName,
}: SelectFilterParentProps) => {
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const selectRef = useRef<HTMLDivElement>(null);

	const selectClickHandler = (item: { filterType: string; placeholder: string }) => {
		setIsShowOptions(false);
		if (item.filterType === 'volunteers') {
			const newValue = [{ id: +new Date(), filterType: item.filterType, placeholder: item.placeholder }];
			setFilterTypes(newValue);
		} else {
			const newValue = filterTypes.map(filter => {
				if (filter.id === filterId)
					return {
						...filter,
						filterType: item.filterType,
						placeholder: item.placeholder,
					};
				return filter;
			});
			setFilterTypes(newValue);
		}
	};

	const handleClickOutside = (event: Event) => {
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, []);

	return (
		<div
			className={cx(styles.dropdown_select, parentClassName && parentClassName, {
				[styles.dropdown_select__active]: isShowOptions,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!selectedTitle,
				})}
				onClick={() =>
					// !selectedTitle &&
					setIsShowOptions(!isShowOptions)
				}
			>
				<span>{selectedTitle || placeholder}</span>
				{!isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div className={styles.dropdown_select__options}>
				{SMART_GROUP_FILTER_TYPES.filter(item => !filterTypes.find(i => i.filterType === item.filterType)).map(
					item => (
						<div key={item.filterType} onClick={() => selectClickHandler(item)}>
							{item.placeholder}
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default SelectFilterType;
