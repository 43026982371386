import { GetVolunteerByIdResponse, OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import React, { FC } from 'react';
import LotsVolunteers from './PossibleVolunteersRenderer/LotsVolunteers';
import SingleVolunteer from './PossibleVolunteersRenderer/SingleVolunteer';
import styles from './PossibleVolunteersItem.module.scss';

type PossibleVolunteersItemProps = {
	volunteers: Array<GetVolunteerByIdResponse> | undefined;
	opportunityId: number;
	isApprovalRequired: boolean;
	opportunityName: string;
	opportunityStatus: OPPORTUNITY_STATUSES;
	actionsDisable?: boolean;
	setIsPreviewOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	opportunityType: OPPORTUNITY_TYPES;
	isViewVolunteersDisable?: boolean;
};

const PossibleVolunteersItem: FC<PossibleVolunteersItemProps> = ({
	volunteers,
	opportunityId,
	isApprovalRequired,
	opportunityName,
	actionsDisable,
	setIsPreviewOpen,
	opportunityStatus,
	opportunityType,
	isViewVolunteersDisable,
}: PossibleVolunteersItemProps) => {
	if (!volunteers || !volunteers.length) return <div className={styles.empty} />;
	if (volunteers.length === 1)
		return (
			<SingleVolunteer
				opportunityName={opportunityName}
				volunteerId={volunteers[0].id}
				opportunityId={opportunityId}
				isApprovalRequired={isApprovalRequired}
				photo={volunteers[0].imagePath}
				volunteerName={`${volunteers[0].firstName} ${volunteers[0].lastName}`}
				volunteersArray={volunteers}
				volunteerStatus={volunteers[0].status}
				isOptionsDisable={actionsDisable}
				opportunityStatus={opportunityStatus}
				setIsPreviewOpen={setIsPreviewOpen}
				volunteerCreatedDate={volunteers[0].createDate}
				volunteerOrganizations={volunteers[0].organizations}
				opportunityType={opportunityType}
				isViewVolunteersDisable={isViewVolunteersDisable}
			/>
		);
	return (
		<LotsVolunteers
			opportunityName={opportunityName}
			opportunityId={opportunityId}
			volunteersArray={volunteers}
			isOptionsDisable={actionsDisable}
			setIsPreviewOpen={setIsPreviewOpen}
			opportunityStatus={opportunityStatus}
			opportunityType={opportunityType}
			isViewVolunteersDisable={isViewVolunteersDisable}
		/>
	);
};

export default PossibleVolunteersItem;
