import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
// components
import TabNav from '../TabNav';
import TabNavItem from '../TabNav/TabNavItem';

import DailyQuotes from './DailyQuotes';
import Challenges from './Challenges';

// styles
import styles from './MarketingContent.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../redux/root';
import { getChallenges } from '../../../redux/challenges-service/actions';
import { getDailyQuotes } from '../../../redux/dailyQuotes-service/actions';

enum TABS {
	COUPON = 'Coupon',
	CHALLENGES = 'Challenges',
	DAILY_QUOTES = 'Daily Quotes',
}

const MarketingContent: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		if (location.search.includes('coupon')) setActiveTab(TABS.COUPON);
		if (location.search.includes('challenges')) setActiveTab(TABS.CHALLENGES);
		if (location.search.includes('daily-quotes')) setActiveTab(TABS.DAILY_QUOTES);
	}, [location.search]);

	const dailyQuotesTotal = useSelector((store: Store) => store.quotesRedux.quotesTotal);
	const challengesTotal = useSelector((store: Store) => store.challengeRedux.challengesTotal);

	const [activeTab, setActiveTab] = useState<string>();

	useEffect(() => {
		if (!location.search) history.push({ search: '?coupon' });
		dispatch(getChallenges());
		dispatch(getDailyQuotes(''));
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.content__wrapper}>
				<div className={styles.content}>
					<TabNav>
						<TabNavItem
							parentClassName={styles.tabNavItem}
							counter={0}
							title={TABS.COUPON}
							activeTab={activeTab === TABS.COUPON}
							clickHandler={() => {
								history.push({ search: '?coupon' });
								setActiveTab(TABS.COUPON);
							}}
						/>
						<TabNavItem
							parentClassName={styles.generateGroup}
							counter={challengesTotal || 0}
							title={TABS.CHALLENGES}
							activeTab={activeTab === TABS.CHALLENGES}
							clickHandler={() => {
								history.push({ search: '?challenges' });
								setActiveTab(TABS.CHALLENGES);
							}}
						/>
						<TabNavItem
							parentClassName={styles.generateGroup}
							counter={dailyQuotesTotal || 0}
							title={TABS.DAILY_QUOTES}
							activeTab={activeTab === TABS.DAILY_QUOTES}
							clickHandler={() => {
								history.push({ search: '?daily-quotes' });
								setActiveTab(TABS.DAILY_QUOTES);
							}}
						/>
					</TabNav>
					{activeTab === TABS.COUPON ? null : null}
					{activeTab === TABS.CHALLENGES ? <Challenges /> : null}
					{activeTab === TABS.DAILY_QUOTES ? <DailyQuotes /> : null}
				</div>
			</div>
		</div>
	);
};

export default MarketingContent;
