import React, { FC } from 'react';
import cx from 'classnames';
import SuccessSvg from 'assets/image/Success.svg';
import styles from './ButtonActionCircle.module.scss';

type ButtonConfirmVolunteerProps = {
	clickHandler: () => void;
	disabled?: boolean;
};

const ButtonConfirmCircle: FC<ButtonConfirmVolunteerProps> = ({
	clickHandler,
	disabled,
}: ButtonConfirmVolunteerProps) => {
	return (
		<button className={styles.main} disabled={disabled} onClick={clickHandler}>
			<img className={cx(styles.success, disabled && styles.success_disabled)} src={SuccessSvg} alt="success" />
		</button>
	);
};

export default ButtonConfirmCircle;
