import React from 'react';
import { createStyles } from '@material-ui/core/styles';
import { Dialog, withStyles, WithStyles } from '@material-ui/core';

const stylesForDialog = () =>
	createStyles({
		root: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'end',
			top: '100px !important',

			'& .MuiPaper-rounded': {
				borderRadius: 10,
				boxShadow: 'none',
			},
		},
		backdrop: {
			top: 100,
			backgroundColor: 'rgba(241, 243, 255, 0.8)',
		},
	});

export interface DialogStylesProps extends WithStyles<typeof stylesForDialog> {
	children: React.ReactNode;
	open: boolean;
	maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const DialogStyled = withStyles(stylesForDialog)((props: DialogStylesProps) => {
	const { children, classes, ...other } = props;
	return (
		<Dialog className={classes.root} {...other} BackdropProps={{ className: classes.backdrop }}>
			{children}
		</Dialog>
	);
});

export default DialogStyled;
