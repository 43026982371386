import React, { FC } from 'react';
import ButtonSvg from 'assets/image/trash-light.svg';
import styles from './RemoveFilterButton.module.scss';

type RemoveFilterButtonProps = {
	clickHandler: () => void;
};

const RemoveFilterButton: FC<RemoveFilterButtonProps> = ({ clickHandler }: RemoveFilterButtonProps) => {
	return (
		<button className={styles.main} onClick={clickHandler}>
			<img src={ButtonSvg} alt="trash" />
		</button>
	);
};

export default RemoveFilterButton;
