import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import { ReactComponent as ArrowSvg } from 'assets/image/button-arrow.svg';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { muiDateTinePickerTheme } from '../MUI_THEME';
import s from './index.module.scss';

type InlineDatePickerPropsType = {
	parentClassNames?: string;
	fieldName: string;
	setFieldValue: (field: string, value: Date) => void;
	fieldValue: Date | null;
	availablePast?: boolean;
	withEndDay?: boolean;
	availableFuture?: boolean;
	placeholder?: string;
	isDisabled?: boolean;
};

const useStyles = makeStyles(theme => ({
	emptyLabel: {
		color: '#5b768b',
	},
}));

const InlineDatePicker: FC<InlineDatePickerPropsType> = ({
	parentClassNames,
	fieldName,
	setFieldValue,
	fieldValue,
	availablePast,
	withEndDay,
	availableFuture,
	placeholder,
	isDisabled,
}: InlineDatePickerPropsType) => {
	const classes = useStyles();

	const value = useMemo(() => {
		if (fieldValue) return new Date(fieldValue);
		return null;
	}, [fieldValue]);

	const changeDateHandler = (value: MaterialUiPickersDate) => {
		setFieldValue(fieldName, value as Date);
		if (withEndDay) setFieldValue('endDay', value as Date);
	};

	return (
		<div
			className={cx(s.datepicker, parentClassNames && parentClassNames, {
				disabledClass: isDisabled,
			})}
		>
			<ThemeProvider theme={muiDateTinePickerTheme}>
				<KeyboardDatePicker
					autoOk
					animateYearScrolling
					variant="inline"
					openTo="date"
					value={value}
					onChange={changeDateHandler}
					emptyLabel={placeholder || 'Select date'}
					disablePast={!availablePast}
					disableFuture={!availableFuture}
					format="dd/MM/yyyy"
					helperText=""
					disabled={isDisabled}
					rightArrowIcon={
						<ArrowSvg
							style={{ width: '6px', height: '12px', transform: 'rotate(180deg)' }}
							className={cx(s.button_arrow, {
								disabledClass: isDisabled,
							})}
						/>
					}
					leftArrowIcon={<ArrowSvg style={{ width: '16px', height: '12px' }} className={s.button_arrow} />}
					InputProps={{ className: !value ? classes.emptyLabel : undefined }}
				/>
			</ThemeProvider>
		</div>
	);
};

export default InlineDatePicker;
