import React, { FC, useEffect, useState } from 'react';
import { FieldInputProps } from 'formik';
import Dropzone from 'react-dropzone';
import InputErrorHint from 'shared/components/InputErrorHint';
import { API } from 'core/API';
import Loader from 'shared/components/Loader';
import styles from './index.module.scss';
import InputPreviewImage from './InputPreviewImage';

type InputPhotoProps = {
	field: FieldInputProps<string>;
	id?: string;
	setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	fieldError?: string;
	fieldTouched?: boolean;
};

const InputPhoto: FC<InputPhotoProps> = ({
	field,
	id,
	fieldError,
	fieldTouched,
	setFieldValue,
	setFieldTouched,
}: InputPhotoProps) => {
	const [photoName, setPhotoName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [customError, setCustomError] = useState('');

	const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		event.preventDefault();
		if (event.target && event.target.files && event.target.files[0]) {
			setIsLoading(true);
			setCustomError('');
			const fileName = event.target.files[0].name;
			const photo = event.target.files[0];
			try {
				const response = await API.uploadPhoto({ data: photo, fileName });
				response.fileName.length && setPhotoName(response.fileName);
			} catch (error: any) {
				setFieldTouched(field.name, true);
				setIsLoading(false);
				setCustomError(error?.response?.message || error.message);
			}
		}
	};

	const dropHandler = async (acceptedFiles: Array<File>): Promise<void> => {
		if (acceptedFiles?.length) {
			setIsLoading(true);
			setCustomError('');
			const fileName = acceptedFiles[0].name;
			const photo = acceptedFiles[0];
			try {
				const response = await API.uploadPhoto({ data: photo, fileName });
				response.fileName.length && setPhotoName(response.fileName);
			} catch (error: any) {
				setFieldTouched(field.name, true);
				setIsLoading(false);
				setCustomError(error?.response?.message || error.message);
			}
		}
	};

	useEffect(() => {
		const photoName = localStorage.getItem('userPhoto');
		!!photoName && setFieldValue(field.name, photoName);
		return () => localStorage.removeItem('userPhoto');
	}, []);

	useEffect(() => {
		if (photoName?.length) {
			localStorage.setItem('userPhoto', photoName);
			setFieldValue(field.name, photoName);
		}
	}, [photoName]);

	useEffect(() => {
		if (field.value?.length) setIsLoading(false);
	}, [field.value]);

	return (
		<div className={styles.input_file}>
			<label className={styles.upload__button} htmlFor={id && id}>
				<Dropzone accept="image/*" multiple={false} onDrop={acceptedFiles => dropHandler(acceptedFiles)}>
					{({ getRootProps, isDragReject }) => {
						if (field.value)
							return (
								<InputPreviewImage
									value={field.value}
									getRootProps={getRootProps}
									isDragReject={isDragReject}
									fieldError={fieldError}
								/>
							);
						if (isLoading)
							return (
								<div className={styles.loader}>
									<Loader />
								</div>
							);
						return (
							<div className={styles.upload} {...getRootProps()}>
								{isDragReject ? (
									<div className={styles.upload__error}>
										<InputErrorHint errorText="You should drop one file, or file type not accepted." />
									</div>
								) : fieldTouched && fieldError && !customError ? (
									<div className={styles.upload__error}>
										<InputErrorHint errorText={fieldError} />
									</div>
								) : (
									fieldTouched &&
									customError && (
										<div className={styles.upload__error}>
											<InputErrorHint errorText={customError} />
										</div>
									)
								)}
							</div>
						);
					}}
				</Dropzone>
			</label>
			<div className={styles.input_wrapper}>
				<input id={id && id} type="file" accept="image/*" onChange={event => changeHandler(event)} />
				<div>
					Drag and drop your file or
					<label className={styles.upload__button} htmlFor={id && id}>
						Browse file
					</label>
				</div>
			</div>
		</div>
	);
};

export default InputPhoto;
