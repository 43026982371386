import { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
// styles
import 'react-phone-input-2/lib/material.css';
import styles from './InputPhone.module.scss';

type InputPhoneProps = {
	fieldName: string;
	fieldValue: string;
	setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
};

const InputPhone: FC<InputPhoneProps> = ({
	fieldName,
	fieldValue,
	setFieldValue,
	setFieldTouched,
}: InputPhoneProps) => {
	const onChangeHandler = (value: string) => {
		!value.length ? setFieldValue(fieldName, '') : setFieldValue(fieldName, `+${value}`.trim());
	};

	return (
		<PhoneInput
			placeholder="Phone number"
			specialLabel=""
			containerClass={styles.phone}
			inputClass={styles.phone__input}
			dropdownClass={styles.flag__dropdown}
			buttonClass={styles.flag__button}
			onBlur={() => setFieldTouched(fieldName, true)}
			onChange={onChangeHandler}
			value={fieldValue}
			country="us"
		/>
	);
};

export default InputPhone;
