import React, { FC, useEffect, useRef } from 'react';
import { FieldInputProps } from 'formik';
import s from './index.module.scss';

type InputTextProps = {
	placeholder?: string;
	isDisabled?: boolean;
	field: FieldInputProps<string>;
	id?: string;
	setFieldValue: (field: string, value: string) => void;
	fieldValue?: string;
	isTrimValue?: boolean;
};

let timeout: NodeJS.Timeout;

const InputText: FC<InputTextProps> = ({
	placeholder,
	isDisabled,
	field,
	id,
	setFieldValue,
	fieldValue,
	isTrimValue,
}: InputTextProps) => {
	const ref = useRef<HTMLInputElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		if (!!fieldValue && !!field && !!setFieldValue) {
			window.clearTimeout(timeout);
			timeout = setTimeout(() => {
				setFieldValue(field?.name, fieldValue.trim());
			}, 700);
		}
	}, [fieldValue, setFieldValue]);

	useEffect(() => {
		if (isTrimValue) setFieldValue(field.name, field.value.trim());
	}, [field.value, isTrimValue]);

	return (
		<div className={s.input__text}>
			<input
				id={id && id}
				type="text"
				placeholder={placeholder || ''}
				{...field}
				ref={ref}
				disabled={isDisabled}
				value={fieldValue || field.value}
			/>
			{field && !!field.value && !isDisabled && <div className={s.input__text_edit} onClick={setFocus} />}
		</div>
	);
};

export default InputText;
