import React, { FC, ReactNode } from 'react';
import PopupContainer from 'shared/components/PopupContainer';

type PopupMainProps = {
	children?: ReactNode;
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

const PopupMain: FC<PopupMainProps> = ({ children, setIsShowPopup }: PopupMainProps) => {
	return <PopupContainer setIsShowPopup={setIsShowPopup}>{children}</PopupContainer>;
};

export default PopupMain;
