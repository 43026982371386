import { ChannelMemberResponse } from 'stream-chat';
//types
import { Member } from 'core/types';

export const getChannelMembers = (members: Member, clientId: string | undefined): Array<ChannelMemberResponse> =>
	Object.values(members).filter(member => member.user?.id !== clientId);

//TODO change any
export const getChannelName = (members: Array<ChannelMemberResponse>, channel: any): string => {
	const defaultName = 'New Channel';

	if (members?.length <= 1) {
		const [firstMember] = members;
		return firstMember?.user?.name || defaultName;
	}
	return channel.data?.name || defaultName;
};

export const getDisplayedUnreadMessagesCount = (unreadCount: number): number | '10+' =>
	unreadCount > 10 ? '10+' : unreadCount;
