import { FC, useContext, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// formik
import { Field, FieldProps, Form, Formik } from 'formik';
// validation
import { userVolunteerInfoFormValidationSchema } from 'core/validation';
// redux
import { updateUser } from 'redux/companiesUsers-service/actions';
import { setError } from 'redux/error-service/action';
// context
import { UserTableDataContext } from 'pages/Admin/SuperAdminUserList/UsersTable';
// types
import { IUpdateUserRequest } from '@joc/api-gateway';
// components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import InputText from 'shared/components/FormInputs/InputText';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import InputErrorHint from 'shared/components/InputErrorHint';
import Loader from 'shared/components/Loader';
import InlineDatePicker from 'shared/components/Pickers/InlineDatePicker';
import GenderSelect from 'shared/components/Selects/SelectDropdown/GenderSelect';
import UploadUserPhoto from 'components/Volunteer/UploadUserPhoto';
// styles
import styles from './UserInfoForm.module.scss';

type UserInfoFormParentProps = {
	setIsUpdateSuccess: React.Dispatch<React.SetStateAction<boolean>>;
	isDisabled?: boolean;
};

const UserInfoForm: FC<UserInfoFormProps> = ({
	setIsUpdateSuccess,
	setError,
	updateUser,
	isDisabled,
}: UserInfoFormProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const { userData } = useContext(UserTableDataContext);
	const initialValues = useMemo(() => {
		return {
			firstName: userData.firstName,
			lastName: userData.lastName,
			email: userData.email,
			phoneNumber: userData.phoneNumber,
			birthDate: userData.birthDate,
			gender: userData.gender?.id,
			instagramLink: userData.instagramLink,
			imagePath: userData.imagePath,
			address: userData?.address,
		};
	}, [userData]);

	const submitClickHandler = async (values: IUpdateUserRequest) => {
		try {
			setIsLoading(true);
			await updateUser(+userData.id, values);
			setIsLoading(false);
			setIsUpdateSuccess(true);
		} catch (error: any) {
			setIsLoading(false);
			setError(error?.response?.message || error.response, error?.response?.code || error.code);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={userVolunteerInfoFormValidationSchema}
			onSubmit={(values): Promise<void> => submitClickHandler(values)}
		>
			{({ errors, touched, setFieldValue, setFieldTouched, dirty }) => (
				<Form className={styles.form}>
					<Field name="imagePath">
						{({ field }: FieldProps) => (
							<InputContainer parentClassnames={styles.upload_photo}>
								<UploadUserPhoto
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									fieldName={field.name}
									fieldValue={field.value}
								/>
							</InputContainer>
						)}
					</Field>
					<Field name="firstName">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="firstName">
									First Name
								</label>
								<InputText
									id="firstName"
									field={field}
									placeholder="my name is..."
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.firstName && touched.firstName && (
									<InputErrorHint errorText={errors.firstName} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="lastName">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="lastName">
									Last Name
								</label>
								<InputText
									id="lastName"
									field={field}
									placeholder="fill in..."
									fieldValue={field.value}
									setFieldValue={setFieldValue}
								/>
								{errors.lastName && touched.lastName && <InputErrorHint errorText={errors.lastName} />}
							</InputContainer>
						)}
					</Field>
					<Field name="email">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="email">
									Email
								</label>
								<InputText
									id="email"
									field={field}
									placeholder="fill in..."
									fieldValue={field.value}
									setFieldValue={setFieldValue}
									isDisabled={isDisabled}
								/>
								{errors.email && touched.email && <InputErrorHint errorText={errors.email} />}
							</InputContainer>
						)}
					</Field>
					<Field name="phoneNumber">
						{({ field }: FieldProps) => (
							<InputContainer>
								<label className={styles.label} htmlFor="phoneNumber">
									Phone number
								</label>
								<InputPhone
									fieldName={field.name}
									fieldValue={field.value}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
								/>
								{errors.phoneNumber && touched.phoneNumber && (
									<InputErrorHint errorText={errors.phoneNumber} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="birthDate">
						{({ field }: FieldProps) => (
							<InputContainer isDisabledMargin isSmallHeight>
								<InlineDatePicker
									fieldName={field.name}
									setFieldValue={setFieldValue}
									parentClassNames={styles.birthDate}
									fieldValue={field.value}
									placeholder="Date of birth"
									availablePast
									isDisabled={isDisabled}
								/>
								{errors.birthDate && touched.birthDate && (
									<InputErrorHint errorText={errors.birthDate as string} />
								)}
							</InputContainer>
						)}
					</Field>
					<Field name="gender">
						{({ field }: FieldProps) => (
							<InputContainer isDisabledMargin isSmallHeight>
								<GenderSelect
									placeholder="Gender"
									setFieldTouched={setFieldTouched}
									setFieldValue={setFieldValue}
									fieldName={field.name}
									fieldValue={field.value}
									parentClassName={styles.gender}
									isDisabled={isDisabled}
								/>
								{errors.gender && touched.gender && <InputErrorHint errorText={errors.gender} />}
							</InputContainer>
						)}
					</Field>
					<Field name="address">
						{({ field }: FieldProps) => (
							<InputContainer isDisabledMargin isSmallHeight>
								<GoogleApiAutoCompleat
									fieldName={field.name}
									selectHandler={address => {
										setFieldValue(field.name, address);
									}}
									setFieldTouched={setFieldTouched}
									fieldValue={field.value}
								/>
								{errors.address && touched.address && (
									<InputErrorHint addressErrorText={errors.address} />
								)}
							</InputContainer>
						)}
					</Field>

					{isLoading ? (
						<Loader loadProps={{ disableCenterStyle: true }} />
					) : (
						<ButtonDefault submitType classList={['primary', 'lg']} title="Confirm" disabled={!dirty} />
					)}
				</Form>
			)}
		</Formik>
	);
};

const mapDispatchToProps = { updateUser, setError };

const connector = connect(null, mapDispatchToProps);

type UserInfoFormProps = ConnectedProps<typeof connector> & UserInfoFormParentProps;

export default connector(UserInfoForm);
