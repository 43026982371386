import React, { BaseSyntheticEvent, FC } from 'react';
import cx from 'classnames';
import Appeal from '../Appeal/DefaultRenderer';
import PopupContainer from '../PopupContainer';
import ButtonDefault from '../Buttons/ButtonsDefault';
import styles from './PopupConfirmation.module.scss';

type PopupConfirmationProps = {
	confirmClickHandler: (() => void) | ((e: BaseSyntheticEvent) => void);
	cancelClickHandler: (() => void) | ((e: BaseSyntheticEvent) => void);
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	subtitle?: string;
};

const PopupConfirmation: FC<PopupConfirmationProps> = ({
	confirmClickHandler,
	cancelClickHandler,
	setIsShowPopup,
	subtitle,
}: PopupConfirmationProps) => {
	return (
		<PopupContainer setIsShowPopup={setIsShowPopup} isDisablePadding isDisableCloseButton>
			<Appeal styleThink>
				<h1 className={cx('title', styles.title)}>Are you sure?</h1>
				<h3 className={cx('subtitle', styles.subtitle)}>{subtitle}</h3>
				<ButtonDefault
					parentClassName={styles.button_confirm}
					classList={['primary', 'lg']}
					title="Confirm"
					clickHandler={confirmClickHandler}
				/>
				<ButtonDefault classList={['secondary', 'lg']} title="Close" clickHandler={cancelClickHandler} />
			</Appeal>
		</PopupContainer>
	);
};

export default PopupConfirmation;
