import MainPageLayout from 'components/Organization/MainPageLayout';
import React, { FC } from 'react';
import VolListContent from 'shared/components/VolListContent';
import styles from './SuperAdminVolList.module.scss';

const SuperAdminVolList: FC = () => {
	return (
		<MainPageLayout>
			<div className={styles.container}>
				<h1 className="title">Volunteers List</h1>
				<VolListContent />
			</div>
		</MainPageLayout>
	);
};

export default SuperAdminVolList;
