import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Store } from 'redux/root';
import { setError } from 'redux/error-service/action';
import { getVolunteers, resetVolunteers } from 'redux/volunteer-service/actions';
import {
	ISearchVolunteersByGroupRequest,
	ISearchVolunteersRequest,
	PaginationRequest,
} from '@joc/api-gateway/lib/api-client';
import { INITIAL_PAGINATION } from 'core/constants';
import { parseQueryStringVolunteersSearch } from 'core/functions';
import { VolunteersPageContext, VolunteersPageContextType } from 'pages/Organization/Volunteers';
import VolunteersTable from 'components/Organization/Volunteers/Table';
import VolunteersFilters from 'components/Organization/Volunteers/VolunteersFilters';
import { useDispatch, useSelector } from 'react-redux';
// styles
import styles from './VolListContent.module.scss';

const VolListContent: FC = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const location = useLocation();
	const userLocation = useSelector((store: Store) => store.userRedux.userData?.address);
	const [visibilityFilters, setVisibilityFilters] = useState({
		isClose: false,
		isShowFilter: false,
	});
	const [isClearSearch, setIsClearSearch] = useState(false);

	const requestBody: ISearchVolunteersRequest | ISearchVolunteersByGroupRequest = useMemo(() => {
		return parseQueryStringVolunteersSearch(location.search, userLocation);
	}, [location.search]);

	const changeVisibilityFilters = useCallback(() => {
		setVisibilityFilters({ ...visibilityFilters, isClose: !visibilityFilters.isClose });
		setTimeout(
			() => setVisibilityFilters({ ...visibilityFilters, isShowFilter: !visibilityFilters.isShowFilter }),
			200
		);
	}, [visibilityFilters]);

	const volunteersPageContextValue: VolunteersPageContextType = useMemo(() => {
		return {
			isLoading,
			isClearSearch,
			sort: requestBody?.sort,
			setPagination,
			changeVisibilityFilters,
			setIsClearSearch,
		};
	}, [isLoading, isClearSearch, requestBody, changeVisibilityFilters]);

	const getVolunteersRequest = useCallback(
		async (checkedPagination: PaginationRequest) => {
			setIsLoading(true);
			try {
				await dispatch(getVolunteers({ ...requestBody, pagination: checkedPagination }));
				setIsLoading(false);
			} catch (error: any) {
				setIsLoading(false);
				setError(error?.response?.message || error.message, error.code);
			}
		},
		[pagination, requestBody]
	);

	useEffect(() => {
		const checkedPagination =
			pagination.take > 0
				? PaginationRequest.fromJS(pagination)
				: PaginationRequest.fromJS({ ...pagination, take: 10 });
		getVolunteersRequest(checkedPagination);
	}, [getVolunteersRequest]);

	useEffect(() => {
		dispatch(resetVolunteers());
		setPagination(INITIAL_PAGINATION);
	}, [location.search]);

	useEffect(() => {
		return () => {
			dispatch(resetVolunteers());
		};
	}, []);

	return (
		<div className={styles.content__wrapper}>
			<div className={styles.content}>
				<VolunteersPageContext.Provider value={volunteersPageContextValue}>
					<VolunteersTable />
				</VolunteersPageContext.Provider>
			</div>
			{visibilityFilters.isShowFilter && (
				<VolunteersFilters
					changeVisibilityFilters={changeVisibilityFilters}
					isCloseFilters={visibilityFilters.isClose}
					setIsClearSearch={setIsClearSearch}
				/>
			)}
		</div>
	);
};

export default VolListContent;
