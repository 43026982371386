import React, { FC } from 'react';
import cx from 'classnames';
import CanceledSvg from 'assets/image/Canceled.svg';
import styles from './ButtonActionCircle.module.scss';

type ButtonRejectCircleProps = {
	clickHandler: () => void;
	disabled?: boolean;
};

const ButtonRejectCircle: FC<ButtonRejectCircleProps> = ({ clickHandler, disabled }: ButtonRejectCircleProps) => {
	return (
		<button disabled={disabled} className={styles.main} onClick={clickHandler}>
			<img className={cx(styles.reject, disabled && styles.reject_disabled)} src={CanceledSvg} alt="canceled" />
		</button>
	);
};

export default ButtonRejectCircle;
