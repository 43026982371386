import React, { FC, Fragment, useMemo, useState } from 'react';
import PopupContainer from 'shared/components/PopupContainer';
import { IGetVolunteerByIdResponse, OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import { Store } from 'redux/root';
import { connect, ConnectedProps } from 'react-redux';
// import { selectorGetOrgId } from 'redux/organization-service/selector';
import ButtonConfirmAll from './ButtonConfirmAll';
import SmartGroupActions from './SmartGroupActions';
import PossibleVolunteersTable from './PossibleVolunteersTable';
import CreateVolunteersSmartGroup from './CreateVolunteersSmartGroup';
import styles from './PopupVolunteers.module.scss';

type PopupVolunteersParentProps = {
	opportunityId: number;
	opportunityName: string;
	opportunityStatus: OPPORTUNITY_STATUSES;
	volunteersArray: Array<IGetVolunteerByIdResponse>;
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	opportunityType: OPPORTUNITY_TYPES;
};

const PopupVolunteers: FC<PopupVolunteersProps> = ({
	opportunityId,
	setIsShowPopup,
	opportunityName,
	volunteersArray,
	opportunityStatus,
	opportunityType,
}: PopupVolunteersProps) => {
	const [smartGroupVolunteers, setSmartGroupVolunteers] = useState<Array<number>>([]);
	// const volunteersArrayMembers = useMemo(() => {
	// 	if (orgId) return volunteersArray.filter(i => i.organizations?.map(org => +org.id).includes(orgId));
	// 	return [];
	// }, [orgId]);

	const [isCreateSmartGroup, setIsCreateSmartGroup] = useState(false);

	const setCheckedVolunteer = (checked?: boolean, value?: number): void => {
		checked && value
			? setSmartGroupVolunteers([...smartGroupVolunteers, value])
			: setSmartGroupVolunteers(smartGroupVolunteers.filter((id: number) => id !== value));
	};

	// const setSmartGroupVolunteersAll = () => {
	// 	volunteersArrayMembers && smartGroupVolunteers?.length < volunteersArrayMembers.length && orgId
	// 		? setSmartGroupVolunteers(volunteersArrayMembers.map(i => +i.volunteerId))
	// 		: setSmartGroupVolunteers([]);
	// };

	return (
		<PopupContainer setIsShowPopup={setIsShowPopup}>
			{isCreateSmartGroup ? (
				<CreateVolunteersSmartGroup
					closeHandle={() => setIsCreateSmartGroup(false)}
					smartGroupVolunteers={smartGroupVolunteers}
					setIsShowPopup={setIsShowPopup}
				/>
			) : (
				<Fragment>
					<div className={styles.content__header}>
						<h2 className="title">
							{opportunityStatus === OPPORTUNITY_STATUSES.CLOSED
								? 'Select volunteers to create smart group'
								: 'Possible Volunteers'}
						</h2>
						<span className={styles.content__header__chesed}>{opportunityName}</span>
						{/* {opportunityStatus === OPPORTUNITY_STATUSES.OPEN ? (
							<ButtonConfirmAll opportunityId={opportunityId} volunteersArray={volunteersArray} />
						) : (
							opportunityStatus === OPPORTUNITY_STATUSES.CLOSED && (
								<SmartGroupActions
									disabled={!smartGroupVolunteers.length}
									clickHandler={() => setIsCreateSmartGroup(true)}
								/>
							)
						)} */}
					</div>
					<div className={styles.content}>
						<PossibleVolunteersTable
							opportunityId={opportunityId}
							volunteersArray={volunteersArray}
							opportunityStatus={opportunityStatus}
							smartGroupVolunteers={smartGroupVolunteers}
							setSmartGroupVolunteers={setCheckedVolunteer}
							// setSmartGroupVolunteersAll={setSmartGroupVolunteersAll}
							opportunityType={opportunityType}
							// volunteersArrayMembers={volunteersArrayMembers}
						/>
					</div>
				</Fragment>
			)}
		</PopupContainer>
	);
};

const connector = connect(null);

type PopupVolunteersProps = ConnectedProps<typeof connector> & PopupVolunteersParentProps;

export default connector(PopupVolunteers);
