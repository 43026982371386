import React, { FC } from 'react';
import { isShowNewBadge } from 'core/functions';
import NewUserBadge from 'assets/image/new-user.svg';
import MemberBadge from 'assets/image/diplom.svg';
import styles from './VolunteerBadges.module.scss';

type VolunteerBadgesProps = {
	createDate?: Date;
	isShowMemberBadge?: boolean;
};

const VolunteerBadges: FC<VolunteerBadgesProps> = ({ createDate, isShowMemberBadge }: VolunteerBadgesProps) => {
	return (
		<div className={styles.badges}>
			{createDate && isShowNewBadge(createDate) && (
				<img className={styles.badge_new} src={NewUserBadge} alt="badge" />
			)}
			{isShowMemberBadge && <img className={styles.badge_member} src={MemberBadge} alt="badge" />}
		</div>
	);
};

export default VolunteerBadges;
