import { Dispatch } from 'redux';
import { LiteralStringForUnion, StreamChat } from 'stream-chat';
//types
import {
	SET_CHAT_UNREAD_MESSAGE_COUNT,
	SET_CHAT_USER_TO_CONNECT,
	RESET_CHAT,
	SET_CHAT_CLIENT_CONNECT_STATE,
} from 'redux/actions-types';
import { GenerateChatUserTokenRequest } from '@joc/api-gateway';
import { UserToConnectType } from './types';
//API
import { API } from 'core/API';

export type AttachmentType = {};
export type ChannelType = {};
export type CommandType = LiteralStringForUnion;
export type EventType = {};
export type MessageType = {};
export type ReactionType = {};
export type UserType = { image?: string };

const apiKey = process.env.REACT_APP_STREAM_KEY;
export const chatClient = StreamChat.getInstance(apiKey!);

export const setChatUserToConnect =
	(userToConnect: UserToConnectType) =>
	(dispatch: Dispatch): void => {
		dispatch({ type: SET_CHAT_USER_TO_CONNECT, payload: userToConnect });
	};

export const connectUserToGetStream =
	(userToConnect: UserToConnectType) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const userChatToken: string = await API.generateChatUserToken(
				GenerateChatUserTokenRequest.fromJS({ chatId: userToConnect.id })
			);
			const connectedUser = await chatClient.connectUser({ id: userToConnect.id as string }, userChatToken);
			dispatch({ type: SET_CHAT_CLIENT_CONNECT_STATE, payload: !!connectedUser || false });
			const unreadMessagesCount = connectedUser && connectedUser.me?.total_unread_count;
			if (unreadMessagesCount)
				dispatch({
					type: SET_CHAT_UNREAD_MESSAGE_COUNT,
					payload: unreadMessagesCount,
				});
		} catch (error: any) {
			console.error(error);
			chatClient?.disconnectUser();
		}
	};

export const setChatUnreadMessagesCount =
	(count: number) =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: SET_CHAT_UNREAD_MESSAGE_COUNT,
			payload: count,
		});
	};

export const resetChat = () => (dispatch: Dispatch) => {
	dispatch({
		type: RESET_CHAT,
	});
};
