// User
export const GET_USER = 'GET_USER';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const UPDATE_USER = 'UPDATE_USER';
export const VERIFY_TOKEN_USER = 'VERIFY_TOKEN_USER';
export const CREATE_USER = 'CREATE_USER';
export const CHANGE_NAVBAR_MODE = 'CHANGE_NAVBAR_MODE';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const UNSET_USER_ROLE = 'UNSET_USER_ROLE';
export const SET_USER_POSITION = 'SET_USER_POSITION';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const DELETE_USER = 'DELETE_USER';
export const REMOVE_PUSH_NOTIFICATION_TOKEN = 'REMOVE_PUSH_NOTIFICATION_TOKEN';

// Dashboard
export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO';
export const GET_LIVESCREEN_INFO = 'GET_LIVESCREEN_INFO';
export const UPDATE_LIVESCREEN_INFO = 'UPDATE_LIVESCREEN_INFO';
export const RESET_LIVESCREEN_INFO = 'RESET_LIVESCREEN_INFO';

// CompaniesUsers
export const GET_COMPANIES_USERS_ALL = 'GET_COMPANIES_USERS_ALL';
export const GET_COMPANIES_USERS_ADMIN = 'GET_COMPANIES_USERS_ADMIN';
export const GET_COMPANIES_USERS_COORDINATORS = 'GET_COMPANIES_USERS_COORDINATORS';
export const GET_COMPANIES_USERS_MARKETERS = 'GET_COMPANIES_USERS_MARKETERS';
export const RESET_COMPANIES_USERS = 'RESET_COMPANIES_USERS';

// Org
export const GET_ORGANIZATIONS_ALL = 'GET_ORGNIZATIONS_ALL';
export const RESET_ORGANIZATIONS = 'RESET_ORGANIZATIONS';
export const UPDATE_ACTIVE_STATUS_ORG = 'UPDATE_ACTIVE_STATUS_ORG';
export const UPDATE_ORG_INFO = 'UPDATE_ORG_INFO';
export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS';
export const RESET_ORGANIZATION_USERS = 'RESET_ORGANIZATION_USERS';
export const SET_CURRENT_VIDEO = 'SET_CURRENT_VIDEO';
export const UNSET_CURRENT_VIDEO = 'UNSET_CURRENT_VIDEO';
export const DELETE_CURRENT_VIDEO = 'DELETE_CURRENT_VIDEO';
export const ADD_VIDEO_COUNT_WATCH = 'ADD_VIDEO_COUNT_WATCH';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';

// Opportunities
export const GET_OPPORTUNITIES_ALL = 'GET_OPPORTUNITIES_ALL';
export const GET_OPPORTUNITIES_ALL_REPLACE = 'GET_OPPORTUNITIES_ALL_REPLACE';
export const GET_OPPORTUNITIES = 'GET_OPPORTUNITIES';
export const GET_OPPORTUNITY_BY_ID = 'GET_OPPORTUNITY_BY_ID';
export const GET_OPPORTUNITIES_COUNT = 'GET_OPPORTUNITIES_COUNT';
export const RESET_OPPORTUNITIES_RECORDS = 'RESET_OPPORTUNITIES_RECORDS';
export const CREATE_OPPORTUNITY = 'CREATE_OPPORTUNITY';
export const UPDATE_OPPORTUNITY = 'UPDATE_OPPORTUNITY';
export const DELETE_OPPORTUNITY = 'DELETE_OPPORTUNITY';
export const GET_OPPORTUNITIES_REMINDER_TAPE = 'GET_OPPORTUNITIES_REMINDER_TAPE';
export const CANTMAKEIT = 'CANTMAKEIT';
export const CHANGE_STATUS_MANUAL_OPPORTUNITY = 'CHANGE_STATUS_MANUAL_OPPORTUNITY';
export const UPDATE_ACTIVE_STATUS_OPPO = 'UPDATE_ACTIVE_STATUS_OPPO';
export const JOIN_TO_OPPORTUNITY_BY_VOLUNTEER = 'JOIN_TO_OPPORTUNITY_BY_VOLUNTEER';

// Invites
export const GET_INVITES_ALL = 'GET_INVITES_ALL';
export const GET_INVITES_JOINED = 'GET_INVITES_JOINED';
export const GET_INVITES_PENDING = 'GET_INVITES_PENDING';
export const RESET_INVITES = 'RESET_INVITES';
export const DELETE_INVITE = 'DELETE_INVITE';
export const UPDATE_INVITE = 'UPDATE_INVITE';
// Reminder Tape
export const GET_REMINDER_TAPE_OPPORTUNITIES = 'GET_REMINDER_TAPE_OPPORTUNITIES';

// Daily quotes
export const GET_QUOTES_ALL = 'GET_QUOTES_ALL';
export const RESET_QUOTES = 'RESET_QUOTES';
export const CREATE_QUOTES = 'CREATE_QUOTES';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const DELETE_QUOTE = 'DELETE_QUOTE';

// Challenges
export const GET_CHALLENGES_ALL = 'GET_CHALLENGES_ALL';
export const CREATE_CHALLENGE = 'CREATE_CHALLENGE';
export const UPDATE_CHALLENGE = 'UPDATE_CHALLENGE';
export const DELETE_CHALLENGE = 'DELETE_CHALLENGE';

export const RESET_CHALLENGES = 'RESET_CHALLENGES';
export const LOADING_STATUS_CHALLENGES = 'LOADING_STATUS_CHALLENGES';

// Volunteers
export const GET_VOLUNTEERS_ALL = 'GET_VOLUNTEERS_ALL';
export const GET_VOLUNTEERS_BY_GROUP = 'GET_VOLUNTEERS_BY_GROUP';
export const UPDATE_VOLUNTEER = 'UPDATE_VOLUNTEER';
export const RESET_VOLUNTEERS = 'RESET_VOLUNTEERS';
export const UPDATE_ACTIVE_STATUS_VOL = 'UPDATE_ACTIVE_STATUS_VOL';

// Smart groups
export const GET_SMART_GROUPS_ALL = 'GET_SMART_GROUPS_ALL';
export const CREATE_SMART_GROUP = 'CREATE_SMART_GROUP';
export const DELETE_SMART_GROUP = 'DELETE_SMART_GROUP';

// Errors
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

// Pushes
export const SET_PUSH_MESSAGE = 'SET_PUSH_MESSAGE';

// Chat
export const SET_CHAT_USER_TO_CONNECT = 'SET_CHAT_USER_TO_CONNECT';
export const SET_CHAT_CLIENT_CONNECT_STATE = 'SET_CHAT_CLIENT_CONNECT_STATE';
export const SET_CHAT_UNREAD_MESSAGE_COUNT = 'SET_CHAT_UNREAD_MESSAGE_COUNT';
export const RESET_CHAT = 'RESET_CHAT';

// Placeholders
export const GET_PLACEHOLDERS_ALL = 'GET_PLACEHOLDERS_ALL';
export const UPDATE_PLACEHOLDER = 'UPDATE_PLACEHOLDER';
export const DELETE_PLACEHOLDER = 'DELETE_PLACEHOLDER';
