import { Quill } from 'react-quill';

// Extend Quill's Bold blot
const Inline = Quill.import('blots/inline');

export class CustomBold extends Inline {
	static create() {
		const node = super.create();
		// Apply custom styles for bold text
		node.setAttribute('style', 'font-weight: 600;');
		return node;
	}

	static formats() {
		return true;
	}
}
