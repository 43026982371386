import { FC, ReactElement } from 'react';
import { ButtonBase } from '@material-ui/core';
import cx from 'classnames';

import styles from './index.module.scss';

type ButtonOutlinedProps = {
	title: string;
	clickHandler: () => void;
	parentClassName?: string;
	icon?: ReactElement;
};
const ButtonOutlined: FC<ButtonOutlinedProps> = ({
	title,
	clickHandler,
	parentClassName,
	icon,
}: ButtonOutlinedProps) => {
	return (
		<ButtonBase type="button" className={cx(styles.outlined, parentClassName)} onClick={clickHandler}>
			{icon || null}
			<span>{title}</span>
		</ButtonBase>
	);
};

export default ButtonOutlined;
