import React, { FC, SetStateAction, Dispatch, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import qs from 'qs';
import cx from 'classnames';
import FiltersContainer from 'components/Organization/Filters/FiltersContainer';
import InputContainer from 'shared/components/FormInputs/InputContainer';
// import InlineDatePicker from 'shared/components/Pickers/InlineDatePicker';
import SelectDropdown from 'shared/components/Selects/SelectDropdown/SingleSelect';
import OrganizationSelect from 'shared/components/Selects/SelectDropdown/OrganizationSelect';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import GenderSelect from 'shared/components/Selects/SelectDropdown/GenderSelect';
import SliderRange from 'shared/components/SliderRange';
import { useHistory, useLocation } from 'react-router';
import { deserializeQueryParamsVolunteersFilters } from 'core/functions/deserializeQueryParamsVolunteersFilters';
import { VolunteersFiltersSubmitClickHandler } from 'core/functions';
import { VOLUNTEERS_FILTERS_VALUES } from 'core/types/VOLUNTEERS_FILTERS_VALUES';
import { INITIAL_VOLUNTEERS_FILTERS_VALUES } from 'core/types/INITIAL_VOLUNTEERS_FILTERS_VALUES';
// import InputErrorHint from 'shared/components/InputErrorHint';
// import { INITIAL_PAGINATION } from 'core/constants';
// import { resetVolunteers } from 'redux/volunteer-service/actions';
// import { connect, ConnectedProps } from 'react-redux';
import { values } from 'lodash';
import styles from './VolunteersFilters.module.scss';

type VolunteersFiltersProps = {
	changeVisibilityFilters: () => void;
	isCloseFilters: boolean;
	setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
};

const VolunteersFilters: FC<VolunteersFiltersProps> = ({
	changeVisibilityFilters,
	isCloseFilters,
	setIsClearSearch,
}: // error,
VolunteersFiltersProps) => {
	const location = useLocation();
	const history = useHistory();
	const ageTemplate = new Array(61).fill(0).map((item, index) => index + 15);

	const [initialValues, setInitialValues] = useState<VOLUNTEERS_FILTERS_VALUES>(INITIAL_VOLUNTEERS_FILTERS_VALUES);

	useEffect(() => {
		const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
		if (Object.keys(queryParams).length) {
			const requestBody = deserializeQueryParamsVolunteersFilters(queryParams);
			setInitialValues(requestBody);
		}
		if (queryParams?.groupId) {
			changeVisibilityFilters();
		}
	}, [location.search]);

	return (
		<div className={styles.container}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={values => VolunteersFiltersSubmitClickHandler(values, history)}
			>
				{({ setFieldValue, values, resetForm, setFieldTouched }) => (
					<FiltersContainer
						isCloseFilters={isCloseFilters}
						changeVisibilityFilters={changeVisibilityFilters}
						isOrgFilter
						resetForm={() => {
							setInitialValues(INITIAL_VOLUNTEERS_FILTERS_VALUES);
							resetForm();
							VolunteersFiltersSubmitClickHandler(undefined, history);
							setIsClearSearch && setIsClearSearch(true);
						}}
					>
						<Form className={styles.form}>
							<OrganizationSelect setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />

							<div className={styles.form__field}>
								<InputContainer isDisableHeight isDisabledMargin>
									<label className={styles.label}>Gender:</label>
									<Field name="genderId">
										{({ field }: FieldProps) => (
											<GenderSelect
												fieldName={field.name}
												fieldValue={field.value}
												setFieldValue={setFieldValue}
												setFieldTouched={setFieldTouched}
											/>
										)}
									</Field>
								</InputContainer>
							</div>
							<div className={cx(styles.form__field, styles.field_wrap)}>
								<label className={styles.label}>How far?</label>
								<Field name="howFar">
									{({ field }: FieldProps) => (
										<SliderRange
											min={0}
											max={500}
											fieldValue={field.value}
											fieldName={field.name}
											setFieldValue={setFieldValue}
										/>
									)}
								</Field>
							</div>
							<div className={styles.form__field}>
								<label className={styles.label}>Student/Volunteer age</label>
								<div className={styles.ageLimit}>
									<Field name="minAge">
										{({ field }: FieldProps) => (
											<InputContainer isDisabledMargin>
												<div className={styles.labelAge}>Min Age</div>
												<SelectDropdown
													field={field}
													placeholder="Min age"
													options={ageTemplate.filter(i =>
														values.maxAge ? i <= values.maxAge : i
													)}
													setFieldValue={setFieldValue}
													setFieldTouched={setFieldTouched}
													classList={['md']}
												/>
											</InputContainer>
										)}
									</Field>
									<Field name="maxAge">
										{({ field }: FieldProps) => (
											<InputContainer isDisabledMargin>
												<div className={styles.labelAge}>Max Age</div>
												<SelectDropdown
													field={field}
													placeholder="Max age"
													options={ageTemplate.filter(i =>
														values.minAge ? i >= values.minAge : i
													)}
													setFieldValue={setFieldValue}
													setFieldTouched={setFieldTouched}
													classList={['md']}
												/>
											</InputContainer>
										)}
									</Field>
								</div>
							</div>
							<ButtonDefault title="Apply" submitType classList="secondary md" />
						</Form>
					</FiltersContainer>
				)}
			</Formik>
		</div>
	);
};

export default VolunteersFilters;
