import React, { FC, useRef } from 'react';
import cx from 'classnames';
import s from './index.module.scss';

type GroupNameInputProps = {
	id: string;
	placeholder: string;
	value: string;
	setValue: (value: string) => void;
	parentClassName?: string;
};

const GroupNameInput: FC<GroupNameInputProps> = ({
	id,
	placeholder = '',
	value,
	setValue,
	parentClassName,
}: GroupNameInputProps) => {
	const ref = useRef<HTMLInputElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	return (
		<div className={cx(s.input_text, parentClassName && parentClassName)}>
			<input id={id} type="text" placeholder={placeholder} ref={ref} onChange={e => setValue(e.target.value)} />
			{value && <div className={s.input_text__edit} onClick={setFocus} />}
		</div>
	);
};

export default GroupNameInput;
