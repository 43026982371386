import { FC } from 'react';
// components
import Checkbox from 'shared/components/Checkbox';
// styles
import styles from './CheckboxVolunteerCell.module.scss';

type CheckboxVolunteerCellProps = {
	changeHandle: (checked: boolean, value?: number | undefined) => void;
	volunteerId: number;
	defaultChecked: boolean;
};

const CheckboxVolunteerCell: FC<CheckboxVolunteerCellProps> = ({
	volunteerId,
	changeHandle,
	defaultChecked,
}: CheckboxVolunteerCellProps) => {
	return (
		<div className={styles.main}>
			<Checkbox value={volunteerId} changeHandle={changeHandle} defaultChecked={defaultChecked} />
		</div>
	);
};

export default CheckboxVolunteerCell;
