import { FC } from 'react';
import { Avatar } from 'stream-chat-react';
import { Channel, ChannelMemberResponse } from 'stream-chat';
//functions
import { setUserPhotoSrc } from 'core/functions';
//components
import { AvatarGroup } from '../MessagingChannelHeader/MessagingChannelHeader';

export type ChannelProps = Channel;

type ChannelSearchResultProps = {
	channel: ChannelProps;
	setActiveChannel: any;
};

export const ChannelSearchResult: FC<ChannelSearchResultProps> = ({ channel, setActiveChannel }) => {
	const members: ChannelMemberResponse[] = Object.values(channel.state.members || {});

	return (
		<li className="messaging-create-channel__user-result" onClick={() => setActiveChannel(channel)}>
			{members.length === 2 ? (
				<Avatar
					image={setUserPhotoSrc(
						members
							.slice(1, 2)
							.map(member => member?.user?.image)
							.toString()
					)}
					size={50}
				/>
			) : (
				<AvatarGroup members={members} />
			)}
			<div className="messaging-create-channel__user-result__details">
				<span>
					{channel.data?.name ||
						Object.values(channel.state.members)
							.slice(-1, 1)
							.map(member => member?.user?.name)
							.filter(name => !!name)
							.join(', ')}
				</span>
			</div>
		</li>
	);
};
