import { IPlaceholderReducer, PlaceholdersActionTypes } from './types';
import { DELETE_PLACEHOLDER, GET_PLACEHOLDERS_ALL, UPDATE_PLACEHOLDER } from '../actions-types';

const initialState: IPlaceholderReducer = {
	records: [],
	total: 0,
};

const placeholders = (state = initialState, action: PlaceholdersActionTypes): IPlaceholderReducer => {
	const { type, payload }: PlaceholdersActionTypes = action;

	switch (type) {
		case GET_PLACEHOLDERS_ALL: {
			return { records: payload, total: payload.length };
		}
		case UPDATE_PLACEHOLDER: {
			return {
				...state,
				records: state.records.map(placeholder => {
					if (placeholder.id === payload.id) {
						return payload;
					}
					return placeholder;
				}),
			};
		}
		case DELETE_PLACEHOLDER: {
			return {
				records: { ...state.records.filter(placeholder => Number(placeholder.id) !== Number(payload)) },
				total: state.records.length - 1,
			};
		}
		default:
			return state;
	}
};

export default placeholders;
