import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// images
import HeartSvg from 'assets/image/heart.svg';
import ArrowDarkSvg from 'assets/image/button-arrow-dark.svg';
import RingSvg from 'assets/image/ring.svg';
import UserSvg from 'assets/image/user.svg';
import HammerSvg from 'assets/image/hammer.svg';
import UsergroupSvg from 'assets/image/usergroup.svg';
// styles
import styles from './index.module.scss';

type DashboardItemProps = {
	count: number;
	title: string;
	urlTo: string;
	ringsvg?: boolean;
	heartsvg?: boolean;
	usersvg?: boolean;
	usergroupsvg?: boolean;
	hammersvg?: boolean;
};

const DashboardItem: FC<DashboardItemProps> = ({
	count,
	title,
	ringsvg,
	usersvg,
	usergroupsvg,
	heartsvg,
	urlTo,
	hammersvg,
}: DashboardItemProps) => {
	return (
		<div className={styles.dashboard_item}>
			<div className={styles.item__ico}>
				<img
					src={
						ringsvg
							? RingSvg
							: usersvg
							? UserSvg
							: usergroupsvg
							? UsergroupSvg
							: heartsvg
							? HeartSvg
							: hammersvg
							? HammerSvg
							: HeartSvg
					}
				/>
			</div>
			<div className={styles.item__count}>{count}</div>
			<div className={styles.item__caption}>{title}</div>
			<Link to={urlTo} className={styles.item__navButton}>
				<img src={ArrowDarkSvg} />
			</Link>
		</div>
	);
};

export default DashboardItem;
