import { IBasicVolunteerResponse } from '@joc/api-gateway';
import { Store } from 'redux/root';

export const selectorGetVolunteersAllRecords = (store: Store): Array<IBasicVolunteerResponse> => {
	return store.volunteersRedux.volunteers.records;
};

export const selectorGetVolunteersChatId = (store: Store): Array<string> => {
	return store.volunteersRedux.volunteers.records.map(el => (el?.chatId ? el.chatId : '')).filter(i => i.length);
};

export const selectorGetVolunteersTotal = (store: Store): number => {
	return store.volunteersRedux.volunteers.total;
};
