import { Store } from 'redux/root';
import { DashboardReducer } from './types';
import { DashboardSettingsResponse } from '@joc/api-gateway/lib/api-client';

export const selectorGetDashboard = (store: Store): DashboardReducer => {
	return store.dashboardRedux;
};

export const selectorGetLiveScreen = (store: Store): DashboardSettingsResponse | null => {
	return store.dashboardRedux.liveScreen;
};
