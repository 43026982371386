export const INITIAL_VOLUNTEER_VALUES = {
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	birthDate: null,
	gender: null,
	instagramLink: '',
	imagePath: '',
	address: {
		countryName: '',
		stateName: '',
		cityName: '',
		streetName: '',
		buildingNumber: '',
		zipCode: '',
		pobox: '',
		longitude: 0,
		latitude: 0,
	},
	organisationId: '',
};
