export const TABLE_ITEMS_CLASSNAMES = {
	avatar: 'avatar',
	fullName: 'fullName',
	age: 'age',
	email: 'email',
	phone: 'phone',
	gender: 'gender',
	location: 'location',
	forms: 'forms',
	totalTimeSpent: 'totalTimeSpent',
	date: 'date',
	time: 'time',
	options: 'options',
	chesedName: 'chesedName',
	coordinator: 'coordinator',
	volunteers: 'volunteers',
	volunteers_actions: 'volunteers_actions',
	checkbox: 'checkbox',
	listOrganizations: 'listOrganizations',
	logo: 'avatar',
	type: 'type',
	contacts: 'contacts',
	owner: 'owner',
	totalVolunteers: 'total_volunteers',
	totalUsers: 'total_users',
	totalOppos: 'total_oppotunities',
	totalOppoHours: 'total_oppotunity_hours',
	position: 'position',
	organizations: 'organizations',
	organization: 'organization',
	role: 'role',
	grade: 'grade',
	spendTime: 'spend_time',
	// suspend: 'suspend',
};
