// import { GenerateChatUserTokenRequest } from '@joc/api-gateway';
// import { API } from 'core/API';
import { urls } from 'core/appUrls';
import React, { FC, useState } from 'react';
// import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
// import { Store } from 'redux/root';
// import { setError } from 'redux/error-service/action';
// import { selectorGetOrgChatId } from 'redux/organization-service/selector';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';
// import { StreamChat } from 'stream-chat';
import qs from 'qs';
import PopupContainer from 'shared/components/PopupContainer';
import PopupUsersTable from 'pages/Admin/SuperAdminOrgList/PopupUsersTable';
import BroadcastInputTextPopup from 'shared/components/BroadcastInputTextPopup';
import styles from './ContactCell.module.scss';
import { BroadcastEnum } from 'shared/components/BroadcastInputTextPopup/helpers';

type ContactCellProps = { chatId: string; orgId: string; users?: boolean; organizations?: boolean };

const ContactCell: FC<ContactCellProps> = ({
	chatId,
	orgId,
	users = false,
	organizations = false,
}: ContactCellProps) => {
	const history = useHistory();
	const [showPopup, setShowPopup] = useState(false);
	const [isShowBroadcastPopup, setIsShowMessageInputPopup] = useState(false);

	const createChat = () => {
		const queryString = qs.stringify({ members: [chatId], type: 'all' });
		history.push(`${urls.inbox}?${queryString}`);
	};

	const toggleMessageInputPopupHandler = () => setIsShowMessageInputPopup(!isShowBroadcastPopup);

	return (
		<div className={styles.contact}>
			<ButtonOutlined
				parentClassName={styles.button}
				title={organizations ? 'Contact Admin' : 'Contact'}
				clickHandler={createChat}
			/>
			{organizations && (
				<ButtonOutlined
					parentClassName={styles.button}
					title="Broadcast"
					clickHandler={toggleMessageInputPopupHandler}
				/>
			)}
			{users && (
				<ButtonOutlined parentClassName={styles.button} title="Users" clickHandler={() => setShowPopup(true)} />
			)}
			{showPopup && (
				<PopupContainer setIsShowPopup={setShowPopup}>
					<h2 className={styles.popup_title}>Users of organization</h2>
					<PopupUsersTable orgId={orgId} />
				</PopupContainer>
			)}

			{isShowBroadcastPopup && (
				<BroadcastInputTextPopup
					broadcastType={BroadcastEnum.Organizations}
					organizationId={orgId}
					closePopupHandler={toggleMessageInputPopupHandler}
				/>
			)}
		</div>
	);
};

export default ContactCell;
