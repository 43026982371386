import React, { BaseSyntheticEvent, FC, Fragment, useState } from 'react';
import { deleteSmartGroup } from 'redux/smartGroups-service/actions';
import { connect, ConnectedProps } from 'react-redux';
import ButtonCross from 'assets/image/cross-button.svg';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import styles from './TabNavItemActions.module.scss';

type TabNavItemActionsParentProps = {
	groupId: number | undefined;
};

const TabNavItemActions: FC<TabNavItemActionsProps> = ({ groupId, deleteSmartGroup }: TabNavItemActionsProps) => {
	const [isShowPopup, setIsShowPopup] = useState(false);

	const cancelClickHandler = (e: BaseSyntheticEvent) => {
		e.stopPropagation();
		setIsShowPopup(false);
	};

	const confirmClickHandler = (e: BaseSyntheticEvent) => {
		e.stopPropagation();
	};

	const deleteClickHandler = (e: BaseSyntheticEvent) => {
		e.stopPropagation();
		setIsShowPopup(true);
	};

	return (
		<Fragment>
			<div className={styles.button_close} onClick={deleteClickHandler}>
				<img src={ButtonCross} className={styles.button_close__icon} />
			</div>
			{isShowPopup && (
				<PopupConfirmation
					cancelClickHandler={e => cancelClickHandler(e)}
					confirmClickHandler={e => confirmClickHandler(e)}
					setIsShowPopup={setIsShowPopup}
				/>
			)}
		</Fragment>
	);
};

const mapDispatchToProps = {
	deleteSmartGroup,
};

const connector = connect(null, mapDispatchToProps);

type TabNavItemActionsProps = ConnectedProps<typeof connector> & TabNavItemActionsParentProps;

export default connector(TabNavItemActions);
