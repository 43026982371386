import React, { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
import { API } from 'core/API';
import { IGendersResponse } from '@joc/api-gateway';
import styles from './index.module.scss';

const getGenders = async (setGenders: React.Dispatch<React.SetStateAction<Array<IGendersResponse>>>) => {
	try {
		const response = await API.getAllGenders();
		response.length && setGenders(response);
	} catch (error: any) {
		console.error(error);
	}
};

type GenderSelectSmartGroupTypes = {
	placeholder: string;
	setGenderId: React.Dispatch<React.SetStateAction<number>>;
	genderId: number;
	classList?: Array<string>;
};

const GenderSelectSmartGroup: FC<GenderSelectSmartGroupTypes> = ({
	placeholder,
	setGenderId,
	genderId,
	classList,
}: GenderSelectSmartGroupTypes) => {
	const [options, setOptions] = useState<Array<IGendersResponse>>([]);
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const selectRef = useRef<HTMLDivElement>(null);

	const optionClickHandler = (genderId: number): void => {
		setGenderId(genderId);
		setIsShowOptions(false);
	};

	const handleClickOutside = (event: Event) => {
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};

	useEffect(() => {
		getGenders(setOptions);
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	return (
		<div
			className={cx(styles.dropdown_select, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!genderId,
				})}
				onClick={() => {
					setIsShowOptions(!isShowOptions);
				}}
			>
				<span>{genderId ? options.find(option => option.id === genderId)?.genderName : placeholder}</span>
				{!isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div
				className={cx(styles.dropdown_select__options, {
					[styles.dropdown_select__options_center]: classList?.includes('option_center'),
				})}
			>
				{options.map((option: IGendersResponse, index: number) => (
					<div
						key={option.id}
						className={styles.dropdown_select__options__item}
						onClick={() => optionClickHandler(option.id)}
					>
						{option.genderName}
					</div>
				))}
			</div>
		</div>
	);
};

export default GenderSelectSmartGroup;
