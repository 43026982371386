import React, { FC } from 'react';
import PencilSvg from 'assets/image/pencil-light.svg';
import styles from './ButtonPencil.module.scss';

type ButtonPencilProps = {
	clickHandler: () => void;
};

const ButtonPencil: FC<ButtonPencilProps> = ({ clickHandler }: ButtonPencilProps) => {
	return (
		<button className={styles.main} onClick={clickHandler}>
			<img className={styles.icon} src={PencilSvg} alt="pencil" />
		</button>
	);
};

export default ButtonPencil;
