import React, { createRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
import { API } from 'core/API';
import {
	ORGANISATION_GENERAL_TYPES,
	SearchOrganisationByAdminResponse,
	SearchOrganizationByAdminRequest,
} from '@joc/api-gateway';
import Loader from 'shared/components/Loader';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import { Field, FieldProps } from 'formik';
import styles from './index.module.scss';
import formStyles from '../../../../components/Organization/Volunteers/VolunteersFilters/VolunteersFilters.module.scss';

enum ORGANIZATION_SELECT_FIELDS_NAMES {
	schoolId = 'schoolId',
	organizationId = 'organizationId',
}

type OrganizationSelectTypes = {
	fieldValue?: number;
	setFieldValue: (field: string, value: number) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	fieldTouched?: boolean;
	classList?: Array<string>;
	parentClassName?: string;
	isDisabled?: boolean;
};

type OrganizationSelectDropdownTypes = {
	placeholder?: string;
	fieldValue?: number;
	fieldName?: any;
	setFieldValue: (field: string, value: number) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	fieldTouched?: boolean;
	classList?: Array<string>;
	parentClassName?: string;
	isDisabled?: boolean;
	options: SearchOrganisationByAdminResponse[];
	error: boolean;
	getOrganizations: () => Promise<void>;
};

const OrganizationSelect: FC<OrganizationSelectTypes> = ({
	setFieldValue,
	setFieldTouched,
	classList,
	fieldValue,
	fieldTouched,
	parentClassName,
	isDisabled,
}: OrganizationSelectTypes) => {
	const [options, setOptions] = useState<Array<SearchOrganisationByAdminResponse>>([]);
	const [error, setError] = useState(false);

	const getOrganizations = useCallback(async () => {
		try {
			setError(false);
			const response = await (
				await API.searchOrganizationsByAdmin(SearchOrganizationByAdminRequest.fromJS({}))
			).records;
			if (response.length) return setOptions(response);
		} catch (error: any) {
			setError(true);
		}
	}, []);

	useEffect(() => {
		getOrganizations();
	}, []);

	return (
		<>
			<div className={formStyles.form__field}>
				<InputContainer isDisabledMargin>
					<label className={formStyles.label}>School:</label>
					<Field name={ORGANIZATION_SELECT_FIELDS_NAMES.schoolId}>
						{({ field }: FieldProps) => (
							<OrganizationSelectDropDown
								placeholder="Select school"
								fieldName={field.name}
								fieldValue={field.value}
								setFieldValue={setFieldValue}
								options={options.filter(
									option => option.organisationGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
								)}
								setFieldTouched={setFieldTouched}
								error={error}
								getOrganizations={getOrganizations}
							/>
						)}
					</Field>
				</InputContainer>
			</div>
			<div className={formStyles.form__field}>
				<InputContainer isDisabledMargin>
					<label className={formStyles.label}>Organization:</label>
					<Field name={ORGANIZATION_SELECT_FIELDS_NAMES.organizationId}>
						{({ field }: FieldProps) => (
							<OrganizationSelectDropDown
								placeholder="Select organization"
								fieldName={field.name}
								fieldValue={field.value}
								setFieldValue={setFieldValue}
								options={options.filter(
									option => option.organisationGeneralType === ORGANISATION_GENERAL_TYPES.OTHER
								)}
								setFieldTouched={setFieldTouched}
								error={error}
								getOrganizations={getOrganizations}
							/>
						)}
					</Field>
				</InputContainer>
			</div>
		</>
	);
};

const OrganizationSelectDropDown: FC<OrganizationSelectDropdownTypes> = ({
	placeholder = 'Select organization',
	classList,
	fieldValue,
	fieldName,
	setFieldValue,
	fieldTouched,
	setFieldTouched,
	parentClassName,
	isDisabled,
	options,
	error,
	getOrganizations,
}: OrganizationSelectDropdownTypes) => {
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const title = useMemo(() => {
		if (options.length && fieldValue)
			return options.find(option => option.id.toString() === fieldValue.toString())?.organizationName;

		return '';
	}, [fieldValue, options, fieldTouched]);

	const selectRef = useRef<HTMLDivElement>(null);

	const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

	const resetOtherField = (chosenFieldName: ORGANIZATION_SELECT_FIELDS_NAMES) => {
		setFieldValue(
			chosenFieldName === ORGANIZATION_SELECT_FIELDS_NAMES.organizationId
				? ORGANIZATION_SELECT_FIELDS_NAMES.schoolId
				: ORGANIZATION_SELECT_FIELDS_NAMES.organizationId,
			0
		);
	};

	const optionClickHandler = (id: number): void => {
		setFieldValue(fieldName, id);
		resetOtherField(fieldName);
		setIsShowOptions(false);
	};

	const handleClickOutside = (event: Event) => {
		isShowOptions && setFieldTouched && setFieldTouched(fieldName, true);
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};
	const titleClickHandler = () => {
		if (!isDisabled) {
			const valueIndex = options.findIndex(i => +i.id === fieldValue);
			const target = optionRef[valueIndex]?.current;
			if (target)
				// set timeout cause in a first time the scroll doesn't work
				setTimeout(() => {
					target.scrollIntoView();
				}, 0);
			if (isShowOptions) setFieldTouched(fieldName, true);
			setIsShowOptions(!isShowOptions);
		}
	};

	useEffect(() => {
		if (fieldValue) setFieldValue(fieldName, fieldValue);
	}, [fieldValue]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	if (error) return <div onClick={() => getOrganizations()}>Something went wrong, plese click to try again</div>;
	if (!options.length) return <Loader />;

	return (
		<div
			className={cx(styles.dropdown_select, parentClassName && parentClassName, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
				[styles.disabled]: isDisabled,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!fieldValue || fieldTouched,
				})}
				onClick={titleClickHandler}
			>
				<span className={cx({ [styles.disabled_span]: isDisabled })}>{title || placeholder}</span>
				{!isDisabled && !isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div className={styles.dropdown_select__options}>
				{options.map((option: SearchOrganisationByAdminResponse) => (
					<div
						key={option.id}
						className={styles.dropdown_select__options__item}
						onClick={() => optionClickHandler(+option.id)}
					>
						{option.organizationName}
					</div>
				))}
			</div>
		</div>
	);
};

export default OrganizationSelect;
