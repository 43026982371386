import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import styles from './TabNav.module.scss';

type TabNavPropsType = {
	children: Array<ReactNode>;
	className?: string;
};

const TabNav: FC<TabNavPropsType> = ({ children, className }: TabNavPropsType) => {
	return <div className={cx(styles.nav, className)}>{children}</div>;
};

export default TabNav;
