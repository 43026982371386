import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getChallenges, setLoadingChallenges } from '../../../../redux/challenges-service/actions';

import ChallengeModal from './ChallengeModal';
import { ChallengesTable } from './ChallengesTable';
import ChallengePopupCongrats from './ChallengePopupCongrats';
import InputSearch from '../../Inputs/InputSearch';
import ButtonDefault from '../../Buttons/ButtonsDefault';

import { ReactComponent as Plus } from 'assets/image/plus.svg';

import styles from './Challenges.module.scss';

const Challenges: FC = () => {
	const dispatch = useDispatch();

	const [isOpenChallengeModal, setIsOpenChallengeModal] = useState(false);
	const [isOpenChallengeCongratsModal, setIsOpenChallengeCongratsModal] = useState(false);

	const [isSearching, setIsSearching] = useState(false);

	const challengesSearchHandler = (value: string) => {
		setIsSearching(!!value);
		dispatch(setLoadingChallenges(true));
		dispatch(getChallenges(value));
	};

	const handleChallengeCreate = () => {
		setIsOpenChallengeModal(true);
	};

	const handleCloseChallengeModal = (successSubmitType?: boolean) => {
		setIsOpenChallengeModal(false);

		if (successSubmitType) {
			setIsOpenChallengeCongratsModal(true);
		}
	};

	useEffect(() => {
		dispatch(setLoadingChallenges(true));
		dispatch(getChallenges());
	}, []);

	return (
		<section className={styles.challenges}>
			<div className={styles.challenges__header}>
				<InputSearch placeholder="Search..." classList={['big']} changeHandler={challengesSearchHandler} />

				<ButtonDefault
					title="Create"
					classList={['primary', 'sm']}
					clickHandler={handleChallengeCreate}
					icon={<Plus style={{ width: '16px', height: '16px' }} />}
				/>
			</div>

			<ChallengesTable isSearching={isSearching} />

			<ChallengeModal isModalOpen={isOpenChallengeModal} onClose={handleCloseChallengeModal} />

			{isOpenChallengeCongratsModal ? (
				<ChallengePopupCongrats setIsShowPopup={setIsOpenChallengeCongratsModal} isNew={true} />
			) : null}
		</section>
	);
};

export default Challenges;
