import {
	GetLocationAddressByIdResponse,
	IDiplomasResponse,
	IGetLocationAddressByIdResponse,
	IOrganisationResponse,
	IRolesResponse,
	IUserResponse,
} from '@joc/api-gateway';
import { Store } from 'redux/root';

export const selectorGetUserData = (store: Store): IUserResponse | null => {
	return store.userRedux.userData;
};
export const selectorGetUserRoles = (store: Store): Array<IRolesResponse> | undefined => {
	return store.userRedux.userData?.roles;
};
// export const selectorGetUserLocation = (store: Store): GetLocationAddressByIdResponse | undefined => {
// 	return store.userRedux.userData?.address;
// };
export const selectorGetUserFullName = (store: Store): string => {
	return store.userRedux.userData?.firstName && store.userRedux.userData?.lastName
		? `${store.userRedux.userData?.firstName} ${store.userRedux.userData?.lastName}`
		: '';
};

export const selectorGetUserPosition = (store: Store): Array<string> | undefined => {
	return store.userRedux.userData?.roles?.map(i => i.roleName.toLowerCase());
};
// export const selectorGetUserImage = (store: Store): string | undefined => {
// 	if (store.userRedux.userData?.volunteerId) return store.userRedux.userData?.imagePath;
// 	return store.organizationRedux.organizationsRecords?.organizationLogoPath;
// };

export const selectorGetUserOrganizations = (store: Store): Array<IOrganisationResponse> | undefined => {
	return store.userRedux.userData?.organizations;
};

export const selectorGetUserVolunteerId = (store: Store): string | undefined => {
	return store.userRedux.userData?.volunteerId;
};

export const selectorGetUserId = (store: Store): number | undefined => {
	return store.userRedux.userData?.id ? +store.userRedux.userData?.id : undefined;
};

export const selectorGetHiddenNavbarModeState = (store: Store): boolean => {
	return store.userRedux.navbarHiddenMode;
};

export const selectorGetChatId = (store: Store): string | undefined => {
	return store.userRedux.userData?.chatId;
};

export const selectorGetUserVolunteerDiploma = (store: Store): IDiplomasResponse | undefined => {
	return store.userRedux.userData?.diploma;
};

export const selectorGetUserVolunteerPoints = (store: Store): number | undefined => {
	return store.userRedux.userData?.points;
};

export const selectorGetUserAddress = (store: Store): IGetLocationAddressByIdResponse | undefined => {
	return store.userRedux.userData?.address;
};
