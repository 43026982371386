import { FC, useState } from 'react';
import cx from 'classnames';
// type
import { LinkCopiedSuccessfully } from 'core/constants';
import { IOpportunityResponse, OPPORTUNITY_ACTIVE_STATUS, OPPORTUNITY_STATUSES } from '@joc/api-gateway';
// redux
import { connect, ConnectedProps } from 'react-redux';
import { deleteOpportunity, getAllOpposCounts, updateActiveStatus } from 'redux/opportunities-service/action';
import { setError } from 'redux/error-service/action';
// components
import PopupMain from 'components/Organization/Opportunities/OpportunityPopups/PopupMain';
import PopupUpdate from 'components/Organization/Opportunities/OpportunityPopups/PopupUpdate';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import ButtonTrash from 'shared/components/Buttons/ButtonTrash';
import ButtonPencil from 'shared/components/Buttons/ButtonPencil';
// import ButtonShare from 'shared/components/Buttons/ButtonShare';
import SnackbarSuccess from 'shared/components/Snackbars/SnackbarSuccess';
// photos
import PlaySVG from 'assets/image/play.svg';
import PauseSVG from 'assets/image/pause.svg';
// styles
import ButtonSuspending from 'shared/components/Buttons/ButtonSuspending';
import styles from './OpportunityActionsCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';

type OpportunityActionsCellParentProps = {
	opportunityData: IOpportunityResponse;
};

const OpportunityActionsCell: FC<OpportunityActionsCellProps> = ({
	opportunityData,
	deleteOpportunity,
	updateActiveStatus,
	getAllOpposCounts,
	setError,
}: OpportunityActionsCellProps) => {
	const [isShowUpdatePopup, setIsShowUpdatePopup] = useState(false);
	const [isShowConfirmation, setIsShowConfirmation] = useState(false);
	const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

	const deleteConfirmClickHandler = async () => {
		try {
			await deleteOpportunity(opportunityData.id);
			await getAllOpposCounts();
		} catch (error: any) {
			setError(error?.response?.message || error.message, error.code);
		}
	};

	const editClickHandler = () => {
		setIsShowUpdatePopup(true);
	};
	const createClickHandler = () => {
		setIsShowUpdatePopup(true);
	};

	return (
		<div className={cx(styles.actions, defaultStyles.main)}>
			<div className={styles.actions__content}>
				<ButtonPencil
					clickHandler={
						opportunityData.status === OPPORTUNITY_STATUSES.CLOSED ? createClickHandler : editClickHandler
					}
				/>
				<ButtonTrash clickHandler={() => setIsShowConfirmation(true)} />
				{/* <ShareLinks
					customSvg={ShareSvg}
					customStyles={styles.norelative}
					customLink={`${process.env.REACT_APP_MAIN_URL_DEV}${urls.feedviewLink}/${opportunityData.id}`}
					isShowPopup={isShowPopup}
					setIsShowPopup={setIsShowPopup}
					setIsOpenSnackbar={setIsOpenSnackbar}
				/> */}
				{opportunityData.opportunityActiveStatus.status !== OPPORTUNITY_ACTIVE_STATUS.SUSPENDED ? (
					<ButtonSuspending
						clickHandler={() => {
							updateActiveStatus({
								opportunityId: `${opportunityData.id}`,
								status: OPPORTUNITY_ACTIVE_STATUS.SUSPENDED,
							});
						}}
						title="suspend opportunity"
						href={PauseSVG}
					/>
				) : (
					<ButtonSuspending
						clickHandler={() => {
							updateActiveStatus({
								opportunityId: `${opportunityData.id}`,
								status: OPPORTUNITY_ACTIVE_STATUS.ACTIVE,
							});
						}}
						title="suspend opportunity"
						href={PlaySVG}
					/>
				)}
			</div>
			{isShowUpdatePopup && (
				<PopupMain setIsShowPopup={setIsShowUpdatePopup}>
					<PopupUpdate
						setIsShowUpdateOpportunityPopup={setIsShowUpdatePopup}
						opportunityData={opportunityData}
					/>
				</PopupMain>
			)}
			{isShowConfirmation && (
				<PopupConfirmation
					confirmClickHandler={deleteConfirmClickHandler}
					cancelClickHandler={() => setIsShowConfirmation(false)}
					setIsShowPopup={setIsShowConfirmation}
				/>
			)}
			{isOpenSnackbar && (
				<SnackbarSuccess
					isOpenSnackbar={isOpenSnackbar}
					setIsOpenSnackbar={setIsOpenSnackbar}
					text={LinkCopiedSuccessfully}
				/>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	deleteOpportunity,
	updateActiveStatus,
	setError,
	getAllOpposCounts,
};

const connector = connect(null, mapDispatchToProps);

type OpportunityActionsCellProps = ConnectedProps<typeof connector> & OpportunityActionsCellParentProps;

export default connector(OpportunityActionsCell);
