import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import styles from './WhiteContainer.module.scss';

type WhiteContainerProps = {
	children: ReactNode;
	title: string;
	parentClassName?: string;
};

const WhiteContainer: FC<WhiteContainerProps> = ({ children, title, parentClassName }: WhiteContainerProps) => {
	return (
		<div className={cx(styles.main, parentClassName && parentClassName)}>
			<h1 className={cx('title', styles.title)}>{title}</h1>
			{children}
		</div>
	);
};

export default WhiteContainer;
