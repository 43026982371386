import { RESET_ERROR, SET_ERROR } from 'redux/actions-types';
import { ErrorActionTypes, ErrorPayload, IErrorReducer } from './types';

const initState: IErrorReducer = {
	error: { state: false, message: '', code: 0 },
};

const errorReducer = (state = initState, action: ErrorActionTypes): IErrorReducer => {
	const { type, payload }: ErrorActionTypes = action;

	switch (type) {
		case SET_ERROR: {
			return {
				error: {
					state: (payload as ErrorPayload).state,
					message: (payload as ErrorPayload).message,
					code: (payload as ErrorPayload).code,
				},
			};
		}
		case RESET_ERROR: {
			return initState;
		}
		default:
			return state;
	}
};

export default errorReducer;
