import React, { FC } from 'react';
import ShareSvg from 'assets/image/share-light.svg';
import styles from './ButtonShare.module.scss';

type ButtonShareProps = {
	clickHandler: () => void;
};

const ButtonShare: FC<ButtonShareProps> = ({ clickHandler }: ButtonShareProps) => {
	return (
		<button className={styles.main} onClick={clickHandler} aria-label="share">
			<img className={styles.icon} src={ShareSvg} alt="" title="Update invite" />
		</button>
	);
};

export default ButtonShare;
