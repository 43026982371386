import { Dispatch, FC, SetStateAction, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// redux
import { resetError } from 'redux/error-service/action';
import { Store } from 'redux/root';
import { selectorGetError } from 'redux/error-service/selector';
// components
import CongratsPopup from 'components/Organization/CongratsPopup';
import ResponseFailure from 'shared/components/ResponseFailure';
import WhiteContainer from 'shared/components/WhiteContainer';
import UserInfoForm from '../UserInfoForm';
// styles
import styles from './EditUserAccount.module.scss';

type EditUserAccountParentProps = {
	setIsShowPopup: Dispatch<SetStateAction<boolean>>;
	isDisabled?: boolean;
};

const EditUserAccount: FC<EditUserAccountProps> = ({
	error,
	resetError,
	setIsShowPopup,
	isDisabled,
}: EditUserAccountProps) => {
	const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

	const congratsDoneButtonClickHandler = () => {
		setIsUpdateSuccess(false);
		setIsShowPopup(false);
	};

	return (
		<div className={styles.container}>
			{error.state ? (
				<ResponseFailure
					message={error.message}
					buttonClickHandler={resetError}
					buttonTitle="Check and try again"
					styleTable
				/>
			) : isUpdateSuccess ? (
				<CongratsPopup
					subtitle="Volunteer's account have been updated successfully"
					doneButtonClickHandler={congratsDoneButtonClickHandler}
					isFullHeight
				/>
			) : (
				<WhiteContainer title="User info">
					<UserInfoForm setIsUpdateSuccess={setIsUpdateSuccess} isDisabled={isDisabled} />
				</WhiteContainer>
			)}
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});

const mapDispatchToProps = {
	resetError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditUserAccountProps = ConnectedProps<typeof connector> & EditUserAccountParentProps;

export default connector(EditUserAccount);
