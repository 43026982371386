import { Dispatch } from 'redux';
import { SET_PUSH_MESSAGE } from 'redux/actions-types';
import { MessagePayload } from './types';

export const setPushMessage =
	(data: MessagePayload) =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: SET_PUSH_MESSAGE,
			payload: data,
		});
	};
