import React, { FC, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
// components
import TableHead from 'shared/components/Table/TableHead';
import TableRow from 'shared/components/Table/TableRow';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableBody from 'shared/components/Table/TableBody';
// types
import {
	IBasicVolunteerResponse,
	IFullTextSearchVolunteers,
	ISearchVolunteersSort,
	SEARCH_VOLUNTEER_POSSIBLE_SEARCH,
	SEARCH_VOLUNTEER_POSSIBLE_SORT,
	SortDirection,
} from '@joc/api-gateway';
// core
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES, INITIAL_PAGINATION } from 'core/constants';
import {
	calculateUserAge,
	convertTimeFromSecondsToHours,
	generateGenderName,
	generateLocation,
	setSearchOrSortQueryString,
} from 'core/functions';
// styles
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import CheckboxVolunteerCell from 'shared/components/Table/CellRenderers/CheckboxVolunteerCell';
import qs from 'qs';
import TableMenu from 'components/Organization/TableMenu';
import { Store } from 'redux/root';
import { selectorGetVolunteersAllRecords, selectorGetVolunteersTotal } from 'redux/volunteer-service/selector';
import { selectorGetError } from 'redux/error-service/selector';
import { connect, ConnectedProps } from 'react-redux';
import TableHeadCheckboxVolunteerCell from 'shared/components/Table/TableHead/TableHeadCell/TableHeadCheckboxVolunteerCell';
import ResponseFailure from 'shared/components/ResponseFailure';
import { VolunteersPageContext, VolunteersPageContextType } from 'pages/Organization/Volunteers';
import { resetVolunteers } from 'redux/volunteer-service/actions';
import { resetError } from 'redux/error-service/action';
import { useHistory } from 'react-router';
import Loader from 'shared/components/Loader';
import Observer from 'shared/components/Observer';
import Actions from '../Actions';
import { GroupType } from '../../..';
import styles from './index.module.scss';

const errorMessage = 'We couldn`t find the volunteers you were looking for this time.';

type SelectableVolunteersTableParentProps = {
	setIsShowSelectableVolunteersTable: React.Dispatch<React.SetStateAction<boolean>>;
	checkedVolunteersId: Array<number>;
	setCheckedVolunteersId: React.Dispatch<React.SetStateAction<Array<number>>>;
	filterId: number;
	filterTypes: Array<GroupType>;
	setFilterTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
};

const SelectableVolunteersTable: FC<SelectableVolunteersTableProps> = ({
	setIsShowSelectableVolunteersTable,
	checkedVolunteersId,
	setCheckedVolunteersId,
	filterId,
	filterTypes,
	setFilterTypes,
	volunteersRecords,
	volunteersTotal,
	error,
	resetError,
	resetVolunteers,
}: SelectableVolunteersTableProps) => {
	const history = useHistory();
	const [isCloseTable, setIsCloseTable] = useState(false);
	const [currentCheckedVolunteersId, setCurrentCheckedVolunteersId] = useState<Array<number>>(checkedVolunteersId);

	const { isLoading, sort, setPagination, changeVisibilityFilters }: VolunteersPageContextType =
		useContext(VolunteersPageContext);

	const setCheckedVolunteers = (checked: boolean, value?: number) =>
		checked && value
			? setCurrentCheckedVolunteersId([...currentCheckedVolunteersId, value])
			: setCurrentCheckedVolunteersId(currentCheckedVolunteersId.filter(id => id !== value));

	const closeTableClickHandler = () => {
		setIsCloseTable(true);
		setTimeout(() => setIsShowSelectableVolunteersTable(false), 200);
	};

	const selectAllVolunteers = () => {
		currentCheckedVolunteersId.length < volunteersRecords.length
			? setCurrentCheckedVolunteersId(volunteersRecords.map(i => +i.id))
			: setCurrentCheckedVolunteersId([]);
	};

	const searchVolunteersChangeHandler = (value: string): void => {
		const fullTextSearchParams: IFullTextSearchVolunteers = {
			value,
			fields: Object.values(SEARCH_VOLUNTEER_POSSIBLE_SEARCH),
		};
		if (fullTextSearchParams.value.length) {
			appendQueryString(fullTextSearchParams, 'fullTextSearch');
		} else {
			const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
			delete searchParams.fullTextSearch;
			history.push({ search: qs.stringify(searchParams) });
		}
	};

	const appendQueryString = (
		newQueryParams: ISearchVolunteersSort | IFullTextSearchVolunteers,
		searchBy: string
	): void => {
		const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
		history.push({ search: searchQuery });
	};

	const buttonFailureClickHandler = async () => {
		history.push({ search: '' });
	};

	useEffect(() => {
		resetVolunteers();
		setPagination(INITIAL_PAGINATION);
		// const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
		// setRequestBody(queryParams);
		disableBodyScroll(document.body, { reserveScrollBarGap: true });
		return () => {
			clearAllBodyScrollLocks();
			resetVolunteers();
		};
	}, []);

	return (
		<div
			className={cx(styles.container, {
				[styles.unvisible]: isCloseTable,
			})}
		>
			<div className={styles.table}>
				<h2 className="title">Select the volunteers you’d like to include</h2>
				<TableMenu
					changeVisibilityFilters={changeVisibilityFilters}
					searchChangeHandler={searchVolunteersChangeHandler}
				>
					<Actions
						filterId={filterId}
						filterTypes={filterTypes}
						setFilterTypes={setFilterTypes}
						closeTableClickHandler={closeTableClickHandler}
						checkedVolunteersId={checkedVolunteersId}
						currentCheckedVolunteersId={currentCheckedVolunteersId}
						setCheckedVolunteersId={setCheckedVolunteersId}
					/>
				</TableMenu>
				{!isLoading && error.state ? (
					<ResponseFailure
						styleTable
						message={error.message || 'We couldn`t find the volunteers you were looking for this time.'}
						buttonClickHandler={() => {
							setPagination(INITIAL_PAGINATION);
							resetError();
						}}
						buttonTitle="Get all"
					/>
				) : !volunteersRecords.length && !isLoading ? (
					<ResponseFailure
						styleTable
						message={errorMessage}
						buttonTitle="Get all"
						buttonClickHandler={buttonFailureClickHandler}
					/>
				) : !volunteersRecords.length && isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<TableMain>
						<TableHead className={styles.table__header}>
							<TableHeadCheckboxVolunteerCell
								clickHandler={selectAllVolunteers}
								isChecked={
									!!currentCheckedVolunteersId?.length &&
									currentCheckedVolunteersId.length === volunteersTotal
								}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.avatar}
								text={TABLE_HEADERS.avatar}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
								text={TABLE_HEADERS.fullName}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.FirstName,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.email}
								text={TABLE_HEADERS.email}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Email,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.phone}
								text={TABLE_HEADERS.phoneNumber}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.PhoneNumber,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.age}
								text={TABLE_HEADERS.age}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.BirthDate,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.gender}
								text={TABLE_HEADERS.gender}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Gender,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.location}
								text={TABLE_HEADERS.location}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.forms}
								text={TABLE_HEADERS.forms}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.totalTimeSpent}
								text={TABLE_HEADERS.totalTimeSpent}
								isSortDisabled
							/>
						</TableHead>
						<TableBody>
							{volunteersRecords.map((volunteer: IBasicVolunteerResponse) => (
								<TableRow key={volunteer.id} className={styles.table__row}>
									<CheckboxVolunteerCell
										volunteerId={+volunteer.id}
										changeHandle={setCheckedVolunteers}
										defaultChecked={
											!!currentCheckedVolunteersId?.length &&
											currentCheckedVolunteersId.includes(+volunteer.id)
										}
									/>
									<AvatarCell imagePath={volunteer.imagePath} />
									<FullNameCell
										firstName={volunteer.firstName}
										lastName={volunteer.lastName}
										createDate={volunteer.createDate}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.email}
										text={volunteer.email || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.phone}
										text={volunteer.phoneNumber || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.age}
										text={calculateUserAge(volunteer.birthDate) || 'No data'}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.gender}
										text={generateGenderName(volunteer.gender?.genderName)}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.location}
										text={generateLocation(volunteer.address)}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.forms}
										// TODO: be should implement this
										text="Empty data"
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.totalTimeSpent}
										text={convertTimeFromSecondsToHours(volunteer.spendTime)}
									/>
								</TableRow>
							))}
						</TableBody>
					</TableMain>
				)}
				{!!volunteersRecords.length && volunteersRecords.length < volunteersTotal && !error.state && (
					<Observer
						paginationSkip={volunteersRecords.length}
						setPagination={setPagination}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	volunteersRecords: selectorGetVolunteersAllRecords(store),
	volunteersTotal: selectorGetVolunteersTotal(store),
	error: selectorGetError(store),
});

const mapDispatchToProps = {
	resetError,
	resetVolunteers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SelectableVolunteersTableProps = ConnectedProps<typeof connector> & SelectableVolunteersTableParentProps;

export default connector(SelectableVolunteersTable);
