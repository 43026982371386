import React, { FC, useEffect, useRef } from 'react';
import { FieldInputProps } from 'formik';
import s from './index.module.scss';

type InputTextAreaProps = {
	placeholder?: string;
	field: FieldInputProps<string>;
	id?: string;
	setFieldValue: (field: string, value: string) => void;
};

let timeout: NodeJS.Timeout;

const InputTextArea: FC<InputTextAreaProps> = ({ placeholder = '', field, id, setFieldValue }: InputTextAreaProps) => {
	const ref = useRef<HTMLTextAreaElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		if (!!field.value && !!field && !!setFieldValue) {
			window.clearTimeout(timeout);
			timeout = setTimeout(() => {
				setFieldValue(field.name, field.value.trim());
			}, 700);
		}
	}, [field.value]);

	useEffect(() => {
		const stringWithoutInitialHtml = field.value.replaceAll(/<[^>]*>/g, '');
		const stringWithParsedUserHtml = stringWithoutInitialHtml.replaceAll('&lt;', '<').replaceAll('&gt;', '>');

		setFieldValue(field.name, stringWithParsedUserHtml);
	}, []);

	return (
		<div className={s.input__textarea}>
			<textarea id={id && id} ref={ref} placeholder={placeholder} {...field} />
			{!!field.value && <div className={s.input__textarea_edit} onClick={setFocus} />}
		</div>
	);
};

export default InputTextArea;
