import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import PopupVolunteers from 'components/Organization/Opportunities/OpportunityPopups/PopupVolunteers';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';
import { IGetVolunteerByIdResponse, OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import { setUserPhotoSrc } from 'core/functions';
import styles from './VolunteerRenderer.module.scss';

type LotsVolunteersPropsType = {
	volunteersArray: Array<IGetVolunteerByIdResponse>;
	isOptionsDisable?: boolean;
	opportunityId: number;
	opportunityName: string;
	opportunityStatus: OPPORTUNITY_STATUSES;
	setIsPreviewOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	opportunityType: OPPORTUNITY_TYPES;
	isViewVolunteersDisable?: boolean;
};

const LotsVolunteers: FC<LotsVolunteersPropsType> = ({
	volunteersArray,
	isOptionsDisable,
	opportunityId,
	opportunityName,
	setIsPreviewOpen,
	opportunityStatus,
	opportunityType,
	isViewVolunteersDisable,
}: LotsVolunteersPropsType) => {
	const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
	const [countVolunteersPreview] = useState(3);

	useEffect(() => {
		if (setIsPreviewOpen) setIsPreviewOpen(isShowPopup);
	}, [isShowPopup]);

	return (
		<div className={styles.volunteer}>
			<div className={styles.volunteer__info}>
				{!!volunteersArray?.length && (
					<div
						className={cx(
							styles.volunteer__info__photos,
							volunteersArray.slice(0, countVolunteersPreview).length < countVolunteersPreview &&
								styles.two_photos
						)}
					>
						{volunteersArray.slice(0, countVolunteersPreview).map(volunteer => (
							<img
								key={volunteer.id}
								className={styles.volunteer__info__photo}
								src={setUserPhotoSrc(volunteer.imagePath)}
								alt="avatar"
							/>
						))}
						{volunteersArray.length > countVolunteersPreview && (
							<span className={styles.volunteer__info__counter}>
								+{volunteersArray.length - countVolunteersPreview}
							</span>
						)}
					</div>
				)}
			</div>

			{!isViewVolunteersDisable && (
				<div className={styles.volunteer__options}>
					<ButtonOutlined title="View more" clickHandler={() => setIsShowPopup(true)} />
				</div>
			)}
			{isShowPopup && (
				<PopupVolunteers
					setIsShowPopup={setIsShowPopup}
					opportunityId={opportunityId}
					volunteersArray={volunteersArray}
					opportunityName={opportunityName}
					opportunityStatus={opportunityStatus}
					opportunityType={opportunityType}
				/>
			)}
		</div>
	);
};

export default LotsVolunteers;
