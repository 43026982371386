import * as Yup from 'yup';
import { defaultFileMimeTypes } from '../../../../shared/components/BroadcastInputTextPopup/helpers';

export const MAX_TITLE_LENGTH = 150;
export const MAX_DESCRIPTION_LENGTH = 300;

const nameSchema = Yup.string()
	.min(2, 'Placeholder name should be more than 2 characters')
	.max(MAX_TITLE_LENGTH, `Placeholder name must be at most ${MAX_TITLE_LENGTH} characters`)
	.required('Placeholder name is required');

const descriptionSchema = Yup.string()
	.min(2, 'Description should be more than 2 characters')
	.max(MAX_DESCRIPTION_LENGTH, `Description must be at most ${MAX_DESCRIPTION_LENGTH} characters`)
	.required('Description is required');

export const placeholderValidation = Yup.object().shape({
	name: nameSchema,
	description: descriptionSchema,
	imagePath: Yup.string().nullable(),
});

export const defaultPhotoTypes = 'image/jpeg, image/jpg, image/png, image/bmp';

export const getIsUploadedPhotoValid = (photo: File, maxMegabyteSize = 5): boolean => {
	const MAX_PHOTO_SIZE_IN_MEGABYTES = maxMegabyteSize;
	const megabyteInBytes = 1024 * 1024;
	const photoSizeInMegabytes = photo.size / megabyteInBytes;

	return defaultFileMimeTypes.includes(photo.type) && photoSizeInMegabytes < MAX_PHOTO_SIZE_IN_MEGABYTES;
};
