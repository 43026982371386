import React, { FC, useEffect, useMemo, useState } from 'react';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import styles from '../CreateFilterForm.module.scss';
import AgeFieldSelect from './AgeFieldSelect';
import { GroupType } from '..';

type AgeFieldPropsType = {
	filterId: number;
	filterTypes: Array<GroupType>;
	setFilterTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
};

const ageTemplate = new Array(61).fill(0).map((item, index) => index + 15);

const AgeField: FC<AgeFieldPropsType> = ({ filterId, filterTypes, setFilterTypes }: AgeFieldPropsType) => {
	const [ageField, setAgeField] = useState({ id: 0, min: 0, max: 0 });
	const minAgeTemplate = useMemo(() => {
		if (ageField.max) return ageTemplate.filter(age => age <= ageField.max);
		return ageTemplate;
	}, [ageField.max]);
	const maxAgeTemplate = useMemo(() => {
		if (ageField.min) return ageTemplate.filter(age => age >= ageField.min);
		return ageTemplate;
	}, [ageField.min]);

	useEffect(() => {
		if (ageField.min && ageField.max) {
			const newFilterValue = filterTypes.map(i => {
				if (i.id === filterId) return { ...i, data: [{ min: ageField.min, max: ageField.max }] };
				return i;
			});
			setFilterTypes(newFilterValue);
		}
	}, [ageField]);

	return (
		<div className={styles.form__age}>
			<InputContainer>
				<label className={styles.label} htmlFor="min">
					Age group
				</label>
				<AgeFieldSelect
					placeholder="Min age"
					options={minAgeTemplate}
					ageFieldValue={ageField.min}
					setAgeField={(age: number) => setAgeField({ ...ageField, min: age })}
					classList={['option_center']}
				/>
			</InputContainer>
			<span>To</span>
			<InputContainer>
				<label className={styles.label__unvisible} htmlFor="max">
					Age group
				</label>
				<AgeFieldSelect
					placeholder="Max age"
					options={maxAgeTemplate}
					ageFieldValue={ageField.max}
					setAgeField={(age: number) => setAgeField({ ...ageField, max: age })}
					classList={['option_center']}
				/>
			</InputContainer>
		</div>
	);
};

export default AgeField;
