import React, { FC, useState } from 'react';
import VolunteerPastOpportunitiesPopup from 'components/Organization/Volunteers/VolunteersPopups/VolunteerPastOpportunitiesPopup';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';
import styles from './PastOpportunitiesCell.module.scss';

type PastOpportunitiesCellProps = {
	volunteerId: number;
	volunteerFullName: string;
};

const PastOpportunitiesCell: FC<PastOpportunitiesCellProps> = ({
	volunteerId,
	volunteerFullName,
}: PastOpportunitiesCellProps) => {
	const [isShowPastOpportunitiesPopup, setIsShowPastOpportunitiesPopup] = useState(false);

	return (
		<div className={styles.main}>
			<ButtonOutlined title="Past opportunities" clickHandler={() => setIsShowPastOpportunitiesPopup(true)} />
			{isShowPastOpportunitiesPopup && (
				<VolunteerPastOpportunitiesPopup
					volunteerId={volunteerId}
					setIsShowPopup={setIsShowPastOpportunitiesPopup}
					volunteerFullName={volunteerFullName}
				/>
			)}
		</div>
	);
};

export default PastOpportunitiesCell;
