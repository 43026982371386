import React, { FC, useEffect, useState } from 'react';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import styles from '../CreateFilterForm.module.scss';
import GenderSelectSmartGroup from './GenderSelectSmartGroup/GenderSelectSmartGroup';
import { GroupType } from '..';

type GenderFieldPropsType = {
	filterId: number;
	filterTypes: Array<GroupType>;
	setFilterTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
};

const GenderField: FC<GenderFieldPropsType> = ({ filterId, filterTypes, setFilterTypes }: GenderFieldPropsType) => {
	const [genderId, setGenderId] = useState<number>(0);

	useEffect(() => {
		if (genderId) {
			const newFilterTypes = filterTypes.map(i => {
				if (i.id === filterId) return { ...i, data: { genderId } };
				return i;
			});
			setFilterTypes(newFilterTypes);
		}
	}, [genderId]);

	return (
		<div className={styles.form__gender}>
			<InputContainer>
				<GenderSelectSmartGroup genderId={genderId} setGenderId={setGenderId} placeholder="Select the gender" />
				{/* <InputErrorHint errorText={errors.gender} /> */}
			</InputContainer>
		</div>
	);
};

export default GenderField;
