import { INITIAL_VOLUNTEERS_FILTERS_VALUES } from 'core/constants';
import { ORGANIZATION_TYPES } from 'core/types/ORGANIZATION_TYPES';

export const deserializeQueryParamsVolunteersFilters = (queryParams: qs.ParsedQs) => {
	const organizationType = (queryParams.organizationType as ORGANIZATION_TYPES)
		? (queryParams.organizationType as ORGANIZATION_TYPES)
		: INITIAL_VOLUNTEERS_FILTERS_VALUES.organizationType;

	const requestBody = {
		minAge:
			Object.keys(queryParams).includes('ages') &&
			!!queryParams.ages?.length &&
			((queryParams.ages as Array<any>)[0].min as string)
				? +((queryParams.ages as Array<any>)[0].min as string)
				: INITIAL_VOLUNTEERS_FILTERS_VALUES.minAge,
		maxAge:
			Object.keys(queryParams).includes('ages') &&
			!!queryParams.ages?.length &&
			((queryParams.ages as Array<any>)[0].max as string)
				? +((queryParams.ages as Array<any>)[0].max as string)
				: INITIAL_VOLUNTEERS_FILTERS_VALUES.maxAge,
		genderId: (queryParams.genderId as string)
			? +(queryParams.genderId as string)
			: INITIAL_VOLUNTEERS_FILTERS_VALUES.genderId,
		howFar: (queryParams.howFar as string)
			? +(queryParams.howFar as string)
			: INITIAL_VOLUNTEERS_FILTERS_VALUES.howFar,
		organizationId:
			(queryParams.organizationId as string) && organizationType === ORGANIZATION_TYPES.organization
				? +(queryParams.organizationId as string)
				: INITIAL_VOLUNTEERS_FILTERS_VALUES.organizationId,
		schoolId:
			(queryParams.organizationId as string) && organizationType === ORGANIZATION_TYPES.school
				? +(queryParams.organizationId as string)
				: INITIAL_VOLUNTEERS_FILTERS_VALUES.organizationId,
		organizationType,
	};

	return requestBody;
};
