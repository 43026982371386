import React, { FC, ReactNode, Dispatch, SetStateAction, useEffect, useState } from 'react';
import qs from 'qs';
// types
import { useLocation } from 'react-router';
// componens
import InputSearch from 'shared/components/Inputs/InputSearch';
import cx from 'classnames';
import FilterSvg from 'assets/image/filter.svg';
// styles
import styles from './TableMenuWithFilter.module.scss';

type TableMenuWithFilterProps = {
	changeVisibilityFilters?: () => void;
	children?: ReactNode;
	searchChangeHandler: (value: string) => void;
	isClearSearch?: boolean;
	setIsClearSearch?: Dispatch<SetStateAction<boolean>>;
};

const TableMenuWithFilter: FC<TableMenuWithFilterProps> = ({
	changeVisibilityFilters,
	children,
	searchChangeHandler,
	isClearSearch,
	setIsClearSearch,
}: TableMenuWithFilterProps) => {
	const location = useLocation();
	const [isDisableFilters, setIsDisableFilters] = useState(false);

	useEffect(() => {
		const queryParams = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		});
		queryParams?.groupId ? setIsDisableFilters(true) : setIsDisableFilters(false);
	}, [location.search]);

	return (
		<div className={styles.container}>
			<div className={styles.filter}>
				<InputSearch
					placeholder="Search..."
					classList={['big']}
					changeHandler={searchChangeHandler}
					isClearSearch={isClearSearch}
					setIsClearSearch={setIsClearSearch}
				/>
				<div
					className={cx(styles.filter__image, isDisableFilters && styles.disabled)}
					onClick={changeVisibilityFilters}
				>
					<img src={FilterSvg} alt="filter" />
				</div>
			</div>
			{children && children}
		</div>
	);
};

export default TableMenuWithFilter;
