import { Dispatch } from 'redux';
import { API } from 'core/API';
import {
	DELETE_USER,
	GET_COMPANIES_USERS_ADMIN,
	GET_COMPANIES_USERS_ALL,
	GET_COMPANIES_USERS_COORDINATORS,
	GET_COMPANIES_USERS_MARKETERS,
	RESET_COMPANIES_USERS,
	SET_ERROR,
	SET_USER_POSITION,
	SET_USER_ROLE,
	// UNSET_USER_ROLE,
	UPDATE_USER_DATA,
} from 'redux/actions-types';
import {
	ISearchUsersForAdminRequest,
	IUpdateUserRequest,
	SearchUsersForAdminRequest,
	UpdateUserRequest,
	UserPositionRequest,
	UserRoleRequest,
} from '@joc/api-gateway';

export const getCompaniesUsersAll =
	(body: ISearchUsersForAdminRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const getCompaniesUsersResponse = await API.userAdminSearch(SearchUsersForAdminRequest.fromJS(body));
			dispatch({
				type: GET_COMPANIES_USERS_ALL,
				payload: { records: getCompaniesUsersResponse.records, total: getCompaniesUsersResponse.total },
			});
		} catch (error: any) {
			throw error;
		}
	};

export const getCompaniesUsersAdmins =
	(body: ISearchUsersForAdminRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const getCompaniesUsersRespons = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({
					...body,
					// sort: { sortBy: 'users.firstName', sortDir: 'ASC' },
				})
			);

			const getCompaniesUsersResponsAdmin = getCompaniesUsersRespons.records.filter(item =>
				item.roles?.find(role => role.roleName === 'ADMIN')
			);
			dispatch({
				type: GET_COMPANIES_USERS_ADMIN,
				payload: { records: getCompaniesUsersResponsAdmin, total: getCompaniesUsersResponsAdmin.length },
			});
		} catch (error: any) {
			throw error;
		}
	};

export const getCompaniesUsersCoordinators =
	(body: ISearchUsersForAdminRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const getCompaniesUsersRespons = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({
					...body,
					// sort: { sortBy: 'users.firstName', sortDir: 'ASC' },
				})
			);
			const getCompaniesUsersResponsCoordinators = getCompaniesUsersRespons.records.filter(item =>
				item.roles?.find(role => role.roleName === 'COORDINATOR')
			);
			dispatch({
				type: GET_COMPANIES_USERS_COORDINATORS,
				payload: {
					records: getCompaniesUsersResponsCoordinators,
					total: getCompaniesUsersResponsCoordinators.length,
				},
			});
		} catch (error: any) {
			throw error;
		}
	};

export const getCompaniesUsersMarketers =
	(body: ISearchUsersForAdminRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const getCompaniesUsersRespons = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({
					...body,
					// sort: { sortBy: 'users.firstName', sortDir: 'ASC' },
				})
			);
			const getCompaniesUsersResponsMarketers = getCompaniesUsersRespons.records.filter(item =>
				item.roles?.find(role => role.roleName === 'MARKETER')
			);
			dispatch({
				type: GET_COMPANIES_USERS_MARKETERS,
				payload: {
					records: getCompaniesUsersResponsMarketers,
					total: getCompaniesUsersResponsMarketers.length,
				},
			});
		} catch (error: any) {
			throw error;
		}
	};

export const resetCompaniesUsers =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({ type: RESET_COMPANIES_USERS });
	};

export const setUserRole =
	(userId: number, orgId: string, roleId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.attachRole(userId, orgId, UserRoleRequest.fromJS({ roleId }));
			const userResponse = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({ userIds: [userId.toString()] })
			);
			dispatch({ type: SET_USER_ROLE, payload: userResponse.records[0] });
		} catch (error: any) {
			console.warn({ error });
		}
	};

export const unSetUserRole =
	(userId: number, orgId: string, roleId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.detachRole(userId, orgId, UserRoleRequest.fromJS({ roleId }));
			const userResponse = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({ userIds: [userId.toString()] })
			);
			// TODO: [JF-822] fix situation when user have no roles

			// console.log('userResponse.total: ', userResponse.total);
			if (userResponse.total === 1 && userResponse.records.some(el => userId.toString() === el.id)) {
				dispatch({ type: SET_USER_ROLE, payload: userResponse.records[0] });
			} else {
				dispatch({ type: DELETE_USER, payload: userId });
				throw new Error('check user response');
			}
		} catch (error: any) {
			dispatch({ type: DELETE_USER, payload: userId });
			console.warn({ error });
		}
	};

export const setUserPosition =
	(userId: number, orgId: string, positionId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.attachPosition(userId, orgId, UserPositionRequest.fromJS({ positionId }));
			const userResponse = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({ userIds: [userId.toString()] })
			);
			dispatch({ type: SET_USER_POSITION, payload: userResponse.records[0] });
		} catch (error: any) {
			console.warn({ error });
		}
	};

export const unSetUserPosotion =
	(userId: number, orgId: string, positionId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.detachPosition(userId, orgId, UserPositionRequest.fromJS({ positionId }));
			const userResponse = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({ userIds: [userId.toString()] })
			);
			// TODO: [JF-822] fix situation when user have no roles

			// dispatch({ type: SET_USER_POSITION, payload: userResponse.records[0] });

			if (userResponse.total === 1 && userResponse.records.some(el => userId.toString() === el.id)) {
				dispatch({ type: SET_USER_POSITION, payload: userResponse.records[0] });
			} else {
				dispatch({ type: DELETE_USER, payload: userId });
				throw new Error('check user response');
			}
		} catch (error: any) {
			dispatch({ type: DELETE_USER, payload: userId });
			console.warn({ error });
		}
	};

export const updateUser =
	(id: number, updateValue: IUpdateUserRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// const updateData = await API.updateUser(id, UpdateUserRequest.fromJS(updateValue));
			await API.updateUser(id, UpdateUserRequest.fromJS(updateValue));
			const updateData = await API.userAdminSearch(
				SearchUsersForAdminRequest.fromJS({ userIds: [id.toString()] })
			);
			dispatch({
				type: UPDATE_USER_DATA,
				payload: updateData.records[0],
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};
