import React, { FC } from 'react';
import CreateFilterForm from './CreateFilterForms';
import styles from './GenerateSmartGroup.module.scss';

const GenerateSmartGroup: FC = () => {
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<CreateFilterForm />
			</div>
		</div>
	);
};

export default GenerateSmartGroup;
