import { OPPORTUNITY_TYPES, WITH_WHO } from '@joc/api-gateway';
import { OpportunityInitialValuesType } from 'core/types/OPPORTUNITIES';

export const OPPORTUNITY_INITIAL_VALUES: OpportunityInitialValuesType = {
	opportunityName: '',
	opportunityDescription: '',
	isApprovalRequired: null,
	startDay: null,
	endDay: null,
	startTime: '',
	endTime: '',
	user: null,
	withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
	opportunityTypes: null,
	organisationId: '',
	address: null,
};
export const OPPORTUNITIES_INITIAL_VALUES_MANUAL: OpportunityInitialValuesType = {
	opportunityName: '',
	opportunityDescription: '',
	isApprovalRequired: false,
	startDay: null,
	endDay: null,
	startTime: '',
	endTime: '',
	user: null,
	withWho: [WITH_WHO.BABIES, WITH_WHO.OTHER, WITH_WHO.ADULTS, WITH_WHO.SENIORS, WITH_WHO.KIDS, WITH_WHO.TEENS],
	opportunityTypes: OPPORTUNITY_TYPES.MANUAL,
	organisationId: '',
	address: null,
};
export const OPPOTUNITIES_FULL_TEXT_SEARCH_FIELDS = [
	'opportunityName',
	'startTime',
	'endTime',
	'opportunityDescription',
	'user',
	'location',
];
