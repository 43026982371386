import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { ReactComponent as ThanksSvg } from 'assets/image/onboarding/thanks-for-contact.svg';
import { ReactComponent as CongratsSvg } from 'assets/image/onboarding/chesed.svg';
import { ReactComponent as PensiveSvg } from 'assets/image/onboarding/pensive.svg';
import { ReactComponent as ContactSvg } from 'assets/image/onboarding/contact.svg';
import { ReactComponent as ThinkingSvg } from 'assets/image/onboarding/thinking.svg';
import { ReactComponent as LoginVSvg } from 'assets/image/onboarding/girls.svg';
import { ReactComponent as CongratsMyChesedSvg } from 'assets/image/onboarding/congrats-my-chesed.svg';

import styles from './Appeal.module.scss';

type AppealPropsType = {
	children?: ReactNode;
	styleCongrats?: boolean;
	styleJoin?: boolean;
	stylePensive?: boolean;
	styleThink?: boolean;
	styleLoginV?: boolean;
	styleContact?: boolean;
	styleTable?: boolean;
	isHeightDisable?: boolean;
};

const Appeal: FC<AppealPropsType> = ({
	children,
	styleCongrats,
	stylePensive,
	styleThink,
	styleJoin,
	styleLoginV,
	styleContact,
	styleTable,
	isHeightDisable,
}: AppealPropsType) => {
	return (
		<div
			className={cx(styles.container, {
				[styles.congrats]: styleCongrats,
				[styles.styleTable]: styleTable,
				[styles.heightDisable]: isHeightDisable,
				[styles.paddingTopDisabled]: styleLoginV,
			})}
		>
			<div className={styles.container__gradient} />
			{styleCongrats ? (
				<CongratsSvg className={styles.container__image} />
			) : stylePensive ? (
				<PensiveSvg className={styles.container__image} />
			) : styleThink ? (
				<ThinkingSvg className={styles.container__image} />
			) : styleJoin ? (
				<CongratsMyChesedSvg className={styles.container__image} />
			) : styleLoginV ? (
				<LoginVSvg className={styles.container__image} />
			) : styleContact ? (
				<ContactSvg className={styles.container__image} />
			) : (
				<ThanksSvg className={styles.container__image} />
			)}
			{children}
		</div>
	);
};

export default Appeal;
