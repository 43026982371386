import { Dispatch } from 'redux';
import { API } from 'core/API';
import { IQuoteResponse, QuoteRequest, SearchQuotesRequest } from '@joc/api-gateway';
import { CREATE_QUOTES, DELETE_QUOTE, GET_QUOTES_ALL, SET_ERROR, UPDATE_QUOTE } from '../actions-types';
import { CreateQuotesRequest } from '@joc/api-gateway/lib/api-client';

export const getDailyQuotes =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const dailyQuotesResponse = await API.searchQuotes(SearchQuotesRequest.fromJS(searchBody));

			dispatch({
				type: GET_QUOTES_ALL,
				payload: { records: dailyQuotesResponse.records, total: dailyQuotesResponse.total },
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const createDailyQuote =
	(quotes: Array<QuoteRequest>) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response = await API.createQuotes(CreateQuotesRequest.fromJS({ dailyQuotes: quotes }));

			dispatch({ type: CREATE_QUOTES, payload: response });
		} catch (error: any) {
			throw error;
		}
	};

export const editDailyQuote =
	(id: number, quote: QuoteRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.updateQuote(id, quote);

			dispatch({ type: UPDATE_QUOTE, payload: { id, ...quote } });
		} catch (error: any) {
			throw error;
		}
	};

export const deleteDailyQuote =
	(quote: IQuoteResponse) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.deleteQuote(quote.id);

			dispatch({ type: DELETE_QUOTE, payload: quote });
		} catch (error: any) {
			throw error;
		}
	};
