import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Store } from 'redux/root';
import { INITIAL_PAGINATION } from 'core/constants';
// components
import { getInvitesAll, getInvitesJoined, getInvitesPending, resetInvites } from 'redux/invite-service/actions';
import { ISearchInvitedUserRequest } from '@joc/api-gateway';
import qs from 'qs';
import InvitesTable from './InvitesTable';
import TabNav from '../TabNav';
import TabNavItem from '../TabNav/TabNavItem';
// styles
import styles from './InvitesListContent.module.scss';

const parseQueryString = (
	search: string
	// userLocation: IGetLocationAddressByIdResponse | undefined
): ISearchInvitedUserRequest => {
	const searchParams: any = qs.parse(search, {
		ignoreQueryPrefix: true,
	});
	return searchParams;
};

enum TABS {
	ALL,
	JOINED,
	PENDING,
}

const InvitesListContent: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const initTab = () => {
		if (location.search === '') return TABS.ALL;
		if (location.search.includes('joined')) return TABS.JOINED;
		if (location.search.includes('pending')) return TABS.PENDING;
	};
	const [isLoading, setIsLoading] = useState(true);
	const [activeTab, setActiveTab] = useState(initTab);
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const [requestBody, setRequestBody] = useState<ISearchInvitedUserRequest>();

	const invitesRecordsTotal = useSelector((store: Store) => store.invitesRedux.invites.total);

	const getInvitesRequest = useCallback(async () => {
		setIsLoading(true);
		try {
			if (location.search.includes('joined')) {
				await dispatch(getInvitesJoined({ ...requestBody, pagination }));
			} else if (location.search.includes('pending')) {
				await dispatch(getInvitesPending({ ...requestBody, pagination }));
			} else {
				await dispatch(getInvitesAll({ ...requestBody, pagination }));
			}
			setIsLoading(false);
		} catch (error: any) {
			setIsLoading(false);
			console.error(error);
		}
	}, [pagination, requestBody]);

	useEffect(() => {
		if (requestBody !== undefined) getInvitesRequest();
	}, [getInvitesRequest]);

	useEffect(() => {
		dispatch(resetInvites());
		setPagination(INITIAL_PAGINATION);
		setRequestBody(parseQueryString(location.search));
	}, [location.search]);

	useEffect(() => {
		return () => {
			dispatch(resetInvites());
		};
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.content__wrapper}>
				<div className={styles.content}>
					<TabNav>
						<TabNavItem
							parentClassName={styles.tabNavItem}
							counter={invitesRecordsTotal}
							title="All"
							activeTab={activeTab === TABS.ALL}
							clickHandler={() => {
								history.push({ search: '' });
								setActiveTab(TABS.ALL);
								dispatch(resetInvites());
							}}
						/>
						<TabNavItem
							parentClassName={styles.generateGroup}
							counter={getInvitesRequest.length || 0}
							title="Joined"
							activeTab={activeTab === TABS.JOINED}
							clickHandler={() => {
								history.push({ search: '?joined' });
								setActiveTab(TABS.JOINED);
								dispatch(resetInvites());
							}}
						/>
						<TabNavItem
							parentClassName={styles.generateGroup}
							counter={getInvitesRequest.length || 0}
							title="Pending"
							activeTab={activeTab === TABS.PENDING}
							clickHandler={() => {
								history.push({ search: '?pending' });
								setActiveTab(TABS.PENDING);
								dispatch(resetInvites());
							}}
						/>
					</TabNav>
					<InvitesTable
						setPagination={setPagination}
						isLoading={isLoading}
						sort={requestBody?.sort}
						setRequestBody={setRequestBody}
					/>
				</div>
			</div>
		</div>
	);
};

export default InvitesListContent;
