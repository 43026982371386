import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import {
	IOpportunityResponse,
	ISearchOpportunitiesRequest,
	ISearchOpportunitiesSort,
	ISearchOpportunityResponse,
	OPPORTUNITY_STATUSES,
	PaginationRequest,
	SearchOpportunitiesRequest,
	SearchOpportunitiesSort,
	SearchOpportunityResponse,
	SEARCH_OPPORTUNITIES_POSSIBLE_SORT,
	SortDirection,
} from '@joc/api-gateway';
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES, INITIAL_PAGINATION } from 'core/constants';
import { API } from 'core/API';
import { convertTimeToHoursAndMinutes, formatDate, generateLocation } from 'core/functions';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import OpportunityNameCell from 'shared/components/Table/CellRenderers/OpportunityNameCell';
import TableBody from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableRow from 'shared/components/Table/TableRow';
import PopupContainer from 'shared/components/PopupContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import Loader from 'shared/components/Loader';
import Observer from 'shared/components/Observer';
import styles from './VolunteerPastOpportunitiesPopup.module.scss';

type VolunteerPastOpportunitiesParentPopupProps = {
	volunteerId: number;
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	volunteerFullName: string;
};

const VolunteerPastOpportunitiesPopup: FC<VolunteerPastOpportunitiesParentPopupProps> = ({
	volunteerId,
	setIsShowPopup,
	volunteerFullName,
}: VolunteerPastOpportunitiesParentPopupProps) => {
	const [volunteerPastOpportunities, setVolunteerPastOpportunities] = useState<
		ISearchOpportunityResponse | undefined
	>();
	const [isLoading, setIsLoading] = useState(false);
	const [customError, setCustomError] = useState('');
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);
	const [sort, setSort] = useState<ISearchOpportunitiesSort>();

	const volunteerPastOpportunitiesRequestBody: ISearchOpportunitiesRequest | null = useMemo(() => {
		if (!volunteerId) return null;
		return {
			volunteerId,
			status: [OPPORTUNITY_STATUSES.CLOSED],
			pagination: PaginationRequest.fromJS(pagination),
			sort: SearchOpportunitiesSort.fromJS(sort),
		};
	}, [pagination, volunteerId, sort]);

	const getVolunteerPastOpportunitiesByOrg = useCallback(async () => {
		setIsLoading(true);
		try {
			if (!volunteerPastOpportunitiesRequestBody) throw new Error('Sorry, we can`t find current volunteer');
			const opportunitiesResponse = await API.searchOpportunitiesByOrganisation(
				undefined,
				undefined,
				SearchOpportunitiesRequest.fromJS(volunteerPastOpportunitiesRequestBody)
			);
			setVolunteerPastOpportunities({
				total: opportunitiesResponse.total,
				records: volunteerPastOpportunities?.records
					? [...volunteerPastOpportunities.records, ...opportunitiesResponse.records]
					: [...opportunitiesResponse.records],
				pagination: SearchOpportunityResponse.fromJS(pagination),
			});
			setIsLoading(false);
		} catch (error: any) {
			setCustomError(error?.response?.message || error.message);
			setIsLoading(false);
		}
	}, [volunteerPastOpportunitiesRequestBody]);

	const setSortByField = useCallback(
		(sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT) => {
			setVolunteerPastOpportunities(undefined);
			setPagination(INITIAL_PAGINATION);
			setSort({
				sortBy,
				sortDir: sort?.sortDir === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
			});
		},
		[sort]
	);

	useEffect(() => {
		getVolunteerPastOpportunitiesByOrg();
	}, [getVolunteerPastOpportunitiesByOrg]);

	return (
		<div className={styles.main}>
			<PopupContainer setIsShowPopup={setIsShowPopup}>
				<h2 className={cx('title', styles.title)}>{`${volunteerFullName}'s past opportunities`}</h2>
				{isLoading && !volunteerPastOpportunities?.records.length ? (
					<Loader loadProps={{ stylePageCenter: true }} />
				) : !isLoading && !volunteerPastOpportunities?.records.length ? (
					<ResponseFailure message="We can`t find volunteer`s closed opportunities" buttonUnshow styleTable />
				) : (
					<TableMain>
						<TableHead callChild="oppotunitiesVolunteer">
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.chesedName}
								text={TABLE_HEADERS.chesedName}
								clickHandler={() => setSortByField(SEARCH_OPPORTUNITIES_POSSIBLE_SORT.OpportunityName)}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.date}
								text={TABLE_HEADERS.date}
								clickHandler={() => setSortByField(SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDay)}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.time}
								text={TABLE_HEADERS.start}
								clickHandler={() => setSortByField(SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartTime)}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.time}
								text={TABLE_HEADERS.end}
								clickHandler={() => setSortByField(SEARCH_OPPORTUNITIES_POSSIBLE_SORT.EndTime)}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.location}
								text={TABLE_HEADERS.location}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
								text={TABLE_HEADERS.coordinator}
								isSortDisabled
							/>
						</TableHead>
						<TableBody>
							{volunteerPastOpportunities?.records.map((opportunity: IOpportunityResponse) => {
								return (
									<TableRow key={opportunity.id} callChild="oppotunitiesVolunteer">
										<OpportunityNameCell
											opportunityName={opportunity.opportunityName}
											opportunityType={opportunity.opportunityType}
											volunteers={opportunity.volunteers}
											adminId={opportunity?.user?.id}
											isInitialOppo={opportunity.isInitial}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.date}
											text={formatDate(opportunity.startDate)}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.time}
											text={convertTimeToHoursAndMinutes(opportunity.startDate)}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.time}
											text={convertTimeToHoursAndMinutes(opportunity.endDate)}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.location}
											text={
												opportunity?.isVirtual
													? opportunity.opportunityUrl
													: generateLocation(opportunity.address)
											}
										/>
										<FullNameCell
											firstName={opportunity.user?.firstName}
											lastName={opportunity.user?.lastName}
											disableTextStyle
										/>
									</TableRow>
								);
							})}
						</TableBody>
					</TableMain>
				)}
				{volunteerPastOpportunities?.records.length === 0
					? ''
					: volunteerPastOpportunities?.records?.length &&
					  volunteerPastOpportunities.records.length !== volunteerPastOpportunities?.total &&
					  !customError.length && (
							<Observer
								paginationSkip={volunteerPastOpportunities.records.length}
								setPagination={setPagination}
								isLoading={isLoading}
							/>
					  )}
			</PopupContainer>
		</div>
	);
};

export default VolunteerPastOpportunitiesPopup;
