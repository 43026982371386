import React, { FC } from 'react';
import MainPageLayout from 'components/Organization/MainPageLayout';

import MarketingComponent from 'shared/components/MarketingComponent';

import styles from './SuperAdminMarketing.module.scss';

const SuperAdminInviteList: FC = () => {
	return (
		<MainPageLayout>
			<div className={styles.container}>
				<div className={styles.block_title}>
					<h1 className="title">Marketing</h1>
					<MarketingComponent />
				</div>
			</div>
		</MainPageLayout>
	);
};

export default SuperAdminInviteList;
