import React, { FC, useMemo, useState } from 'react';
import cx from 'classnames';

import { setWithWhoFilterVision } from 'core/functions';
import styles from './WhoItem.module.scss';

type WhoItemProps = {
	// fieldName: string;
	who: string;
	checked: boolean;
	parentClassName?: string;
};

const WhoItem: FC<WhoItemProps> = ({ who, parentClassName, checked }: WhoItemProps) => {
	const [whoVision] = useState(setWithWhoFilterVision(who));
	const placeholder = useMemo(() => {
		return `${whoVision.name} ${whoVision.age}`;
	}, [whoVision]);

	return (
		<div className={cx(styles.main, checked && styles.checked, parentClassName && parentClassName)}>
			<div className={styles.icon}>
				<div className={styles.icon__label} />
				{whoVision.src && <img className={styles.icon__image} src={whoVision.src} alt="who" />}
			</div>
			<span className={styles.placeholder}>{placeholder}</span>
		</div>
	);
};

export default WhoItem;
