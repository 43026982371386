import {
	CREATE_CHALLENGE,
	DELETE_CHALLENGE,
	GET_CHALLENGES_ALL,
	LOADING_STATUS_CHALLENGES,
	UPDATE_CHALLENGE,
} from '../actions-types';
import { ChallengesActionTypes, IChallengesPayload, IChallengesReducer } from './types';

const initState: IChallengesReducer = {
	challengesRecords: [],
	challengesTotal: 0,
	isLoading: false,
};

const challengesReducer = (state = initState, action: ChallengesActionTypes): IChallengesReducer => {
	const { type, payload }: ChallengesActionTypes = action;

	switch (type) {
		case GET_CHALLENGES_ALL: {
			return {
				...state,
				challengesRecords: (payload as IChallengesPayload).records,
				challengesTotal: (payload as IChallengesPayload).total,
			};
		}

		case CREATE_CHALLENGE: {
			return {
				...state,
				challengesRecords: [...state.challengesRecords, payload],
				challengesTotal: state.challengesTotal + 1,
			};
		}

		case UPDATE_CHALLENGE: {
			return {
				...state,
				challengesRecords: state.challengesRecords.map(challenge =>
					challenge.id !== payload.id ? challenge : payload
				),
			};
		}

		case DELETE_CHALLENGE: {
			return {
				...state,
				challengesRecords: state.challengesRecords.filter(challenge => challenge.id !== payload),
				challengesTotal: state.challengesTotal - 1,
			};
		}

		case LOADING_STATUS_CHALLENGES: {
			return {
				...state,
				isLoading: payload,
			};
		}

		default:
			return state;
	}
};

export default challengesReducer;
