import React, { FC } from 'react';
import cx from 'classnames';
import DownloadSvg from 'assets/image/download.svg';
import styles from './ButtonDownload.module.scss';

type ButtonDownloadProps = { clickHandler: () => void; disabled?: boolean };

const ButtonDownload: FC<ButtonDownloadProps> = ({ clickHandler, disabled }: ButtonDownloadProps) => {
	return (
		<button className={styles.button} onClick={clickHandler} disabled={disabled}>
			<img
				className={cx(styles.button__icon, disabled && styles.button__icon_disabled)}
				src={DownloadSvg}
				alt="download"
			/>
		</button>
	);
};

export default ButtonDownload;
