import { ChangeEvent, ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
// helpers
import {
	BroadcastEnum,
	broadcastMessage,
	defaultFileMimeTypes,
	defaultPhotoTypes,
	getIsUploadedPhotoValid,
	getUploadedPhotoInvalidError,
	MAX_PHOTOS,
} from './helpers';
// images
import ButtonCross from 'assets/image/cross-button.svg';
import { ReactComponent as SendSVG } from 'assets/image/send.svg';
import { ReactComponent as ClipSVG } from 'assets/image/clip.svg';
// components
import ButtonIcon from 'shared/components/Buttons/ButtonIcon';
import Loader from 'shared/components/Loader';
// styles
import styles from './BroadcastInputTextPopup.module.scss';

type BroadcastInputTextPopupProps = {
	closePopupHandler: () => void;
	broadcastType: BroadcastEnum;
	organizationId?: string;
};

const BroadcastInputTextPopup: FC<BroadcastInputTextPopupProps> = ({
	closePopupHandler,
	broadcastType,
	organizationId,
}) => {
	const [inputValue, setInputValue] = useState('');
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const [uploadedPhotos, setUploadedPhotos] = useState<Array<File>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [fileError, setFileError] = useState('');

	const selectRef = useRef<HTMLDivElement>(null);
	const photoInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	const handleClickOutside = (event: Event) => {
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
			closePopupHandler();
		}
	};

	const openFileUpload = () => photoInputRef.current?.click();

	const handleUploadImages = (event: ChangeEvent<HTMLInputElement>): void => {
		event.preventDefault();
		setFileError('');

		const selectedFiles = event.target.files;

		if (selectedFiles) {
			const photos = Array.from(selectedFiles)
				.slice(0, MAX_PHOTOS - uploadedPhotos.length)
				.filter(getIsUploadedPhotoValid);

			Array.from(selectedFiles).forEach(file => {
				const error = getUploadedPhotoInvalidError(file);
				if (error) {
					setFileError(error);
				}
			});

			setUploadedPhotos((prevState: Array<File>) => [...prevState, ...photos]);
		}
	};

	const removePhotoHandler = (index: number) => (): void => {
		setFileError('');
		setUploadedPhotos((prevState: Array<File>) => prevState.filter((_, previousIndex) => previousIndex !== index));
	};

	const sendClickHandler = async () => {
		setIsLoading(true);
		try {
			await broadcastMessage(broadcastType, inputValue, uploadedPhotos, organizationId);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		} finally {
			setIsLoading(false);
			closePopupHandler();
		}
	};

	const inputChangeHandler: ChangeEventHandler<HTMLTextAreaElement> = e => {
		const newInputValue = e.target.value;
		setInputValue(newInputValue);
	};

	const isInputFileDisabled = uploadedPhotos.length >= MAX_PHOTOS;
	const isButtonSendDisabled = !inputValue.trim().length;

	return (
		<div className={styles.backdrop}>
			<div className={styles.wrapper} ref={selectRef}>
				<div className={styles.main}>
					<textarea
						className={styles.textarea}
						placeholder="Type your message..."
						value={inputValue || ''}
						onChange={inputChangeHandler}
						rows={10}
					/>
				</div>
				<div
					className={cx(styles.bottom, {
						[styles.bottom_error]: fileError,
					})}
				>
					<input
						className={styles.hidden}
						type="file"
						onChange={handleUploadImages}
						ref={photoInputRef}
						accept={defaultFileMimeTypes}
						multiple
					/>
					<ButtonIcon onClick={openFileUpload} disabled={isInputFileDisabled}>
						<ClipSVG />
					</ButtonIcon>

					{uploadedPhotos.length ? (
						<div className={styles.imagePreview}>
							{uploadedPhotos.map((photo, index) => (
								<div className={styles.imagePreview__uploaded} key={photo.name + index}>
									{defaultPhotoTypes.includes(photo.type) ? (
										<img src={URL.createObjectURL(photo)} alt="" />
									) : (
										// eslint-disable-next-line jsx-a11y/media-has-caption
										<video src={URL.createObjectURL(photo)} />
									)}{' '}
									<div
										className={styles.imagePreview__uploaded__buttonRemove}
										onClick={removePhotoHandler(index)}
									>
										<img
											src={ButtonCross}
											className={styles.imagePreview__uploaded__buttonRemove__icon}
											alt=""
										/>
									</div>
								</div>
							))}
						</div>
					) : null}

					{isLoading ? (
						<Loader loadProps={{ size: 30, parentClassName: styles.loader }} />
					) : (
						<ButtonIcon
							onClick={sendClickHandler}
							disabled={isButtonSendDisabled}
							parentClassName={styles.send}
						>
							<SendSVG />
						</ButtonIcon>
					)}
				</div>
				{fileError && <span className={styles.error}>{fileError}</span>}
			</div>
		</div>
	);
};

export default BroadcastInputTextPopup;
