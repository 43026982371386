import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';

import styles from '../ChallengeForm/ChallengeForm.module.scss';
import ChesedInput from './ChesedInput';

type ChesedIdeasProps = {
	fieldValue: Array<string>;
	// eslint-disable-next-line no-unused-vars
	onChange: (value: Array<string>) => void;
};

const ChesedIdeas: FC<ChesedIdeasProps> = ({ fieldValue, onChange }) => {
	const [touched, setTouched] = useState<Map<number, boolean>>();
	const [focused, setFocused] = useState<Map<number, boolean>>();

	const onChangeHandler = (value: string, index: number) => {
		const updatedValues = [...fieldValue];
		updatedValues[index] = value;
		touched?.set(index, true);
		onChange(updatedValues);
	};

	const onFocusHandler = (index: number) => {
		focused?.set(index, true);
	};

	const onBlurHandler = (index: number) => {
		focused?.set(index, false);
	};

	const onRemoveHandler = (index: number) => {
		const updatedValues = [...fieldValue];
		updatedValues.splice(index, 1);

		onChange(updatedValues);
	};

	useEffect(() => {
		const touchedInit = new Map();
		const focusedInit = new Map();

		fieldValue.forEach((v, i) => {
			focusedInit.set(i, false);
			touchedInit.set(i, false);
		});
		setTouched(touchedInit);
		setFocused(focusedInit);
	}, []);

	return (
		<>
			<div className={cx(styles.ChallengeForm__form_item, styles.ChallengeForm__form_item__ideas)}>
				<label className={styles.ChallengeForm__form_label} htmlFor="chesedIdeas">
					Chesed ideas<span className={styles.ChallengeForm__form_required}>*</span>
				</label>

				{fieldValue.length ? (
					fieldValue.map((idea, index) => (
						<div key={index} className={styles.ChallengeForm__form_idea}>
							<ChesedInput
								value={idea}
								placeholder="Idea"
								hideRemoveIcon={!index}
								onChange={value => onChangeHandler(value, index)}
								onFocus={() => onFocusHandler(index)}
								onBlur={() => onBlurHandler(index)}
								focused={focused?.get(index)}
								onRemove={() => onRemoveHandler(index)}
								touched={touched?.get(index)}
							/>
						</div>
					))
				) : (
					<div className={styles.ChallengeForm__form_idea}>
						<ChesedInput
							onChange={value => onChangeHandler(value, 0)}
							placeholder="Idea"
							onFocus={() => onFocusHandler(0)}
							onBlur={() => onBlurHandler(0)}
							focused={focused?.get(0)}
							hideRemoveIcon={true}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default ChesedIdeas;
