import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import styles from './FiltersContainer.module.scss';
import FiltersHeader from '../FiltersHeader';

type FiltersContainerProps = {
	children: ReactNode;
	changeVisibilityFilters: () => void;
	resetForm: () => void;
	isCloseFilters: boolean;
	isOrgFilter?: boolean;
};

const FiltersContainer: FC<FiltersContainerProps> = ({
	children,
	changeVisibilityFilters,
	isCloseFilters,
	isOrgFilter,
	resetForm,
}: FiltersContainerProps) => {
	return (
		<div className={cx(styles.main, isCloseFilters && styles.close, isOrgFilter && styles.orgStyle)}>
			<div className={styles.content}>
				<FiltersHeader changeVisibilityFilters={changeVisibilityFilters} resetForm={resetForm} />
				{!isCloseFilters && <hr className={styles.separator} />}
				<div className={styles.content__filters}>{children}</div>
			</div>
		</div>
	);
};

export default FiltersContainer;
