import React, { FC, useContext, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ISearchOrganisationByAdminResponse, ORGANISATION_ACTIVE_STATUS } from '@joc/api-gateway';
import { deleteOrganization, updateActiveStatus } from 'redux/organization-service/actions';
import { OrganizationsTableContext } from 'shared/components/Table/AdminOrgTable';
// components
import EditOrg from 'pages/Organization/EditOrg';
import ButtonPencil from 'shared/components/Buttons/ButtonPencil';
import ButtonSuspending from 'shared/components/Buttons/ButtonSuspending';
import PopupContainer from 'shared/components/PopupContainer';
// import ButtonTrash from 'shared/components/Buttons/ButtonTrash';
// icons
import PlaySVG from 'assets/image/play.svg';
import PauseSVG from 'assets/image/pause.svg';
// styles
import styles from './OrgActionCell.module.scss';
import ButtonTrash from '../../../../../shared/components/Buttons/ButtonTrash';
import PopupConfirmation from '../../../../../shared/components/PopupConfirmation';

const OrgActionCell: FC<OrgActionCellProps & { organizationId: string }> = ({
	updateActiveStatus,
	deleteOrganization,
	organizationId,
}) => {
	const [isShowPopup, setIsShowPopup] = useState(false);
	const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);

	const organizationData: ISearchOrganisationByAdminResponse = useContext(OrganizationsTableContext);

	const deleteConfirmClickHandler = async () => {
		try {
			await deleteOrganization(Number(organizationId));
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div>
			<div className={styles.actions__content}>
				<ButtonPencil
					clickHandler={
						() => setIsShowPopup(true)
						// opportunityData.status === OPPORTUNITY_STATUSES.CLOSED ? createClickHandler : editClickHandler
					}
				/>
				<ButtonTrash clickHandler={() => setIsShowDeleteConfirmation(true)} />

				{organizationData.organizationActiveStatus.status !== ORGANISATION_ACTIVE_STATUS.SUSPENDED ? (
					<ButtonSuspending
						clickHandler={() => {
							updateActiveStatus({
								organisationId: organizationData.id,
								status: ORGANISATION_ACTIVE_STATUS.SUSPENDED,
							});
						}}
						title="suspend organization"
						href={PauseSVG}
					/>
				) : (
					<ButtonSuspending
						clickHandler={() => {
							updateActiveStatus({
								organisationId: organizationData.id,
								status: ORGANISATION_ACTIVE_STATUS.ACTIVE,
							});
						}}
						title="suspend organization"
						href={PlaySVG}
					/>
				)}
				{/* <ButtonTrash
					clickHandler={
						// () => setIsShowConfirmation(true)
					}
				/> */}
			</div>
			{isShowPopup && (
				<PopupContainer setIsShowPopup={setIsShowPopup}>
					<EditOrg setIsShowPopup={setIsShowPopup} />
				</PopupContainer>
			)}
			{isShowDeleteConfirmation && (
				<PopupConfirmation
					confirmClickHandler={deleteConfirmClickHandler}
					cancelClickHandler={() => setIsShowDeleteConfirmation(false)}
					setIsShowPopup={setIsShowDeleteConfirmation}
				/>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	updateActiveStatus,
	deleteOrganization,
};

const connector = connect(null, mapDispatchToProps);

type OrgActionCellProps = ConnectedProps<typeof connector>;

export default connector(OrgActionCell);
