import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setPushMessage } from 'redux/push-service/action';
import { selectorGetPushMessage } from 'redux/push-service/selector';
import { Store } from 'redux/root';

const SnackbarInfo: FC<SnackbarInfoProps> = ({ text, setPushMessage }: SnackbarInfoProps) => {
	if (!text.title) return null;

	return (
		<Snackbar open={!!text.title} autoHideDuration={10000} onClose={() => setPushMessage({ title: '', body: '' })}>
			<Alert onClose={() => setPushMessage({ title: '', body: '' })} severity="info">
				{text.title}
				<br />
				{text.body}
			</Alert>
		</Snackbar>
	);
};

// export default SnackbarInfo;
const mapStateToProps = (store: Store) => ({
	text: selectorGetPushMessage(store),
});
const mapDispatchToProps = {
	setPushMessage,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type SnackbarInfoProps = ConnectedProps<typeof connector>;
export default connector(SnackbarInfo);
