import { useEffect, useRef, useState } from 'react';
import { DefaultStreamChatGenerics, useChatContext } from 'stream-chat-react';
//hooks
import { useDebounceValue } from 'core/customHooks';
//types
import { OwnUserResponse } from 'stream-chat/dist/types/types';
import { SearchOrganizationRequest, SearchVolunteersRequest } from '@joc/api-gateway/lib/api-client';
//API
import { API } from 'core/API';
//components
import { ChannelProps, ChannelSearchResult } from './ChatSearchResult';
import { ChatSearchJocApiResult } from './ChatSearchJocApiResult';
import InputSearch from 'shared/components/Inputs/InputSearch';
//styles
import './ChatSearch.global.scss';

type ChannelsSearchResponse = Array<ChannelProps>;
interface CustomUserResponseType extends OwnUserResponse {
	type?: string;
}

export type UsersResponseType = {
	id?: string;
	name?: string;
	imagePath?: string;
};

const ChatSearch = () => {
	const { client, setActiveChannel } = useChatContext<DefaultStreamChatGenerics>();

	const [focusedUser] = useState<number>();
	const [searchQuery, setSearchQuery] = useState('');
	const [channels, setChannels] = useState<ChannelsSearchResponse>([]);
	const [userResponse, setResponse] = useState<UsersResponseType[]>();
	const [isFetching, setIsFetching] = useState(false);
	const [pagination] = useState({ skip: 0, take: 6 });

	const inputRef = useRef<HTMLInputElement>(null);

	const findUsers = async (queryValue: string) => {
		setIsFetching(true);
		try {
			const channels = await client.queryChannels({
				name: { $autocomplete: queryValue },
				members: { $in: [client?.user?.id!] },
			});
			setChannels(channels);
			if ((client?.user as CustomUserResponseType).type === 'volunteer') {
				const response = await API.findOrganisation(
					SearchOrganizationRequest.fromJS({
						pagination,
						fullTextSearch: { value: queryValue, fields: ['organizationName'] },
					})
				);

				const userList: UsersResponseType[] = response.records.map(el => {
					const { chatId, organizationName, organizationLogoPath } = el;
					return { id: chatId, name: organizationName, imagePath: organizationLogoPath };
				});
				setResponse(userList);
			} else if ((client?.user as CustomUserResponseType).type === 'organisation') {
				const response = await API.searchVolunteers(
					undefined,
					SearchVolunteersRequest.fromJS({
						pagination,
						fullTextSearch: { value: queryValue, fields: ['firstName', 'lastName'] },
					})
				);

				const userList: UsersResponseType[] = response.records.map(el => {
					const { chatId, firstName, lastName, imagePath } = el;
					return { id: chatId, name: `${firstName} ${lastName}`, imagePath };
				});
				setResponse(userList);
			} else {
				const response1 = await API.searchVolunteers(
					undefined,
					SearchVolunteersRequest.fromJS({
						pagination,
						fullTextSearch: { value: queryValue, fields: ['firstName', 'lastName'] },
					})
				);
				const response2 = await API.findOrganisation(
					SearchOrganizationRequest.fromJS({
						pagination,
						fullTextSearch: { value: queryValue, fields: ['organizationName'] },
					})
				);

				const userList: UsersResponseType[] = [
					...response1.records.map(el => {
						const { chatId, firstName, lastName, imagePath } = el;
						return { id: chatId, name: `${firstName} ${lastName}`, imagePath };
					}),
					...response2.records.map(el => {
						const { chatId, organizationName, organizationLogoPath } = el;
						return { id: chatId, name: organizationName, imagePath: organizationLogoPath };
					}),
				];
				setResponse(userList);
			}
			setIsFetching(false);
		} catch (error: any) {
			setIsFetching(false);
			console.error({ error });
		}
	};

	const debounceSearchQuery = useDebounceValue(searchQuery);

	useEffect(() => {
		if (debounceSearchQuery) {
			findUsers(debounceSearchQuery);
		}
	}, [debounceSearchQuery]);

	return (
		<>
			<InputSearch autoFocus placeholder="Search" value={searchQuery} changeHandler={setSearchQuery} />
			{debounceSearchQuery && (
				<div className="messaging-create-channel__user-results">
					{!!channels?.length || !!userResponse?.length ? (
						<div>
							{channels.map((channel, i) => (
								<div
									className={`messaging-create-channel__user-result ${
										focusedUser === i && 'focused'
									}`}
									key={channel.cid}
								>
									<ChannelSearchResult channel={channel} setActiveChannel={setActiveChannel} />
								</div>
							))}
							{userResponse?.map((user, i) => (
								<div
									className={`messaging-create-channel__user-result ${
										focusedUser === i && 'focused'
									}`}
									key={user.id}
								>
									<ChatSearchJocApiResult
										member={user}
										userId={client.user?.id as string}
										setActiveChannel={setActiveChannel}
									/>
								</div>
							))}
						</div>
					) : (
						!isFetching && (
							<div
								onClick={() => {
									inputRef.current?.focus();
								}}
								className="messaging-create-channel__user-result empty"
							>
								No people found...
							</div>
						)
					)}
				</div>
			)}
		</>
	);
};

export default ChatSearch;
