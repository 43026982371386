import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import WhiteContainer from 'shared/components/WhiteContainer';
import { Field, FieldProps, Form, Formik } from 'formik';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import InputText from 'shared/components/FormInputs/InputText';
import InputErrorHint from 'shared/components/InputErrorHint';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import RolesSelect from 'shared/components/Selects/SelectDropdown/RolesSelect';
import Loader from 'shared/components/Loader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import { IInvitedUserResponse } from '@joc/api-gateway';
import InputNumber from 'shared/components/Inputs/InputNumber';
import GradeSelect from 'shared/components/Selects/SelectDropdown/GradeSelect';
import { updateInvite } from 'redux/invite-service/actions';
import styles from './InviteUpdatePopup.module.scss';

type InviteUpdatePopupProps = {
	setIsShowPopup: (a: boolean) => void;
	inviteData: IInvitedUserResponse;
	// setIsOpenSnackbar: (a: boolean) => void;
	// link: string;
};

const InviteUpdatePopup: FC<InviteUpdatePopupProps> = ({
	setIsShowPopup,
	inviteData,
}: // link,
// setIsOpenSnackbar,
InviteUpdatePopupProps) => {
	// const [isLoading, setIsLoading] = useState(true);
	const ref = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const handleClickOutside = (event: Event) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			setIsShowPopup(false);
		}
	};

	const clickHandler = () => {
		dispatch(updateInvite(inviteData.organisationId, inviteData.id));
		setIsShowPopup(false);
	};
	// const copyClickHandler = () => {
	// 	const cb = navigator.clipboard;
	// 	// cb.writeText(link);
	// 	// setIsOpenSnackbar(true);
	// 	setIsShowPopup(false);
	// };

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, []);

	return (
		<div ref={ref}>
			<div className={styles.container}>
				<WhiteContainer title="Update invite">
					<Formik
						enableReinitialize
						initialValues={inviteData}
						// validationSchema={inviteUsersValidationSchema}
						onSubmit={clickHandler}
					>
						{({ errors, touched, setFieldValue, setFieldTouched, values }) => (
							<Form
								className={styles.form}
								// title={isOrgSuspended ? 'Your organization is suspended' : ''}
							>
								<Field name="firstName">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="firstName">
												First name
											</label>
											<InputText
												id="firstName"
												field={field}
												// placeholder="fill in..."
												setFieldValue={setFieldValue}
												fieldValue={field.value}
												// isDisabled={isOrgSuspended}
											/>
											{errors.firstName && touched.firstName && (
												<InputErrorHint errorText={errors.firstName} />
											)}
										</InputContainer>
									)}
								</Field>
								<Field name="lastName">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="lastName">
												Last name
											</label>
											<InputText
												id="lastName"
												field={field}
												// placeholder="fill in..."
												setFieldValue={setFieldValue}
												fieldValue={field.value}
												// isDisabled={isOrgSuspended}
											/>
											{errors.lastName && touched.lastName && (
												<InputErrorHint errorText={errors.lastName} />
											)}
										</InputContainer>
									)}
								</Field>
								<Field name="email">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="email">
												Email address
											</label>
											<InputText
												id="email"
												field={field}
												// placeholder="fill in..."
												setFieldValue={setFieldValue}
												fieldValue={field.value}
												// isDisabled={isOrgSuspended}
											/>
											{errors.email && touched.email && (
												<InputErrorHint errorText={errors.email} />
											)}
										</InputContainer>
									)}
								</Field>
								<Field name="phoneNumber">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="phoneNumber">
												Phone number
											</label>
											<InputPhone
												fieldName={field.name}
												fieldValue={field.value}
												setFieldValue={setFieldValue}
												setFieldTouched={setFieldTouched}
												// disabled={isOrgSuspended}
											/>
											{errors.phoneNumber && touched.phoneNumber && (
												<InputErrorHint errorText={errors.phoneNumber} />
											)}
										</InputContainer>
									)}
								</Field>
								<Field name="timeSpend">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="timeSpend">
												Spended time
											</label>
											{JSON.stringify(field.value)}
											<InputNumber
												id="timeSpend"
												field={field.value}
												// placeholder="fill in..."
												setFieldValue={setFieldValue}
											/>
										</InputContainer>
									)}
								</Field>
								<Field name="roleId">
									{({ field }: FieldProps) => (
										<InputContainer>
											<label className={styles.label} htmlFor="roleId">
												Role
											</label>
											<RolesSelect
												id="roleId"
												fieldName={field.name}
												fieldValue={field.value}
												setFieldValue={setFieldValue}
												setFieldTouched={setFieldTouched}
												// disabled={isOrgSuspended}
											/>
											{errors.roleId && touched.roleId && (
												<InputErrorHint errorText={errors.roleId} />
											)}
										</InputContainer>
									)}
								</Field>
								{!!inviteData.gradeId && (
									<Field name="grade">
										{({ field }: FieldProps) => (
											<InputContainer>
												<label className={styles.label} htmlFor="gradeId">
													School grade
												</label>
												<GradeSelect
													fieldName={field.name}
													fieldValue={field.value.gradeName}
													setFieldValue={setFieldValue}
													setFieldTouched={setFieldTouched}
												/>
												{/* {errors.grade && touched.grade && (
													<InputErrorHint errorText={errors.grade} />
												)} */}
											</InputContainer>
										)}
									</Field>
								)}
								{/* {isLoading ? (
									<Loader />
								) : ( */}
								<ButtonDefault
									submitType
									classList={['primary', 'lg']}
									title="Confirm"
									clickHandler={clickHandler}
									// disabled={isOrgSuspended}
								/>
								{/* )} */}
							</Form>
						)}
					</Formik>
				</WhiteContainer>
			</div>
		</div>
	);
};

export default InviteUpdatePopup;
