import { createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
//types
import { IGradeResponse } from '@joc/api-gateway';
//assets
import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
//components
import Loader from 'shared/components/Loader';
//styles
import styles from './index.module.scss';

type GradeSelectProps = {
	grades?: Array<IGradeResponse>;
	placeholder?: string;
	fieldValue?: string;
	fieldName: string;
	setFieldValue: (field: string, value: string) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	fieldTouched?: boolean;
	classList?: Array<string>;
	parentClassName?: string;
	isDisabled?: boolean;
};

const GradeSelect: FC<GradeSelectProps> = ({
	grades = [],
	placeholder = 'Select grade',
	setFieldValue,
	setFieldTouched,
	classList,
	fieldValue,
	fieldName,
	fieldTouched,
	parentClassName,
	isDisabled,
}: GradeSelectProps) => {
	const [options] = useState<Array<IGradeResponse>>(grades);
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);

	const title = useMemo(() => {
		if (options.length && fieldValue) {
			return options.find(option => option.id.toString() === fieldValue.toString())?.gradeName;
		}
		return '';
	}, [fieldValue, options, fieldTouched]);
	const selectRef = useRef<HTMLDivElement>(null);
	const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

	const optionClickHandler = (id: string): void => {
		setFieldValue(fieldName, id);
		setIsShowOptions(false);
	};

	const handleClickOutside = (event: Event) => {
		isShowOptions && setFieldTouched && setFieldTouched(fieldName, true);
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};

	const titleClickHandler = () => {
		if (!isDisabled) {
			const valueIndex = options.findIndex(i => Number(i.id) === Number(fieldValue));
			const target = optionRef[valueIndex]?.current;
			if (target)
				// set timeout cause in a first time the scroll doesn't work
				setTimeout(() => {
					target.scrollIntoView();
				}, 0);
			if (isShowOptions) setFieldTouched(fieldName, true);
			setIsShowOptions(!isShowOptions);
		}
	};

	useEffect(() => {
		if (fieldValue) setFieldValue(fieldName, fieldValue);
	}, [fieldValue]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	if (!options.length) return <Loader />;
	return (
		<div
			className={cx(styles.dropdown_select, parentClassName && parentClassName, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
				[styles.disabled]: isDisabled,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!fieldValue || fieldTouched,
				})}
				onClick={titleClickHandler}
			>
				<span className={cx({ [styles.disabled_span]: isDisabled })}>{title || placeholder}</span>
				{!isDisabled && !isShowOptions ? (
					<BlueArrow className={styles.dropdown_select__title__vector} />
				) : (
					<WhiteArrow className={styles.dropdown_select__title__vector} />
				)}
			</div>
			<div className={styles.dropdown_select__options}>
				{options.map((option: IGradeResponse) => (
					<div
						key={option.id}
						className={styles.dropdown_select__options__item}
						onClick={() => optionClickHandler(option.id)}
					>
						{option.gradeName}
					</div>
				))}
			</div>
		</div>
	);
};

export default GradeSelect;
