import {
	IBasicVolunteerResponse,
	IUpdateVolunteerResponse,
	IVolunteerActiveStatusResponse,
	VolunteerActiveStatusResponse,
} from '@joc/api-gateway';
import update from 'immutability-helper';
import { GET_VOLUNTEERS_ALL, UPDATE_VOLUNTEER, RESET_VOLUNTEERS, UPDATE_ACTIVE_STATUS_VOL } from 'redux/actions-types';
import { IVolunteerReducer, VolunteerActionTypes, VolunteerPayload } from './types';

const initState: IVolunteerReducer = {
	volunteers: { records: [], total: 0 },
};

const volunteersReducer = (state = initState, action: VolunteerActionTypes): IVolunteerReducer => {
	const { type, payload }: VolunteerActionTypes = action;

	switch (type) {
		case GET_VOLUNTEERS_ALL:
			return {
				...state,
				volunteers: {
					records: [...state.volunteers.records, ...(payload as VolunteerPayload).records],
					total: (payload as VolunteerPayload).total,
				},
			};
		case UPDATE_VOLUNTEER: {
			const index = state.volunteers.records.findIndex(
				item => +item.id === +(payload as IUpdateVolunteerResponse).id
			);
			return update(state, {
				volunteers: {
					records: {
						[index]: {
							$set: { ...state.volunteers.records[index], ...(payload as IBasicVolunteerResponse) },
						},
					},
				},
			});
		}

		case UPDATE_ACTIVE_STATUS_VOL: {
			const index = state.volunteers.records.findIndex(
				item => +item.id === +(payload as IVolunteerActiveStatusResponse).volunteerId
			);
			return update(state, {
				volunteers: {
					records: {
						[index]: {
							volunteerActiveStatus: { $merge: payload as IVolunteerActiveStatusResponse },
						},
					},
				},
			});
		}

		case RESET_VOLUNTEERS: {
			return initState;
		}

		default:
			return state;
	}
};

export default volunteersReducer;
