import { FC, ReactNode } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type ButtonIconProps = {
	onClick: () => void;
	children: ReactNode;
	disabled?: boolean;
	parentClassName?: string;
};

const ButtonIcon: FC<ButtonIconProps> = ({ onClick, children, disabled, parentClassName }) => {
	return (
		<button className={cx(styles.button, parentClassName)} onClick={onClick} disabled={disabled}>
			{children}
		</button>
	);
};
export default ButtonIcon;
