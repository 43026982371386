import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
// components
import Logo from 'shared/components/Logo';
import LangSwitch from './LangSwitch';
import ChatInfo from './ChatInfo';
// styles
import styles from './HeaderWrapper.module.scss';

type HeaderWrapperParentProps = {
	children: ReactNode;
	dropdown: ReactNode;
	isGuest?: boolean;
	chatLink: string;
};

const HeaderWrapper: FC<HeaderWrapperParentProps> = ({
	// userImage,
	children,
	isGuest,
	chatLink,
}: HeaderWrapperParentProps) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.logoBlockWrapper}>
				<Logo isOnboarding={isGuest} />
				{!isGuest && children}
			</div>
			<div className={styles.content}>
				<Link to={chatLink}>
					<ChatInfo />
				</Link>
				{/* <LangSwitch /> */}
			</div>
		</div>
	);
};

export default HeaderWrapper;
