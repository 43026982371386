import React, { FC } from 'react';
import { ReactComponent as ChatSvg } from 'assets/image/chat.svg';
import { selectorGetChatUnreadMessagesCount } from 'redux/chat-service/selector';
import { Store } from 'redux/root';
import { connect, ConnectedProps } from 'react-redux';
import styles from './index.module.scss';

const ChatInfo: FC<ChatInfoProps> = ({ count }: ChatInfoProps) => {
	return (
		<div className={styles.chatInfo}>
			<ChatSvg className={styles.chatInfo__image} />
			{!!count && <div className={styles.chatInfo__counter}>{count}</div>}
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	count: selectorGetChatUnreadMessagesCount(store),
});

const connector = connect(mapStateToProps);
type ChatInfoProps = ConnectedProps<typeof connector>;

export default connector(ChatInfo);
