import update from 'immutability-helper';
import {
	ICountsOpportunitiesResponse,
	IOpportunityActiveStatusResponse,
	IOpportunityResponse,
	OpportunityActiveStatusResponse,
	OpportunityResponse,
	OPPORTUNITY_STATUSES,
} from '@joc/api-gateway';
import { IOpportunitiesCountsResponse } from 'core/types/OPPORTUNITIES';
import {
	CANTMAKEIT,
	CHANGE_STATUS_MANUAL_OPPORTUNITY,
	CREATE_OPPORTUNITY,
	DELETE_OPPORTUNITY,
	GET_OPPORTUNITIES,
	GET_OPPORTUNITIES_ALL,
	GET_OPPORTUNITIES_ALL_REPLACE,
	GET_OPPORTUNITIES_COUNT,
	GET_OPPORTUNITIES_REMINDER_TAPE,
	GET_OPPORTUNITY_BY_ID,
	RESET_OPPORTUNITIES_RECORDS,
	UPDATE_OPPORTUNITY,
	UPDATE_ACTIVE_STATUS_OPPO,
} from '../actions-types';
import {
	ICantMakeItPayload,
	IChangeStatusManualOpportunityPayload,
	IOpportuinitiesPayload,
	IOpportunitiesReducer,
	OpportunitiesActionTypes,
} from './types';

const initState: IOpportunitiesReducer = {
	opportunitiesAll: {
		records: [],
		total: 0,
	},
	opportunitiesCount: {
		opportunitiesTotal: 0,
		opportunitiesPast: 0,
		opportunitiesManual: 0,
		opportunitiesUpcoming: 0,
	},
	opportunitiesReminderTape: [],
};

const opportunitiesReducer = (state = initState, action: OpportunitiesActionTypes): IOpportunitiesReducer => {
	const { type, payload }: OpportunitiesActionTypes = action;

	switch (type) {
		case GET_OPPORTUNITIES_ALL:
			return {
				...state,
				opportunitiesAll: {
					records: [...state.opportunitiesAll.records, ...(payload as IOpportuinitiesPayload).records],
					total: (payload as IOpportuinitiesPayload).total,
				},
			};
		case GET_OPPORTUNITIES_ALL_REPLACE:
			return {
				...state,
				opportunitiesAll: {
					records: [...(payload as IOpportuinitiesPayload).records],
					total: (payload as IOpportuinitiesPayload).total,
				},
			};
		case GET_OPPORTUNITIES:
			return {
				...state,
				opportunitiesAll: {
					records: [...state.opportunitiesAll.records, ...(payload as IOpportuinitiesPayload).records],
					total: (payload as IOpportuinitiesPayload).total,
				},
			};
		case GET_OPPORTUNITY_BY_ID:
			return {
				...state,
				currentOpportunity: payload as IOpportunityResponse,
			};
		case RESET_OPPORTUNITIES_RECORDS: {
			return { ...state, opportunitiesAll: initState.opportunitiesAll };
		}
		case GET_OPPORTUNITIES_REMINDER_TAPE:
			return { ...state, opportunitiesReminderTape: (payload as IOpportuinitiesPayload).records };
		case CREATE_OPPORTUNITY: {
			return {
				...state,
				opportunitiesAll: {
					records: [payload as OpportunityResponse, ...state.opportunitiesAll.records],
					total: state.opportunitiesAll.total + 1,
				},
			};
		}
		case UPDATE_OPPORTUNITY: {
			const index = state.opportunitiesAll.records.findIndex(i => i.id === (payload as OpportunityResponse).id);
			const reminderTapeIndex = state.opportunitiesReminderTape.findIndex(
				i => i.id === (payload as OpportunityResponse).id
			);
			return index >= 0 && reminderTapeIndex >= 0
				? update(state, {
						opportunitiesAll: { records: { [index]: { $set: payload as OpportunityResponse } } },
						opportunitiesReminderTape: { [reminderTapeIndex]: { $set: payload as OpportunityResponse } },
				  })
				: index >= 0 && reminderTapeIndex < 0
				? update(state, {
						opportunitiesAll: { records: { [index]: { $set: payload as OpportunityResponse } } },
				  })
				: index < 0 && reminderTapeIndex >= 0
				? update(state, {
						opportunitiesReminderTape: { [reminderTapeIndex]: { $set: payload as OpportunityResponse } },
				  })
				: state;
		}
		case UPDATE_ACTIVE_STATUS_OPPO: {
			const index = state.opportunitiesAll.records.findIndex(
				item => +item.id === +(payload as IOpportunityActiveStatusResponse).opportunityId
			);
			return update(state, {
				opportunitiesAll: {
					records: {
						[index]: { opportunityActiveStatus: { $merge: payload as IOpportunityActiveStatusResponse } },
					},
				},
			});
		}
		case DELETE_OPPORTUNITY:
			return {
				...state,
				opportunitiesAll: {
					records: state.opportunitiesAll.records.filter(i => i.id !== payload),
					total: state.opportunitiesAll.total - 1,
				},
			};
		case GET_OPPORTUNITIES_COUNT:
			return { ...state, opportunitiesCount: payload as IOpportunitiesCountsResponse };
		case CANTMAKEIT:
			return {
				...state,
				opportunitiesAll: {
					records: state.opportunitiesAll.records.filter(i => i.id !== (payload as ICantMakeItPayload).id),
					total: state.opportunitiesAll.total - 1,
				},
			};
		case CHANGE_STATUS_MANUAL_OPPORTUNITY: {
			if ((payload as IChangeStatusManualOpportunityPayload).opportunityStatus === OPPORTUNITY_STATUSES.CANCELED)
				return {
					...state,
					opportunitiesAll: {
						...state.opportunitiesAll,
						records: state.opportunitiesAll.records.filter(
							i => +i.id !== +(payload as IChangeStatusManualOpportunityPayload).id
						),
					},
				};
			const index = state.opportunitiesAll.records.findIndex(
				i => +i.id === +(payload as IChangeStatusManualOpportunityPayload).id
			);
			const updatedOpportunity = {
				...state.opportunitiesAll.records[index],
				status: (payload as IChangeStatusManualOpportunityPayload).opportunityStatus,
			};
			return update(state, {
				opportunitiesAll: { records: { [index]: { $set: updatedOpportunity } } },
			});
		}
		default:
			return state;
	}
};

export default opportunitiesReducer;
