import firebase from 'firebase';
import { Dispatch } from 'redux';
import {
	AddNotificationTokenRequest,
	CreateExternalUserRequest,
	DeviceType,
	ICreateExternalUserRequest,
	IUpdateUserRequest,
	USER_ROLES,
	UpdateUserRequest,
	VerifyTokenRequest,
} from '@joc/api-gateway';
import { API } from 'core/API';
import {
	CHANGE_NAVBAR_MODE,
	GET_USER,
	REMOVE_PUSH_NOTIFICATION_TOKEN,
	RESET_USER_DATA,
	SET_ERROR,
} from 'redux/actions-types';
import { SocialProvider } from 'components/Volunteer/LoginFormV';

// export const getVolunteerByEmailAndPassword =
// 	(email: string, password: string) =>
// 	async (dispatch: Dispatch): Promise<void> => {
// 		try {
// 			const userFirebaseResponse = await firebase.auth().signInWithEmailAndPassword(email, password);
// 			const userFirebaseToken = await userFirebaseResponse.user?.getIdToken();
// 			if (userFirebaseToken) {
// 				const accessTokenResponse = await API.verifyToken(
// 					VerifyTokenRequest.fromJS({ token: userFirebaseToken })
// 				);
// 				if (accessTokenResponse) {
// 					localStorage.setItem('accessToken', accessTokenResponse.accessToken);
// 					const currentUser = await API.getCurrentUser();
// 					localStorage.setItem('volunteerId', currentUser.volunteerId || '');
// 					dispatch({ type: GET_USER, payload: currentUser });
// 				}
// 			}
// 		} catch (e) {
// 			throw e;
// 		}
// 	};

// export const getOrganizationUserByEmailAndPassword =
// 	(email: string, password: string) =>
// 	async (dispatch: Dispatch): Promise<void> => {
// 		try {
// 			const userFirebaseResponse = await firebase.auth().signInWithEmailAndPassword(email, password);
// 			const userFirebaseToken = await userFirebaseResponse.user?.getIdToken();
// 			if (userFirebaseToken) {
// 				const accessTokenResponse = await API.verifyTokenCompany(
// 					VerifyTokenRequest.fromJS({ token: userFirebaseToken })
// 				);
// 				if (accessTokenResponse) {
// 					localStorage.setItem('accessToken', accessTokenResponse.accessToken);
// 					const currentUser = await API.getCurrentUser();
// 					dispatch({ type: GET_USER, payload: currentUser });
// 				}
// 			}
// 		} catch (e) {
// 			throw e;
// 		}
// 	};

// export const logout = () => async (dispatch: Dispatch) => {};

export const getUserByEmailAndPassword = (email: string, password: string) => async (dispatch: Dispatch) => {
	try {
		const userFirebaseResponse = await firebase.auth().signInWithEmailAndPassword(email, password);
		const userFirebaseToken = await userFirebaseResponse.user?.getIdToken();
		if (userFirebaseToken) {
			const accessTokenResponse = await API.verifyTokenCompany(
				VerifyTokenRequest.fromJS({ token: userFirebaseToken })
			);
			if (accessTokenResponse) {
				localStorage.setItem('accessToken', accessTokenResponse.accessToken);
				const currentUser = await API.getCurrentUser();
				const isSuperAdmin = !!currentUser.roles?.some(role => role.roleName === USER_ROLES.SUPER_ADMIN);
				console.log('isSuperAdmin: ', isSuperAdmin);
				if (isSuperAdmin) {
					dispatch({ type: GET_USER, payload: currentUser });
				} else {
					throw new Error('Access Denied');
				}
			}
		}
	} catch (e) {
		throw e;
	}
};

export const getUserBySocialProvider = (socialProvider: SocialProvider) => async (dispatch: Dispatch) => {
	try {
		const firebaseUserResponse = await firebase.auth().signInWithPopup(socialProvider);
		const firebaseTokenResponse = await firebaseUserResponse.user?.getIdToken();
		if (firebaseTokenResponse) {
			const accessTokenResponse = await API.verifyToken(
				VerifyTokenRequest.fromJS({ token: firebaseTokenResponse })
			);
			if (accessTokenResponse.accessToken) {
				localStorage.setItem('accessToken', accessTokenResponse.accessToken);
				const currentUser = await API.getCurrentUser();
				if (currentUser.volunteerId) localStorage.setItem('volunteerId', currentUser.volunteerId);
				dispatch({ type: GET_USER, payload: currentUser });
			}
		}
	} catch (error: any) {
		if (error?.code !== 'auth/account-exists-with-different-credential') {
			throw error;
		} else {
			const providersResponse = await firebase.auth().fetchSignInMethodsForEmail(error?.email);
			const message = `An account already exists with the same email address but different sign-in credentials. Please, sign in with ${providersResponse.join(
				', '
			)} ${providersResponse.length > 1 ? 'accounts' : 'account'}`;
			const customError = { message, code: 502 };
			throw customError;
		}
	}
};

export const getCurrentUser = () => async (dispatch: Dispatch) => {
	try {
		const currentUser = await API.getCurrentUser();
		dispatch({ type: GET_USER, payload: currentUser });
	} catch (error: any) {
		dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
	}
};

export const userCreate =
	(userFormData: ICreateExternalUserRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await API.create(CreateExternalUserRequest.fromJS(userFormData));
			// await getOrganizationUserByEmailAndPassword(userFormData.email, userFormData.password);
		} catch (error: any) {
			dispatch({
				type: SET_ERROR,
				payload: { state: true, message: error?.response?.message || error.messagee },
			});
		}
	};

export const userUpdate = (id: number, values: IUpdateUserRequest) => async (dispatch: Dispatch) => {
	try {
		await API.updateUser(id, UpdateUserRequest.fromJS(values));
		// updatedUser.email && (await firebase.auth().currentUser?.updateEmail(updatedUser.email));
		const updatedUser = await API.getCurrentUser();
		dispatch({ type: GET_USER, payload: updatedUser });
	} catch (error: any) {
		throw error;
	}
};

export const userResetData =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: RESET_USER_DATA,
		});
	};

export const changeNavbarMode =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: CHANGE_NAVBAR_MODE,
		});
	};

export const setUserNotificationToken = async (pushToken: string) => {
	try {
		await API.addNotificationToken(
			AddNotificationTokenRequest.fromJS({
				token: pushToken,
				deviceType: DeviceType.Web,
			})
		);
		getCurrentUser();
	} catch (error: any) {
		console.warn({ error });
	}
};

export const deleteUserNotificationToken =
	(type: DeviceType = DeviceType.Web) =>
	async (dispatch: Dispatch) => {
		try {
			await API.deleteNotificationToken(
				AddNotificationTokenRequest.fromJS({
					deviceType: type,
				})
			);
			const updatedUser = await API.getCurrentUser();
			dispatch({ type: REMOVE_PUSH_NOTIFICATION_TOKEN, payload: updatedUser });
		} catch (error: any) {
			console.warn({ error });
		}
	};
