import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
// type
// import { LinkCopiedSuccessfully } from 'core/constants';
import { IInvitedUserResponse } from '@joc/api-gateway';
// redux
import { deleteInvite, getInvitesAll, resetInvites } from 'redux/invite-service/actions';
// components
import PopupConfirmation from 'shared/components/PopupConfirmation';
import PopupContainer from 'shared/components/PopupContainer';
import ButtonShare from 'shared/components/Buttons/ButtonShare';
import ButtonTrash from 'shared/components/Buttons/ButtonTrash';
// import { urls } from 'core/appUrls';
import { useLocation } from 'react-router';
import InviteUpdatePopup from '../InviteUpdatePopup';
// styles
// import defaultStyles from '../CellDefault.module.scss';
import styles from './InvitesActionCell.module.scss';

type InvitesActionCellParentProps = {
	inviteData: IInvitedUserResponse;
};

const InvitesActionCell: FC<InvitesActionCellParentProps> = ({
	inviteData,
}: // setError,
InvitesActionCellParentProps) => {
	const [isShowUpdatePopup, setIsShowUpdatePopup] = useState(false);
	const [isShowConfirmation, setIsShowConfirmation] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();

	const deleteInviteHandler = () => {
		dispatch(deleteInvite(inviteData.organisationId, inviteData.id));
		// dispatch(resetInvites());
		// dispatch(getInvitesAll(location.search));
		setIsShowConfirmation(false);
	};

	return (
		<div className={cx(styles.actions, styles.main)}>
			<div className={styles.actions__content}>
				<ButtonTrash clickHandler={() => setIsShowConfirmation(true)} title="Delete invite" />

				<ButtonShare clickHandler={() => setIsShowUpdatePopup(!isShowUpdatePopup)} />
			</div>
			{isShowUpdatePopup && (
				<PopupContainer setIsShowPopup={setIsShowUpdatePopup}>
					<InviteUpdatePopup setIsShowPopup={setIsShowUpdatePopup} inviteData={inviteData} />
				</PopupContainer>
			)}
			{isShowConfirmation && (
				<PopupConfirmation
					cancelClickHandler={() => setIsShowConfirmation(false)}
					confirmClickHandler={deleteInviteHandler}
					setIsShowPopup={() => setIsShowConfirmation(false)}
				/>
			)}
		</div>
	);
};

export default InvitesActionCell;
