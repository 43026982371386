import React, { FC } from 'react';
import cx from 'classnames';
import PrintSvg from 'assets/image/print.svg';
import styles from './ButtonPrint.module.scss';

type ButtonPrintProps = { clickHandler?: () => void; disabled?: boolean };

const ButtonPrint: FC<ButtonPrintProps> = ({ clickHandler, disabled }: ButtonPrintProps) => {
	return (
		<button className={styles.button} onClick={clickHandler} disabled={disabled}>
			<img
				className={cx(styles.button__icon, disabled && styles.button__icon_disabled)}
				src={PrintSvg}
				alt="print"
			/>
		</button>
	);
};

export default ButtonPrint;
