import React, { ChangeEvent, FC, Fragment } from 'react';
import styles from './index.module.scss';

interface CheckboxProps {
	disabled?: boolean;
	required?: boolean;
	changeHandle?: (checked: boolean, value?: number | undefined) => void;
	label?: string | JSX.Element;
	value?: number;
	defaultChecked?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
	disabled,
	required,
	changeHandle,
	label,
	value,
	defaultChecked,
}: CheckboxProps) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		changeHandle && changeHandle(e.target.checked, value);
	};

	return (
		<label className={styles.container} title={disabled ? 'not allowed' : ''}>
			<input
				type="checkbox"
				onChange={handleChange}
				required={required}
				value={value}
				disabled={disabled}
				checked={defaultChecked}
			/>
			<span className={styles.checkmark} />
			{label}
		</label>
	);
};

export default Checkbox;
