/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { DefaultStreamChatGenerics, useChannelStateContext, useChatContext } from 'stream-chat-react';
import type { ChannelMemberResponse } from 'stream-chat';
//functions
import { getChannelMembers } from 'core/functions/chat';
//components
import { ChannelInfoIcon, ChannelSaveIcon, getCleanImage, HamburgerIcon } from 'shared/components/RandomImage';
import CustomAvatar from '../CustomAvatar';
//styles
import './MessagingChannelHeader.css';

export const AvatarGroup = ({ members }: { members: ChannelMemberResponse[] }) => {
	switch (members.length) {
		case 1:
			return (
				<div className="messaging__channel-header__avatars">
					<CustomAvatar image={getCleanImage(members[0])} size={40} />;
				</div>
			);

		case 2:
			return (
				<div className="messaging__channel-header__avatars two">
					<span>
						<CustomAvatar image={getCleanImage(members[0])} shape="square" size={40} />
					</span>
					<span>
						<CustomAvatar image={getCleanImage(members[1])} shape="square" size={40} />
					</span>
				</div>
			);

		case 3:
			return (
				<div className="messaging__channel-header__avatars three">
					<span>
						<CustomAvatar image={getCleanImage(members[0])} shape="square" size={40} />
					</span>
					<span>
						<CustomAvatar image={getCleanImage(members[1])} shape="square" size={20} />
						<CustomAvatar image={getCleanImage(members[2])} shape="square" size={20} />
					</span>
				</div>
			);

		default:
			return (
				<div className="messaging__channel-header__avatars four">
					<span>
						<CustomAvatar image={getCleanImage(members[members.length - 1])} shape="square" size={20} />
						<CustomAvatar image={getCleanImage(members[members.length - 2])} shape="square" size={20} />
					</span>
					<span>
						<CustomAvatar image={getCleanImage(members[members.length - 3])} shape="square" size={20} />
						<CustomAvatar image={getCleanImage(members[members.length - 4])} shape="square" size={20} />
					</span>
				</div>
			);
	}
};

type Props = {
	theme: string;
	toggleMobile: () => void;
	// members?: any;
};

const MessagingChannelHeader: React.FC<Props> = props => {
	const { theme, toggleMobile } = props;

	const { client } = useChatContext<DefaultStreamChatGenerics>();

	const { channel } = useChannelStateContext<DefaultStreamChatGenerics>();

	const [channelName, setChannelName] = useState(channel.data?.name || '');
	const [isEditing, setIsEditing] = useState(false);
	const [title, setTitle] = useState('');

	const inputRef = useRef<HTMLInputElement>(null);

	const members: Array<ChannelMemberResponse> = getChannelMembers(channel.state.members, client?.user?.id);

	const updateChannel = async () => {
		if (channelName && channelName !== channel.data?.name) {
			await channel.update({ name: channelName }, { text: `Channel name changed to ${channelName}` });
		}

		setIsEditing(false);
	};

	useEffect(() => {
		if (isEditing && inputRef.current) {
			inputRef?.current.focus();
		}
	}, [isEditing]);

	useEffect(() => {
		if (!channelName) {
			setTitle(members.map(member => member.user?.name || member.user?.id || 'Unnamed User').join(', '));
		}
	}, [channelName, members]);

	const EditHeader = () => (
		<form
			style={{ flex: 1 }}
			onSubmit={event => {
				event.preventDefault();
				inputRef?.current?.blur();
			}}
		>
			<input
				autoFocus
				className="channel-header__edit-input"
				onBlur={updateChannel}
				onChange={event => setChannelName(event.target.value)}
				placeholder="Type a new name for the chat"
				ref={inputRef}
				value={channelName}
			/>
		</form>
	);

	return (
		<div className="messaging__channel-header">
			<div id="mobile-nav-icon" className={`${theme}`} onClick={() => toggleMobile()}>
				<HamburgerIcon />
			</div>
			<AvatarGroup members={members} />
			{!isEditing ? <div className="channel-header__name">{channelName || title}</div> : <EditHeader />}

			<div className="messaging__channel-header__right">
				{channelName !== 'Social Demo' &&
					(!isEditing ? <ChannelInfoIcon {...{ isEditing, setIsEditing }} /> : <ChannelSaveIcon />)}
			</div>
		</div>
	);
};

export default React.memo(MessagingChannelHeader);
