import React, { FC, ReactNode } from 'react';
import Navbar from 'components/Organization/NavBar';
import HeaderOrg from 'components/Headers/HeaderOrg';
import styles from './MainPageLayout.module.scss';

type MainPageLayoutPropsType = {
	children: ReactNode;
};

const MainPageLayout: FC<MainPageLayoutPropsType> = ({ children }: MainPageLayoutPropsType) => {
	return (
		<div className={styles.main}>
			<HeaderOrg />
			<div className={styles.main__content__container}>
				<Navbar />
				<div className={styles.main__content}>{children}</div>
			</div>
		</div>
	);
};

export default MainPageLayout;
