/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-no-comment-textnodes */
import MainPageLayout from 'components/Organization/MainPageLayout';
import React, { FC } from 'react';

import ChatOk from 'shared/components/ChatOk';
import styles from './Inbox.module.scss';

const InboxPage: FC = () => {
	return (
		<MainPageLayout>
			<div className={styles.container}>
				<h1 className="title">Inbox</h1>
				<div className="container">
					<ChatOk />
				</div>
			</div>
		</MainPageLayout>
	);
};

export default InboxPage;
