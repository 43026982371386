import React, { FC, useState } from 'react';
import cx from 'classnames';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import { GroupType } from '..';
import SelectableVolunteersTable from './SelectableVolunteersTable/Table';
import styles from './OwnField.module.scss';

type OwnFieldParentProps = {
	filterId: number;
	filterTypes: Array<GroupType>;
	setFilterTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
};

const OwnField: FC<OwnFieldParentProps> = ({ filterId, filterTypes, setFilterTypes }: OwnFieldParentProps) => {
	const [isShowSelectableVolunteersTable, setIsShowSelectableVolunteersTable] = useState(false);
	const [checkedVolunteersId, setCheckedVolunteersId] = useState<Array<number>>([]);

	return (
		<div
			className={cx(styles.container, {
				[styles.container_tableMode]: isShowSelectableVolunteersTable,
			})}
		>
			<div className={styles.container__field}>
				{!isShowSelectableVolunteersTable && !!checkedVolunteersId?.length && (
					<div className={styles.fieldInfo}>
						{checkedVolunteersId.length}
						{checkedVolunteersId.length > 1 ? ' volunteers ' : ' volunteer '}
						selected
					</div>
				)}

				{!isShowSelectableVolunteersTable && (
					<ButtonDefault
						title={
							filterTypes.find(i => i.id === filterId)?.data?.length ? 'View / Edit' : 'Select volunteers'
						}
						classList={['secondary', 'lg']}
						clickHandler={() => setIsShowSelectableVolunteersTable(true)}
					/>
				)}
			</div>
			{isShowSelectableVolunteersTable && (
				<SelectableVolunteersTable
					filterId={filterId}
					filterTypes={filterTypes}
					setFilterTypes={setFilterTypes}
					setIsShowSelectableVolunteersTable={setIsShowSelectableVolunteersTable}
					checkedVolunteersId={checkedVolunteersId}
					setCheckedVolunteersId={setCheckedVolunteersId}
					// setFilteringVolunteerData={setFilteringVolunteerData}
				/>
			)}
		</div>
	);
};

export default OwnField;
