import React, { Dispatch, FC, SetStateAction } from 'react';
import {
	ChannelPreviewUIComponentProps,
	ChatContextValue,
	DefaultStreamChatGenerics,
	useChatContext,
} from 'stream-chat-react';
import type { Channel, ChannelMemberResponse } from 'stream-chat';
//functions
import { getChannelMembers } from 'core/functions/chat';
//components
import AvatarGroup from './AvatarGroup';
//styles
import './MessagingChannelPreview.css';

const getTimeStamp = (channel: Channel) => {
	let lastHours = channel.state.last_message_at?.getHours();
	let lastMinutes: string | number | undefined = channel.state.last_message_at?.getMinutes();
	let half = 'AM';

	if (lastHours === undefined || lastMinutes === undefined) {
		return '';
	}

	if (lastHours > 12) {
		lastHours -= 12;
		half = 'PM';
	}

	if (lastHours === 0) lastHours = 12;
	if (lastHours === 12) half = 'PM';

	if (lastMinutes.toString().length === 1) {
		lastMinutes = `0${lastMinutes}`;
	}

	return `${lastHours}:${lastMinutes} ${half}`;
};

const getChannelName = (members: ChannelMemberResponse[]) => {
	const defaultName = 'Johnny Blaze';

	if (!members.length || members.length === 1) {
		return members[0]?.user?.name || defaultName;
	}

	return `${members[0]?.user?.name || defaultName}, ${members[1]?.user?.name || defaultName}`;
};

type Props = ChannelPreviewUIComponentProps & {
	channel: Channel;
	setIsCreating: Dispatch<SetStateAction<boolean>>;
	latestMessage: string | JSX.Element;
	unread?: number;
	setActiveChannel?: ChatContextValue['setActiveChannel'];
	setCreatedActiveChannel: Dispatch<any>;
};

const MessagingChannelPreview: FC<Props> = props => {
	const { channel, latestMessage, unread, setIsCreating, setCreatedActiveChannel } = props;

	const { channel: activeChannel, client, setActiveChannel } = useChatContext<DefaultStreamChatGenerics>();

	const members: Array<ChannelMemberResponse> = getChannelMembers(channel.state.members, client?.user?.id);

	return (
		<div
			className={
				channel?.id === activeChannel?.id ? 'channel-preview__container selected' : 'channel-preview__container'
			}
			onClick={() => {
				setIsCreating(false);
				setCreatedActiveChannel(undefined);
				setActiveChannel?.(channel);
			}}
		>
			<AvatarGroup members={members} />
			<div className="channel-preview__content-wrapper">
				<div className="channel-preview__content-top">
					<p className="channel-preview__content-name">{channel.data?.name || getChannelName(members)}</p>
					{typeof latestMessage === 'string' ? (
						<p className="channel-preview__content-message">{latestMessage || 'Send a message'}</p>
					) : (
						React.cloneElement(latestMessage, { className: 'channel-preview__content-message' })
					)}
				</div>
				<div className="channel-preview__content-bottom">
					<p className="channel-preview__content-time">{getTimeStamp(channel)}</p>
					{unread ? <p className="channel-preview__unread-message-count">{unread}</p> : ''}
				</div>
			</div>
		</div>
	);
};

export default MessagingChannelPreview;
