import { FC, useMemo } from 'react';
import { IGetVolunteerByIdResponse, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import { ReactComponent as ManualOppo } from 'assets/image/icons/heart.svg';
import { ReactComponent as PreviousCompleted } from 'assets/image/icons/previous-completed.svg';
import { TABLE_ITEMS_CLASSNAMES } from 'core/constants';
import styles from '../CellDefault.module.scss';
import { useTranslation } from 'react-i18next';

type OpportunityNameCellProps = {
	opportunityName: string;
	opportunityType: OPPORTUNITY_TYPES | number;
	volunteers?: Array<IGetVolunteerByIdResponse>;
	adminId?: string;
	isInitialOppo?: boolean;
};

const OpportunityNameCell: FC<OpportunityNameCellProps> = ({
	opportunityType,
	opportunityName,
	adminId,
	volunteers,
	isInitialOppo,
}: OpportunityNameCellProps) => {
	const { t } = useTranslation();

	const isManualOpportunity = useMemo(() => opportunityType === OPPORTUNITY_TYPES.MANUAL, [opportunityType]);

	const isAdmin = useMemo(() => volunteers?.length === 1 && volunteers[0].userId !== adminId, [volunteers, adminId]);

	return (
		<div className={styles[TABLE_ITEMS_CLASSNAMES.chesedName]}>
			{isManualOpportunity ? (
				<div className={styles.manualOpportunity__mark}>
					<div className={styles.manualOpportunity__mark_manual}>
						{isInitialOppo ? (
							<PreviousCompleted height={25} width={25} className={styles.previous_completed_mark} />
						) : (
							<ManualOppo height={20} width={20} />
						)}
					</div>
					{isAdmin && <p className={styles.manualOpportunity__mark_admin}>{t('chesed.admin')}</p>}
				</div>
			) : null}
			<span title={opportunityName}>{opportunityName}</span>
		</div>
	);
};

export default OpportunityNameCell;
