import { IGroupsResponse } from '@joc/api-gateway';
import { CREATE_SMART_GROUP, DELETE_SMART_GROUP, GET_SMART_GROUPS_ALL } from 'redux/actions-types';
import { ISmartGroupReducer, SmartGroupsActionTypes } from './types';

const initState: ISmartGroupReducer = {
	smartGroups: [],
};

const smartGroupsReducer = (state = initState, action: SmartGroupsActionTypes): ISmartGroupReducer => {
	const { type, payload }: SmartGroupsActionTypes = action;

	switch (type) {
		case GET_SMART_GROUPS_ALL: {
			return { ...state, smartGroups: payload as Array<IGroupsResponse> };
		}

		case CREATE_SMART_GROUP: {
			return { ...state, smartGroups: [...state.smartGroups, payload as IGroupsResponse] };
		}

		case DELETE_SMART_GROUP: {
			return { ...state, smartGroups: state.smartGroups.filter(i => i.id !== payload) };
		}

		default:
			return state;
	}
};
export default smartGroupsReducer;
