import React, { FC } from 'react';

import InputContainer from 'shared/components/FormInputs/InputContainer';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import { GroupType } from '..';
import styles from '../CreateFilterForm.module.scss';

type LocationFieldPropsType = {
	filterId: number;
	filterTypes: Array<GroupType>;
	setFilterTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
};

const LocationField: FC<LocationFieldPropsType> = ({
	filterId,
	filterTypes,
	setFilterTypes,
}: LocationFieldPropsType) => {
	return (
		<div className={styles.form__location}>
			<InputContainer>
				<label className={styles.label} htmlFor="city">
					Where:
				</label>
				{/* <GoogleApiAutoCompleat fieldName={field.name} setFieldValue={setFieldValue} /> */}
			</InputContainer>
		</div>
	);
};
export default LocationField;
