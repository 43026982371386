import React, { FC } from 'react';
import InputContainer from 'shared/components/FormInputs/InputContainer';
import ArrowSvg from 'assets/image/button-arrow-dark.svg';
import { MERIDIEM } from '..';
import styles from './CustomTimePickerField.module.scss';

type CustomTimePickerFieldProps = {
	fieldName?: string;
	setFieldValue: (value: number | MERIDIEM) => void;
	template: Array<number | MERIDIEM>;
	meridiemType?: boolean;
	fieldValue: null | number | MERIDIEM;
};

const CustomTimePickerField: FC<CustomTimePickerFieldProps> = ({
	fieldName,
	setFieldValue,
	template,
	meridiemType,
	fieldValue,
}: CustomTimePickerFieldProps) => {
	const arrowMoreClickHandler = (value: number | null | MERIDIEM) => {
		if (!value && value !== 0) {
			setFieldValue(template[0]);
			return;
		}
		const valueIndex = template.indexOf(value);
		if (valueIndex === template.length - 1) {
			setFieldValue(template[0]);
		} else {
			setFieldValue(template[valueIndex + 1]);
		}
	};
	const arrowLessClickHandler = (value: number | null | MERIDIEM) => {
		if (!value) {
			setFieldValue(template[template.length - 1]);
			return;
		}
		const valueIndex = template.indexOf(value);
		if (valueIndex === 0) {
			setFieldValue(template[template.length - 1]);
		} else {
			setFieldValue(template[valueIndex - 1]);
		}
	};

	return (
		<InputContainer isDisabledMargin isDisableHeight>
			<div className={styles.container}>
				<img className={styles.arrow_more} src={ArrowSvg} onClick={() => arrowMoreClickHandler(fieldValue)} />
				<input
					className={styles.picker_input}
					type={meridiemType ? 'string' : 'number'}
					onChange={e => {
						const value = +e.target.value;
						value < template[0]
							? setFieldValue(template[0])
							: value > template[template.length - 1]
							? setFieldValue(template[template.length - 1])
							: setFieldValue(+e.target.value);
					}}
					placeholder="__"
					value={
						fieldValue || fieldValue === 0 ? (fieldValue <= 9 ? `0${fieldValue}` : fieldValue) : undefined
					}
					readOnly={meridiemType}
				/>
				<img className={styles.arrow_less} src={ArrowSvg} onClick={() => arrowLessClickHandler(fieldValue)} />
				<span className={styles.field_name}>{fieldName}</span>
			</div>
		</InputContainer>
	);
};

export default CustomTimePickerField;
