import { ComponentType, memo, useCallback, useRef, useState } from 'react';
import { ImageDropzone } from 'react-file-utils';
import { Attachment, logChatPromiseExecution, UserResponse } from 'stream-chat';
import {
	ChatAutoComplete,
	DefaultStreamChatGenerics,
	MessageInputProps,
	StreamMessage,
	UploadsPreview,
	useChannelActionContext,
	useChannelStateContext,
	useMessageInputContext,
	useMessageInputState,
} from 'stream-chat-react';
//components
import { EmojiIcon, LightningBoltSmall, SendIcon } from 'shared/components/RandomImage';
import CustomEmojiPicker from '../EmojiPicker/CustomEmojiPicker';
//styles
import './MessagingInput.css';
import { CustomTrigger } from 'stream-chat-react/dist/types/types';

const GiphyIcon = () => (
	<div className="giphy-icon__wrapper">
		<LightningBoltSmall />
		<p className="giphy-icon__text">GIPHY</p>
	</div>
);

const MessagingInput: ComponentType<MessageInputProps<DefaultStreamChatGenerics, CustomTrigger>> = props => {
	const { acceptedFiles, maxNumberOfFiles, multipleUploads } = useChannelStateContext<DefaultStreamChatGenerics>();

	const { sendMessage } = useChannelActionContext();

	const { insertText } = useMessageInputContext();

	const [giphyState, setGiphyState] = useState(false);
	const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

	const emojiPickerRef = useRef(null);

	const overrideSubmitHandler = (message: {
		attachments: Attachment[];
		mentioned_users: UserResponse[];
		text: string;
		parent?: StreamMessage;
	}) => {
		let updatedMessage;

		if (message.attachments?.length && message.text?.startsWith('/giphy')) {
			const updatedText = message.text.replace('/giphy', '');
			updatedMessage = { ...message, text: updatedText };
		}

		if (giphyState) {
			const updatedText = `/giphy ${message.text}`;
			updatedMessage = { ...message, text: updatedText };
		}

		if (sendMessage) {
			const newMessage = updatedMessage || message;
			const parentMessage = newMessage.parent;

			const messageToSend = {
				...newMessage,
				parent: parentMessage
					? {
							...parentMessage,
							created_at: parentMessage.created_at?.toString(),
							pinned_at: parentMessage.pinned_at?.toString(),
							updated_at: parentMessage.updated_at?.toString(),
					  }
					: undefined,
			};

			const sendMessagePromise = sendMessage(messageToSend as any);
			logChatPromiseExecution(sendMessagePromise, 'send message');
		}

		setGiphyState(false);
	};

	const messageInput = useMessageInputState({ ...(props as any), overrideSubmitHandler });

	const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
		event => {
			const { value } = event.target;

			const deletePressed = !!(
				event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType === 'deleteContentBackward'
			);

			if (messageInput.text.length === 1 && deletePressed) {
				setGiphyState(false);
			}

			if (!giphyState && messageInput.text.startsWith('/giphy') && !messageInput.numberOfUploads) {
				event.target.value = value.replace('/giphy', '');
				setGiphyState(true);
			}

			messageInput.handleChange(event);
		},
		[giphyState, messageInput]
	);

	const onEmojiSelect = (emoji: any) => insertText(emoji.native);

	const changeEmojiPickerOpenStatus = () => setIsEmojiPickerOpen(prevStatus => !prevStatus);

	return (
		<div className="str-chat__messaging-input">
			<div
				className="messaging-input__button emoji-button"
				role="button"
				aria-roledescription="button"
				aria-label="Emoji"
				onClick={changeEmojiPickerOpenStatus}
				ref={emojiPickerRef}
			>
				<EmojiIcon />
			</div>
			<ImageDropzone
				accept={acceptedFiles}
				handleFiles={messageInput.uploadNewFiles}
				multiple={multipleUploads}
				disabled={
					(maxNumberOfFiles !== undefined && messageInput.numberOfUploads >= maxNumberOfFiles) || giphyState
				}
			>
				<div className="messaging-input__input-wrapper">
					{giphyState && !messageInput.numberOfUploads && <GiphyIcon />}
					<UploadsPreview />
					<ChatAutoComplete
						handleSubmit={messageInput.handleSubmit}
						onChange={onChange}
						value={messageInput.text}
						rows={1}
						placeholder="Send a message"
						onPaste={messageInput.onPaste}
					/>
				</div>
			</ImageDropzone>
			<div
				className="messaging-input__button"
				role="button"
				aria-roledescription="button"
				aria-label="Send"
				onClick={messageInput.handleSubmit}
			>
				<SendIcon />
			</div>
			<CustomEmojiPicker
				onSelectEmoji={onEmojiSelect}
				emojiPickerRef={emojiPickerRef}
				isOpen={isEmojiPickerOpen}
				changeIsOpenStatus={changeEmojiPickerOpenStatus}
			/>
		</div>
	);
};

export default memo(MessagingInput);
